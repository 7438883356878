import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Auth0 {
    jwtTocken: string;
    idpCustomerId: string;
}

export const initialState: Auth0 = {
    jwtTocken: '',
    idpCustomerId: '',
};

const auth0Slice = createSlice({
    name: 'auth0',
    initialState,
    reducers: {
        setAuth0: (state, action: PayloadAction<Auth0>) => {
            return {
                ...state,
                ...action.payload,
            };
        },
    },
});

export const { actions, name } = auth0Slice;

export default auth0Slice.reducer;
