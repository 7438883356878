import { IDefaultModifier, ISelectedModifier } from '../../redux/types';

export interface IGetChangedModifiers {
    addedModifiers: ISelectedModifier[];
    removedDefaultModifiers: IDefaultModifier[];
    modifiersIsChanged: boolean;
}

export const getChangedModifiers = (
    selectedModifiers: ISelectedModifier[],
    defaultModifiers: IDefaultModifier[],
    defaultSubModiers?: IDefaultModifier[],
    includeQuantity?: boolean
): IGetChangedModifiers => {
    const addedModifiers =
        selectedModifiers?.reduce((acc, curr) => {
            const hideDefaultModifier = !defaultModifiers.find((item) => {
                return item.productId === curr.productId && !(includeQuantity && curr.quantity > item.defaultQuantity);
            });

            const showDefaultModifier = defaultModifiers.find((item) => {
                //show when sub modifiers have been added to the default modifier
                return item.productId === curr.productId && curr.modifiers?.length;
            });

            const addedSubModifiers =
                (defaultSubModiers &&
                    curr.modifiers?.filter(
                        (modifier) => !defaultSubModiers.find((item) => item.productId === modifier.productId)
                    )) ||
                [];

            const removedSubModifiers = ((curr.modifiers && defaultSubModiers) || []).filter(
                (defaultSubModier) =>
                    !curr.modifiers?.find((currModifier) => currModifier.productId === defaultSubModier.productId)
            );

            if (hideDefaultModifier || showDefaultModifier) {
                return [
                    ...acc,
                    {
                        ...curr,
                        modifiers: addedSubModifiers || [],
                        removedModifiers: removedSubModifiers,
                    },
                ];
            }
            return acc;
        }, []) || [];

    const removedDefaultModifiers =
        defaultModifiers?.reduce((acc, curr) => {
            if (curr.itemGroupId) {
                const hasIntensityChanged = selectedModifiers?.find((it) => it.itemGroupId === curr.itemGroupId);

                if (hasIntensityChanged) {
                    return acc;
                }
            }

            if (!selectedModifiers?.find((item) => item.productId === curr.productId)) {
                return [...acc, curr];
            }
            return acc;
        }, []) || [];

    return {
        addedModifiers,
        removedDefaultModifiers,
        modifiersIsChanged: !!removedDefaultModifiers?.length || !!addedModifiers?.length,
    };
};
