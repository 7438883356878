import {
    isFriday,
    isMonday,
    isSaturday,
    isSunday,
    isThursday,
    isTuesday,
    isWednesday,
    isWithinInterval,
    set,
    utcToZonedTime,
} from './dateTime';
import { CategoryModel, IIntervalModel } from '../../@generated/webExpApi/models';

export interface IValidityIntervals {
    [key: string]: IIntervalModel[];
}

interface ICategories {
    [key: string]: CategoryModel;
}

export const dayMap = {
    SUNDAY: isSunday,
    MONDAY: isMonday,
    TUESDAY: isTuesday,
    WEDNESDAY: isWednesday,
    THURSDAY: isThursday,
    FRIDAY: isFriday,
    SATURDAY: isSaturday,
};

export const getParsedInt = (int: string): number => parseInt(int);

export const checkIsValidDayOfWeek = (availableDays: string[], currentZonedDate: Date): boolean => {
    if (!availableDays.length) return true;
    return availableDays.map((day) => dayMap[day] && dayMap[day](currentZonedDate)).some(Boolean);
};

export const getCurrentZonedDate = (orderTime: string, timezone: string): Date => {
    const currentDate = orderTime ? new Date(orderTime) : new Date();
    return utcToZonedTime(currentDate, timezone);
};

export const checkIsValidDateAndTime = (validityIntervals: IValidityIntervals, currentZonedDate: Date): boolean => {
    if (!validityIntervals) return true;

    const { Date: date, Time: time } = validityIntervals;

    const validItemByDate = date.find(({ value, dayOfTheWeek }) => {
        const [startDate, endDate] = value.split('/');
        const availableDays = dayOfTheWeek?.split(',') || [];

        return (
            isWithinInterval(currentZonedDate, { start: new Date(startDate), end: new Date(endDate) }) &&
            checkIsValidDayOfWeek(availableDays, currentZonedDate)
        );
    });

    const validItemByTime = time.find(({ value, dayOfTheWeek }) => {
        const availableDays = dayOfTheWeek?.split(',') || [];
        const [startTime, endTime] = value.split('/');
        const [startTimeHour, startTimeMinutes] = startTime.split(':').map(getParsedInt);
        const [endTimeHour, endTimeMinutes] = endTime.split(':').map(getParsedInt);
        return (
            isWithinInterval(currentZonedDate, {
                start: set(currentZonedDate, { hours: startTimeHour, minutes: startTimeMinutes }),
                end: set(currentZonedDate, { hours: endTimeHour, minutes: endTimeMinutes }),
            }) && checkIsValidDayOfWeek(availableDays, currentZonedDate)
        );
    });

    return !!validItemByDate && !!validItemByTime;
};

export const getUnavailableDomainMenuCategories = (
    categories: ICategories,
    orderTime: string,
    timezone: string
): string[] => {
    const currentZonedDate = getCurrentZonedDate(orderTime, timezone);
    if (!categories) {
        return [];
    }
    const unavailableCategories = Object.values(categories)
        .filter((category) => {
            const validityIntervals = category?.availability?.validityIntervals;
            return !checkIsValidDateAndTime(validityIntervals, currentZonedDate);
        })
        .map((category) => category.id);

    return unavailableCategories;
};
