import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BeerMenuResponseModel } from '../@generated/webExpApi';
import { getBeerMenuByLocationId } from '../common/services/localTapList';
import { InspireThunk } from './helpers';

const SLICE_NAME = 'localTapList';

export interface ILocalTapListState {
    payload: BeerMenuResponseModel | null;
    loading: boolean;
    error: boolean;
}

export const initialState: ILocalTapListState = { payload: null, loading: false, error: false };

const getLocalTapList = createAsyncThunk(`${SLICE_NAME}/getLocalTapList`, async (locationId: string) => {
    return getBeerMenuByLocationId(locationId);
});

const setLocalTapListReducer = (_: ILocalTapListState, action: { payload: BeerMenuResponseModel | null }) => {
    const payload = action.payload;

    return {
        payload,
        loading: false,
        error: false,
    };
};

const localTapListSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setLocalTapList: setLocalTapListReducer,
    },
    extraReducers: (builder) => {
        InspireThunk(builder, getLocalTapList, setLocalTapListReducer);
    },
});

export const actions = { ...localTapListSlice.actions, getLocalTapList };

export default localTapListSlice.reducer;
