import React, { FunctionComponent, RefObject } from 'react';
import classnames from 'classnames';
import styles from './link.module.css';

export interface IInspireSimpleLinkProps {
    link?: string;
    isPhone?: boolean;
    text?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    className?: string;
    type?: 'primary' | 'secondary';
    gtmId?: string;
    newtab?: boolean;
    ariaLabel?: string;
    dataTestId?: string;
}

export const InspireSimpleLink: FunctionComponent<IInspireSimpleLinkProps> = React.forwardRef(
    (props, ref: RefObject<HTMLAnchorElement>) => {
        const {
            link,
            isPhone,
            text,
            type,
            onClick,
            children,
            className,
            gtmId,
            newtab,
            ariaLabel,
            dataTestId,
            ...restProps
        } = props;

        const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            onClick && onClick(event);
        };

        const linkClasses = classnames(styles.link, className, {
            [styles.linkPrimaryActive]: type === 'primary',
            [styles.linkSecondaryActive]: type === 'secondary',
        });

        return (
            <a
                ref={ref}
                onClick={handleClick}
                data-gtm-id={gtmId}
                className={linkClasses}
                href={isPhone ? `tel:${link}` : link}
                aria-label={ariaLabel}
                data-testid={dataTestId}
                {...(newtab && { target: '_blank', rel: 'noreferrer' })}
                {...restProps}
            >
                {children || text || link}
            </a>
        );
    }
);
