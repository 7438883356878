import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { isAccountOn } from '../../lib/getFeatureFlags';

export const useIDToken = (): { idToken: string } => {
    const { isAuthenticated, user, getIdTokenClaims } = useAuth0();
    const [idToken, setIdToken] = useState<string>('');

    const isAccountEnabled = isAccountOn();

    useEffect(() => {
        if (isAuthenticated && user && isAccountEnabled) {
            getIdTokenClaims().then((res) => {
                setIdToken(res?.__raw);
            });
        }
    }, [isAuthenticated, user, isAccountEnabled, getIdTokenClaims]);

    return { idToken };
};
