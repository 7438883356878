import { TServiceTypeModel } from '../../@generated/webExpApi';
import { PickupAddress } from '../../redux/orderLocation';
import { showIsLocationTemporarilyUnavailable } from '../getFeatureFlags';
import getLocationService from './getLocationService';

const isLocationOrderAheadAvailable = (pickupAddress: PickupAddress, isOAEnabled: boolean): boolean => {
    if (showIsLocationTemporarilyUnavailable() && pickupAddress?.additionalFeatures?.isOffline) {
        return false;
    }

    return (
        isOAEnabled &&
        !!pickupAddress?.isDigitallyEnabled &&
        !!getLocationService(pickupAddress, TServiceTypeModel.OrderAhead)
    );
};

export default isLocationOrderAheadAvailable;
