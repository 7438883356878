import { IInitialPaymentTypeSelectionOption, TInitialPaymentTypes, TCardOnFIle } from './types';

const PAYMENT_TYPE_PLACEHOLDER: IInitialPaymentTypeSelectionOption = {
    type: TInitialPaymentTypes.PLACEHOLDER,
    text: 'Select payment method',
    icon: 'info-card',
};

const PAYMENT_TYPE_PAY_IN_STORE: IInitialPaymentTypeSelectionOption = {
    type: TInitialPaymentTypes.PAY_IN_STORE,
    image: '/brands/inspire/pay_in_store_icon.svg',
    text: 'Pay In Store',
};

const PAYMENT_TYPE_CREDIT_OR_DEBIT: IInitialPaymentTypeSelectionOption = {
    type: TInitialPaymentTypes.CREDIT_OR_DEBIT,
    image: '/brands/inspire/credit_card_icon.svg',
    text: 'Credit or Debit card',
};

// The brand specific filtering done inside a component, so you don't need to remove not used types from here
export const ALL_INITIAL_PAYMENT_TYPES: { [k: string]: IInitialPaymentTypeSelectionOption } = {
    [TInitialPaymentTypes.PLACEHOLDER]: PAYMENT_TYPE_PLACEHOLDER,
    [TInitialPaymentTypes.PAY_IN_STORE]: PAYMENT_TYPE_PAY_IN_STORE,
    [TInitialPaymentTypes.CREDIT_OR_DEBIT]: PAYMENT_TYPE_CREDIT_OR_DEBIT,
    // according to current approach you should add an object instead of text
    /* VENMO: 'VENMO',
    PAYPAL: 'PAYPAL',
    APPLE_PAY: 'APPLE_PAY',
    GIFT_CARD: 'GIFT_CARD', */
};

export const CARD_ON_FILE: TCardOnFIle = 'CARD_ON_FILE';

export const CREDIT_CARD_IMG = '/brands/inspire/payment_icon.svg';
