import { GetLocationByIdRequest } from '../../../@generated/webExpApi/apis/WebExperienceApi';
import { WebExperienceApi } from '../../../@generated/webExpApi/apis/index';

import createErrorWrapper from '../createErrorWrapper';
import { LocationWithDetailsModel } from './types';

const expApi = new WebExperienceApi();

const getLocationByIdApi = createErrorWrapper<LocationWithDetailsModel, GetLocationByIdRequest>(
    'getLocationById',
    expApi.getLocationById.bind(expApi)
);

const getLocationById = (payload: GetLocationByIdRequest): Promise<LocationWithDetailsModel> => {
    const { locationId } = payload;

    return getLocationByIdApi({
        locationId,
    });
};

export default getLocationById;
