import React, { FC } from 'react';
import { isPersonalizationEnabled } from '../lib/getFeatureFlags';
import { usePersonalizationFactsChangeListener } from '../redux/hooks/usePersonalization';

export const PersonalizationProvider: FC = ({ children }) => (
    <>
        {isPersonalizationEnabled() ? (
            <PersonalizationFactsChangeListener>{children}</PersonalizationFactsChangeListener>
        ) : (
            children
        )}
    </>
);

export const PersonalizationFactsChangeListener: FC = ({ children }) => {
    usePersonalizationFactsChangeListener();

    return <>{children}</>;
};
