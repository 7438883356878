import getBrandInfo from '../../lib/brandInfo';
import { getRelatedProduct } from '../../lib/domainProduct';
import { selectProductSize } from '../../redux/selectors/domainMenu';
import { RootState } from '../../redux/store';
import { getPrice } from '../services/gtmService/gtmService';
import {
    GtmActionFields,
    GtmEventNames,
    GtmTransactionCompleteData,
    GtmTransactionCompleteEventBrandsData,
} from '../services/gtmService/types';
import { categoryNameModify } from './categoryNameModify';

/**
 * Helper method to return gtm data for transactionComplete event for all brands without Sonic brand
 *
 * @function getGtmTransactionCompleteData
 * @param {GtmTransactionCompleteData} data
 * @param {RootState} state
 * @returns {GtmTransactionCompleteEventBrandsData} gtm event data
 */
const getGtmTransactionCompleteData = (
    data: GtmTransactionCompleteData,
    state: RootState
): GtmTransactionCompleteEventBrandsData => {
    const { tallyOrder, request, orderId, productsById, domainProducts, bagEntries, productsByProductId } = data || {};
    const { tax, total, sellingChannel } = tallyOrder || {};
    const brandId = getBrandInfo().brandId;
    const entries = bagEntries || [];

    const gtmData = {
        [GtmActionFields.Purchase]: {
            actionField: {
                id: orderId,
                affiliation: sellingChannel,
                revenue: total,
                tip: request.orderData.driverTip || request.orderData.serverTip || '',
                shipping: tallyOrder.fees.find((fee) => fee.type === 'DELIVERY')?.amount || '',
                service: tallyOrder.fees.find((fee) => fee.type === 'SERVICE')?.amount || '',
                tax,
            },
            products: entries.map(({ productId, price, quantity, childItems }) => {
                const category = entries.find((curr) => curr?.productId === productId)?.category;

                if (childItems) {
                    const { sideFood, sideDrink } = childItems.slice(1).reduce(
                        (acc, curr) => {
                            if (productsByProductId[curr.productId]?.tags?.['PRODUCT'] === 'DRINK') {
                                return { ...acc, sideDrink: curr.name };
                            }

                            return { ...acc, sideFood: curr.name };
                        },
                        { sideFood: '', sideDrink: '' }
                    );
                    return {
                        id: productId,
                        price: getPrice(childItems, price),
                        brand: brandId,
                        quantity,
                        name:
                            productsById[productId]?.fields?.name ||
                            getRelatedProduct(domainProducts[productId], productsById)?.fields?.name,
                        category: categoryNameModify(category),
                        sideFood,
                        sideDrink,
                        comboSize: selectProductSize(state, productId),
                    };
                } else {
                    return {
                        id: productId,
                        price: getPrice(childItems, price),
                        brand: brandId,
                        quantity,
                        name:
                            productsById[productId]?.fields?.name ||
                            getRelatedProduct(domainProducts[productId], productsById)?.fields?.name,
                        category: categoryNameModify(category),
                        sideFood: 'none',
                        sideDrink: 'none',
                    };
                }
            }),
        },
    };

    return { event: GtmEventNames.TransactionComplete, gtmData };
};

export default getGtmTransactionCompleteData;
