import {
    ITallyError500ExternalResponseModel,
    TallyRequest,
    TallyResponseModel,
    WebExperienceApi,
} from '../../../@generated/webExpApi';
import createErrorWrapper from '../createErrorWrapper';

class TallyService {
    validate: (request: TallyRequest) => Promise<TallyResponseModel | ITallyError500ExternalResponseModel>;

    constructor() {
        const expApi = new WebExperienceApi();

        this.validate = createErrorWrapper<TallyResponseModel, TallyRequest>('tallyOrder', expApi.tally.bind(expApi));
    }
}

export default new TallyService();
