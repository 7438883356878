import { Dictionary } from '@reduxjs/toolkit';

import {
    IAvailableTimesModel,
    IRewardApplicabilityModel,
    IRewardsDiscountItemDSResponseModel,
    ItemModel,
    DiscountDetailsTypeModel,
    TallyModifierGroupModel,
    TallyModifierModel,
    TallyProductModel,
} from '../../../@generated/webExpApi';

import { PDPTallyItem } from '../../../redux/pdp';
import { LocationWithDetailsModel } from '../../services/locationService/types';
import { getDiscountPrice, getDiscountType, getTallyPriceType } from '../discountHelper';
import { WritableDraft } from 'immer/dist/internal';
import { BagState, ProductsDiscountsItemPayload } from '../../../redux/bag';
import { MAX_PRODUCT_QUANTITY } from '../../constants/bag';
import { IBagRenderItem } from '../../../components/organisms/bag/bagModel';
import { sortObject } from '../sortObject';
import { MainDealsTypesByStructure } from '../dealHelper';
import { checkProductIsAvailable } from '../checkProductIsAvailable';

function getTallyBagItemProductIds(bagEntry: TallyProductModel): string[] {
    let productIds = [bagEntry.productId];

    if (bagEntry.modifierGroups) {
        productIds = productIds.concat(
            bagEntry.modifierGroups
                .flatMap((group) => group.modifiers)
                .filter(Boolean)
                .map((modifier) => modifier.productId)
        );
    }

    if (bagEntry.childItems) {
        bagEntry.childItems.forEach(
            (childItem) => (productIds = productIds.concat(getTallyBagItemProductIds(childItem)))
        );
    }

    return productIds;
}

export const getTallyBagProductIds = (bagEntries: TallyProductModel[] = []): string[] => {
    return bagEntries.reduce((productIds, bagEntry) => productIds.concat(getTallyBagItemProductIds(bagEntry)), []);
};

const getTallyBagModifier = (
    modifier: TallyModifierModel,
    domainProducts: Dictionary<ItemModel>,
    isIncludeSourceProductId?: boolean
): TallyModifierModel => ({
    productId: modifier.productId,
    quantity: modifier.quantity,
    price: modifier.price, // getCheapestPriceFromProduct(domainProducts[modifier.productId], location), keep like a tip
    modifierGroups: modifier.modifierGroups,
    sizeGroupId: modifier.sizeGroupId,
    sourceProductId: isIncludeSourceProductId
        ? domainProducts[modifier.productId]?.metadata?.SOURCE_PRODUCT_ID
        : undefined,
});

function getTallyBagEntry(
    bagEntry: TallyProductModel,
    domainProducts: Dictionary<ItemModel>,
    location?: LocationWithDetailsModel,
    date?: Date,
    isIncludeSourceProductId?: boolean
): TallyProductModel {
    const { lineItemId, productId, quantity, ssRecommendationId } = bagEntry;
    const discountPrice = getDiscountPrice(domainProducts[productId], location, date);
    const discountType = getDiscountType(domainProducts[productId], location, date);

    const tallyItem: TallyProductModel = {
        lineItemId,
        productId,
        quantity,
        price: discountPrice !== null ? discountPrice : domainProducts[productId]?.price?.currentPrice || 0,
        priceType: getTallyPriceType(discountType),
        ssRecommendationId,
        // price: getCheapestPriceFromProduct(domainProducts[productId], location), // actual price from current domain products, keep like a tip
    };

    if (isIncludeSourceProductId) {
        tallyItem.sourceProductId = domainProducts[productId]?.metadata?.SOURCE_PRODUCT_ID;
    }

    if (bagEntry.childItems) {
        tallyItem.childItems = bagEntry.childItems.map((childItem) => {
            const item = getTallyBagEntry(childItem, domainProducts);

            item.price = childItem.price;

            return item;
        });
    }

    if (bagEntry.modifierGroups) {
        tallyItem.modifierGroups = bagEntry.modifierGroups.map((group) => ({
            productId: group?.productId,
            modifiers: group?.modifiers?.map((modifier) =>
                getTallyBagModifier(modifier, domainProducts, isIncludeSourceProductId)
            ),
        }));
    }

    return tallyItem;
}

export const getTallyBagEntries = (
    bagEntries: TallyProductModel[],
    domainProducts: Dictionary<ItemModel>,
    location?: LocationWithDetailsModel,
    date?: Date,
    isIncludeSourceProductId?: boolean
): TallyProductModel[] => {
    return bagEntries.map((bagEntry) =>
        getTallyBagEntry(bagEntry, domainProducts, location, date, isIncludeSourceProductId)
    );
};

export function getBagItemSizeLabel(size?: string): string {
    if (!size || size.toLocaleLowerCase() === 'none') return '';

    switch (size) {
        case 'Small':
            return 'S';
        case 'Medium':
            return 'M';
        case 'Large':
            return 'L';
        default:
            return size;
    }
}

export const getAvailableEntries = (
    bagEntries: TallyProductModel[],
    domainProducts: Dictionary<ItemModel>,
    unavailableCategories?: string[]
): TallyProductModel[] => {
    return bagEntries.filter((entry) => {
        const categoryIds = domainProducts?.[entry.productId]?.categoryIds;
        const isAvailable = checkProductIsAvailable(domainProducts?.[entry.productId]);
        return isAvailable && !checkIsUnavailableProductByCategories(categoryIds, unavailableCategories);
    });
};

export const areAllProductsAvailable = (
    bagEntries: TallyProductModel[],
    domainProducts: Dictionary<ItemModel>,
    unavailableCategories: string[]
): boolean => {
    return bagEntries.every((entry) => {
        const categoryIds = domainProducts?.[entry.productId]?.categoryIds;
        const isAvailable = checkProductIsAvailable(domainProducts?.[entry.productId]);
        return isAvailable && !checkIsUnavailableProductByCategories(categoryIds, unavailableCategories);
    });
};

const modifiersSorting = (a: TallyModifierModel, b: TallyModifierModel) => a.productId.localeCompare(b.productId);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeUndefinedValues = (obj: { [key: string]: any }): { [key: string]: any } => JSON.parse(JSON.stringify(obj));
const prepareForComparsion = (modifier: TallyModifierModel): TallyModifierModel => ({
    productId: modifier.productId,
    price: modifier.price,
    quantity: modifier.quantity,
    modifierGroups:
        modifier.modifierGroups?.map((modifierGroup) => ({
            productId: modifierGroup.productId,
            modifiers: modifierGroup.modifiers?.map(prepareForComparsion),
        })) || [],
});

export const compareModifierGroups = (a: TallyModifierGroupModel[], b: TallyModifierGroupModel[]): boolean => {
    return a.every((left, index) => {
        const right = b[index];

        if (left.productId !== right.productId || (left.modifiers || []).length !== (right.modifiers || []).length) {
            return false;
        }

        const leftModifiers = (left.modifiers || [])
            .map(prepareForComparsion)
            .map(removeUndefinedValues)
            .sort(modifiersSorting)
            .map(sortObject);

        const rightModifiers = (right.modifiers || [])
            .map(prepareForComparsion)
            .map(removeUndefinedValues)
            .sort(modifiersSorting)
            .map(sortObject);

        return JSON.stringify(leftModifiers) === JSON.stringify(rightModifiers);
    });
};

const getNonEmptyModifierGroups = (modifierGroups: TallyModifierGroupModel[]) =>
    modifierGroups.filter((mg) => mg.modifiers.length > 0);

export const compareTallyItems = (
    a: TallyProductModel | PDPTallyItem,
    b: TallyProductModel | PDPTallyItem
): boolean => {
    const aModifierGroups = a.modifierGroups ? getNonEmptyModifierGroups(a.modifierGroups) : [];
    const bModifierGroups = b.modifierGroups ? getNonEmptyModifierGroups(b.modifierGroups) : [];

    if (
        a.productId !== b.productId ||
        aModifierGroups.length !== bModifierGroups.length ||
        (a.childItems || []).length !== (b.childItems || []).length
    ) {
        return false;
    }

    const isModifierGroupsEqual = compareModifierGroups(aModifierGroups, bModifierGroups);

    // @ts-ignore - every doesn't work well with union types in ts 4+
    const isChildItemsEqual = (a.childItems || []).every(
        (tallyItem, index) => b.childItems[index] && compareTallyItems(tallyItem, b.childItems[index])
    );

    return isModifierGroupsEqual && isChildItemsEqual;
};

export const findItemIndexInBag = (bagEntries: TallyProductModel[], item: TallyProductModel | PDPTallyItem): number => {
    return bagEntries.findIndex((bagEntry) => compareTallyItems(bagEntry, item));
};

export const updateStateBagItemCount = (state: WritableDraft<BagState>, bagEntryIndex: number, value: number): void => {
    if (state.LineItems[bagEntryIndex].quantity + value <= MAX_PRODUCT_QUANTITY) {
        state.LineItems[bagEntryIndex].quantity += value;
    } else {
        state.LineItems[bagEntryIndex].quantity = MAX_PRODUCT_QUANTITY;
    }
};

export function checkIsUnavailableProductByCategories(
    productCategoryIds?: string[],
    unavailableCategories?: string[]
): boolean {
    if (!(productCategoryIds && unavailableCategories)) return false;
    return productCategoryIds
        .map((category) => unavailableCategories.includes(category))
        .reduce((cur, prev) => cur && prev);
}

export const removeUnavailableModifiers = (
    tallyItem: TallyProductModel,
    unavailableModifiers: string[],
    unavailableSubModifiers: string[]
): TallyProductModel => {
    return {
        ...tallyItem,
        modifierGroups: tallyItem.modifierGroups.map((modifierGroupsItem) => {
            return {
                ...modifierGroupsItem,
                modifiers: modifierGroupsItem.modifiers
                    .filter((modifier) => !unavailableModifiers?.includes(modifier.productId))
                    .map((modifier) => {
                        return {
                            ...modifier,
                            ...(modifier?.modifierGroups && {
                                modifierGroups: modifier?.modifierGroups.map((modifierGroupsItem) => {
                                    return {
                                        ...modifierGroupsItem,
                                        modifiers: modifierGroupsItem.modifiers.filter(
                                            (modifier) => !unavailableSubModifiers?.includes(modifier.productId)
                                        ),
                                    };
                                }),
                            }),
                        };
                    }),
            };
        }),
    };
};

export const getFirstTimeValue = (availableTimeSlots: IAvailableTimesModel): string => {
    const byDay = Object.entries(availableTimeSlots?.byDay || {}).map(([_, timeRanges]) => ({
        timeRange: timeRanges.map((time) => `${time.utc}`),
    }));

    return byDay.length > 0 && byDay[0].timeRange.length > 0 ? byDay[0].timeRange[0] : null;
};

export const findDuplicatedItemAndCollapse = (
    resultLineItems: TallyProductModel[],
    currentItem: TallyProductModel,
    duplicatedItemIndex: number,
    discountedItem?: TallyProductModel
) => {
    const result = [
        ...resultLineItems.slice(0, duplicatedItemIndex),
        {
            ...resultLineItems[duplicatedItemIndex],
            quantity: resultLineItems[duplicatedItemIndex].quantity + currentItem.quantity,
        },
        ...resultLineItems.slice(duplicatedItemIndex + 1),
    ];

    if (discountedItem) {
        return [discountedItem, ...result];
    }

    return result;
};

export const mapDiscountItemResponse = (item: IRewardsDiscountItemDSResponseModel): ProductsDiscountsItemPayload => {
    return {
        discountableQuantity: item.discountableQuantity,
        lineItemId: item.lineItemId,
        productId: item.productId,
        quantity: item.quantity,
        childItems: item?.childItems?.map(mapDiscountItemResponse) || [],
    };
};

export const getBagEntriesWithoutCondiments = (
    bagEntries: IBagRenderItem[] = [],
    isCondiment: (productId: string) => boolean,
    markedAsRemovedLineItemsIds: number[] = []
) => {
    return bagEntries.filter(
        ({ entry, isAvailable }) =>
            !isCondiment(entry?.productId) && isAvailable && !markedAsRemovedLineItemsIds.includes(entry?.lineItemId)
    );
};

/**
 * Method to find correct deal and apply it to render item
 * @method setAppliedDealForRenderItem
 * @param {IBagRenderItem} renderItem render item to apply deal
 * @returns {IBagRenderItem} render item with applied deal
 * @example
 * setAppliedDealForRenderItem({..., discounts: [{..., type: 'OFFER_REWARD'}, {..., type: 'AUTO_DISCOUNT'}]})
 */
export const setAppliedDealForRenderItem = (renderItem: IBagRenderItem): IBagRenderItem => {
    const discount = renderItem.entry?.discounts?.find(
        (discount) =>
            discount.type === DiscountDetailsTypeModel.OfferReward ||
            discount.type === DiscountDetailsTypeModel.PromoCode
    );

    if (discount) {
        return {
            ...renderItem,
            entry: {
                ...renderItem.entry,
                discounts: [discount],
            },
        };
    }

    return renderItem;
};

/**
 * Method to find item in bag applicable to deal
 * @method getDiscountedBagItem
 * @param {MainDealsTypesByStructure} dealType type of deal
 * @param {IBagRenderItem[]} renderItems items to render on bag
 * @param {IRewardApplicabilityModel} applicability ids that applicable to current deal
 * @returns {IBagRenderItem} render item with applied deal
 * @example
 * getDiscountedBagItem('BUY_X', {..., entry: {..., productId: '123'}}, {eligibleIds: [{menuId: '123'}]}})
 */
export const getDiscountedBagItem = (
    dealType: MainDealsTypesByStructure,
    renderItems: IBagRenderItem[],
    applicability: IRewardApplicabilityModel
) => {
    const applicableIds =
        dealType === MainDealsTypesByStructure.BuyX
            ? applicability.eligibleIds
            : applicability.getIds.flatMap((getId) => [...getId.menuIds]);

    return renderItems
        .filter((item) => !item.markedAsRemoved)
        .find((item) => applicableIds.some((menuIdItem) => menuIdItem.menuId === item.entry.productId));
};
