import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationPickupAndDeliveryResponseModel } from '../@generated/webExpApi';
import { IDeliveryAddressObject, LocationWithDetailsModel } from '../common/services/locationService/types';

export type DeliveryAddress = IDeliveryAddressObject;
export type PickupAddress = LocationWithDetailsModel;
export type LocationAvailableTimes = LocationPickupAndDeliveryResponseModel;
export type PreviousLocation = PickupAddress | DeliveryAddress | null;

export enum OrderLocationMethod {
    NOT_SELECTED = 'NOT_SELECTED',
    PICKUP = 'PICKUP',
    DELIVERY = 'DELIVERY',
}
export interface OrderLocationState {
    method: OrderLocationMethod;
    deliveryAddress?: DeliveryAddress;
    pickupAddress?: PickupAddress;
    pickupLocationTimeSlots?: LocationAvailableTimes;
    deliveryLocationTimeSlots?: LocationAvailableTimes;
    previousLocation?: PreviousLocation;
}

export const initialState: OrderLocationState = {
    method: OrderLocationMethod.NOT_SELECTED,
};

const orderLocationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setPickupLocation: (state, action: PayloadAction<PickupAddress>) => {
            return {
                method: OrderLocationMethod.PICKUP,
                pickupAddress: action.payload,
                deliveryAddress: state.deliveryAddress || null,
                pickupLocationTimeSlots: state.pickupLocationTimeSlots || null,
                deliveryLocationTimeSlots: state.deliveryLocationTimeSlots || null,
                previousLocation: state.previousLocation || null,
            };
        },
        setDeliveryLocation: (state, action: PayloadAction<DeliveryAddress>) => {
            return {
                method: OrderLocationMethod.DELIVERY,
                deliveryAddress: action.payload,
                pickupAddress: state.pickupAddress || null,
                pickupLocationTimeSlots: state.pickupLocationTimeSlots || null,
                deliveryLocationTimeSlots: state.deliveryLocationTimeSlots || null,
                previousLocation: state.previousLocation || null,
            };
        },
        flushSelectedLocation: () => {
            return {
                method: OrderLocationMethod.NOT_SELECTED,
                deliveryAddress: null,
                pickupAddress: null,
                pickupLocationTimeSlots: null,
                deliveryLocationTimeSlots: null,
                previousLocation: null,
            };
        },
        flushDeliveryLocation: (state) => {
            if (state.method === OrderLocationMethod.PICKUP) {
                return {
                    method: OrderLocationMethod.PICKUP,
                    deliveryAddress: null,
                    pickupAddress: state.pickupAddress || null,
                    pickupLocationTimeSlots: state.pickupLocationTimeSlots || null,
                    deliveryLocationTimeSlots: null,
                    previousLocation: state.previousLocation || null,
                };
            }

            if (state.pickupAddress && state.pickupLocationTimeSlots) {
                return {
                    method: OrderLocationMethod.PICKUP,
                    deliveryAddress: null,
                    pickupAddress: state.pickupAddress,
                    pickupLocationTimeSlots: state.pickupLocationTimeSlots,
                    deliveryLocationTimeSlots: null,
                    previousLocation: state.previousLocation || null,
                };
            }

            return {
                method: OrderLocationMethod.NOT_SELECTED,
                deliveryAddress: null,
                pickupAddress: null,
                pickupLocationTimeSlots: null,
                deliveryLocationTimeSlots: null,
                previousLocation: null,
            };
        },
        setPickupLocationAvailableTimeSlots: (state, action: PayloadAction<LocationAvailableTimes>) => {
            return {
                method: state.method,
                deliveryAddress: state.deliveryAddress || null,
                pickupAddress: state.pickupAddress || null,
                pickupLocationTimeSlots: action.payload || null,
                deliveryLocationTimeSlots: state.deliveryLocationTimeSlots || null,
                previousLocation: state.previousLocation || null,
            };
        },
        setDeliveryLocationAvailableTimeSlots: (state, action: PayloadAction<LocationAvailableTimes>) => {
            return {
                method: state.method,
                deliveryAddress: state.deliveryAddress || null,
                pickupAddress: state.pickupAddress || null,
                deliveryLocationTimeSlots: action.payload || null,
                pickupLocationTimeSlots: state.pickupLocationTimeSlots || null,
                previousLocation: state.previousLocation || null,
            };
        },
        setMethod: (state, action: PayloadAction<OrderLocationMethod>) => {
            state.method = action.payload;
            return state;
        },
        setPreviousLocation: (state, action: PayloadAction<PreviousLocation>) => {
            state.previousLocation = action.payload;
            return state;
        },
        updatePickupLocation: (state, action: PayloadAction<PickupAddress>) => {
            return {
                ...state,
                pickupAddress: action.payload,
            };
        },
        updateDeliveryLocation: (state, action: PayloadAction<DeliveryAddress>) => {
            return {
                ...state,
                deliveryAddress: action.payload,
            };
        },
    },
});
export const { actions } = orderLocationSlice;
export default orderLocationSlice.reducer;
