import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useDomainMenu, useOrderLocation } from '../../../redux/hooks';
import { InspireButton } from '../../atoms/button';
import ContentfulImage from '../../atoms/ContentfulImage';
import { Divider } from '../../atoms/divider';
import { InspireLinkButton } from '../../atoms/link';
import { UnavailableModal } from '../../molecules/unavailableModal/unavailableModal';
import { ILocationWithDetailsModel } from '../../../@generated/webExpApi/models';
import { DESCRIPTION, FOOTER_TEXT, TITLE } from './constants';
import { OrderLocationMethod } from '../../../redux/orderLocation';
import { IDeliveryAddressObject } from '../../../common/services/locationService/types';
import { useBagRenderItems } from '../../../common/hooks/useBagRenderItems';
import { useScreen } from '../../../common/hooks/useScreen';
import styles from './unavailableItemsByLocationModal.module.css';

export const UnavailableItemsByLocationModal: FC = () => {
    const { isDesktop } = useScreen();
    // eslint-disable-next-line testing-library/render-result-naming-convention
    const renderItems = useBagRenderItems();
    const {
        isCurrentLocationOAAvailable: isOnlineOrderAvailable,
        previousLocation,
        actions: { setPreviousLocation, setPickupLocation, setDeliveryLocation },
    } = useOrderLocation();

    const {
        loading,
        actions: { getDomainMenu },
    } = useDomainMenu();

    const [open, setOpen] = useState(false);

    const unavailableBagItems = isOnlineOrderAvailable ? renderItems.filter((item) => !item.isAvailable) : renderItems;

    useEffect(() => {
        if (!unavailableBagItems.length) {
            setPreviousLocation(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unavailableBagItems.length]);

    useEffect(() => {
        if (previousLocation && unavailableBagItems.length && !loading) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [loading, unavailableBagItems.length, previousLocation]);

    const handleCloseModal = () => {
        setOpen(false);
        setPreviousLocation(null);
    };

    const isDeliveryLocation = (
        address: ILocationWithDetailsModel | IDeliveryAddressObject
    ): address is IDeliveryAddressObject => {
        return 'deliveryLocation' in address;
    };

    const onKeepCurrentLocation = () => {
        if (isDeliveryLocation(previousLocation)) {
            setDeliveryLocation(previousLocation);
            getDomainMenu(previousLocation.locationDetails, OrderLocationMethod.DELIVERY);
        } else {
            setPickupLocation(previousLocation);
            getDomainMenu(previousLocation);
        }
        setPreviousLocation(null);
        setOpen(false);
    };

    const renderButtons = () => (
        <div className={styles.buttonsBlock}>
            <InspireButton
                onClick={handleCloseModal}
                size="small"
                text={isDesktop ? 'Yes, proceed to new location' : 'Yes, select new'}
                className={styles.button}
                fullWidth={!isDesktop}
            />
            {isDesktop ? (
                <InspireLinkButton linkType="secondary" className={styles.buttonLink} onClick={onKeepCurrentLocation}>
                    No, keep current location
                </InspireLinkButton>
            ) : (
                <>
                    <Divider className={styles.divider} />
                    <InspireButton
                        onClick={onKeepCurrentLocation}
                        size="small"
                        text="No, keep current"
                        className={styles.button}
                        fullWidth
                    />
                </>
            )}
        </div>
    );

    return (
        <UnavailableModal
            open={open}
            title={TITLE}
            description={DESCRIPTION}
            footerText={FOOTER_TEXT}
            onClose={handleCloseModal}
            renderButtons={renderButtons}
        >
            {unavailableBagItems.map(({ entry, contentfulProduct }) => (
                <div key={entry.lineItemId} className={styles.simpleCard}>
                    <ContentfulImage className={styles.simpleCardImage} asset={contentfulProduct?.fields?.image} />
                    <div className={classnames('t-subheader-smaller', styles.simpleCardInfo)}>
                        {`${entry.quantity > 1 ? entry.quantity + 'X ' : ''}${contentfulProduct?.fields?.name}`}
                        <div className={classnames('t-paragraph-hint', styles.notAvailable)}>Not Available</div>
                    </div>
                </div>
            ))}
        </UnavailableModal>
    );
};
