export const PROFILE = 'PROFILE';
export const DEALS = 'DEALS';
export const REWARDS = 'REWARDS';
export const ACTIVITY = 'ACTIVITY';
export const ORDERS = 'ORDERS';
export const MY_TEAMS = 'MY_TEAMS';

export interface IAccountNavLink {
    name: string;
    link: string;
    exact?: boolean;
    count?: number;
    type?: string;
    trackingId?: string;
}
