import { Asset } from 'contentful';
import React, { ImgHTMLAttributes } from 'react';
import ContentfulImage from '../ContentfulImage';
import styles from './contentfulBackgroundImageContainer.module.css';
import classnames from 'classnames';

interface IContentfulBackgroundImageContainer {
    asset?: Asset;
    imageUrl?: string;
    children?: React.ReactElement | React.ReactElement[];
    backgroundColor?: string;
    backgroundClassName?: string;
    containerClassName?: string;
    role?: string;
    dataGtmId?: string;
    objectFitClassName?: string;
    maxWidth?: number;
    altText?: string;
    assetLoadingType?: ImgHTMLAttributes<HTMLImageElement>['loading'];
}

function ContentfulBackgroundImageContainer(props: IContentfulBackgroundImageContainer): JSX.Element {
    const {
        asset,
        assetLoadingType = 'lazy',
        backgroundColor,
        role,
        dataGtmId,
        containerClassName,
        backgroundClassName,
        children,
        objectFitClassName,
        maxWidth,
        imageUrl,
    } = props;

    return (
        <div className={classnames(styles.container, containerClassName)} role={role} data-gtm-id={dataGtmId}>
            {backgroundColor ? (
                <div className={classnames(styles.background, backgroundClassName)} style={{ backgroundColor }}></div>
            ) : undefined}
            {asset || imageUrl ? (
                <ContentfulImage
                    asset={asset}
                    imageUrl={imageUrl}
                    loading={assetLoadingType}
                    className={classnames(styles.background, backgroundClassName)}
                    objectFitClass={objectFitClassName ? objectFitClassName : styles.backgroundImageObjectFit}
                    maxWidth={maxWidth}
                    imageAlt={''}
                />
            ) : undefined}
            {children ? <>{children}</> : undefined}
        </div>
    );
}

export default ContentfulBackgroundImageContainer;
