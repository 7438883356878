import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { RootState, useAppDispatch } from '../store';
import * as SelectedSellStore from '../selectedSell';

import { Dictionary, PayloadAction } from '@reduxjs/toolkit';
import fetchRecommendedProductsApi from '../../common/services/recommendationService';
import { actions } from '../selectedSell';
import {
    ItemModel,
    ProductsRecommendationRequestModel,
    ProductsRecommendationResponseModel,
} from '../../@generated/webExpApi';
import { useDomainProducts } from './domainMenu';
import { getCookieValue } from '../../common/helpers/cookieHelper';

export interface UseSelectedSellHook {
    correlationId: string;
    recommendationId: string;
    recommendedProducts: Dictionary<ItemModel>;
    recommendedProductIds: Array<string>;
    actions: {
        setCorrelationId: () => PayloadAction<string>;
        fetchRecommendedProducts: (
            recommendationPayload: ProductsRecommendationRequestModelWithoutCustomerType
        ) => Promise<PayloadAction<ProductsRecommendationResponseModel>>;
    };
}

export type ProductsRecommendationRequestModelWithoutCustomerType = Omit<
    ProductsRecommendationRequestModel,
    'customerType'
>;

export default function UseSelectedSell(): UseSelectedSellHook {
    const dispatch = useAppDispatch();

    const correlationId = useSelector<RootState, string>((state) => state.selectedSell.correlationId);

    const setCorrelationId = () => dispatch(SelectedSellStore.actions.setCorrelationId(uuid()));

    const recommendationId = useSelector<RootState, string>((state) => state.selectedSell.recommendationId);
    const recommendedProductIds = useSelector<RootState, string[]>((state) => state.selectedSell.recommendedProducts);
    const recommendedProducts = useDomainProducts(recommendedProductIds);

    const fetchRecommendedProducts = (
        recommendationPayload: ProductsRecommendationRequestModelWithoutCustomerType
    ): Promise<PayloadAction<ProductsRecommendationResponseModel>> => {
        const customerTypeCookie = getCookieValue('customerType');
        if (customerTypeCookie) {
            const recommndationPayloadWithCustomerType: ProductsRecommendationRequestModel = {
                customerType: customerTypeCookie,
                ...recommendationPayload,
            };
            return fetchRecommendedProductsApi(recommndationPayloadWithCustomerType)
                .then((response) => {
                    return dispatch(actions.fetchRecommendedItemsSuccess(response));
                })
                .catch(() => {
                    return dispatch(actions.fetchRecommendedItemsFailure());
                });
        }
    };

    return {
        correlationId,
        recommendationId,
        recommendedProducts,
        recommendedProductIds,
        actions: {
            setCorrelationId,
            fetchRecommendedProducts,
        },
    };
}
