import { ItemModifierGroupModel } from '../../../@generated/webExpApi';

export const getDefaultChild = (group: ItemModifierGroupModel) => {
    const { defaultItemModifierId } = group;

    if (group[defaultItemModifierId]) {
        return group[defaultItemModifierId];
    }

    // hack for situation when no defaultQuantity =1 case data issue
    const modifiersArray = Object.values(group.itemModifiers);

    return modifiersArray.find((item) => item.defaultQuantity === 1) || modifiersArray[0];
};
