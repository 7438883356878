import {
    DiscountRequestModel,
    ItemModel,
    TallyFulfillmentTypeModel,
    TallyModifierGroupModel,
    TallyProductModel,
    TallyRequestDeliveryAddressModel,
    TallyRequestModel,
} from '../../../@generated/webExpApi';
import { IDeliveryLocation } from '../../services/locationService/types';
import { IProducts } from '../../../redux/hooks/domainMenu';

export const addLineItemId = (product, index): TallyProductModel => {
    const entryLineItemId = index + 1;
    const childItems =
        Array.isArray(product.childItems) &&
        product.childItems.map((entry, index) => addLineItemId(entry, entryLineItemId * 100 + index));

    return {
        ...product,
        lineItemId: entryLineItemId,
        ...(childItems && { childItems }),
    };
};

interface ICreateTallyRequestOptions {
    products: TallyProductModel[];
    locationId: string;
    fulfillmentType: TallyFulfillmentTypeModel;
    orderTime?: Date;
    discounts?: DiscountRequestModel;
    customerId?: string;
    ssCorrelationId?: string;
    deliveryLocation?: IDeliveryLocation;
}

export const getDeliveryAddress = (deliveryLocation: IDeliveryLocation): TallyRequestDeliveryAddressModel => {
    const { addressLine1, addressLine2, city, state, zipCode } = deliveryLocation;
    const countryCode = 'US';
    return {
        line1: addressLine1,
        line2: addressLine2,
        city: city,
        stateProvinceCode: state,
        postalCode: zipCode,
        countryCode,
    };
};

export const createTallyRequest = ({
    products,
    locationId,
    fulfillmentType,
    orderTime,
    discounts,
    customerId,
    ssCorrelationId,
    deliveryLocation,
}: ICreateTallyRequestOptions): TallyRequestModel => {
    const tallyRequest: TallyRequestModel = {
        locationId,
        fulfillmentType,
        ...(orderTime && { requestedDateTime: orderTime }),
        isAsap: !orderTime,
        products,
        ssCorrelationId,
    };

    if (discounts) {
        tallyRequest.discounts = discounts;
    }

    if (customerId) {
        tallyRequest.customerId = customerId;
    }
    if (deliveryLocation) {
        tallyRequest.deliveryAddress = getDeliveryAddress(deliveryLocation);
    }

    return tallyRequest;
};

export const setTallyProductWithModifiers = (tallyProducts: TallyProductModel[], products: IProducts) => {
    const getMappedModifierGroups = (
        modifierGroups: TallyModifierGroupModel[],
        domainProduct: ItemModel,
        isParentModifierGroupFree?
    ) => {
        return modifierGroups?.map((modifierGroup) => {
            const domainGroup = domainProduct?.itemModifierGroups?.find(
                (itemModifierGroup) => itemModifierGroup.productGroupId === modifierGroup.productId
            );

            const isGroupWithFreeModifiers = !!domainGroup?.freeQuantity;

            if (!(isGroupWithFreeModifiers || isParentModifierGroupFree)) {
                return modifierGroup;
            }

            const modifiers = modifierGroup.modifiers.map((modifier) => {
                const subModifierGroups = getMappedModifierGroups(modifier.modifierGroups, domainProduct, true);
                return {
                    ...modifier,
                    ...(subModifierGroups && { modifierGroups: subModifierGroups }),
                    price: 0,
                };
            });

            return {
                ...modifierGroup,
                modifiers,
            };
        });
    };

    return tallyProducts.map((tallyProduct) => {
        const domainProduct = products[tallyProduct.productId];
        const modifierGroups = getMappedModifierGroups(tallyProduct.modifierGroups, domainProduct);
        return {
            ...tallyProduct,
            modifierGroups,
        };
    });
};

export const isDealInValid = (discounts) => {
    const { discountDetails } = discounts || {};
    return (
        discountDetails &&
        discountDetails.length > 0 &&
        discountDetails[0].applied &&
        discountDetails[0].applied.failureCode === '300'
    );
};
