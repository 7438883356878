import { createSelector } from '@reduxjs/toolkit';

import { PDPTallyItem } from '../pdp';
import { RootState } from '../store';
import { selectProducts } from './domainMenu';
import { selectTallyItemUpdatePrices } from './tally';
import { TallyProductModel } from '../../@generated/webExpApi';
import { selectOrderMethod } from './orderLocation';
import { OrderLocationMethod } from '../orderLocation';
import { OrderTimeType } from '../bag';
import { checkProductIsAvailable } from '../../common/helpers/checkProductIsAvailable';

export const selectBag = (state: RootState): RootState['bag'] => state.bag;

export const selectLineItems = createSelector(selectBag, (bag) => bag.LineItems);

export const selectDeal = createSelector(selectBag, (bag) => bag.dealId);

export const selectOrderTimeAndType = createSelector(selectBag, selectOrderMethod, (bag, method): {
    orderTime: string;
    orderTimeType: OrderTimeType;
} => {
    const { pickupTime, pickupTimeType, deliveryTime, deliveryTimeType } = bag;

    if (method === OrderLocationMethod.PICKUP) {
        return {
            orderTime: pickupTime,
            orderTimeType: pickupTimeType,
        };
    }

    if (method === OrderLocationMethod.DELIVERY) {
        return {
            orderTime: deliveryTime,
            orderTimeType: deliveryTimeType,
        };
    }

    return {
        orderTime: null,
        orderTimeType: 'asap',
    };
});

export const selectBagEntriesCount = createSelector(selectLineItems, selectProducts, (lineItems, products): number => {
    if (!products) {
        return 0;
    }
    return lineItems
        .filter((entry) => {
            const product = products[entry.productId];

            return checkProductIsAvailable(product);
        })
        .reduce((result, currentItem) => result + currentItem.quantity, 0);
});

export const generateNextLineItemId = (LineItems: TallyProductModel[]): number =>
    ((LineItems?.length > 0 && Math.max(...LineItems?.map((li) => li.lineItemId))) || 0) + 1;

export function convertPdpTallyToBagEntry(
    LineItems: TallyProductModel[],
    pdpTallyItem: PDPTallyItem,
    newLineItemId?: number
): TallyProductModel {
    // Recursively add lineItemId to all children, using current if already exists
    const lineItemId = pdpTallyItem.lineItemId || newLineItemId || generateNextLineItemId(LineItems);
    const tallyItem: TallyProductModel = {
        ...pdpTallyItem,
        lineItemId,
        childItems: pdpTallyItem.childItems?.map((childItem) =>
            convertPdpTallyToBagEntry(LineItems, childItem, childItem.lineItemId)
        ),
    };

    return tallyItem;
}

export const selectBagEntryForPDPTally = (state: RootState, pdpTally: PDPTallyItem): TallyProductModel => {
    const LineItems = selectLineItems(state);
    const tallyItem = convertPdpTallyToBagEntry(LineItems, pdpTally, pdpTally.lineItemId);

    return selectTallyItemUpdatePrices(state, tallyItem);
};
