export const MAX_PRODUCT_QUANTITY = 50;
export const MIN_PRODUCT_QUANTITY = 1;
export const MAX_LIMIT_ORDER = 100;

export const ORDER_NOT_AVAILABLE_FOR_LOCATION =
    'Online order is not currently available for this location. Please select a different store or try again later.';
export const ITEMS_NOT_AVAILABLE_FOR_LOCATION =
    'Your items are not available at this location. Please select a different store or try again later.';
export const TALLY_ORDER_OFFER_INVALID =
    'Oops. All products in your bag do not qualify for the offer. Please see offer description for more details and try again.';
export const LIMIT_FOR_SUBTOTAL_ORDER = `Online orders over $${MAX_LIMIT_ORDER} cannot be placed on Arbys.com. Please call the restaurant to help fulfill your order`;
