import { createContext, useContext } from 'react';
import getFeatureFlags, { IFeatureFlags } from '../../lib/getFeatureFlags';
interface UseFeatureFlagsContext {
    featureFlags: IFeatureFlags;
}

export const FeatureFlagsContext = createContext({ featureFlags: getFeatureFlags() });

export const useFeatureFlags = (): UseFeatureFlagsContext => {
    return useContext(FeatureFlagsContext);
};
