import { PDPTallyItem } from '../../pdp';
import { RootState } from '../../store';

/**
 * Calculates combo price as sum of all combo items prices
 * @param {RootState} state
 * @param tallyItem
 * @returns calculated combo price
 */
export function selectComboPrice(_: RootState, tallyItem: PDPTallyItem) {
    return tallyItem.childItems.reduce((price, item) => price + item.price, 0);
}
