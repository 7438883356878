/** @namespace useExternalBrazeId*/
import { useCallback, useEffect, useMemo, useState } from 'react';
import { initAccountService } from '../../services/customerService/account';
import { useIDToken } from '../useIDToken';
import { useAccount } from '../../../redux/hooks';
import { isBrazeEnabled } from '../../../lib/getFeatureFlags';
import { authorizationHeaderBuilder } from '../../helpers/accountHelper';

interface IUseExternalBrazeId {
    externalBrazeId: string;
    getBrazeId: () => Promise<void>;
}

/**
 * @method useExternalBrazeId
 * @added 17-04-2023
 * @description Get externalID for account from domain service
 * @returns {IUseExternalBrazeId} - externalBrazeId and function for getting externalBrazeId
 * @version 1.0.0
 * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
 * @memberOf useExternalBrazeId
 * @example const { externalBrazeId } = useExternalBrazeId();
 */
export const useExternalBrazeId = (): IUseExternalBrazeId => {
    const { account } = useAccount();
    const { idToken } = useIDToken();
    const [externalBrazeId, setExternalBrazeId] = useState<string>('');

    const { getProfileExternalId } = useMemo(() => initAccountService(authorizationHeaderBuilder(idToken)), [idToken]);

    const getBrazeId = useCallback(async () => {
        if (!(idToken && account?.email)) return;
        try {
            const response = await getProfileExternalId({ email: account.email });
            setExternalBrazeId(response.externalId);
        } catch {
            setExternalBrazeId('');
        }
    }, [account?.email, getProfileExternalId, idToken]);

    useEffect(() => {
        if (isBrazeEnabled()) {
            getBrazeId();
        }
    }, [getBrazeId]);

    return {
        externalBrazeId,
        getBrazeId,
    };
};
