import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import styles from './index.module.css';

export interface IBrandIconProps extends HTMLAttributes<HTMLSpanElement> {
    icon: string;
    size?: IconSize;
    variant?: IconVariant;
    className?: string;
    ariaLabel?: string;
    role?: string;
    paths?: number;
}

export type IconSize = 'huge' | 'xl' | 'l' | 'm' | 's' | 'xs' | 'tiny';
type IconVariant = 'dark' | 'light' | 'colorful' | 'gray6' | 'gray4';

function BrandIcon({
    icon = '',
    size = 'm',
    ariaLabel,
    variant,
    className = '',
    onClick,
    role,
    paths, // number of inner spans
    children,
    ...restProps
}: IBrandIconProps): JSX.Element {
    return (
        <span
            className={classNames(
                'brand-icon',
                variant,
                icon,
                styles.iconContainer,
                styles[size],
                { [styles[variant]]: !!variant },
                className
            )}
            onClick={onClick}
            {...(ariaLabel && { 'aria-label': ariaLabel })}
            role={role}
            {...restProps}
        >
            {/* some icons are multicolor and require to render additional inner spans */}
            {paths &&
                Array(paths)
                    .fill(undefined)
                    .map((_, i) => <span key={i} className={`path${i + 1}`} />)}
            {children}
        </span>
    );
}

export default BrandIcon;
