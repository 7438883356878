import { v4 as uuid } from 'uuid';
import {
    DineInOrderPaymentTypeModel,
    GetNationalMenuTypeIdEnumModel,
    IDineInPayOrderPaymentModel,
    IDineInPayOrderRequestModel,
    OrderPaymentModel,
    OrderRequest,
    OrderV2Request,
    PaymentTypeModel,
    DiscountDetailsTypeModel,
    TallyDiscountTypeModel,
    TallyModifierGroupModel,
    TallyProductModel,
    TallyResponseModel,
} from '../../@generated/webExpApi';

import {
    IPaymentState,
    TInitialPaymentTypes,
} from '../../components/clientOnly/paymentInfoContainer/paymentTypeDropdown/types';
import { IGiftCard, IPaymentRequest } from '../../components/clientOnly/paymentInfoContainer/types';
import { getDeliveryAddress } from './tallyHelper';
import { IOrderPayload } from '../../redux/hooks/useSubmitOrder';
import { CARD_ON_FILE } from '../../components/clientOnly/paymentInfoContainer/paymentTypeDropdown/constants';
import { IDineInOrderPayload } from '../../redux/hooks/useDineInSubmitOrder';
import { getFloatNumber } from './getFloatNumber';

export const createSubmitOrderModifierGroup = ({
    productId,
    modifiers,
}: TallyModifierGroupModel): TallyModifierGroupModel => {
    const orderModifierGroup: TallyModifierGroupModel = { productId };

    if (modifiers && modifiers.length > 0) {
        orderModifierGroup.modifiers = modifiers.map((modifier) => ({ ...modifier }));
    }

    return orderModifierGroup;
};

function createSubmitOrderItem(tallyItem: TallyProductModel): TallyProductModel {
    return {
        lineItemId: tallyItem.lineItemId,
        productId: tallyItem.productId,
        quantity: tallyItem.quantity,
        priceType: tallyItem.priceType,
        price: tallyItem.price,
        description: tallyItem.description,
        note: tallyItem.note,
        childItems: tallyItem.childItems && tallyItem.childItems.map(createSubmitOrderItem),
        modifierGroups: tallyItem.modifierGroups && tallyItem.modifierGroups.map(createSubmitOrderModifierGroup),
    };
}

function createSubmitOrderPayment(
    paymentRequest: IPaymentRequest,
    paymentInfo: IPaymentState,
    tallyOrder: TallyResponseModel,
    tips: number
): OrderPaymentModel {
    if (paymentRequest.paymentType === CARD_ON_FILE) {
        return {
            type: PaymentTypeModel.Wallet,
            details: {
                keys: paymentRequest.paymentKeys,
                sessionKey: paymentRequest.sessionKey,
                cardToken: paymentRequest.cardToken,
                amount: parseFloat((tallyOrder.total + tips).toFixed(2)),
                maskedCardNumber: paymentRequest.maskedCardNumber,
                cardIssuer: paymentRequest.cardIssuer,
            },
        };
    }

    const paymentTypeMap = {
        [TInitialPaymentTypes.CREDIT_OR_DEBIT]: PaymentTypeModel.Card,
        [TInitialPaymentTypes.APPLE_PAY]: PaymentTypeModel.ApplePay,
        [TInitialPaymentTypes.GOOGLE_PAY]: PaymentTypeModel.GooglePay,
        [TInitialPaymentTypes.GIFT_CARD]: PaymentTypeModel.GiftCard,
        [TInitialPaymentTypes.PAY_IN_STORE]: PaymentTypeModel.PayAtLocation,
    };

    if (paymentInfo.type === TInitialPaymentTypes.NO_PAYMENT) {
        return {
            type: PaymentTypeModel.NoPayment,
        };
    }

    return {
        type: paymentTypeMap[paymentRequest.paymentType || paymentInfo.type],
        details: {
            keys: paymentRequest.paymentKeys,
            sessionKey: paymentRequest.sessionKey,
            cardToken: paymentRequest.cardToken,
            billingPostalCode: paymentRequest.billingPostalCode,
            cardIssuer: paymentRequest.cardIssuer,
            maskedCardNumber: paymentRequest.maskedCardNumber,
            amount: parseFloat((tallyOrder.total + tips).toFixed(2)),
            applePayInfo: paymentRequest.applePayInfo,
            googlePayInfo: paymentRequest.googlePayInfo,
        },
    };
}

function createSubmitOrderGiftCardPayment(giftCard: IGiftCard): OrderPaymentModel {
    const paymentKeys = giftCard.paymentToken?.keys || [];
    return {
        type: PaymentTypeModel.GiftCard,
        details: {
            keys: paymentKeys.length > 1 ? [paymentKeys[1]] : paymentKeys,
            sessionKey: giftCard.sessionKey,
            cardIssuer: giftCard.paymentToken?.cardIssuer,
            maskedCardNumber: giftCard.paymentToken?.maskedCardNumber,
            amount: giftCard.balance,
            giftCardPin: giftCard.pin,
            giftCardNumber: giftCard.number,
        },
    };
}

export const createSubmitOrderRequest = ({
    tallyOrder,
    customerInfo,
    customerId,
    paymentRequest,
    paymentInfo,
    giftCards = [],
    location,
    orderTime,
    instructions,
    deliveryLocation,
    driverTip,
    serverTip,
    deviceIdentifier,
    ssCorrelationId,
}: IOrderPayload): {
    orderV3Request: OrderRequest;
    orderV4Request: OrderV2Request;
} => {
    const idempotentId = uuid();
    const sanitizedCustomerId = customerId?.length ? customerId : null;

    const hasCardHolderName = paymentRequest?.chFullName || (paymentRequest?.chFirstName && paymentRequest?.chLastName);

    const giftCardPayments = giftCards.map(createSubmitOrderGiftCardPayment);
    const giftCardTotal = giftCardPayments.reduce(
        (total, giftCardPayment) => total + giftCardPayment.details.amount,
        0
    );
    const tips = driverTip || serverTip;
    const payment = paymentRequest && createSubmitOrderPayment(paymentRequest, paymentInfo, tallyOrder, tips);
    const totalToPay = parseFloat((tallyOrder.total + (tips || 0)).toFixed(2));
    if (payment && hasCardHolderName) {
        payment.details.cardHolderName =
            paymentRequest.chFullName?.trim() ||
            `${paymentRequest.chFirstName.trim()} ${paymentRequest.chLastName.trim()}`;
    }

    // written this way because of prettier bug
    if (
        (payment && paymentRequest.paymentType === TInitialPaymentTypes.GOOGLE_PAY) ||
        (payment && paymentRequest.paymentType === TInitialPaymentTypes.APPLE_PAY)
    ) {
        payment.details.billingPostalCode = paymentRequest.billingPostalCode || '';
    }

    if (payment && giftCardTotal > 0) {
        payment.details.amount = totalToPay > giftCardTotal ? parseFloat((totalToPay - giftCardTotal).toFixed(2)) : 0;
    }

    const payments: OrderPaymentModel[] = giftCardPayments.map((giftCardPayment, i) => {
        const previousGiftCardPayments = giftCardPayments
            .slice(0, i)
            .reduce((total, giftCardPayment) => total + giftCardPayment.details.amount, 0);

        const remainingTotal = parseFloat((totalToPay - previousGiftCardPayments).toFixed(2));
        const giftCardPaymentAmount =
            giftCardPayment.details.amount > remainingTotal ? remainingTotal : giftCardPayment.details.amount;
        return {
            ...giftCardPayment,
            details: {
                ...giftCardPayment.details,
                amount: giftCardPaymentAmount,
            },
        };
    });

    if (payment) {
        payments.push(payment);
    }

    const orderV2Request: OrderV2Request = {
        orderV2RequestModel: {
            deviceId: deviceIdentifier || '',
            customerId: sanitizedCustomerId || undefined,
            cartId: idempotentId,
            fulfillment: {
                email: customerInfo.email,
                firstName: btoa(customerInfo.firstName),
                lastName: btoa(customerInfo.lastName),
                phoneNumber: customerInfo.phone,
                instructions,
            },
            payments,
            tallyResponse: tallyOrder,
        },
        fulfillmentType: tallyOrder.fulfillment.type,
    };

    if (deliveryLocation) {
        const deliveryAddress = getDeliveryAddress(deliveryLocation);

        orderV2Request.orderV2RequestModel.fulfillment.deliveryAddress = {
            address1: deliveryAddress.line1,
            address2: deliveryAddress.line2,
            city: deliveryAddress.city,
            country: deliveryAddress.countryCode,
            postalCode: deliveryAddress.postalCode,
            stateCode: deliveryAddress.stateProvinceCode,
        };
    }

    if (serverTip || driverTip) {
        orderV2Request.orderV2RequestModel.tips = {
            serverTip,
            driverTip,
        };
    }

    const orderRequest: OrderRequest = {
        orderRequestModel: {
            orderData: {
                locationId: location.id,
                menuType: GetNationalMenuTypeIdEnumModel.Allday,
                fulfillmentType: tallyOrder.fulfillment.type,
                isAsap: !orderTime,
                requestedDateTime: orderTime ? orderTime : undefined,
                customerId: sanitizedCustomerId || undefined,
                cartId: idempotentId,
                driverTip,
                serverTip,
                email: customerInfo.email,
                firstName: btoa(customerInfo.firstName),
                lastName: btoa(customerInfo.lastName),
                phoneNumber: customerInfo.phone,
                instructions,
                products: tallyOrder.products.map(createSubmitOrderItem),
                ssCorrelationId,
            },
            payments,
            deviceId: deviceIdentifier || '',
        },
        fulfillmentType: tallyOrder.fulfillment.type,
    };

    if (tallyOrder?.discounts) {
        orderRequest.orderRequestModel.orderData.discounts = {
            discountType: tallyOrder?.discounts?.discountType as TallyDiscountTypeModel,
            discountDetails: tallyOrder?.discounts?.discountDetails.map((discount) => {
                return {
                    type: discount?.type as DiscountDetailsTypeModel,
                    code: discount?.code,
                };
            }),
        };
    }

    if (deliveryLocation) {
        orderRequest.orderRequestModel.orderData.deliveryAddress = getDeliveryAddress(deliveryLocation);
    }

    return {
        orderV3Request: orderRequest,
        orderV4Request: orderV2Request,
    };
};

function createDineInSubmitOrderPayment(paymentRequest: IPaymentRequest, total = 0): IDineInPayOrderPaymentModel {
    return {
        type: DineInOrderPaymentTypeModel.Credit,
        paymentKeys: paymentRequest.paymentKeys,
        sessionKey: paymentRequest.sessionKey,
        cardIssuer: paymentRequest.cardIssuer,
        maskedCardNumber: paymentRequest.maskedCardNumber,
        amount: total,
    };
}

function createDineInSubmitOrderGiftCardPayment(giftCard: IGiftCard): IDineInPayOrderPaymentModel {
    return {
        type: DineInOrderPaymentTypeModel.Giftcard,
        paymentKeys:
            giftCard.paymentToken.keys.length > 1 ? [giftCard.paymentToken.keys[1]] : giftCard.paymentToken.keys,
        sessionKey: giftCard.sessionKey,
        cardIssuer: giftCard.paymentToken.cardIssuer,
        maskedCardNumber: giftCard.paymentToken.maskedCardNumber,
        amount: giftCard.balance,
    };
}

export const createDineInSubmitOrderRequest = ({
    orderInfo,
    paymentRequest,
    giftCards = [],
    deviceId,
    tipAmount,
}: IDineInOrderPayload): IDineInPayOrderRequestModel => {
    const totalWithoutTips = getFloatNumber(orderInfo.subTotalAfterDiscounts + orderInfo.tax);
    const totalToPay = getFloatNumber(totalWithoutTips + (tipAmount || 0));

    const hasCardHolderName = paymentRequest?.chFirstName && paymentRequest?.chLastName;

    const payments = [];

    const payment = paymentRequest && createDineInSubmitOrderPayment(paymentRequest, totalToPay);
    const giftCardPayments = giftCards.map(createDineInSubmitOrderGiftCardPayment);

    const { leftToPay, resultGiftCardPayments } = giftCardPayments.reduce(
        (acc, currGiftCardPayment) => {
            const giftCardPaymentAmount =
                currGiftCardPayment.amount > acc.leftToPay ? acc.leftToPay : currGiftCardPayment.amount;

            return {
                resultGiftCardPayments: [
                    ...acc.resultGiftCardPayments,
                    {
                        ...currGiftCardPayment,
                        amount: giftCardPaymentAmount,
                    },
                ],
                leftToPay: getFloatNumber(acc.leftToPay - giftCardPaymentAmount),
            };
        },
        { leftToPay: totalToPay, resultGiftCardPayments: [] }
    );

    payments.push(...resultGiftCardPayments);

    if (payment && leftToPay !== 0) {
        payment.amount = leftToPay;
        if (hasCardHolderName) {
            payment.cardHolderName = `${paymentRequest?.chFirstName?.trim()} ${paymentRequest?.chLastName?.trim()}`;
        }
        payments.push(payment);
    }

    const request: IDineInPayOrderRequestModel = {
        orderId: orderInfo.orderId,
        deviceId,
        firstName: paymentRequest?.chFirstName || 'noFirstName',
        lastName: paymentRequest?.chLastName || 'noLastName',
        email: paymentRequest?.email,
        tipAmount,
        amount: totalWithoutTips,
        payments,
    };

    return request;
};

export const getPaymentType = (mode: PaymentTypeModel) => {
    switch (mode) {
        case PaymentTypeModel.Card:
            return 'New';
        case PaymentTypeModel.Wallet:
            return 'Saved';
        case PaymentTypeModel.ApplePay:
        case PaymentTypeModel.GooglePay:
            return 'Digital';
        case PaymentTypeModel.GiftCard:
            return 'Gift Card';
        default:
            return '';
    }
};

export const getPaymentMethod = (mode: PaymentTypeModel, cardIssuer: string) => {
    switch (mode) {
        case PaymentTypeModel.Card:
        case PaymentTypeModel.Wallet:
            return cardIssuer;
        case PaymentTypeModel.ApplePay:
            return 'ApplePay';
        case PaymentTypeModel.GooglePay:
            return 'GooglePay';
        case PaymentTypeModel.GiftCard:
            return 'GiftCard';
        default:
            return '';
    }
};
