import React, { FunctionComponent } from 'react';
import Link from 'next/link';

import {
    IDocumentLink,
    IExternalLink,
    IMenuCategoryLink,
    IPageLink,
    IPhoneNumberLink,
    IProduct,
    IColor,
} from '../../../@generated/@types/contentful';
import { getLinkDetails } from '../../../lib/link';
import useGlobalProps from '../../../redux/hooks/useGlobalProps';
import { InspireSimpleLink } from './simpleLink';

export interface IInspireLinkProps {
    link: IExternalLink | IPageLink | IMenuCategoryLink | IPhoneNumberLink | IProduct | IDocumentLink | string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    disabled?: boolean;
    className?: string;
    type?: 'primary' | 'secondary';
    gtmId?: string;
    backgroundColor?: IColor; // TODO remove temporary workarownd after styled-jsx/css will be supported with babel plugin
    currentCategoryUrl?: string;
    newtab?: boolean;
    text?: string;
    dataTestId?: string;
}

// TODO remove link typography from global and use only this link across app
export const InspireLink: FunctionComponent<IInspireLinkProps> = (props) => {
    const {
        link,
        type,
        onClick,
        children,
        className,
        gtmId,
        currentCategoryUrl,
        newtab,
        text,
        backgroundColor,
        ...restProps
    } = props;
    const bgColor = backgroundColor?.fields?.hexColor;

    const { productDetailsPagePaths } = useGlobalProps();
    const { href, isExternal, isPhone, name } = getLinkDetails(link, {
        productDetailsPagePaths,
        currentCategoryUrl,
    });

    const openInNewTab = newtab === undefined ? isExternal : newtab;

    if (isExternal || isPhone) {
        return (
            <>
                <InspireSimpleLink
                    className={className}
                    link={href}
                    isPhone={isPhone}
                    text={name || text}
                    onClick={onClick}
                    gtmId={gtmId}
                    type={type}
                    newtab={openInNewTab}
                    {...restProps}
                >
                    {children}
                </InspireSimpleLink>
                <style jsx>{`
                    .backgroundColor {
                        background-color: #${bgColor};
                    }
                `}</style>
            </>
        );
    }

    return (
        <>
            <Link href={href} passHref>
                <InspireSimpleLink
                    className={className}
                    text={name || text}
                    onClick={onClick}
                    gtmId={gtmId}
                    type={type}
                    newtab={newtab}
                    {...restProps}
                >
                    {children}
                </InspireSimpleLink>
            </Link>
            <style jsx>{`
                .backgroundColor {
                    background-color: #${bgColor};
                }
            `}</style>
        </>
    );
};
