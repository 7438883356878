import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IBranchIOState {
    data?: string;
    data_parsed?: {
        $canonical_url: string;
        '~referring_link': string;
    };
    developer_identity: string;
    has_app: boolean;
    identity: string;
    referring_identity: string;
    referring_link: string;
    error: string | null;
}

export const initialState: IBranchIOState = {
    data: '',
    data_parsed: {
        $canonical_url: '',
        '~referring_link': '',
    },
    developer_identity: '',
    has_app: false,
    identity: '',
    referring_identity: '',
    referring_link: '',
    error: null,
};

const branchIOSlice = createSlice({
    name: 'branchIO',
    initialState,
    reducers: {
        setBranchIO: (_, action) => ({
            ...action.payload,
        }),

        setBranchIOError: (_, action: PayloadAction<string>) => ({
            ...initialState,
            error: action.payload,
        }),
    },
});

export const { actions, name } = branchIOSlice;

export default branchIOSlice.reducer;
