/** @namespace  UseRewards*/
import { useDispatch, useSelector } from 'react-redux';
import { RootState, useAppSelector } from '../store';
import * as RewardsStore from '../rewards';

import { PayloadAction } from '@reduxjs/toolkit';
import {
    selectAppliedPromocode,
    selectAutoDiscounts,
    selectCertificates,
    selectCertificatesSortedByStatus,
    selectFirstExpiringOffer,
    selectInactiveOffers,
    selectLastPurchasedCertificate,
    selectOffers,
    selectRewardsActivityHistory,
    selectRewardsCatalog,
    selectRewardsRecommendations,
} from '../selectors/rewards';
import {
    DiscountDetailsTypeModel,
    ICertificateModel,
    IPurchaseCustomerAccountRewardResponseModel,
    TOffersUnionModel,
} from '../../@generated/webExpApi';

interface IUseRewards {
    rewards: RewardsStore.IRewards;
    offers: RewardsStore.Offers;
    autoDiscounts: RewardsStore.AutoDiscounts;
    certificates: RewardsStore.Certificates;
    certificatesSortedByStatus: RewardsStore.Certificates;
    lastPurchasedCertificate: RewardsStore.LastPurchasedCertificate;
    rewardsCatalog: RewardsStore.IRewardsCatalog;
    rewardsRecommendations: RewardsStore.TRewardsRecommendations;
    firstExpiringOffer?: TOffersUnionModel;
    getOfferById: (offerId: string) => TOffersUnionModel;
    getCertificateById: (certificateId: string) => ICertificateModel;
    getApplicableOffers: (locationId?: string, includePromocode?: boolean) => RewardsStore.Offers;
    totalCount: number;
    isLoading: boolean;
    isCatalogLoading: boolean;
    isApplyPromocodeLoading: boolean;
    inactiveOffers: RewardsStore.InactiveOffers;
    rewardsActivityHistory: RewardsStore.IRewardsActivityHistory;
    rewardsActivityHistoryLoading: boolean;
    appliedPromocode: TOffersUnionModel;
    actions: {
        setRewards: (payload: RewardsStore.IRewards) => PayloadAction<RewardsStore.IRewardsState>;
        setAutoDiscounts: (payload: RewardsStore.AutoDiscounts) => PayloadAction<RewardsStore.AutoDiscounts>;
        setAppliedPromocode: (payload: RewardsStore.AppliedPromocode) => PayloadAction<RewardsStore.AppliedPromocode>;
        removePromocode: (payload: string) => PayloadAction<string>;
        setRewardsCatalog: (payload: RewardsStore.IRewardsCatalog) => PayloadAction<RewardsStore.IRewardsCatalog>;
        setRewardsLoading: (payload: boolean) => void;
        setApplyPromocodeLoading: (payload: boolean) => void;
        setRewardStatus: (payload: RewardsStore.ISetRewardStatusPayload) => void;
        setRewardsCatalogLoading: (payload: boolean) => void;
        setRewardsRecommendations: (
            payload: RewardsStore.TRewardsRecommendations
        ) => PayloadAction<RewardsStore.TRewardsRecommendations>;
        setRewardsActivityHistory: (
            payload: RewardsStore.IRewardsActivityHistory
        ) => PayloadAction<RewardsStore.IRewardsActivityHistory>;
        setRewardsActivityHistoryLoading: (payload: boolean) => void;
        setLastPurchasedCertificate: (
            payload: RewardsStore.LastPurchasedCertificate
        ) => PayloadAction<RewardsStore.LastPurchasedCertificate>;
    };
}

/**
 * Hook that contains logic related to rewards functionality
 * @method UseRewards
 * @added  2022-02-13
 * @version  1.0.0
 * @memberof UseRewards
 * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
 * @property {RewardsStore.IRewardsState} rewards piece of redux state related to rewards
 * @property {TOffersUnionModel[] | TOffersByStatusModel[]} offers actual offers
 * @property {AutoDiscountOfferModel[]} autoDiscounts actual auto discounts
 * @property {ICertificateModel[]} certificates actual certificates
 * @property {ICertificateModel[]} certificatesSortedByStatus actual certificates sorted by status
 * @property {IPurchaseCustomerAccountRewardResponseModel} lastPurchasedCertificate last purchsed certificate
 * @property {TCatalogCertificatesByCategoryModel} rewardsCatalog actual rewards catalog
 * @property {TOffersUnionModel[]} inactiveOffers inactive offers
 * @property {IGetCustomerRewardsActivityHistoryResponseModel[]} rewardsActivityHistory activity history
 * @property {TOffersUnionModel} appliedPromocode applied promocode
 * @property {Function} setLastPurchasedCertificate method to dispatch last purchased certificate
 * @property {Function} setRewards method to dispatch rewards
 * @property {Function} setRewardsCatalog method to dispatch rewards catalog
 * @property {Function} setRewardsRecommendations method to dispatch rewards recomendations
 * @property {Function} setRewardsLoading method to dispatch rewards loading state
 * @property {Function} setApplyPromocodeLoading method to dispatch applying promocode loading state
 * @property {Function} setRewardsCatalogLoading method to dispatch rewards catalog loading state
 * @property {Function} getOfferById method to find offer by provided id
 * @property {Function} getApplicableOffers method to get applicable offers by provided location id
 * @property {Function} getCertificateById method to find certificate by provided id
 * @property {Function} setRewardStatus method to dispatch reward status
 * @property {Function} setRewardsActivityHistory method to dispatch rewards activity history
 * @property {Function} setRewardsActivityHistoryLoading method to dispatch rewards activity history loading state
 * @property {Function} setAutoDiscounts method to dispatch auto discounts
 * @property {Function} setAppliedPromocode method to dispatch applied promocode response
 * @property {Function} removePromocode method to remove promocode from the offers state
 * @returns {IUseRewards} returns a rewards related data and actions
 */
export default function UseRewards(): IUseRewards {
    const dispatch = useDispatch();

    const rewards = useSelector<RootState, RewardsStore.IRewardsState>((state) => state.rewards);
    const offers = useAppSelector(selectOffers);
    const autoDiscounts = useAppSelector(selectAutoDiscounts);
    const firstExpiringOffer = useAppSelector(selectFirstExpiringOffer);
    const certificates = useAppSelector(selectCertificates);
    const certificatesSortedByStatus = useAppSelector(selectCertificatesSortedByStatus);
    const lastPurchasedCertificate = useAppSelector(selectLastPurchasedCertificate);
    const rewardsCatalog = useAppSelector(selectRewardsCatalog);
    const rewardsRecommendations = useAppSelector(selectRewardsRecommendations);
    const inactiveOffers = useAppSelector(selectInactiveOffers);
    const rewardsActivityHistory = useAppSelector(selectRewardsActivityHistory);
    const appliedPromocode = useAppSelector(selectAppliedPromocode);

    /**
     * Method to dispatch last purchased certificate
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setLastPurchasedCertificate
     * @param {IPurchaseCustomerAccountRewardResponseModel} payload
     * @returns {PayloadAction<RewardsStore.LastPurchasedCertificate>}
     */
    const setLastPurchasedCertificate = (payload: IPurchaseCustomerAccountRewardResponseModel) =>
        dispatch(RewardsStore.actions.setLastPurchasedCertificate(payload));

    /**
     * Method to dispatch rewards
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setRewards
     * @param {RewardsStore.IRewardsState} payload
     * @returns {PayloadAction<RewardsStore.IRewardsState>}
     */
    const setRewards = (payload: RewardsStore.IRewardsState) => dispatch(RewardsStore.actions.setRewards(payload));

    /**
     * Method to dispatch rewards catalog
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setRewardsCatalog
     * @param {RewardsStore.IRewardsCatalog} payload
     * @returns {PayloadAction<RewardsStore.IRewardsCatalog>}
     */
    const setRewardsCatalog = (payload: RewardsStore.IRewardsCatalog) =>
        dispatch(RewardsStore.actions.setRewardsCatalog(payload));

    /**
     * Method to dispatch rewards recomendations
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setRewardsRecommendations
     * @param {RewardsStore.TRewardsRecommendations} payload
     * @returns {PayloadAction<RewardsStore.TRewardsRecommendations>}
     */
    const setRewardsRecommendations = (payload: RewardsStore.TRewardsRecommendations) =>
        dispatch(RewardsStore.actions.setRewardsRecommendations(payload));

    /**
     * Method to dispatch rewards loading state
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setRewardsLoading
     * @param {boolean} payload
     * @returns {void}
     */
    const setRewardsLoading = (payload: boolean) => dispatch(RewardsStore.actions.setRewardsLoading(payload));

    /**
     * Method to dispatch rewards loading state
     * @added  2022-03-20
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setApplyPromocodeLoading
     * @param {boolean} payload
     * @returns {void}
     */
    const setApplyPromocodeLoading = (payload: boolean) =>
        dispatch(RewardsStore.actions.setApplyPromocodeLoading(payload));

    /**
     * Method to dispatch rewards catalog loading state
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setRewardsCatalogLoading
     * @param {boolean} payload
     * @returns {void}
     */
    const setRewardsCatalogLoading = (payload: boolean) =>
        dispatch(RewardsStore.actions.setRewardsCatalogLoading(payload));

    /**
     * Method to find offer by provided id
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method getOfferById
     * @param {string} userOfferId - offer id
     * @returns {TOffersUnionModel}
     */
    const getOfferById = (userOfferId: string) => {
        return offers.find((item) => item.userOfferId === userOfferId);
    };

    /**
     * Method to get applicable offers by provided location id
     * @added  2022-02-13
     * @version  1.1.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method getApplicableOffers
     * @param {string} locationId - location id
     * @param {boolean} includePromocode - parameter defines if a promo code should be included in the offers array
     * @returns {TOffersUnionModel[]}
     */
    const getApplicableOffers = (locationId?: string, includePromocode = false) => {
        if (
            !locationId ||
            (!includePromocode &&
                !rewards?.allLocationsHaveOffers &&
                !rewards?.locationsWithOffers?.includes(locationId))
        ) {
            return [];
        }

        return offers.filter((offer) => {
            if (!includePromocode && offer.discountDetailsType === DiscountDetailsTypeModel.PromoCode) {
                return false;
            }

            if (offer.locationRestrictions?.isApplicableToAllLocations) {
                return true;
            }

            if (!offer.locationRestrictions?.exclusion && offer.locationRestrictions?.locations?.includes(locationId)) {
                return true;
            }

            if (offer.locationRestrictions?.exclusion && !offer.locationRestrictions?.locations?.includes(locationId)) {
                return true;
            }

            return false;
        });
    };

    /**
     * Method to find certificate by provided id
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method getCertificateById
     * @param {string} certificateId - certificate id
     * @returns {ICertificateModel}
     */
    const getCertificateById = (certificateId: string) => {
        return certificates.find((item) => item.number === certificateId);
    };

    /**
     * Method to dispatch reward status
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setRewardStatus
     * @param {IRewardsStore.ISetRewardStatusPayload} payload
     * @returns {void}
     */
    const setRewardStatus = (payload: RewardsStore.ISetRewardStatusPayload) =>
        dispatch(RewardsStore.actions.setRewardStatus(payload));

    /**
     * Method to dispatch rewards activity history
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setRewardsActivityHistory
     * @param {RewardsStore.IRewardsActivityHistory} payload
     * @returns {PayloadAction<RewardsStore.IRewardsActivityHistory>}
     */
    const setRewardsActivityHistory = (payload: RewardsStore.IRewardsActivityHistory) =>
        dispatch(RewardsStore.actions.setRewardsActivityHistory(payload));

    /**
     * Method to dispatch rewards activity history loading state
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setRewardsActivityHistoryLoading
     * @param {boolean} payload
     * @returns {void}
     */
    const setRewardsActivityHistoryLoading = (payload: boolean) =>
        dispatch(RewardsStore.actions.setRewardsActivityHistoryLoading(payload));

    /**
     * Method to dispatch auto discounts
     * @added  2022-02-13
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setAutoDiscounts
     * @param {RewardsStore.AutoDiscounts} payload
     * @returns {PayloadAction<RewardsStore.AutoDiscounts>}
     */
    const setAutoDiscounts = (payload: RewardsStore.AutoDiscounts) =>
        dispatch(RewardsStore.actions.setAutoDiscounts(payload));

    /**
     * Method to dispatch apply promocode response
     * @added  2022-03-20
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method setAppliedPromocode
     * @param {RewardsStore.AppliedPromocode} payload
     * @returns {PayloadAction<RewardsStore.AppliedPromocode>}
     */
    const setAppliedPromocode = (payload: RewardsStore.AppliedPromocode) =>
        dispatch(RewardsStore.actions.setAppliedPromocode(payload));

    /**
     * Method to remove promo code from the offers state
     * @added  2022-03-22
     * @version  1.0.0
     * @memberof UseRewards
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @method removePromocode
     * @param {string} payload
     * @returns {PayloadAction<string>}
     */
    const removePromocode = (payload: string) => dispatch(RewardsStore.actions.removePromocode(payload));

    return {
        rewards,
        offers,
        autoDiscounts,
        certificates,
        certificatesSortedByStatus,
        lastPurchasedCertificate,
        rewardsCatalog,
        rewardsRecommendations,
        firstExpiringOffer,
        getOfferById,
        getCertificateById,
        getApplicableOffers,
        isCatalogLoading: rewards.rewardsCatalogLoading,
        isApplyPromocodeLoading: rewards.applyPromocodeLoading,
        isLoading: rewards.loading,
        totalCount: rewards.totalCount,
        inactiveOffers,
        rewardsActivityHistory,
        rewardsActivityHistoryLoading: rewards.rewardsActivityHistoryLoading,
        appliedPromocode,
        actions: {
            setRewards,
            removePromocode,
            setRewardStatus,
            setAutoDiscounts,
            setRewardsLoading,
            setRewardsCatalog,
            setAppliedPromocode,
            setApplyPromocodeLoading,
            setRewardsCatalogLoading,
            setRewardsRecommendations,
            setRewardsActivityHistory,
            setLastPurchasedCertificate,
            setRewardsActivityHistoryLoading,
        },
    };
}
