import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductsRecommendationResponseModel } from '../@generated/webExpApi';

export interface SelectedSell {
    correlationId: string;
    recommendedProducts: Array<string>;
    recommendationId: string;
}

export const initialState: SelectedSell = {
    correlationId: '',
    recommendedProducts: [],
    recommendationId: '',
};

const selectedSellSlice = createSlice({
    name: 'selectedSell',
    initialState,
    reducers: {
        setCorrelationId: (state, action: PayloadAction<string>) => {
            return {
                ...state,
                correlationId: action.payload,
            };
        },
        fetchRecommendedItemsSuccess: (state, action: PayloadAction<ProductsRecommendationResponseModel>) => {
            return {
                ...state,
                recommendedProducts: action.payload.productIds,
                recommendationId: action.payload.id,
            };
        },
        fetchRecommendedItemsFailure: (state) => {
            return {
                ...state,
                recommendedProducts: [],
                recommendationId: '',
            };
        },
    },
});

export const { actions, name } = selectedSellSlice;

export default selectedSellSlice.reducer;
