import { IGetCustomerAccountPaymentMethodsResponseModel } from '../../../../@generated/webExpApi';

export enum TInitialPaymentTypes {
    PLACEHOLDER = '',
    PAY_IN_STORE = 'PAY_IN_STORE',
    CREDIT_OR_DEBIT = 'CREDIT_OR_DEBIT',
    VENMO = 'VENMO',
    PAYPAL = 'PAYPAL',
    APPLE_PAY = 'APPLE_PAY',
    GOOGLE_PAY = 'GOOGLE_PAY',
    GIFT_CARD = 'GIFT_CARD',
    NO_PAYMENT = 'NO_PAYMENT',
}

export enum TFPPaymentTypes {
    APPLE_PAY = 'ApplePay',
    GOOGLE_PAY = 'GooglePay',
    GIFT_CARD = 'GiftCard',
    CREDIT_OR_DEBIT = 'Card',
    CARD_ON_FILE = 'CardOnFile',
}

export enum TFPErrorCodes {
    APPLE_PAY_CANCEL = 'applePayHelperCancel',
}

export type TCardOnFIle = 'CARD_ON_FILE';

export type TPaymentMethodTypes = TInitialPaymentTypes | TCardOnFIle;

export interface IInitialPaymentTypeSelectionOption {
    type: TInitialPaymentTypes;
    text: string;
    image?: string;
    icon?: string;
}

export interface IPaymentMethod {
    type: TPaymentMethodTypes;
    token?: string;
    text: string;
    image?: string;
    icon?: string;
}

export interface IPaymentState {
    type: TPaymentMethodTypes;
    token?: string;
}

export interface IPaymentTypeDropDown {
    onChange: (paymentTypeDropdownSelection: IPaymentState) => void;
    selectedMethod: IPaymentState;
    loadingPaymentMethods?: boolean;
    paymentMethods?: IGetCustomerAccountPaymentMethodsResponseModel;
}

export interface IPaymentTypeDropdownComponent extends IPaymentTypeDropDown {
    paymentOptions: IPaymentMethod[];
}
