import {
    WebExperienceApi,
    IGetCustomerOrderHistoryResponseModel,
    GetOrdersHistoryRequest,
} from '../../../@generated/webExpApi';

import createErrorWrapper from '../createErrorWrapper';
import { getAuthorizationConfig } from '../../helpers/getAuthorizationConfig';

class CustomerOrdersService {
    private readonly getCustomerOrderHistory: (
        request: GetOrdersHistoryRequest
    ) => Promise<IGetCustomerOrderHistoryResponseModel>;

    constructor(JWT: string) {
        const expApi = new WebExperienceApi(getAuthorizationConfig(JWT));

        this.getCustomerOrderHistory = createErrorWrapper<
            IGetCustomerOrderHistoryResponseModel,
            GetOrdersHistoryRequest
        >('getOrdersHistory', expApi.getOrdersHistory.bind(expApi));
    }

    getOrderHistory(): Promise<IGetCustomerOrderHistoryResponseModel> {
        return this.getCustomerOrderHistory({});
    }

    // @TODO: blocked by backend story SC-2767 and web-exp story SC-14330
    async cancelOrder(id: string): Promise<{ errorCode: string }> {
        const response = await fetch(
            `https://snc-api.qa.irb.digital/snc/web-exp-api/v1/customer/account/order/history/cancel/${id}`
        );

        if (response.status >= 200 && response.status < 300) {
            return response.json();
        }
        throw response;
    }
}

export default CustomerOrdersService;
