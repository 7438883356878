import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export enum NotificationType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
}

export type Notification = {
    id?: number;
    title?: string;
    message?: string;
    htmlMessage?: string;
    type?: NotificationType;
    closeByDefault?: boolean;
};

export type NotificationsState = Notification[];

export type RemoveNotificationPayload = {
    id: number;
};

export type EnqueueNotificationPayload = {
    title?: string;
    message?: string;
    htmlMessage?: string;
    type?: NotificationType;
    closeByDefault?: boolean;
};

const notificationsSlice = createSlice<NotificationsState, SliceCaseReducers<NotificationsState>>({
    name: 'notification',
    initialState: [],
    reducers: {
        enqueue: (state: NotificationsState, action: PayloadAction<Notification>): NotificationsState => [
            ...state,
            action.payload,
        ],
        dequeue: (state: NotificationsState): NotificationsState => {
            state.shift();
            return state;
        },
        remove: (state: NotificationsState, action: PayloadAction<RemoveNotificationPayload>): NotificationsState => {
            const index = state.findIndex((notification) => notification.id === action.payload.id);

            if (index > -1) {
                state.splice(index, 1);
            }

            return state;
        },
    },
});

export const createEnqueueNotificationAction = (
    payload: Error | EnqueueNotificationPayload | undefined
): PayloadAction<Notification> => {
    const notification = payload || {};

    const isError = (input: Error | EnqueueNotificationPayload | undefined): input is Error => {
        return (input as Error).name !== undefined;
    };

    if (notification instanceof Error || (isError(notification) && notification?.name === 'Error')) {
        return notificationsSlice.actions.enqueue({
            id: Date.now(),
            message: notification.message,
            type: NotificationType.ERROR,
            closeByDefault:
                (notification as EnqueueNotificationPayload).closeByDefault === undefined
                    ? true
                    : (notification as EnqueueNotificationPayload).closeByDefault,
        });
    }

    return notificationsSlice.actions.enqueue({
        id: Date.now(),
        title: (notification as EnqueueNotificationPayload).title,
        message: notification.message,
        htmlMessage: (notification as EnqueueNotificationPayload).htmlMessage,
        type: (notification as EnqueueNotificationPayload).type || NotificationType.ERROR,
        closeByDefault:
            (notification as EnqueueNotificationPayload).closeByDefault === undefined
                ? true
                : (notification as EnqueueNotificationPayload).closeByDefault,
    });
};

export const { actions } = notificationsSlice;

export default notificationsSlice.reducer;
