import React from 'react';

import { useGlobalProps } from '../../../redux/hooks';
import IPromotionModal from './promotionModal';

interface IPromotionModals {
    pageLink?: string;
}

export default function PromotionModals(props: IPromotionModals): JSX.Element | null {
    const { promotionModals } = useGlobalProps();
    const { pageLink } = props;

    if (!promotionModals?.items?.length) return null;

    return <IPromotionModal promotionModal={promotionModals.items[0]} pageLink={pageLink} />;
}
