import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import * as LocalTapListStore from '../localTapList';
import { BeerMenuResponseModel } from '../../@generated/webExpApi';
import { getBeersByType } from '../../common/helpers/getBeersByType';

interface UseLocalTapListHook {
    list?: BeerMenuResponseModel['beersByType'];
    loading: boolean;
    error: boolean;
    available: boolean;
    actions: {
        getLocalTapList: (locationId: string) => void;
        setLocalTapList: (payload: BeerMenuResponseModel | null) => void;
    };
}

export default function useLocalTapList(): UseLocalTapListHook {
    const { loading, error, payload } = useAppSelector((state) => state.localTapList);
    const dispatch = useAppDispatch();

    const getLocalTapList = (locationId: string) => {
        dispatch(LocalTapListStore.actions.getLocalTapList(locationId));
    };

    const setLocalTapList = (payload: BeerMenuResponseModel | null) => {
        dispatch(LocalTapListStore.actions.setLocalTapList(payload));
    };

    const list = useMemo(() => {
        return getBeersByType(payload);
    }, [payload]);

    const available = useMemo(() => !!Object.keys(list).length, [list]);

    return {
        loading,
        error,
        list,
        available,
        actions: {
            getLocalTapList,
            setLocalTapList,
        },
    };
}
