// global styles for all brands (style resets, default styles, and etc.)
// TODO: Move this as it causes a "cascading dependency" that hurts our Lighthouse score
import '../public/normalize.css';
import '../styles/variables.css';
// TODO: remove global css typography elements styles
import '../styles/global.css';

import { AppProps } from 'next/app';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';

import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { StylesProvider } from '@material-ui/core/styles';

import { AppStore, createStore } from '../redux/store';
const CookieBanner = dynamic(() => import('../components/clientOnly/cookieBanner'));
const Notifications = dynamic(() => import('../components/organisms/notifications/notifications'));
const Bag = dynamic(() => import('../components/organisms/bag'));

import BrazeProvider from '../components/brazeProvider';
import DataProvider from '../components/dataProvider';
import ErrorBoundary from '../components/organisms/errorBoundary';
import PageLoader from '../components/clientOnly/pageLoader';

import scrollBehaviorPolyfillInit from '../common/polyfills/scrollBehaviorPolyfill';
import NewRelicSnippet from '../components/NewRelicSnippet';
import logger, { isNewRelicAvailable } from '../common/services/logger';
import getBrandInfo from '../lib/brandInfo';
import { useInitApp } from '../common/hooks/useInitApp';
import useRouteChangeComplete from '../common/hooks/useRouteChangeComplete';
import { Auth0Provider } from '@auth0/auth0-react';
import { useFeatureFlags } from '../redux/hooks/useFeatureFlags';
import { PersonalizationProvider } from '../components/PersonalizationProvider';
import { isBreadcrumbSchemaOn } from '../lib/getFeatureFlags';
import { useBreadcrumbSchema } from '../common/hooks/useBreadcrumbSchema';
import { UnavailableItemsByLocationModal } from '../components/organisms/unavailableItemsByLocationModal/unavailableItemsByLocationModal';
import GlobalPropsProvider from '../components/globalPropsProvider';
import PromotionModals from '../components/organisms/promotionModals/promotionModals';
import BottomCtaProvider from '../components/BottomCtaProvider';
import BranchIOSnippet from '../components/BranchIOSnippet';
import { PerimeterXSnippet } from '../components/perimeterXSnippet';

scrollBehaviorPolyfillInit();

function InspireApp({ Component, pageProps }: AppProps): JSX.Element {
    const [store, setStore] = useState<AppStore>(null);
    const { featureFlags } = useFeatureFlags();

    const breadcrumbSchema = useBreadcrumbSchema();
    let acceptCookie = false;
    let redirectUri = `/callback`;

    if (process.browser) {
        acceptCookie = JSON.parse(localStorage.getItem('acceptCookie'));
        redirectUri = window && `${window.location.origin}/callback`;
    }

    const [showCookieBanner, setShowCookieBanner] = useState(!acceptCookie);
    const [isBagStaysOpen, setIsBagStaysOpen] = useState(false);

    if (!store) {
        setStore(createStore());
    }

    useInitApp(store);

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
        if (isNewRelicAvailable()) {
            logger.init();
        }
    }, []);
    const brand = process.env.NEXT_PUBLIC_BRAND;
    const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;
    const GOOGLE_OPTIMIZE_KEY = process.env.NEXT_PUBLIC_GOOGLE_OPTIMIZE_KEY;

    const AUTH_0_DOMAIN = process.env.NEXT_PUBLIC_AUTH_0_DOMAIN;
    const AUTH_0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH_0_CLIENT_ID;
    const AUTH_0_SESSION_EXPIRY_IN_DAYS = 365;
    const { brandName } = getBrandInfo();

    useRouteChangeComplete(() => {
        // Makes every NEXT.JS route change event scroll window to top
        window.scrollTo(0, 0);

        window.dataLayer.push({ event: 'optimize.activate' });
    });

    // Forces browser to reload page when navigating back/forward in history
    // without using back-forward caching (used in some mobile browsers (Safari, Chrome))
    // https://developers.google.com/web/updates/2019/02/back-forward-cache
    // https://github.com/vercel/next.js/issues/6128
    useEffect(() => {
        window.onpageshow = function (event) {
            if (event.persisted) {
                window.location.reload();
            }
        };
    }, []);

    const handleCloseCookieBanner = () => {
        setShowCookieBanner(false);
    };

    // cross-browser solution to support viewport height (100vh)
    const handleWindowResize = () => {
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    useEffect(() => {
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_ARTIFACT_VERSION)
            console.log(`VERSION: ${process.env.NEXT_PUBLIC_ARTIFACT_VERSION}`);
    }, []);

    const onRedirectCallback = (appState) => {
        setIsBagStaysOpen(appState?.loginFromBag);
        // Use Next.js's Router.replace method to replace the url
        Router.replace(appState?.target || appState?.returnTo || window.location.pathname);
    };

    const renderComponents = () => {
        return (
            <Provider store={store}>
                <GlobalPropsProvider>
                    <DataProvider>
                        <PersonalizationProvider>
                            <div id="main">
                                <StylesProvider injectFirst>
                                    <BottomCtaProvider>
                                        <BrazeProvider>
                                            <ErrorBoundary>
                                                <Component {...pageProps} />
                                            </ErrorBoundary>
                                            <BranchIOSnippet />
                                            <Bag
                                                isBagStaysOpen={isBagStaysOpen}
                                                setIsBagStaysOpen={setIsBagStaysOpen}
                                            />
                                            <PromotionModals pageLink={pageProps?.page?.fields?.link?.fields?.name} />
                                            <Notifications />
                                            {featureFlags.showUnavailableItemsByLocationModal && (
                                                <UnavailableItemsByLocationModal />
                                            )}
                                            {showCookieBanner && <CookieBanner onClose={handleCloseCookieBanner} />}
                                            <PageLoader />
                                        </BrazeProvider>
                                    </BottomCtaProvider>
                                </StylesProvider>
                            </div>
                        </PersonalizationProvider>
                    </DataProvider>
                </GlobalPropsProvider>
            </Provider>
        );
    };

    return (
        <>
            <Head>
                <title>{brandName}</title>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
                <link rel="preconnect" href="https://www.googletagmanager.com" />
                <link rel="preconnect" href="https://images.ctfassets.net" />
                <link rel="preconnect" href={`${process.env.NEXT_PUBLIC_WEB_EXP_API_BASE}`} />
                <link rel="icon" type="image/png" href={`/brands/${brand}/favicon-16x16.png`} sizes="16x16" />
                <link rel="icon" type="image/png" href={`/brands/${brand}/favicon-32x32.png`} sizes="32x32" />
                <link rel="icon" type="image/png" href={`/brands/${brand}/favicon-48x48.png`} sizes="48x48" />
                <link rel="apple-touch-icon" href={`/brands/${brand}/apple-touch-icon.png`} sizes="180x180" />
                <link rel="mask-icon" href={`/brands/${brand}/logo.svg`} />
                <link rel="manifest" href={`/brands/${brand}/site.webmanifest`} />

                <style
                    dangerouslySetInnerHTML={{
                        __html: `.async-hide { opacity: 0 !important }`,
                    }}
                />

                {isBreadcrumbSchemaOn() && (
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbSchema) }}
                    />
                )}
                {GOOGLE_OPTIMIZE_KEY && (
                    <script
                        id="google-optimize-inline"
                        async
                        src={`https://www.googleoptimize.com/optimize.js?id=${GOOGLE_OPTIMIZE_KEY}`}
                    />
                )}
                <script
                    id="google-optimize-activate"
                    dangerouslySetInnerHTML={{
                        __html: `
                        (function(w,l){w[l]=w[l]||[];w[l].push({'event':'optimize.activate'})
                        })(window,'dataLayer');
                    `,
                    }}
                />

                {/* https://github.com/vercel/next.js/blob/canary/examples/with-google-tag-manager/pages/_app.js */}
                <script
                    id="google-tag-manager"
                    dangerouslySetInnerHTML={{
                        __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${GA_TRACKING_ID}');
                    `,
                    }}
                />
            </Head>
            <PerimeterXSnippet />
            <NewRelicSnippet />

            {process.env.NEXT_PUBLIC_UCOM_SDK_SRC && <Script src={process.env.NEXT_PUBLIC_UCOM_SDK_SRC} />}

            {featureFlags.account ? (
                <Auth0Provider
                    domain={AUTH_0_DOMAIN}
                    clientId={AUTH_0_CLIENT_ID}
                    redirectUri={redirectUri}
                    onRedirectCallback={onRedirectCallback}
                    useRefreshTokens={true}
                    advancedOptions={{
                        defaultScope: 'openid',
                    }}
                    sessionCheckExpiryDays={AUTH_0_SESSION_EXPIRY_IN_DAYS}
                >
                    {renderComponents()}
                </Auth0Provider>
            ) : (
                renderComponents()
            )}
        </>
    );
}

// TODO: In future version of NextJS, we will be able to use getStaticProps to populate our navigation & alertBanners
// export const getStaticProps: GetStaticProps = async (context) => {
//     const navigation = await ContentfulDelivery(context.preview).getNavigation()
//     return { props: { navigation } };
// };

export default InspireApp;
