import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    AutoDiscountOfferModel,
    DiscountDetailsTypeModel,
    ICertificateModel,
    IGetCustomerAccountRewardsResponseModel,
    IGetCustomerAccountRewardsV2ResponseModel,
    IGetCustomerRewardsActivityHistoryResponseModel,
    IInactiveOfferModel,
    IPurchaseCustomerAccountRewardResponseModel,
    TCatalogCertificateModel,
    TCatalogCertificatesByCategoryModel,
    TCertificateStatusModel,
    TOffersUnionModel,
    TRewardOfferStatusModel,
} from '../@generated/webExpApi';

export type IRewards = IGetCustomerAccountRewardsResponseModel | IGetCustomerAccountRewardsV2ResponseModel;
export type IRewardsCatalog = TCatalogCertificatesByCategoryModel;

export type TRewardsRecommendations = Array<TCatalogCertificateModel>;

export type IRewardsState = IRewards & {
    loading: boolean;
    applyPromocodeLoading: boolean;
    lastPurchasedCertificate: IPurchaseCustomerAccountRewardResponseModel;
} & {
    rewardsCatalog: IRewardsCatalog;
    rewardsCatalogLoading: boolean;
    rewardsRecommendations: TRewardsRecommendations;
} & {
    rewardsActivityHistory: IGetCustomerRewardsActivityHistoryResponseModel[];
    rewardsActivityHistoryLoading: boolean;
} & {
    autodiscounts: AutoDiscountOfferModel[];
};

export type ISetRewardStatusPayload = {
    rewardId: string;
    status: TCertificateStatusModel | TRewardOfferStatusModel;
};
export type IRewardsActivityHistory = IGetCustomerRewardsActivityHistoryResponseModel[];
export type InactiveOffers = IInactiveOfferModel[];
export type Offers = TOffersUnionModel[];
export type AutoDiscounts = AutoDiscountOfferModel[];
export type Certificates = ICertificateModel[];
export type AppliedPromocode = TOffersUnionModel;
export type LastPurchasedCertificate = IPurchaseCustomerAccountRewardResponseModel | null;
export const initialState: IRewardsState = {
    totalCount: 0,
    certificates: [],
    offers: [],
    loading: false,
    applyPromocodeLoading: false,
    rewardsCatalog: {},
    rewardsRecommendations: [],
    rewardsCatalogLoading: false,
    rewardsActivityHistory: [],
    rewardsActivityHistoryLoading: false,
    lastPurchasedCertificate: null,
    autodiscounts: [],
};

const rewardsSlice = createSlice({
    name: 'rewards',
    initialState,
    reducers: {
        setRewards: (state, action: PayloadAction<IRewardsState>) => {
            state.totalCount = action.payload.totalCount;
            state.certificates = action.payload.certificates;
            state.offers = action.payload.offers;
            state.allLocationsHaveOffers = action.payload.allLocationsHaveOffers;
            state.locationsWithOffers = action.payload.locationsWithOffers;
            state.locationsWithoutOffers = action.payload.locationsWithoutOffers;
            state.loading = false;
            state.autodiscounts = action.payload.autodiscounts || [];
        },
        setAutoDiscounts: (state, action: PayloadAction<AutoDiscounts>) => {
            state.autodiscounts = action.payload || [];
        },
        setAppliedPromocode: (state, action: PayloadAction<AppliedPromocode>) => {
            state.offers = [action.payload, ...state.offers];
        },
        removePromocode: (state, action: PayloadAction<string>) => {
            state.offers = state.offers.filter(
                (offer) =>
                    offer.discountDetailsType !== DiscountDetailsTypeModel.PromoCode &&
                    offer.userOfferId !== action.payload
            );
        },
        setRewardsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setApplyPromocodeLoading: (state, action: PayloadAction<boolean>) => {
            state.applyPromocodeLoading = action.payload;
        },
        setRewardStatus: (state, action: PayloadAction<ISetRewardStatusPayload>) => {
            const { status, rewardId } = action.payload;
            const certificate = state.certificates.find((certificate) => certificate.number === rewardId);
            const offer = state.offers.find((offer) => offer.id === rewardId);
            const reward = certificate || offer;

            if (!reward) return;
            reward.status = status;
        },
        setRewardsCatalog: (state, action: PayloadAction<IRewardsCatalog>) => {
            state.rewardsCatalog = action.payload;
        },
        setRewardsCatalogLoading: (state, action: PayloadAction<boolean>) => {
            state.rewardsCatalogLoading = action.payload;
        },
        setRewardsRecommendations: (state, action: PayloadAction<TRewardsRecommendations>) => {
            state.rewardsRecommendations = action.payload;
        },
        setRewardsActivityHistory: (state, action: PayloadAction<IRewardsActivityHistory>) => {
            state.rewardsActivityHistory = action.payload;
        },
        setRewardsActivityHistoryLoading: (state, action: PayloadAction<boolean>) => {
            state.rewardsActivityHistoryLoading = action.payload;
        },
        setLastPurchasedCertificate: (state, action: PayloadAction<IPurchaseCustomerAccountRewardResponseModel>) => {
            state.lastPurchasedCertificate = action.payload;
        },
    },
});

export const { actions, name } = rewardsSlice;

export default rewardsSlice.reducer;
