export const NOT_SALEABLE_DESCRIPTION =
    '<span>Online ordering is not available</span> for this product at any location.';
export const NOT_SALEABLE_BUTTON_TEXT = 'View menu';
export const NOT_SALEABLE_BUTTON_LINK = '/menu';
export const NO_SAUCE_CATEGORY_ID = 'IDPSubMenu-019';
export const ON_SIDE_SIZE_GROUP_ID = 'IDPSizeGroup-0034';
export const RECOMMENDED_CATEGORY_ID = 'IDPSubMenu-015';
export const NOT_AVAILABLE_DESCRIPTION =
    'This product is only available during a specific period of time. Update your chosen time to order this product or choose another product.';

export const GTM_NOT_SALEABLE_DESCRIPTION = 'Online ordering is not available for this product at any location.';
export const GTM_UNAVAILABLE_AT_LOCATION_CATEGORY = 'Menu Error - Unavailable at Location';
