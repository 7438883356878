import {
    ClaimMissingPointsRequest,
    GetAccountRewardsDiscountRequest,
    GetCustomerAccountRewardsCatalogRequest,
    GetCustomerAccountRewardsRequest,
    GetCustomerRewardsRequest,
    GetQRCodeForOfferRequest,
} from '../../../@generated/webExpApi/apis/WebExperienceApi';
import { WebExperienceApi } from '../../../@generated/webExpApi/apis/index';
import {
    IClaimMissingPointsResponseModel,
    IGetCustomerAccountRewardsCatalogResponseModel,
    IGetCustomerAccountRewardsResponseModel,
    SellingChannelNamesModel,
    IError500ExternalResponseModel,
    IRewardsDiscountDSResponseModel,
    IGetCustomerAccountRewardsV2ResponseModel,
    TOfferStatusModel,
} from '../../../@generated/webExpApi/models';

import createErrorWrapper, { experiencingTechnicalDifficultiesError } from '../createErrorWrapper';
import { getAuthorizationConfig } from '../../helpers/getAuthorizationConfig';
import HttpStatusCode from '../httpStatusCode';

// TODO need to refactor after implement common service to add JWT to secure routes

class DealsService {
    getCustomerAccountRewardsApi: (
        request: GetCustomerAccountRewardsRequest
    ) => Promise<IGetCustomerAccountRewardsResponseModel>;

    getCustomerRewardsApiV2: (request: GetCustomerRewardsRequest) => Promise<IGetCustomerAccountRewardsV2ResponseModel>;

    getQRCodeForOfferApi: (request: GetQRCodeForOfferRequest) => Promise<string>;

    getCustomerAccountRewardsCatalogApi: (
        request: GetCustomerAccountRewardsCatalogRequest
    ) => Promise<IGetCustomerAccountRewardsCatalogResponseModel>;

    claimPointsApi: (
        request: ClaimMissingPointsRequest
    ) => Promise<IClaimMissingPointsResponseModel> | Promise<IError500ExternalResponseModel>;

    getAccountRewardsDiscount: (request: GetAccountRewardsDiscountRequest) => Promise<IRewardsDiscountDSResponseModel>;

    constructor(JWT?: string) {
        const expApi = new WebExperienceApi(getAuthorizationConfig(JWT));

        const expApiV2 = new WebExperienceApi();

        this.getCustomerAccountRewardsApi = createErrorWrapper<
            IGetCustomerAccountRewardsV2ResponseModel,
            GetCustomerAccountRewardsRequest
        >('getAccountDeals', expApi.getCustomerAccountRewards.bind(expApi));

        this.getAccountRewardsDiscount = createErrorWrapper<
            IRewardsDiscountDSResponseModel,
            GetAccountRewardsDiscountRequest
        >('getCustomerAccountRewardsDiscount', expApi.getAccountRewardsDiscount.bind(expApi), {
            [HttpStatusCode.INTERNAL_SERVER_ERROR]: experiencingTechnicalDifficultiesError,
        });

        this.getCustomerAccountRewardsCatalogApi = createErrorWrapper<
            IGetCustomerAccountRewardsCatalogResponseModel,
            GetCustomerAccountRewardsCatalogRequest
        >('getCustomerAccountRewards', expApi.getCustomerAccountRewardsCatalog.bind(expApi));

        this.getQRCodeForOfferApi = createErrorWrapper<string, GetQRCodeForOfferRequest>(
            'getAccountDeals',
            expApi.getQRCodeForOffer.bind(expApi)
        );
        this.claimPointsApi = createErrorWrapper<IClaimMissingPointsResponseModel, ClaimMissingPointsRequest>(
            'claimPoints',
            expApi.claimMissingPoints.bind(expApi)
        );

        this.getCustomerRewardsApiV2 = createErrorWrapper<
            IGetCustomerAccountRewardsV2ResponseModel,
            GetCustomerRewardsRequest
        >('getCustomerRewards', expApiV2.getCustomerRewards.bind(expApiV2));
    }

    getCustomerRewardsV2({
        idpCustomerId,
        location,
        status,
    }: {
        idpCustomerId?: string;
        location: string;
        status: TOfferStatusModel;
    }): Promise<IGetCustomerAccountRewardsV2ResponseModel> {
        return this.getCustomerRewardsApiV2({
            iCustomerRewardsV2BodyModel: {
                idpCustomerId,
                location,
            },
            sellingChannel: SellingChannelNamesModel.Weboa,
            status,
        });
    }

    getCustomerAccountRewards(
        payload: GetCustomerAccountRewardsCatalogRequest
    ): Promise<IGetCustomerAccountRewardsCatalogResponseModel> {
        const { sellingChannel, pointsBalance } = payload;

        return this.getCustomerAccountRewardsCatalogApi({ sellingChannel, pointsBalance });
    }

    getAccountOffersDiscount(payload: GetAccountRewardsDiscountRequest): Promise<IRewardsDiscountDSResponseModel> {
        return this.getAccountRewardsDiscount(payload);
    }

    getAccountDeals(payload: GetCustomerAccountRewardsRequest): Promise<IGetCustomerAccountRewardsResponseModel> {
        const { sellingChannel } = payload;

        return this.getCustomerAccountRewardsApi({ sellingChannel });
    }

    getQRCodeForOffer(payload: GetQRCodeForOfferRequest): Promise<string> {
        return this.getQRCodeForOfferApi({
            userOfferId: payload.userOfferId,
            sellingChannel: SellingChannelNamesModel.Weboa,
        });
    }

    claimPoints(
        payload: ClaimMissingPointsRequest
    ): Promise<IClaimMissingPointsResponseModel> | Promise<IError500ExternalResponseModel> {
        const {
            sellingChannel,
            iClaimMissingPointsFromClientModel: { checkNumber, claimNumber, date, locationId, subTotal },
        } = payload;
        return this.claimPointsApi({
            iClaimMissingPointsFromClientModel: {
                checkNumber,
                claimNumber,
                date,
                locationId,
                subTotal,
            },
            sellingChannel,
        });
    }
}

export default DealsService;
