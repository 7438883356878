import { GtmItemData } from '../services/gtmService/types';
import { RootState } from '../../redux/store';
import {
    selectAllCondiments,
    selectProductGroups,
    selectProductSize,
    selectSelectedModifiers,
} from '../../redux/selectors/domainMenu';

export const getGtmItemData = (data: GtmItemData, state: RootState) => {
    const { childItems, productId, relatedItemGroups } = data;

    const modifierGroups = childItems?.length ? childItems[0].modifierGroups : data.modifierGroups;
    const productGroups = selectProductGroups(state);
    const selectedModifiers = selectSelectedModifiers()(state, childItems?.length ? childItems[0] : data);

    const sauceModifierGroup = modifierGroups?.find((modifierGroup) =>
        productGroups[modifierGroup.productId]?.name?.toLowerCase().includes('sauce')
    );
    const sauces = selectedModifiers
        .filter((selectedModifier) => {
            return sauceModifierGroup?.modifiers.find((modifier) => {
                return selectedModifier.productId === modifier.productId;
            });
        })
        .map((selectedModifier) => selectedModifier.name);

    const toppingModifierGroup = modifierGroups?.find((modifierGroup) =>
        productGroups[modifierGroup.productId]?.name?.toLowerCase().includes('topping')
    );

    const toppings = selectedModifiers
        .filter((selectedModifier) => {
            return toppingModifierGroup?.modifiers.find((modifier) => {
                return selectedModifier.productId === modifier.productId;
            });
        })
        .map((selectedModifier) => selectedModifier.name);

    const allCondiments = selectAllCondiments(state);
    const condimentGroupId = Object.keys(productGroups).find((key) =>
        productGroups[key]?.name.toLowerCase().includes('condiments')
    );
    const condiments =
        relatedItemGroups
            ?.find((item) => item.productId === condimentGroupId)
            ?.modifiers.map(
                ({ productId, quantity }) =>
                    quantity &&
                    allCondiments.find((condiment) => condiment.displayProductDetails.productId === productId)
                        .displayName
            )
            .filter(Boolean) || [];

    const comboSize = childItems?.length ? selectProductSize(state, productId) : 'none';
    const sideFood = childItems?.[1]?.name || 'none';
    const sideDrink = childItems?.[2]?.name || 'none';

    return { sauces, toppings, condiments, comboSize, sideFood, sideDrink };
};
