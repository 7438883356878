/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContentTypeLink } from 'contentful';

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type Unpacked<T> = T extends (infer U)[]
    ? U
    : T extends (...args: any[]) => infer U
    ? U
    : T extends Promise<infer U>
    ? U
    : T;

export enum LoadingStatusEnum {
    Idle = 'Idle',
    Loading = 'Loading',
    Success = 'Success',
    Error = 'Error',
}

export interface InspireCmsEntry<T> {
    fields: T;
    sys: { id: string; contentType: { sys: ContentTypeLink } };
}

export interface InspireCmsEntryCollection<T> {
    items: InspireCmsEntry<T>[];
}

export enum FetchError {
    NetworkError = 'NetworkError',
    AbortError = 'AbortError',
}

export enum BuildOrigin {
    Cloudflare = 'Cloudflare',
    Gitlab = 'Gitlab',
}

export enum TokenSource {
    CLI = 'cli',
    API = 'api',
}
