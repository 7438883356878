import React, { CSSProperties, FunctionComponent } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import {
    IDocumentLink,
    IExternalLink,
    IMenuCategoryLink,
    IPageLink,
    IPhoneNumberLink,
    IProduct,
} from '../../../@generated/@types/contentful';

import styles from './button.module.css';
import { getLinkDetails } from '../../../lib/link';
import useGlobalProps from '../../../redux/hooks/useGlobalProps';

// TODO: remove sizes from type
export type InspireButtonType = 'primary' | 'secondary' | 'large' | 'small';
type InspireButtonSizeType = 'large' | 'small';

export interface IInspireButtonProps {
    link?: IExternalLink | IPageLink | IMenuCategoryLink | IPhoneNumberLink | IProduct | IDocumentLink | string;
    text?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    disabled?: boolean;
    className?: string;
    linkClassName?: string;
    type?: InspireButtonType;
    size?: InspireButtonSizeType;
    gtmId?: string;
    submit?: boolean;
    fullWidth?: boolean;
    ariaLabel?: string;
    dataTestId?: string;
    id?: string;
    newtab?: boolean;
    willChangeTransform?: boolean;
    style?: CSSProperties | undefined;
    isPreviewOnly?: boolean;
}

export const InspireButton: FunctionComponent<IInspireButtonProps> = (props) => {
    const {
        link,
        type = 'primary',
        size,
        text,
        onClick,
        children,
        disabled,
        className,
        style,
        linkClassName,
        gtmId,
        submit,
        fullWidth,
        ariaLabel,
        dataTestId,
        id,
        newtab,
        willChangeTransform,
        isPreviewOnly = false,
    } = props;

    const { productDetailsPagePaths } = useGlobalProps();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        onClick && onClick(event);
    };

    const buttonClasses = classnames(
        styles.button,
        {
            [styles[type]]: !!type,
            [styles[size]]: !!size,
            [styles.fullWidth]: !!fullWidth,
            [styles.willChangeTransform]: !!willChangeTransform,
        },
        className
    );

    if (!link) {
        const attributes = {
            'data-gtm-id': gtmId,
            'data-testid': dataTestId,
            id: id,
            className: buttonClasses,
            'aria-label': ariaLabel,
            style,
        };
        return isPreviewOnly ? (
            <div {...attributes}>{children || text}</div>
        ) : (
            <button
                {...attributes}
                type={submit ? 'submit' : 'button'}
                disabled={disabled}
                onClick={handleClick}
                tabIndex={0}
            >
                {children || text}
            </button>
        );
    }

    const { href, isExternal, isPhone, name } = getLinkDetails(link, {
        productDetailsPagePaths,
    });

    const linkClasses = classnames(buttonClasses, styles.link, linkClassName);

    return isExternal || newtab || isPhone ? (
        <a
            id={id}
            data-gtm-id={gtmId}
            data-testid={dataTestId}
            target={isExternal || newtab ? '_blank' : undefined}
            rel="noreferrer"
            href={isPhone ? `tel:${href}` : href}
            className={linkClasses}
            style={style}
            onClick={handleClick}
        >
            {children || text || name}
        </a>
    ) : (
        <Link href={href}>
            <a
                id={id}
                data-gtm-id={gtmId}
                data-testid={dataTestId}
                style={style}
                className={classnames(linkClasses, {
                    [styles.disabled]: disabled,
                })}
                onClick={handleClick}
            >
                {children || text || name}
            </a>
        </Link>
    );
};
