import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetCustomerOrderHistoryResponseModel, IOrderModel, OrderStatusEnumModel } from '../@generated/webExpApi';

export type IOrderHistory = IOrderModel[];

export type ISetOrderHistoryPayload = IGetCustomerOrderHistoryResponseModel;

export interface ISetOrderStatusById {
    orderId: string;
    status: OrderStatusEnumModel;
}

export type IOrderHistoryState = {
    orderHistory: IOrderHistory;
    loading: boolean;
};

export const initialState: IOrderHistoryState = {
    orderHistory: [],
    loading: false,
};

const orderHistorySlice = createSlice({
    name: 'orderHistory',
    initialState,
    reducers: {
        setOrderHistory: (state, action: PayloadAction<ISetOrderHistoryPayload>) => {
            state.orderHistory = action.payload.orders;
            state.loading = false;
        },
        setOrderHistoryLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setOrderStatusById: (state, action: PayloadAction<ISetOrderStatusById>) => {
            const order = state.orderHistory.find((order) => order.id === action.payload.orderId);
            if (order) {
                order.orderStatus = action.payload.status;
            }
        },
    },
});

export const { actions, name } = orderHistorySlice;

export default orderHistorySlice.reducer;
