export type PendingCallback = (value: boolean) => void;

class NavInterceptService {
    private request: Promise<boolean> | undefined;
    private pendingCallback: PendingCallback;

    private setPendingCallback(callback: PendingCallback): void {
        this.pendingCallback = callback;
    }

    public intercept(): Promise<boolean> {
        if (this.request) {
            throw new Error('Navigation interception has already been requested');
        }

        this.request = new Promise<boolean>((resolve) => {
            this.setPendingCallback(resolve);
        })
            .then((result) => {
                this.request = undefined;

                return result;
            })
            .catch((error) => {
                this.request = undefined;

                console.warn('[NavInterceptService]', error);

                return false;
            });

        return this.request;
    }

    public navigate(result: boolean) {
        if (this.pendingCallback) {
            this.pendingCallback(result);
        }
    }
}

export default new NavInterceptService();
