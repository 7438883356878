import { TallyProductModel, TallyResponseModel } from '../../@generated/webExpApi';

export const mergeTallyWithBag = (
    tallyResponse: TallyResponseModel,
    bagProducts: TallyProductModel[]
): TallyResponseModel => {
    const restoredProducts = bagProducts?.map((product) => {
        const responseProduct = tallyResponse?.products?.find((resProduct) => {
            return resProduct.productId === product.productId && resProduct?.lineItemId === product?.lineItemId;
        });

        const modifierGroups = product?.modifierGroups?.map((modifierGroup) => {
            const responseModifierGroup = responseProduct?.modifierGroups?.find(
                (resModifierGroup) => resModifierGroup.productId === modifierGroup.productId
            );

            const restoredModifiers = modifierGroup.modifiers?.map((modifier) => {
                const responseModifier = responseModifierGroup?.modifiers?.find(
                    (resModifier) => resModifier.productId === modifier.productId
                );

                return {
                    ...modifier,
                    actionCode: responseModifier?.actionCode,
                    price: responseModifier?.price || 0,
                };
            });

            return { ...modifierGroup, modifiers: restoredModifiers };
        });

        return { ...product, discounts: responseProduct?.discounts, modifierGroups, price: responseProduct?.price };
    });

    return { ...tallyResponse, products: restoredProducts };
};
