import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Screens, SCREEN_RESOLUTION } from '../constants/screens';

export interface IUseScreen {
    isDesktop: boolean;
    isMobile: boolean;
}

/**
 * @typedef IUseScreen
 * @property {boolean} isDesktop
 * @property {boolean} isMobile
 */
/**
 * Method that allow to export the popular sizes isDesktop, isMobile that we use in typography
 * @method useScreen
 * @returns {IUseScreen}
 * @author Siarhei Amelyaniuk <siarhei.amelyaniuk@inspirebrands.com>
 * @added 2023-03-23
 * @version 1.0
 * @example
 * const {isDesktop, isMobile} = useScreen();
 * if (isDesktop) {
 *   // render content for (min-width: 960px)
 * }
 * if (!isDesktop) {
 *   // render content for (max-width: 959px)
 * }
 * if (isMobile) {
 *   // render content for (max-width: 768px)
 * }
 */
export const useScreen = (defaultMatches = false): IUseScreen => {
    const isDesktop = useMediaQuery(`(min-width: ${SCREEN_RESOLUTION[Screens.Desktop]}px)`, {
        defaultMatches,
    });
    const isMobile = useMediaQuery(`(max-width: ${SCREEN_RESOLUTION[Screens.Mobile]}px)`, {
        defaultMatches,
    });

    return { isDesktop, isMobile };
};
