import {
    ActivateCustomerOfferRequest,
    CancelCertificateRequest,
    WebExperienceApi,
    IActivateOfferModelModelOperationEnum,
    SellingChannelNamesModel,
    PurchaseCustomerOfferRequest,
    IPurchaseCustomerAccountRewardResponseModel,
    GetCustomerRewardsActivityHistoryRequest,
    IGetCustomerRewardsActivityHistoryResponseModel,
    CustomerCheckinRequest,
    ICheckinModel,
    IError500ExternalResponseModel,
} from '../../../@generated/webExpApi';

import createErrorWrapper from '../createErrorWrapper';
import { getAuthorizationConfig } from '../../helpers/getAuthorizationConfig';

class CustomerRewardsService {
    private readonly activateCustomerOfferApi: (request: ActivateCustomerOfferRequest) => Promise<void>;
    private readonly cancelCertificateApi: (request: CancelCertificateRequest) => Promise<void>;
    private readonly purchaseCustomerOfferApi: (
        request: PurchaseCustomerOfferRequest
    ) => Promise<IPurchaseCustomerAccountRewardResponseModel>;
    private readonly getCustomerRewardsActivityHistory: (
        request: GetCustomerRewardsActivityHistoryRequest
    ) => Promise<Array<IGetCustomerRewardsActivityHistoryResponseModel>>;
    private readonly customerCheckin: (request: CustomerCheckinRequest) => Promise<ICheckinModel>;

    constructor(JWT: string) {
        const expApi = new WebExperienceApi(getAuthorizationConfig(JWT));
        this.activateCustomerOfferApi = createErrorWrapper<void, ActivateCustomerOfferRequest>(
            'activateOffer',
            expApi.activateCustomerOffer.bind(expApi)
        );
        this.cancelCertificateApi = createErrorWrapper<void, CancelCertificateRequest>(
            'cancelCertificate',
            expApi.cancelCertificate.bind(expApi)
        );
        this.purchaseCustomerOfferApi = createErrorWrapper<
            IPurchaseCustomerAccountRewardResponseModel,
            PurchaseCustomerOfferRequest
        >('purchaseOffer', expApi.purchaseCustomerOffer.bind(expApi));
        this.getCustomerRewardsActivityHistory = createErrorWrapper<
            IGetCustomerRewardsActivityHistoryResponseModel[],
            GetCustomerRewardsActivityHistoryRequest
        >('getRewardsActivityHistory', expApi.getCustomerRewardsActivityHistory.bind(expApi));
        this.customerCheckin = createErrorWrapper<ICheckinModel, CustomerCheckinRequest>(
            'checkinCustomer',
            expApi.customerCheckin.bind(expApi)
        );
    }

    activateOffer(payload: {
        rewardId: string;
        code: string;
        operation: IActivateOfferModelModelOperationEnum;
    }): Promise<void> {
        const { rewardId, code, operation } = payload;
        return this.activateCustomerOfferApi({ rewardId, iActivateOfferModelModel: { code, operation } });
    }

    cancelCertificate(payload: { rewardId: string }): Promise<void> {
        const { rewardId } = payload;
        return this.cancelCertificateApi({ rewardId, sellingChannel: SellingChannelNamesModel.Weboa });
    }

    purchaseOffer(
        code: string
    ): Promise<IPurchaseCustomerAccountRewardResponseModel> | Promise<IError500ExternalResponseModel> {
        return this.purchaseCustomerOfferApi({
            rewardId: code,
        });
    }
    getRewardsActivityHistory(dateFrom: string): Promise<IGetCustomerRewardsActivityHistoryResponseModel[]> {
        return this.getCustomerRewardsActivityHistory({ dateFrom });
    }

    checkin(latitude: number, longitude: number): Promise<ICheckinModel> {
        return this.customerCheckin({
            iCheckInWithLocationModelModel: { latitude, longitude },
            sellingChannel: SellingChannelNamesModel.Weboa,
        });
    }
}

export default CustomerRewardsService;
