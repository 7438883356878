import { useSelector, useDispatch } from 'react-redux';
import { RootState, useAppSelector } from '../store';
import * as AccountStore from '../account';
import { PayloadAction } from '@reduxjs/toolkit';
import { selectAccountInfo } from '../selectors/account';
interface IUseAccountHook {
    account: AccountStore.Account;
    accountInfo: AccountStore.AccountInfo;
    actions: {
        setAccount: (payload: AccountStore.Account) => PayloadAction<AccountStore.Account>;
    };
}

export default function UseAccount(): IUseAccountHook {
    const dispatch = useDispatch();

    const account = useSelector<RootState, AccountStore.Account>((state) => state.account);
    const accountInfo = useAppSelector(selectAccountInfo);
    const setAccount = (payload: AccountStore.Account) => dispatch(AccountStore.actions.setAccount(payload));

    return {
        account,
        accountInfo,
        actions: {
            setAccount,
        },
    };
}
