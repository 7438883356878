import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

export const selectAccount = (state: RootState): RootState['account'] => state.account;

export const selectAccountInfo = createSelector(selectAccount, (account) => {
    if (!account) return null;

    return {
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        birthDate: account.birthDate && account.birthDate.replace(/-/g, '/'),
        phone: account.phones?.[0]?.number.replace(/-/gi, '') ?? '',
        phoneNumber: account?.phones?.[0]?.number ?? '',
        postalCode: account.preferences?.postalCode,
        preferences: account.preferences,
        profileAvatarUrl: account.profileAvatarUrl,
    };
});
