import logger from '../../common/services/logger';
import { FetchError } from '../types';

const addJsonError = async <T>(api: string, apiCall: Promise<T>): Promise<T> => {
    const startTime = Date.now();
    try {
        const result = await apiCall;
        logger.logEvent(`Domain api latency for ${api}`, { api, latency: Date.now() - startTime });
        return result;
    } catch (err) {
        if (err.name === FetchError.AbortError) {
            logger.logError(`The request has been aborted`, err);
            return Promise.reject({ ...err, code: err.name });
        }

        const status = err.status;
        const hasErrorStatus = status && status >= 400;
        if (hasErrorStatus) {
            logger.logEvent(`Domain api latency for ${api}`, { api, latency: Date.now() - startTime });
        }

        let message;
        console.log(err);

        try {
            message = await err.json();
            // eslint-disable-next-line no-empty
        } catch (err) {
            console.log(err);
        }

        if (hasErrorStatus && message) {
            logger.logError(`Error while invoking domain service ${api}`, {
                api,
                apiStatusCode: status,
                apiResponse: message,
                ...err,
            });

            return Promise.reject({ ...message, status });
        }

        logger.logError(`Error while invoking domain service ${api}`, {
            ...err,
            api,
            apiResponse: message,
            apiStatusCode: err.status,
        });

        throw err;
    }
};

export default addJsonError;
