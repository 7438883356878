import { useMemo, useCallback, useEffect, useRef } from 'react';
import { Entry } from 'contentful';
import { IActionParameterFields } from '../../@generated/@types/contentful';
import { useAppDispatch, useAppSelector } from '../store';
import { useGlobalProps } from '.';
import { selectPersonalizationFacts, selectIsAllDependenciesInitialized } from '../selectors/personalization';
import * as PersonalizationStore from '../personalization';
import { isFactsChanged } from '../../common/helpers/personalization';
import { FACTS_DEPENDENCIES } from '../../common/constants/personalization';
import { isPersonalizationEnabled } from '../../lib/getFeatureFlags';
import { InspireCmsEntry } from '../../common/types';

interface IUsePersonalizationHook {
    loading: boolean;
    actions: {
        getPersonalizedSection: (tableRef: string) => InspireCmsEntry<IActionParameterFields>;
        initializePersonalizationDependency: (dep: FACTS_DEPENDENCIES) => void;
    };
}

export const usePersonalization = (): IUsePersonalizationHook => {
    const { loading, actions } = useAppSelector((state) => state.personalization);
    const { actionParameters } = useGlobalProps();
    const dispatch = useAppDispatch();

    const actionParamRefByTableRef = useMemo(
        () =>
            Object.keys(actions).reduce((acc, tableRef) => {
                return { ...acc, [tableRef]: actions[tableRef].section };
            }, {} as Record<string, string>),
        [actions]
    );

    const actionParamByRef = useMemo(() => {
        if (!actionParameters) return {};

        return actionParameters.items.reduce((acc, actionParameter) => {
            return { ...acc, [actionParameter.fields.tableReference]: actionParameter };
        }, {} as Record<string, Entry<IActionParameterFields>>);
    }, [actionParameters]);

    const getPersonalizedSection = useCallback(
        (tableRef: string) => {
            return actionParamByRef[actionParamRefByTableRef[tableRef]];
        },
        [actionParamRefByTableRef, actionParamByRef]
    );

    const initializePersonalizationDependency = (dep: FACTS_DEPENDENCIES) => {
        if (isPersonalizationEnabled()) {
            dispatch(PersonalizationStore.actions.initializeDependency(dep));
        }
    };

    return {
        loading,
        actions: {
            getPersonalizedSection,
            initializePersonalizationDependency,
        },
    };
};

export const usePersonalizationFactsChangeListener = (): void => {
    const dispatch = useAppDispatch();
    const facts = useAppSelector(selectPersonalizationFacts);
    const isAllDepsInitialized = useAppSelector(selectIsAllDependenciesInitialized);
    const prevFactsRef = useRef([]);
    const promiseRef = useRef<{ abort: () => void }>();

    useEffect(() => {
        if (isAllDepsInitialized) {
            if (isFactsChanged(prevFactsRef.current, facts)) {
                if (promiseRef.current) {
                    // abort previous request if it in progress
                    promiseRef.current.abort();
                }

                promiseRef.current = dispatch(PersonalizationStore.actions.getActionsMap(facts));
            }

            prevFactsRef.current = facts;
        } else {
            // wait until all dependencies loaded
            dispatch(PersonalizationStore.actions.setLoading(true));
        }
    }, [facts, prevFactsRef, dispatch, isAllDepsInitialized]);
};
