/**
 * @namespace noop
 */

/**
 * @method noop
 * @memberOf noop
 * @description This method is used to return “undefined” irrespective of the arguments passed to it.
 * @returns {undefined} This method returns undefined.
 */

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};
