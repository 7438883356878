import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILocationByStateOrProvinceDetailsModel } from '../@generated/webExpApi';

export type PutLocationDetailsPayload = ILocationByStateOrProvinceDetailsModel;

export interface LDPState {
    locationDetails: ILocationByStateOrProvinceDetailsModel;
}

export const initialState: LDPState = {
    locationDetails: null,
};

const ldpSlice = createSlice({
    name: 'lpd',
    initialState,
    reducers: {
        resetLocationDetails: (state) => {
            state.locationDetails = null;
        },
        putLocationDetails: (state, action: PayloadAction<ILocationByStateOrProvinceDetailsModel>) => {
            state.locationDetails = action.payload;
        },
    },
});
export const { actions } = ldpSlice;
export default ldpSlice.reducer;
