import { EnhancedStore } from '@reduxjs/toolkit';
import { Dispatch, Action, AnyAction } from 'redux';

import { RootState } from '../store';
import * as NotificationsStore from '../notifications';

const unhandledErrorActionTypes = ['localTapList/getLocalTapList/rejected'];

const errorsMiddleware = (store: EnhancedStore<RootState>) => (next: Dispatch<AnyAction>) => (
    action: AnyAction
): Action<AnyAction> => {
    const actionType: string = action.type.toString();
    const isAbortError = action.error?.name === 'AbortError';
    const isHandledActionType = actionType.includes('/rejected') && !unhandledErrorActionTypes.includes(actionType);
    const hideNotification = action.payload?.hideNotification;

    if (!isAbortError && isHandledActionType && !hideNotification) {
        store.dispatch(NotificationsStore.createEnqueueNotificationAction(action.error || action.payload));
    }

    return next(action as AnyAction);
};

export default errorsMiddleware;
