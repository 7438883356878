import { Asset } from 'contentful';

const imageSizeBreakPoints = [300, 600, 960, 1280, 1920];

export enum ContentfulImageFormats {
    AVIF = 'avif',
    WEBP = 'webp',
}

export const getImageUrl = (asset: Asset, width = 1500): string =>
    asset?.fields?.file?.url ? `${asset.fields.file.url}${width ? `?w=${width}` : ''}` : null;

export const getImageSrcset = (asset: Asset, width = 1500): string =>
    asset?.fields?.file?.url ? `${asset.fields.file.url}?fm=webp${width ? `&w=${width}` : ''}` : null;

export const getResponsiveImagesSrcSet = (
    asset: Asset | string,
    format: ContentfulImageFormats,
    quality = 80,
    maxWidth?: number
): string => {
    const breakpoints =
        maxWidth && imageSizeBreakPoints.indexOf(maxWidth) === -1
            ? [...imageSizeBreakPoints, ...[maxWidth]]
            : [...imageSizeBreakPoints];

    return breakpoints
        .filter((breakpointWidth) => !maxWidth || breakpointWidth <= maxWidth)
        .map((breakpointWidth) => {
            return `${
                typeof asset === 'string' ? asset : asset.fields.file.url
            }?fm=${format}&w=${breakpointWidth}&q=${quality} ${breakpointWidth}w`;
        })
        .join(', ');
};

export const getImageSizes = (maxWidth?: number): string => {
    const breakpoints = (maxWidth && imageSizeBreakPoints.indexOf(maxWidth) === -1
        ? [...imageSizeBreakPoints, ...[maxWidth]]
        : [...imageSizeBreakPoints]
    ).filter((breakpointWidth) => !maxWidth || breakpointWidth <= maxWidth);

    return breakpoints
        .map((breakpointWidth, index) => {
            return `${
                index + 1 < breakpoints.length
                    ? `(max-width: ${breakpointWidth}px) ${breakpointWidth}px`
                    : `${breakpointWidth}px`
            }`;
        })
        .join(', ');
};
