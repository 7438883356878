import {
    IOrderVerifyResponseModel,
    ITallyError500ExternalResponseModel,
    OrderRequest,
    OrderResponseModel,
    VerifyRequest,
    OrderV2Request,
    WebExperienceApi,
    InternalRequest,
    IOrderModel,
} from '../../../@generated/webExpApi';
import { isOrderV4Enabled } from '../../../lib/getFeatureFlags';

import createErrorWrapper from '../createErrorWrapper';

class OrderService {
    verifyOrder: (request: VerifyRequest) => Promise<IOrderVerifyResponseModel | ITallyError500ExternalResponseModel>;
    createV3Order: (request: OrderRequest) => Promise<OrderResponseModel | ITallyError500ExternalResponseModel>;
    createV4Order: (request: OrderV2Request) => Promise<OrderResponseModel | ITallyError500ExternalResponseModel>;
    createOrder: (
        request: OrderRequest | OrderV2Request
    ) => Promise<OrderResponseModel | ITallyError500ExternalResponseModel>;
    getOrderByIdempotentId: (request: InternalRequest) => Promise<IOrderModel>;

    constructor() {
        const expApi = new WebExperienceApi();

        this.verifyOrder = createErrorWrapper<IOrderVerifyResponseModel, VerifyRequest>(
            'verifyOrder',
            expApi.verify.bind(expApi)
        );

        this.createV3Order = createErrorWrapper<OrderResponseModel, OrderRequest>(
            'createOrder',
            expApi.order.bind(expApi)
        );

        this.createV4Order = createErrorWrapper<OrderResponseModel, OrderV2Request>(
            'createV4Order',
            expApi.orderV2.bind(expApi)
        );

        const isOrderV4Request = (request: OrderRequest | OrderV2Request): request is OrderV2Request => {
            return isOrderV4Enabled() && 'orderV2RequestModel' in request;
        };

        this.createOrder = (request: OrderRequest | OrderV2Request) => {
            if (isOrderV4Request(request)) {
                return this.createV4Order(request);
            }

            return this.createV3Order(request);
        };

        this.getOrderByIdempotentId = createErrorWrapper<IOrderModel, InternalRequest>(
            'getOrderByIdempotentId',
            expApi.internal.bind(expApi)
        );
    }
}

export default new OrderService();
