import React, { FC } from 'react';

import BrandLoader from '../../atoms/BrandLoader';
import { usePageLoader } from '../../../redux/hooks';
import styles from './pageLoader.module.css';
import Modal from '@material-ui/core/Modal';

const PageLoader: FC = () => {
    const { show } = usePageLoader();

    return (
        <Modal disableAutoFocus open={show} className={styles.modal}>
            <BrandLoader className={styles.pageLoader} />
        </Modal>
    );
};

export default PageLoader;
