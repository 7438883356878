import { capitalize } from '../helpers/capitalize';
import { useBreadcrumbs } from './useBreadcrumbs';

export const useBreadcrumbSchema = () => {
    const breadcrumbsPaths = useBreadcrumbs();
    const breadcrumbsSchema = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
            {
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@id': process.env.NEXT_PUBLIC_APP_URL,
                    name: 'Home',
                },
            },
            ...breadcrumbsPaths.map(({ as, title }, i) => {
                return {
                    '@type': 'ListItem',
                    position: i + 2,
                    item: {
                        '@id': `${process.env.NEXT_PUBLIC_APP_URL}${as}`,
                        name: capitalize(title),
                    },
                };
            }),
        ],
    };

    return breadcrumbsSchema;
};
