import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import * as DismissedAlertBannersStore from '../dismissedAlertBanners';
import { PayloadAction } from '@reduxjs/toolkit';

interface UseDismissedAlertBanners {
    bannerEntries: DismissedAlertBannersStore.BannerEntry[];
    actions: {
        dismissBanner: (
            payload: DismissedAlertBannersStore.DismissBannerPayload
        ) => PayloadAction<DismissedAlertBannersStore.DismissBannerPayload>;
    };
}

export default function useDismissedAlerBanners(): UseDismissedAlertBanners {
    const dispatch = useDispatch();

    const bannerEntries = useSelector<RootState, DismissedAlertBannersStore.BannerEntry[]>(
        (state) => state.dismissedAlertBanners
    );

    const dismissBanner = (payload: DismissedAlertBannersStore.DismissBannerPayload) =>
        dispatch(DismissedAlertBannersStore.actions.dismissBanner(payload));

    return {
        bannerEntries,
        actions: {
            dismissBanner,
        },
    };
}
