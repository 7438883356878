import React, { FC } from 'react';
import MaterialDivider from '@material-ui/core/Divider';
import styles from './divider.module.css';
import classNames from 'classnames';

export interface IDividerProps {
    orientation?: 'vertical' | 'horizontal';
    className?: string;
}

export const Divider: FC<IDividerProps> = ({ orientation = 'vertical', className, ...props }) => {
    return (
        <MaterialDivider
            classes={{
                root: classNames(styles.divider, className, {
                    [styles.vertical]: orientation === 'vertical',
                }),
            }}
            orientation={orientation}
            {...props}
        />
    );
};
