import {
    createAction,
    // createAsyncThunk,
    createSlice,
    PayloadAction,
    SliceCaseReducers,
    ThunkAction,
    // ThunkDispatch,
} from '@reduxjs/toolkit';

import navInterceptService from '../common/services/navInterceptService';
import { RootState } from './store';

const NAV_INTERCEPT = 'nav/intercept';

const pending = createAction<void>(`${NAV_INTERCEPT}/pending`);
const fulfilled = createAction<boolean>(`${NAV_INTERCEPT}/fulfilled`);

export interface INavInterceptState {
    isPending: boolean;
    hasChanges: boolean;
}

export const setHasChanges = createAction<boolean>(`${NAV_INTERCEPT}/hasChanges`);

export const requestNavIntercept = (): ThunkAction<
    Promise<PayloadAction<boolean>>,
    RootState,
    void,
    PayloadAction<boolean>
> => {
    return (dispatch, getState): Promise<PayloadAction<boolean>> => {
        const { navIntercept } = getState();

        if (navIntercept.hasChanges) {
            dispatch(pending());

            return navInterceptService.intercept().then((result) => dispatch(fulfilled(result)));
        }

        return Promise.resolve(dispatch(fulfilled(true)));
    };
};

// export const requestNavIntercept = createAsyncThunk<boolean>(
//     NAV_INTERCEPT,
//     async (_, { getState }): Promise<boolean> => {
//         const { navIntercept } = getState() as RootState;
//         return navIntercept.hasChanges ? navInterceptService.intercept() : Promise.resolve(true);
//     }
// );

const navInterceptSlice = createSlice<INavInterceptState, SliceCaseReducers<INavInterceptState>>({
    name: NAV_INTERCEPT,
    initialState: { isPending: false, hasChanges: false },
    reducers: {},
    extraReducers: {
        [setHasChanges.type]: (state: INavInterceptState, action: PayloadAction<boolean>): INavInterceptState => ({
            ...state,
            hasChanges: action.payload,
        }),
        [pending.type]: (state: INavInterceptState): INavInterceptState => ({
            ...state,
            isPending: true,
        }),
        [fulfilled.type]: (state: INavInterceptState, action: PayloadAction<boolean>): INavInterceptState => {
            return {
                ...state,
                isPending: false,
                hasChanges: !action.payload,
            };
        },
    },
});

export const { actions } = navInterceptSlice;

export default navInterceptSlice.reducer;
