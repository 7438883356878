export enum TFeatures {
    account,
    mainProductDealsBanner,
    isAccountDealsPage,
    isSessionMRewardsOn,
    showLocationsAsOrderedList,
    locationSpecificMenuCategories,
    signUpPhoneTooltip,
    isDeliveryEnabled,
    isAccountRewardsPage,
    isEpsilonRewardsOn,
    locationPages,
    localTapList,
    shoppingBagLocationSelect,
    locationTimeSlotsEnabled,
    customerPaymentMethod,
    placeholderPaymentOption,
    orderHistory,
    isTippingEnabled,
    isDineTimeEnabled,
    isOpenTableEnabled,
    isGooglePayEnabled,
    isApplePayEnabled,
    personalization,
    isOAEnabled,
    isFraudCheckEnabled,
    isPlayExperienceEnabled,
    isDineInOrdersEnabled,
    isCaloriesDisplayEnabled,
    isBreadcrumbSchemaOn,
    isGiftCardPayEnabled,
    isGiftCardSaveEnabled,
    isCreditOrDebitPayEnabled,
    isCardOnFilePayEnabled,
    isPayAtStoredEnabled,
    isSingUpBannerOnConfirmationEnabled,
    isMyTeamsEnabled,
    isMarketingPreferencesSignupEnabled,
    showNotificationSettingsByEmail,
    showUnavailableItemsByLocationModal,
    isLocationListEnhancementEnabled,
    showIsLocationTemporarilyUnavailable,
    watchMyGeoLocation,
    watchMyGeoLocationOnSafari,
    isProductsFilteringEnabled,
    isTopPicksFilteringEnabled,
    validateMaxItemAmount,
    isHeadsUpMessageEnabled,
    isDealLinkEnabledInPencilBanner,
    isBranchIOEnabled,
    isFutureOrderMessageEnabled,
    isOneClickRegisterOfferEnabled,
    isOrderV4Enabled,
    isReCaptchaEnabled,
    isTextMessageSignUpPageEnabled,
    isSonicOMSRewardsOn,
    isSocialCTAEnabled,
    isContactUsScriptEnabled,
    isPromoCodeEnabled,
    navigateToOrderFailure,
    isOrderDetailsPageEnabled,
    isOrderCheckInEnabled,
    mainRewardsBanner,
    isBadgeEnabled,
    isBrazeEnabled,
}

type TFeaturesValues = keyof typeof TFeatures;

export type IFeatureFlags = {
    [K in TFeaturesValues]: boolean;
};

declare const FEATURE_FLAGS: IFeatureFlags;

export const getFeatureFlags = (): IFeatureFlags => {
    // please consider that webpack wil literally replace `FEATURE_FLAGS` with `{}` object
    return FEATURE_FLAGS;
};

export const getFeature = (name: TFeaturesValues): boolean => {
    return FEATURE_FLAGS[name] || false;
};

export const isAccountOn = (): boolean => getFeature('account');

export const isMainProductDealsBannerOn = (): boolean => getFeature('mainProductDealsBanner');

export const isAccountDealsPage = (): boolean => getFeature('isAccountDealsPage');

export const isSessionMRewardsOn = (): boolean => getFeature('isSessionMRewardsOn');

export const isLocationsShowAsOrderedList = (): boolean => getFeature('showLocationsAsOrderedList');

export const locationSpecificMenuCategoriesEnabled = (): boolean => getFeature('locationSpecificMenuCategories');

export const isSignUpPhoneTooltip = (): boolean => getFeature('signUpPhoneTooltip');

export const isDeliveryFlowEnabled = (): boolean => getFeature('isDeliveryEnabled');

export const isAccountRewardsPage = (): boolean => getFeature('isAccountRewardsPage');

export const isEpsilonRewardsOn = (): boolean => getFeature('isEpsilonRewardsOn');

export const isOneClickRegisterOfferOn = (): boolean => getFeature('isOneClickRegisterOfferEnabled');

export const isLocationPagesOn = (): boolean => getFeature('locationPages');

export const isLocalTapListOn = (): boolean => getFeature('localTapList');

export const isShoppingBagLocationSelectComponentEnabled = (): boolean => getFeature('shoppingBagLocationSelect');

export const locationTimeSlotsEnabled = (): boolean => getFeature('locationTimeSlotsEnabled');

export const isCustomerPaymentMethodEnabled = (): boolean => getFeature('customerPaymentMethod');

export const isPlaceholderPaymentOptionEnabled = (): boolean => getFeature('placeholderPaymentOption');

export const isOrdersHistoryOn = (): boolean => getFeature('orderHistory');

export const isTippingEnabled = (): boolean => getFeature('isTippingEnabled');

export const isDineTimeEnabled = (): boolean => getFeature('isDineTimeEnabled');

export const isOpenTableEnabled = (): boolean => getFeature('isOpenTableEnabled');

export const isGooglePayEnabled = (): boolean => getFeature('isGooglePayEnabled');

export const isApplePayEnabled = (): boolean => getFeature('isApplePayEnabled');

export const isPersonalizationEnabled = (): boolean => getFeature('personalization');

export const isOAEnabled = (): boolean => getFeature('isOAEnabled');

export const isFraudCheckEnabled = (): boolean => getFeature('isFraudCheckEnabled');

export const isPlayExperienceEnabled = (): boolean => getFeature('isPlayExperienceEnabled');

export const isDineInOrdersEnabled = (): boolean => getFeature('isDineInOrdersEnabled');

export const isCaloriesDisplayEnabled = (): boolean => getFeature('isCaloriesDisplayEnabled');

export const isBreadcrumbSchemaOn = (): boolean => getFeature('isBreadcrumbSchemaOn');

export const isGiftCardPayEnabled = (): boolean => getFeature('isGiftCardPayEnabled');

export const isGiftCardSaveEnabled = (): boolean => getFeature('isGiftCardSaveEnabled');

export const isCreditOrDebitPayEnabled = (): boolean => getFeature('isCreditOrDebitPayEnabled');

export const isCardOnFilePayEnabled = (): boolean => getFeature('isCardOnFilePayEnabled');

export const isPayAtStoredEnabled = (): boolean => getFeature('isPayAtStoredEnabled');

export const isSingUpBannerOnConfirmationEnabled = (): boolean => getFeature('isSingUpBannerOnConfirmationEnabled');

export const isMyTeamsEnabled = (): boolean => getFeature('isMyTeamsEnabled');

export const isMarketingPreferencesSignupEnabled = (): boolean => getFeature('isMarketingPreferencesSignupEnabled');

export const showNotificationSettingsByEmail = (): boolean => getFeature('showNotificationSettingsByEmail');

export const showUnavailableItemsByLocationModal = (): boolean => getFeature('showUnavailableItemsByLocationModal');

/*DBBP-69945 Location List enhancement will start with BWW, but we are looking to apply this for the other brands,
 * once this is confirmed we should remove the featureFlag isLocationListEnhancementEnabled and remove the deprecated code
 * from locationsListItem.tsx.
 * */
export const isLocationListEnhancementEnabled = (): boolean => getFeature('isLocationListEnhancementEnabled');

export const showIsLocationTemporarilyUnavailable = (): boolean => getFeature('showIsLocationTemporarilyUnavailable');
export const watchMyGeoLocation = (): boolean => getFeature('watchMyGeoLocation');
export const watchMyGeoLocationOnSafari = (): boolean => getFeature('watchMyGeoLocationOnSafari');

export const isProductsFilteringEnabled = (): boolean => getFeature('isProductsFilteringEnabled');
export const isTopPicksFilteringEnabled = (): boolean => getFeature('isTopPicksFilteringEnabled');
export const validateMaxItemAmount = (): boolean => getFeature('validateMaxItemAmount');
export const isHeadsUpMessageEnabled = (): boolean => getFeature('isHeadsUpMessageEnabled');
export const isDealLinkEnabledInPencilBanner = (): boolean => getFeature('isDealLinkEnabledInPencilBanner');

export const isBranchIOEnabled = (): boolean => getFeature('isBranchIOEnabled');
export const isOrderV4Enabled = (): boolean => getFeature('isOrderV4Enabled');
export const isReCaptchaEnabled = (): boolean => getFeature('isReCaptchaEnabled');

export const isTextMessageSignUpPageEnabled = (): boolean => getFeature('isTextMessageSignUpPageEnabled');

export const isFutureOrderMessageEnabled = (): boolean => getFeature('isFutureOrderMessageEnabled');

export const isSonicOMSRewardsOn = (): boolean => getFeature('isSonicOMSRewardsOn');

export const isSocialCTAEnabled = (): boolean => getFeature('isSocialCTAEnabled');
export const isContactUsScriptEnabled = (): boolean => getFeature('isContactUsScriptEnabled');

export const isPromoCodeEnabled = (): boolean => getFeature('isPromoCodeEnabled');

export const navigateToOrderFailure = (): boolean => getFeature('navigateToOrderFailure');

export const isOrderDetailsPageEnabled = (): boolean => getFeature('isOrderDetailsPageEnabled');

export const isOrderCheckInEnabled = (): boolean => getFeature('isOrderCheckInEnabled');

export const isMainRewardsBannerOn = (): boolean => getFeature('mainRewardsBanner');

export const isBadgeEnabled = (): boolean => getFeature('isBadgeEnabled');

export const isBrazeEnabled = (): boolean => getFeature('isBrazeEnabled');

export default getFeatureFlags;
