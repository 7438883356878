import { DealPDPSelectionByPicks } from '../../dealPdp';
import {
    selectDealPDPOffer,
    selectDealPDPOfferPicks,
    selectDealTallyItems,
    selectDealInfoBySelectedIds,
    selectDealTallyItemById,
    selectDealDefaultTallyItemById,
} from '../../selectors/dealPdp';
import { useAppSelector } from '../../store';

export const useDealPDPOffer = () => useAppSelector((state) => selectDealPDPOffer(state));
export const useDealPDPOfferPicks = () => useAppSelector((state) => selectDealPDPOfferPicks(state));
export const useDealTallyItems = () => useAppSelector((state) => selectDealTallyItems(state));

export const useDealInfoBySelectedIds = (selectedIdsByPicks: DealPDPSelectionByPicks[], pickIndex: number) =>
    useAppSelector((state) => selectDealInfoBySelectedIds(state, selectedIdsByPicks.slice(0, pickIndex + 1)));

export const useGetDealTallyItemById = () =>
    useAppSelector((state) => (pickIndex: number, id: string) => {
        const tallyItem = selectDealTallyItemById(state, pickIndex, id);
        // fallback for the case when selectedIdsByPicks is not initialized
        const defaultTallyItem = selectDealDefaultTallyItemById(state, id);

        return tallyItem || defaultTallyItem;
    });
