import getBrandInfo from '../brandInfo';
import { BrandId } from '../../common/constants/brands';

const brandDefaultStatusText: { [key in BrandId]: string } = {
    Arbys: 'My Arby’s',
    Bww: 'Pickup From',
    Jje: 'Pickup From',
    Sdi: 'Pickup From',
};

const getDefaultStatusText = (): string => {
    const { brandId } = getBrandInfo();
    return brandDefaultStatusText[brandId];
};

export default getDefaultStatusText;
