import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import * as LoyaltyStore from '../loyalty';

import { PayloadAction } from '@reduxjs/toolkit';

interface IUseLoyalty {
    loyalty: LoyaltyStore.ILoyalty;
    isLoading: boolean;
    error: Error | null;
    actions: {
        setLoyaltyPoints: (payload: LoyaltyStore.ILoyalty) => PayloadAction<LoyaltyStore.ILoyaltyState>;
        setLoyaltyPointsLoading: (payload: boolean) => void;
        setLoyaltyError: (payload: Error) => void;
    };
}

export default function UseLoyalty(): IUseLoyalty {
    const dispatch = useDispatch();

    const { error, loading, pointsExpiringDate, pointsExpiring, pointsBalance } = useSelector<
        RootState,
        LoyaltyStore.ILoyaltyState
    >((state) => state.loyalty);

    const setLoyaltyPoints = (payload: LoyaltyStore.ILoyaltyState) =>
        dispatch(LoyaltyStore.actions.setLoyaltyPoints(payload));

    const setLoyaltyPointsLoading = (payload: boolean) =>
        dispatch(LoyaltyStore.actions.setLoyaltyPointsLoading(payload));

    const setLoyaltyError = (payload: Error) => dispatch(LoyaltyStore.actions.setLoyaltyError(payload));

    return {
        loyalty: {
            pointsBalance,
            pointsExpiring,
            pointsExpiringDate,
        },
        isLoading: loading,
        error,
        actions: {
            setLoyaltyPoints,
            setLoyaltyPointsLoading,
            setLoyaltyError,
        },
    };
}
