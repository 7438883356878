import { IUserAccountMenuFields } from '../../@generated/@types/contentful';
import {
    isSessionMRewardsOn,
    isOrdersHistoryOn,
    isEpsilonRewardsOn,
    isSonicOMSRewardsOn,
} from '../../lib/getFeatureFlags';
import { ACTIVITY, DEALS, IAccountNavLink, PROFILE, REWARDS, ORDERS, MY_TEAMS } from '../constants/account';
import { InspireCmsEntry } from '../types';
import { ConfigurationValues } from '../../redux/configuration';

const activityCountForShowingMenu = 5;

export const authorizationHeaderBuilder = (token: string) => {
    return 'Bearer ' + token;
};

export const getAccountNavLinks = (
    userAccountMenu: InspireCmsEntry<IUserAccountMenuFields>,
    activityCount: number,
    configuration: ConfigurationValues
): IAccountNavLink[] => {
    const accountNavLinks = (userAccountMenu?.fields.links || []).map((item) => {
        const name = item.fields.name;
        const link = `/${item.fields.link.fields.nameInUrl}`;
        const exact = item.fields.type.fields.type === PROFILE;
        const type = item.fields.type.fields.type;
        const trackingId = `account${type[0]}${type.slice(1).toLowerCase()}`;
        return {
            name,
            link,
            exact,
            type,
            trackingId,
        };
    });

    return accountNavLinks.filter((item) => {
        switch (item.type) {
            case REWARDS:
                return isEpsilonRewardsOn() || isSonicOMSRewardsOn();
            case DEALS:
                return isSessionMRewardsOn();
            case ACTIVITY:
                return activityCount > activityCountForShowingMenu && (isEpsilonRewardsOn() || isSonicOMSRewardsOn());
            case ORDERS:
                return isOrdersHistoryOn();
            case MY_TEAMS:
                return configuration.isMyTeamsEnabled;
            default:
                return true;
        }
    });
};
