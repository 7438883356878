import Script from 'next/script';
import React, { FC } from 'react';
import { getNewRelicScript } from '../common/helpers/getNewRelicScript';

const NewRelicSnippet: FC = () => {
    if (
        !process.env.NEXT_PUBLIC_NEWRELIC_ACCOUNT_ID ||
        !process.env.NEXT_PUBLIC_NEWRELIC_TRUST_KEY ||
        !process.env.NEXT_PUBLIC_NEWRELIC_AGENT_ID ||
        !process.env.NEXT_PUBLIC_NEWRELIC_LICENSE_KEY ||
        !process.env.NEXT_PUBLIC_NEWRELIC_APPLICATION_ID ||
        !process.env.NEXT_PUBLIC_NEWRELIC_DISTRIBUTED_TRACING_ALLOWED_ORIGINS
    ) {
        return null;
    }

    return (
        <Script
            id="new-relic"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: getNewRelicScript({
                    newRelicAccountId: process.env.NEXT_PUBLIC_NEWRELIC_ACCOUNT_ID,
                    newRelicTrustKey: process.env.NEXT_PUBLIC_NEWRELIC_TRUST_KEY,
                    newRelicAgentId: process.env.NEXT_PUBLIC_NEWRELIC_AGENT_ID,
                    newRelicLicenseKey: process.env.NEXT_PUBLIC_NEWRELIC_LICENSE_KEY,
                    newRelicApplicationId: process.env.NEXT_PUBLIC_NEWRELIC_APPLICATION_ID,
                    newRelicDistributedTracingAllowedOrigins:
                        process.env.NEXT_PUBLIC_NEWRELIC_DISTRIBUTED_TRACING_ALLOWED_ORIGINS,
                }),
            }}
        ></Script>
    );
};

export default NewRelicSnippet;
