import { Dictionary } from '@reduxjs/toolkit';

import { ItemModel, TallyProductModel } from '../../../@generated/webExpApi';
import { IProductFields, IProductModifierFields } from '../../../@generated/@types/contentful';

import { formatPrice } from '../../../lib/domainProduct';
import { DiscountStringMap, getDiscountType } from '../../../common/helpers/discountHelper';
import { LocationWithDetailsModel } from '../../../common/services/locationService/types';
import { InspireCmsEntry } from '../../../common/types';
import { IBagLineItem } from '../../../redux/bag';

export interface IBagRenderItem {
    entry: IBagLineItem;
    isAvailable: boolean;
    markedAsRemoved: boolean;
    contentfulProduct: InspireCmsEntry<IProductFields> | InspireCmsEntry<IProductModifierFields>;
    entryPath?: { href: string; as: string };
    discountBanner?: string;
}

export const getDiscountBanner = (
    entry: TallyProductModel,
    location: LocationWithDetailsModel,
    domainProduct: ItemModel,
    isPromo: boolean
): string | undefined => {
    const discountType = getDiscountType(domainProduct, location);

    if (discountType) {
        return DiscountStringMap[discountType];
    }

    if (isPromo) {
        return `${domainProduct.itemModifierGroups?.length} FOR ${formatPrice(entry.price)}`;
    }

    return undefined;
};

export const getCheckoutEntries = (renderItems: IBagRenderItem[]): TallyProductModel[] =>
    renderItems.filter((item) => item.isAvailable && !item.markedAsRemoved).map((item) => item.entry);

export const hasCheckoutEntries = (renderItems: IBagRenderItem[]): boolean =>
    renderItems.filter((item) => item.isAvailable && !item.markedAsRemoved).length > 0;

export const sortBagItemsByName = (
    renderItems: IBagRenderItem[],
    domainProducts: Dictionary<ItemModel>
): IBagRenderItem[] => {
    return renderItems.sort((left, right) => {
        const leftProductName = domainProducts[left.entry.productId]?.name?.toLowerCase();
        const rightProductName = domainProducts[right.entry.productId]?.name?.toLowerCase();

        if (leftProductName < rightProductName) return -1;
        if (leftProductName > rightProductName) return 1;

        return 0;
    });
};
