import React, { ImgHTMLAttributes } from 'react';
import { Asset } from 'contentful';
import { ContentfulImageFormats, getImageSizes, getResponsiveImagesSrcSet } from '../../common/helpers/contentfulImage';
import classnames from 'classnames';
import { convertHttpToHttps } from '../../common/helpers/convertHttpToHttps';

export interface IContentfulImageProps {
    asset?: Asset;
    imageUrl?: string;
    maxWidth?: number;
    className?: string;
    inlineStyles?: { [key: string]: string };
    gtmId?: string;
    imageAlt?: string;
    dataTestId?: string;
    quality?: number;
    objectFitClass?: string;
    width?: number;
    height?: number;
    loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
    priority?: boolean;
}

export default function ContentfulImage(props: IContentfulImageProps): JSX.Element | null {
    const {
        asset,
        imageUrl,
        maxWidth,
        className,
        inlineStyles,
        gtmId,
        imageAlt,
        dataTestId,
        quality,
        objectFitClass,
        width,
        height,
        loading = 'lazy',
        priority = false,
    } = props;

    if (!asset?.fields?.file?.url && !imageUrl) return null;

    const imageHttpsUrl = convertHttpToHttps(imageUrl);
    const responsiveImagesAsset = asset ? asset : imageHttpsUrl;

    return (
        <picture data-gtm-id={gtmId} style={{ display: 'flex' /* fix spacing for picture tag */ }}>
            <source
                type="image/avif"
                data-gtm-id={gtmId}
                srcSet={getResponsiveImagesSrcSet(
                    responsiveImagesAsset,
                    ContentfulImageFormats.AVIF,
                    quality,
                    maxWidth
                )}
                sizes={getImageSizes(maxWidth)}
            />
            <source
                type="image/webp"
                data-gtm-id={gtmId}
                srcSet={getResponsiveImagesSrcSet(
                    responsiveImagesAsset,
                    ContentfulImageFormats.WEBP,
                    quality,
                    maxWidth
                )}
                sizes={getImageSizes(maxWidth)}
            />
            <img
                data-gtm-id={gtmId}
                data-testid={dataTestId}
                alt={imageAlt ?? (asset.fields.description || asset.fields.title)}
                src={`${asset ? asset.fields.file.url : imageHttpsUrl}?&q=80${maxWidth ? `&w=${maxWidth}` : ''}`}
                className={classnames(className, objectFitClass)}
                style={{ ...(!objectFitClass && { objectFit: 'contain' }), ...inlineStyles }}
                {...(width && { width })}
                {...(height && { height })}
                loading={loading}
                {...(priority && { fetchpriority: 'high' })}
            />
        </picture>
    );
}
