import { Dictionary } from '@reduxjs/toolkit';
import { selectProductIsPromo } from '../selectors/domainMenu';
import { selectTallyUpdateBagItems } from '../selectors/tally';
import { RootState, useAppSelector } from '../store';
import { TallyProductModel } from '../../@generated/webExpApi';

export const useTallyItemIsPromo = (tallyItem: TallyProductModel): boolean =>
    useAppSelector((state: RootState) => selectProductIsPromo(state, tallyItem.productId));

export const useTallyItemIsPromoList = (tallyItems: TallyProductModel[]): Dictionary<boolean> =>
    useAppSelector((state: RootState) => {
        return tallyItems.reduce((map: Dictionary<boolean>, tallyItem: TallyProductModel) => {
            return {
                ...map,
                [tallyItem.productId]: selectProductIsPromo(state, tallyItem.productId),
            };
        }, {});
    });

export const useTallyUpdateBagItems = (): TallyProductModel[] => {
    return useAppSelector(selectTallyUpdateBagItems);
};
