import {
    WebExperienceApi,
    GetCustomerAccountLoyaltyPointsRequest,
    PostContactUsRequest,
} from '../../../@generated/webExpApi/apis';
import { IContactUsRequestModel } from '../../../@generated/webExpApi';

import { IGetCustomerAccountLoyaltyPointsResponseModel } from '../../../@generated/webExpApi/models';

import createErrorWrapper from '../createErrorWrapper';
import { getAuthorizationConfig } from '../../helpers/getAuthorizationConfig';

class LoyaltyService {
    getCustomerLoyaltyPointsApi: (
        request: GetCustomerAccountLoyaltyPointsRequest
    ) => Promise<IGetCustomerAccountLoyaltyPointsResponseModel>;

    concactUsApi: (request: PostContactUsRequest) => Promise<void>;

    constructor(JWT: string) {
        const expApi = new WebExperienceApi(getAuthorizationConfig(JWT));
        this.getCustomerLoyaltyPointsApi = createErrorWrapper<
            IGetCustomerAccountLoyaltyPointsResponseModel,
            GetCustomerAccountLoyaltyPointsRequest
        >('getAccountLoyaltyPoints', expApi.getCustomerAccountLoyaltyPoints.bind(expApi));

        this.concactUsApi = createErrorWrapper<void, PostContactUsRequest>(
            'postContactUs',
            expApi.postContactUs.bind(expApi)
        );
    }

    getAccountLoyaltyPoints(
        payload: GetCustomerAccountLoyaltyPointsRequest
    ): Promise<IGetCustomerAccountLoyaltyPointsResponseModel> {
        const { sellingChannel } = payload;
        return this.getCustomerLoyaltyPointsApi({ sellingChannel });
    }

    contactUs(payload: IContactUsRequestModel): Promise<void> {
        return this.concactUsApi({ iContactUsRequestModel: payload });
    }
}

export default LoyaltyService;
