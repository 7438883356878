import { MAX_PAYLOAD_SIZE } from '../common/services/gtmService/constants';

// eslint-disable-next-line @typescript-eslint/ban-types
export function gtmServicePush(event: string, obj: object = {}): void {
    if (typeof window !== 'undefined' && window.dataLayer) {
        const payload = JSON.stringify(obj);
        if (payload.length > MAX_PAYLOAD_SIZE) {
            console.warn({
                error: {
                    message: `GTM max payload size exceed for event ${event}`,
                },
            });
        }
        window.dataLayer.push({
            event,
            ...obj,
        });
    }
}
