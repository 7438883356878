import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ConfigurationState } from '../configuration';

const selectConfigurationState = (state: RootState): ConfigurationState => state.configuration;

export const selectLastSyncedAt = createSelector(
    selectConfigurationState,
    (configuration: ConfigurationState): ConfigurationState['lastSyncedAt'] => configuration.lastSyncedAt
);

export const selectConfiguration = createSelector(
    selectConfigurationState,
    (configuration: ConfigurationState): ConfigurationState['configuration'] => configuration.configuration
);
