import { BeerMenuResponseModel } from '../../@generated/webExpApi';

export function getBeersByType(data: BeerMenuResponseModel | null): BeerMenuResponseModel['beersByType'] {
    const l = data?.beersByType || {};
    return Object.keys(l).reduce((acc, type) => {
        if (l[type].count) {
            return { ...acc, [type]: l[type] };
        }
        return acc;
    }, {} as BeerMenuResponseModel['beersByType']);
}
