import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import * as NotificationsStore from '../notifications';
import { EnqueueNotificationPayload } from '../notifications';

type EnqueuePayload = Error | NotificationsStore.EnqueueNotificationPayload;
const DEFAULT_ERROR_TITLE = 'Error';
const DEFAULT_SUCCESS_TITLE = 'Success';

export interface IUseNotifications {
    notifications: NotificationsStore.Notification[];
    actions: {
        enqueue: (payload: EnqueuePayload) => void;
        enqueueError: (payload: EnqueuePayload) => void;
        enqueueSuccess: (payload: EnqueuePayload) => void;
        dequeue: () => void;
        remove: (payload: NotificationsStore.RemoveNotificationPayload) => void;
    };
}

export default function useNotifications(): IUseNotifications {
    const dispatch = useDispatch();

    const notifications = useSelector<RootState, NotificationsStore.Notification[]>((state) => state.notifications);

    const enqueue = useCallback(
        (payload: EnqueuePayload) => dispatch(NotificationsStore.createEnqueueNotificationAction(payload)),
        [dispatch]
    );

    const enqueueError = useCallback(
        (payload: EnqueuePayload) =>
            enqueue({
                ...payload,
                type: NotificationsStore.NotificationType.ERROR,
                ...(!(payload as EnqueueNotificationPayload).title && { title: DEFAULT_ERROR_TITLE }),
            }),
        [enqueue]
    );

    const enqueueSuccess = useCallback(
        (payload: EnqueuePayload) =>
            enqueue({
                ...payload,
                type: NotificationsStore.NotificationType.SUCCESS,
                ...(!(payload as EnqueueNotificationPayload).title && { title: DEFAULT_SUCCESS_TITLE }),
            }),
        [enqueue]
    );

    const dequeue = useCallback(() => dispatch(NotificationsStore.actions.dequeue({})), [dispatch]);

    const remove = useCallback(
        (payload: NotificationsStore.RemoveNotificationPayload) => dispatch(NotificationsStore.actions.remove(payload)),
        [dispatch]
    );

    return {
        notifications,
        actions: {
            enqueue,
            enqueueError,
            enqueueSuccess,
            dequeue,
            remove,
        },
    };
}
