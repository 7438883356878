import {
    GetLocationMenuTypeIdEnumModel,
    GetNationalMenuTypeIdEnumModel,
    SellingChannelNamesModel,
    GetNationalMenuRequest,
    GetLocalMenuRequest,
    IMenuModel,
} from '../../@generated/webExpApi';
import { WebExperienceApi } from '../../@generated/webExpApi/apis/index';

import createErrorWrapper from './createErrorWrapper';
import HttpStatusCode from './httpStatusCode';
import { US_DEFAULT_LOCALE } from '../constants/configDefaults';
import { getLocale } from '../helpers/locale';

const experiencingTechnicalDifficultiesError = 'We are experiencing technical difficulties. Please try again later.';

const errorMap = {
    [HttpStatusCode.NOT_FOUND]: experiencingTechnicalDifficultiesError,
    [HttpStatusCode.INTERNAL_SERVER_ERROR]: experiencingTechnicalDifficultiesError,
};

const expApi = new WebExperienceApi();

const getLocationMenuApi = createErrorWrapper<IMenuModel, GetLocalMenuRequest>(
    'getLocationMenu',
    expApi.getLocalMenu.bind(expApi),
    errorMap
);

const getNationalMenuApi = createErrorWrapper<IMenuModel, GetNationalMenuRequest>(
    'getNationalMenu',
    expApi.getNationalMenu.bind(expApi),
    errorMap
);

export function getLocationMenu(locationId: string): Promise<IMenuModel> {
    return getLocationMenuApi({
        sellingChannel: SellingChannelNamesModel.Weboa,
        locationId: locationId,
        menuType: GetLocationMenuTypeIdEnumModel.Allday,
    });
}

export function getNationalMenu(): Promise<IMenuModel> {
    const getNationalMenuParams = {
        sellingChannel: SellingChannelNamesModel.Weboa,
        menuType: GetNationalMenuTypeIdEnumModel.Allday,
    } as GetNationalMenuRequest;
    const currentLocale = getLocale();
    if (currentLocale !== US_DEFAULT_LOCALE) getNationalMenuParams.locale = currentLocale;
    return getNationalMenuApi(getNationalMenuParams);
}
