import isEqual from 'lodash/isEqual';
import { ICustomerPersonalizationFactModel, ILocationByStateOrProvinceDetailsModel } from '../../@generated/webExpApi';
import {
    DAYS_OF_WEEK,
    FACTS_DEPENDENCIES,
    FACTS,
    DATE_FACT_FORMAT,
    TIME_FACT_FORMAT,
} from '../constants/personalization';
import { IPersonalizationState } from '../../redux/personalization';
import { isEpsilonRewardsOn, isAccountOn, isPersonalizationEnabled } from '../../lib/getFeatureFlags';
import { format, utcToZonedTime } from './dateTime';

export const createFact = (name: string, value: string | number | boolean): ICustomerPersonalizationFactModel => ({
    name,
    value,
});
interface ILocationDetailsFactsData {
    locationDetailsPageId?: string;
}

export const getLocationDetailsFactsData = (
    locationDetails: ILocationByStateOrProvinceDetailsModel
): ILocationDetailsFactsData => {
    const locationDetailsPageId = locationDetails?.id || null;
    return { locationDetailsPageId };
};

export const getDateTimeFactsData = (timezone: string) => {
    const zonedDate = utcToZonedTime(new Date(), timezone);

    const date = format(zonedDate, DATE_FACT_FORMAT);
    const time = format(zonedDate, TIME_FACT_FORMAT);
    const dayOfWeek = DAYS_OF_WEEK[zonedDate.getDay()];

    return {
        date,
        time,
        dayOfWeek,
    };
};

export const isFactsChanged = (
    prevFact: ICustomerPersonalizationFactModel[],
    newFact: ICustomerPersonalizationFactModel[]
): boolean => {
    // exclude time from check because it's changing frequently
    const IGNORED_FACTS: string[] = [FACTS.TIME];

    const excludeFacts = (arr: ICustomerPersonalizationFactModel[]) => {
        return arr.filter((c) => !IGNORED_FACTS.includes(c.name));
    };

    return !isEqual(excludeFacts(prevFact), excludeFacts(newFact));
};

export const getInitialDependencies = (): IPersonalizationState['dependencies'] => {
    const dependencies = {};

    if (isPersonalizationEnabled()) {
        if (isAccountOn()) {
            dependencies[FACTS_DEPENDENCIES.ACCOUNT] = {
                initialized: false,
            };
        }

        if (isEpsilonRewardsOn()) {
            dependencies[FACTS_DEPENDENCIES.REWARDS] = {
                initialized: false,
            };
        }
    }

    return dependencies;
};
