import { createSelector } from 'reselect';
import { FACTS } from '../../common/constants/personalization';
import { createFact, getDateTimeFactsData, getLocationDetailsFactsData } from '../../common/helpers/personalization';
import { selectAccount } from './account';
import { selectPointsBalance } from './loyalty';
import { selectCurrenOrderLocation, selectIsCurrentLocationOAAvailable } from './orderLocation';
import { selectState } from './state';
import { RootState } from '../store';
import { ICustomerPersonalizationFactModel } from '../../@generated/webExpApi';
import { selectLocationDetails } from './ldp';
import { getTimezone } from '../../common/helpers/getTimezone';

export const selectPersonalizationFacts = createSelector(
    selectState,
    selectAccount,
    selectCurrenOrderLocation,
    selectIsCurrentLocationOAAvailable,
    selectPointsBalance,
    selectLocationDetails,
    (state, account, orderLocation, isOALocation, points, locationDetails) => {
        const facts: Array<ICustomerPersonalizationFactModel> = [];

        facts.push(createFact(FACTS.AUTHENTICATED_USER, !!account?.idpCustomerId));

        const locationId = orderLocation?.id || null;
        const timezone = getTimezone(orderLocation?.timezone, 'selectPersonalizationFacts');

        facts.push(createFact(FACTS.LOCATION_ID, locationId));

        const { locationDetailsPageId } = getLocationDetailsFactsData(locationDetails);
        facts.push(createFact(FACTS.LOCATION_DETAILS_PAGE_ID, locationDetailsPageId));

        facts.push(createFact(FACTS.IS_LOCATION_SELECTED, !!locationId));
        facts.push(createFact(FACTS.IS_OA_LOCATION, isOALocation));

        const { date, time, dayOfWeek } = getDateTimeFactsData(timezone);

        facts.push(createFact(FACTS.DATE, date));
        facts.push(createFact(FACTS.TIME, time));
        facts.push(createFact(FACTS.DAY_OF_THE_WEEK, dayOfWeek));

        facts.push(createFact(FACTS.REWARDS_POINTS, points));
        facts.push(createFact(FACTS.APPLICATION, 'WEB'));

        return facts;
    }
);

export const selectIsAllDependenciesInitialized = (state: RootState): boolean => {
    const dependencies = state.personalization.dependencies;

    return Object.keys(dependencies).every((dep) => dependencies[dep].initialized);
};
