import { TErrorCodeModel, TTallyErrorCodeModel } from '../../@generated/webExpApi';
import { FetchError } from '../types';
import addJsonError from './addJsonError';
import HttpStatusCode from './httpStatusCode';

export const experiencingTechnicalDifficultiesError =
    'We are experiencing technical difficulties. Please try again later.';

export const defaultHttpStatusCodeErrorMap = {
    [HttpStatusCode.BAD_REQUEST]: experiencingTechnicalDifficultiesError,
    [HttpStatusCode.NOT_FOUND]: experiencingTechnicalDifficultiesError,
    [HttpStatusCode.INTERNAL_SERVER_ERROR]: experiencingTechnicalDifficultiesError,
    [HttpStatusCode.SERVICE_UNAVAILABLE]: experiencingTechnicalDifficultiesError,
};

export const NetworkErrorMessage =
    'Our connection was interrupted. Please check your internet connection or wait for us to try again.';

export const DealErrorMessage =
    'Your order does not meet the deal criteria. Discount is not applied. Please add missing items or check-out without the deal.';

export const FutureOrderErrorMessage =
    'Please be aware that you are currently placing an order for Pick-up at a future date & time because the Store is closed.';

const defaultErrorMap = {
    [FetchError.NetworkError]: NetworkErrorMessage,
    [FetchError.AbortError]: experiencingTechnicalDifficultiesError,
    ...defaultHttpStatusCodeErrorMap,
};

// don't add Generic/Validation/etc. in handledErrors it should handle specific case
export const handledErrors = [
    TTallyErrorCodeModel.ProductsNotAvailable,
    TTallyErrorCodeModel.DeliveryProviderDeliveryTimeNotAvailable,
    TTallyErrorCodeModel.OrderTimeNotValid,
    TTallyErrorCodeModel.LocationNotAvailable,
    TTallyErrorCodeModel.ExceededMaxOrderAmount,
    TTallyErrorCodeModel.QuantitiesModifierGroupNotValid,
    TTallyErrorCodeModel.QuantitiesModifiersNotValid,
    TErrorCodeModel.PaymentAmountLower,
    TErrorCodeModel.DeliveryOrderInvalidPhone,
    TErrorCodeModel.PaymentFailure,
    TErrorCodeModel.PaymentAmountLower,
    TErrorCodeModel.PaymentAmountHigher,
    TErrorCodeModel.PaymentNotValid,
    TErrorCodeModel.PaymentNotPresented,
    TErrorCodeModel.InvalidFutureDate,
    TErrorCodeModel.LocationNotAvailable,
    TErrorCodeModel.InvalidPastDate,
    TErrorCodeModel.InvalidPastDateBeforeEnrollment,
    TErrorCodeModel.AlreadyAwarded,
    TErrorCodeModel.EmailNotVerified,
    TErrorCodeModel.CheckNotFound,
    TErrorCodeModel.OrderFailed,
    TErrorCodeModel.DeleteAccountValidation,
];

export class RequestError extends Error {
    code: string;

    constructor(public status: number | null, message: string, code?: string) {
        super(message);
        this.code = code;
    }
}

const createErrorHandler = <T, R, E = void>(
    api: string,
    apiCall: (...args: [R, E]) => Promise<T>,
    errorMap: Record<string, string> = {}
): ((...args: [R, E]) => Promise<T>) => {
    return async (...args: [R, E]) => {
        return addJsonError<T>(api, apiCall(...args)).catch((error) => {
            if (handledErrors.includes(error.code)) {
                return error;
            }
            if (!error.status) {
                if (error.code === FetchError.AbortError) {
                    throw new RequestError(null, errorMap[error.code] || defaultErrorMap[error.code], error.code);
                }
                throw new RequestError(
                    null,
                    errorMap[FetchError.NetworkError] || defaultErrorMap[FetchError.NetworkError],
                    FetchError.NetworkError
                );
            }

            throw new RequestError(
                error.status,
                errorMap[error.status] || defaultErrorMap[error.status] || errorMap.default,
                error?.code
            );
        });
    };
};

export default createErrorHandler;
