import { PDPTallyItem } from '../../pdp';
import { RootState } from '../../store';

/**
 * Doesn't modify combo items
 * Added to use multibrand version where it required
 * @param _state
 * @param childItems combo items
 * @param _comboId
 * @returns passes combo items
 */
export const selectChildItemsWithDisplayPrices = (_state: RootState, childItems: PDPTallyItem[], _comboId: string) => {
    return childItems;
};
