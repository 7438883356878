import { Dispatch, Action, AnyAction } from 'redux';
import { EnhancedStore } from '@reduxjs/toolkit';
import { RootState } from '../store';
import gtmService from '../../common/services/gtmService/gtmService';
import {
    GTM_MENU_IMPRESSION,
    GTM_TRANSACTION_COMPLETE,
    GTM_TRANSACTION_INFO,
    GTM_CHECKOUT_PAYMENT,
    GTM_CHECKOUT_VIEW,
    GTM_CHECKOUT_CUSTOMER_INFO,
    GTM_CHECKOUT_PAYMENT_INFO,
    GTM_CHECKOUT_PICK_UP_TIME_SELECTION,
    GTM_PRODUCT_VIEW,
    GTM_REMOVE_FROM_CART,
    GTM_RESTORE_ITEM_TO_CART,
    GTM_RE_ADD_ITEM_TO_CART,
    GTM_COMBO_SELECTION,
    GTM_COMBO_MODIFY,
    GTM_MODIFY_PRODUCT,
    GTM_MODIFY_IN_CART,
    GTM_SEARCH_NEW_LOCATION,
    GTM_LOCATION_SHARE,
    GTM_START_PICKUP_ORDER,
    GTM_MAKE_MY_STORE,
    GTM_ADD_MORE_ITEMS,
    GTM_EMAIL_SIGN_UP,
    GTM_MODIFIER_CUSTOMIZATION,
    GTM_GIFT_CARD_BALANCE_FORM_SUBMIT_SUCCESS,
    GTM_MAP_CLICK,
    GTM_MAP_DOUBLE_CLICK,
    GTM_MAP_DRAG_START,
    GTM_MAP_DRAG,
    GTM_MAP_DRAG_END,
    GTM_MAP_LOCATION_CLICK,
    GTM_MAP_LIST_LOCATION_CLICK,
    GTM_USER_ID,
    GTM_ONLINE_ORDER_COMING_SOON,
    GTM_MODIFIER_SECTION,
    GTM_ERROR_EVENT,
    GTM_CHECKOUT_TIP_SELECTION_EVENT,
    GTM_LOCATION_ORDER,
    GTM_CHANGE_LOCATION,
    GTM_CHECK_IN,
    GTM_ACCOUNT_DELETED_FEEDBACK,
    GTM_ACCOUNT_DELETED_SUBMITTED,
    GTM_SEND_A_MESSAGE,
    GTM_WALLET_EVENT,
    GTM_PICKUP_OR_LOCATION_ADDRESS_CHANGE,
    GTM_SEARCH_NAVIGATION_LOCATION,
    GTM_BANNER_SIGN_UP,
    GTM_INGREDIENT_SECTION,
    GTM_CLAIM_RECEIPT_REDEEM,
    GTM_SELECT_A_LOCATION,
    GTM_VIEW_DEALS,
    GTM_VIEW_DEAL,
    GTM_REDEEM_DEAL_ONLINE,
    GTM_REDEEM_DEAL_IN_STORE,
    GTM_REDEEM_DEAL_FROM_BAG,
    GTM_SWAP_THIS_DEAL,
    GTM_PRODUCT_VIEW_DEAL,
    GTM_ADD_TO_BAG_DEAL,
    GTM_COMPLETE_DEAL_PURCHASE,
    GTM_RECENT_ORDERS_REORDER,
    GTM_AUTO_SUGGEST_APPLY_DEAL,
    GTM_RECENT_ORDERS_HAVE_QUESTION,
    GTM_RECENT_ORDERS_CALL_STORE,
    GTM_ORDER_HISTORY_START_ORDER,
    GTM_AVATAR_IMAGE_UPLOAD,
    GTM_ORDER_HISTORY_SHOW_MORE,
    GTM_ORDER_HISTORY_SHOW_LESS,
    GTM_ORDER_HISTORY_REORDER,
    GTM_ORDER_HISTORY_VIEW_DETAILS,
    GTM_SIGNIN_CREATE_ACCOUNT,
    GTM_BEGIN_CHECKOUT_VIEW,
    GTM_CHANGE_LOCATION_CHECKOUT,
    GTM_CHECKOUT_TIP_SELECTION_WITH_CHOICE_EVENT,
    GTM_HAMBURGER_MENU_OPTION_SELECT,
    GTM_CHECKOUT_PRODUCT_QUANTITY_CHANGE,
    GTM_CONDIMENT_OPTION_SELECT,
    GTM_BACK_TO_TOP,
    GTM_CHECKOUT_PAYMENT_METHOD_INFO,
    GTM_COMBO_REPLACE,
    GTM_CROSS_SELL_EVENT,
    GTM_MAKE_IT_A_COMBO,
    GTM_CHECK_IN_CLICK,
    GTM_CHECK_IN_WRONG_LOCATION,
    GTM_JOIN_SONIC_REWARDS,
    GTM_WALLET_CARD_EVENT,
    GTM_EXPLORE_MORE_EVENT,
    GTM_VIEW_ITEM_EVENT,
    GTM_ADD_TO_CART_EVENT,
    GTM_REMOVE_FROM_CART_EVENT,
    GTM_PICKUP_TIME_AFTER_ORDER_SUBMIT,
    GTM_PROMOTIONAL_BANNER,
    GTM_PROMOTIONAL_IMPRESSION_BANNER,
    GTM_ORDER_FROM_ORDER_HISTORY,
} from '../../common/services/gtmService/constants';

const GTM_ACTIONS_MAP = {
    'bag/addToBag': gtmService.pushAddToCardEvent,
    'bag/putToBag': gtmService.pushAddToCardEvent,
    'bag/toggleIsOpen': gtmService.pushCartOpenEvent,
    [GTM_COMBO_SELECTION]: gtmService.pushComboSelectionEvent,
    [GTM_COMBO_MODIFY]: gtmService.pushComboModify,
    [GTM_MODIFY_PRODUCT]: gtmService.pushModifyItemEvent,
    [GTM_MODIFY_IN_CART]: gtmService.pushModifyInCartEvent,
    [GTM_MODIFIER_CUSTOMIZATION]: gtmService.pushModifierCustomizationEvent,
    [GTM_ADD_MORE_ITEMS]: gtmService.pushAddMoreItemsEvent,
    [GTM_REMOVE_FROM_CART]: gtmService.pushRemoveFromCartEvent,
    [GTM_RESTORE_ITEM_TO_CART]: gtmService.pushRestoreItemToCardEvent,
    [GTM_RE_ADD_ITEM_TO_CART]: gtmService.pushReAddItemToCardEvent,
    [GTM_MENU_IMPRESSION]: gtmService.pushImpressionViewEvent,
    [GTM_TRANSACTION_COMPLETE]: gtmService.pushTransactionCompleteEvent,
    [GTM_TRANSACTION_INFO]: gtmService.pushTransactionInfoEvent,
    [GTM_CHECKOUT_PAYMENT]: gtmService.pushCheckoutPaymentEvent,
    [GTM_CHECKOUT_CUSTOMER_INFO]: gtmService.pushCheckoutPaymentEvent,
    [GTM_CHECKOUT_PAYMENT_INFO]: gtmService.pushCheckoutPaymentEvent,
    [GTM_CHECKOUT_VIEW]: gtmService.pushCheckoutViewEvent,
    [GTM_BEGIN_CHECKOUT_VIEW]: gtmService.pushBeginCheckoutViewEvent,
    [GTM_PRODUCT_VIEW]: gtmService.pushProductViewEvent,
    [GTM_SEARCH_NEW_LOCATION]: gtmService.pushSearchNewLocationEvent,
    [GTM_LOCATION_SHARE]: gtmService.pushLocationShareEvent,
    [GTM_START_PICKUP_ORDER]: gtmService.pushStartPickupOrderEvent,
    [GTM_MAKE_MY_STORE]: gtmService.pushMakeMyStoreEvent,
    [GTM_ONLINE_ORDER_COMING_SOON]: gtmService.pushMakeOnlineOrderingComingSoonEvent,
    [GTM_EMAIL_SIGN_UP]: gtmService.pushEmailSignUp,
    [GTM_GIFT_CARD_BALANCE_FORM_SUBMIT_SUCCESS]: gtmService.pushGiftCardBalanceFormSubmitSuccess,
    [GTM_MAP_CLICK]: gtmService.pushMapClickEvent,
    [GTM_MAP_DOUBLE_CLICK]: gtmService.pushMapDoubleClickEvent,
    [GTM_MAP_DRAG_START]: gtmService.pushMapDragStartEvent,
    [GTM_MAP_DRAG]: gtmService.pushMapDragEvent,
    [GTM_MAP_DRAG_END]: gtmService.pushMapDragEndEvent,
    [GTM_MAP_LOCATION_CLICK]: gtmService.pushMapLocationClickEvent,
    [GTM_MAP_LIST_LOCATION_CLICK]: gtmService.pushMapListLocationClickEvent,
    [GTM_USER_ID]: gtmService.pushUserId,
    [GTM_CHECKOUT_PICK_UP_TIME_SELECTION]: gtmService.pushPickupTimeSelection,
    [GTM_MODIFIER_SECTION]: gtmService.pushModifierSelection,
    [GTM_ERROR_EVENT]: gtmService.pushError,
    [GTM_CHECKOUT_TIP_SELECTION_EVENT]: gtmService.pushTipSelection,
    [GTM_CHECKOUT_TIP_SELECTION_WITH_CHOICE_EVENT]: gtmService.pushTipSelectionWithTippingChoice,
    [GTM_LOCATION_ORDER]: gtmService.pushLocationOrder,
    [GTM_CHANGE_LOCATION]: gtmService.pushLocationChange,
    [GTM_CHECK_IN]: gtmService.pushCheckInEvent,
    [GTM_ACCOUNT_DELETED_SUBMITTED]: gtmService.pushAccountDeletedSubmitted,
    [GTM_ACCOUNT_DELETED_FEEDBACK]: gtmService.pushAccountDeletedFeedback,
    [GTM_SEND_A_MESSAGE]: gtmService.pushSendAMessage,
    [GTM_WALLET_EVENT]: gtmService.pushWalletEvent,
    [GTM_PICKUP_OR_LOCATION_ADDRESS_CHANGE]: gtmService.pushPickupOrDeliveryAddressChange,
    [GTM_SEARCH_NAVIGATION_LOCATION]: gtmService.pushSearchNavigationLocation,
    [GTM_BANNER_SIGN_UP]: gtmService.pushBannerSignUp,
    [GTM_INGREDIENT_SECTION]: gtmService.pushIngredientSelection,
    [GTM_CLAIM_RECEIPT_REDEEM]: gtmService.pushClaimReceiptRedeem,
    [GTM_SELECT_A_LOCATION]: gtmService.pushASelectLocation,
    [GTM_VIEW_DEALS]: gtmService.pushAViewDeals,
    [GTM_VIEW_DEAL]: gtmService.pushAViewDeal,
    [GTM_REDEEM_DEAL_ONLINE]: gtmService.pushRedeemOnline,
    [GTM_REDEEM_DEAL_IN_STORE]: gtmService.pushRedeemInStore,
    [GTM_REDEEM_DEAL_FROM_BAG]: gtmService.pushRedeemFromBag,
    [GTM_SWAP_THIS_DEAL]: gtmService.pushSwapToThisDeal,
    [GTM_PRODUCT_VIEW_DEAL]: gtmService.pushProductViewDeal,
    [GTM_ADD_TO_BAG_DEAL]: gtmService.pushAddToBagDeal,
    [GTM_COMPLETE_DEAL_PURCHASE]: gtmService.pushCompleteDealPurchase,
    [GTM_RECENT_ORDERS_REORDER]: gtmService.pushOrderHistoryReorder,
    [GTM_AUTO_SUGGEST_APPLY_DEAL]: gtmService.pushAutoSuggestApplyDeal,
    [GTM_RECENT_ORDERS_HAVE_QUESTION]: gtmService.pushRecentOrdersHaveQuestion,
    [GTM_RECENT_ORDERS_CALL_STORE]: gtmService.pushRecentOrdersCallStore,
    [GTM_ORDER_HISTORY_START_ORDER]: gtmService.pushOrderHistoryStartOrder,
    [GTM_AVATAR_IMAGE_UPLOAD]: gtmService.pushAvatarImageUpload,
    [GTM_ORDER_HISTORY_VIEW_DETAILS]: gtmService.pushOrderHistoryItemCtaClick,
    [GTM_ORDER_HISTORY_SHOW_MORE]: gtmService.pushOrderHistoryItemCtaClick,
    [GTM_ORDER_HISTORY_SHOW_LESS]: gtmService.pushOrderHistoryItemCtaClick,
    [GTM_CROSS_SELL_EVENT]: gtmService.pusCrossSellEvent,
    [GTM_ORDER_HISTORY_REORDER]: gtmService.pushOrderHistoryReorder,
    [GTM_SIGNIN_CREATE_ACCOUNT]: gtmService.pushSignInCreateAccount,
    [GTM_CHANGE_LOCATION_CHECKOUT]: gtmService.pushChangeLocationCheckout,
    [GTM_HAMBURGER_MENU_OPTION_SELECT]: gtmService.pushHamburgerMenuOptionSelect,
    [GTM_CHECKOUT_PRODUCT_QUANTITY_CHANGE]: gtmService.pushCheckoutProductQuantityChangeEvent,
    [GTM_CONDIMENT_OPTION_SELECT]: gtmService.pushCondimentOptionSelect,
    [GTM_BACK_TO_TOP]: gtmService.pushBackToTop,
    [GTM_CHECKOUT_PAYMENT_METHOD_INFO]: gtmService.pushCheckoutPaymentMethodInfoEvent,
    [GTM_COMBO_REPLACE]: gtmService.pushCompoReplace,
    [GTM_MAKE_IT_A_COMBO]: gtmService.pushMakeItACombo,
    [GTM_CHECK_IN_CLICK]: gtmService.pusCheckInClick,
    [GTM_CHECK_IN_WRONG_LOCATION]: gtmService.pusCheckInWrongLocation,
    [GTM_JOIN_SONIC_REWARDS]: gtmService.pushJoinSonicRewards,
    [GTM_WALLET_CARD_EVENT]: gtmService.pushWalletCardEvent,
    [GTM_EXPLORE_MORE_EVENT]: gtmService.pushExploreMoreEvent,
    [GTM_VIEW_ITEM_EVENT]: gtmService.pushViewItemEvent,
    [GTM_ADD_TO_CART_EVENT]: gtmService.pushAddToCartEvent,
    [GTM_REMOVE_FROM_CART_EVENT]: gtmService.pushRemoveFromCart,
    [GTM_PICKUP_TIME_AFTER_ORDER_SUBMIT]: gtmService.pushPickUpTimeAfterOrderSubmit,
    [GTM_PROMOTIONAL_BANNER]: gtmService.pushPromotionalBanner,
    [GTM_PROMOTIONAL_IMPRESSION_BANNER]: gtmService.pushPromotionalImpressionBanner,
    [GTM_ORDER_FROM_ORDER_HISTORY]: gtmService.pushOrderFromOrderHistory,
};
const gtmMiddleware = (store: EnhancedStore<RootState>) => (next: Dispatch<AnyAction>) => (
    action: AnyAction
): Action<AnyAction> => {
    if (GTM_ACTIONS_MAP[action.type]) {
        GTM_ACTIONS_MAP[action.type](action.payload, store.getState());
        return next(action);
    }

    return next(action);
};

export default gtmMiddleware;
