import { useDispatch } from 'react-redux';

import { PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../store';

import * as OrderLocationStore from '../orderLocation';
import useLocationAvailableTimes from '../../common/hooks/useLocationAvailableTimes';
import { OrderLocationMethod } from '../orderLocation';
import {
    selectCondimentsLimit,
    selectCurrenOrderLocation,
    selectIsCurrentLocationOAAvailable,
} from '../selectors/orderLocation';
import { LocationWithDetailsModel } from '../../common/services/locationService/types';
import { selectIsLocationMenuError } from '../selectors/domainMenu';
import { SHOULD_FILTER_TIME_SLOTS } from '../../components/organisms/checkout/constants';
import { addMinutes } from 'date-fns';
import { utcToZonedTime } from '../../common/helpers/dateTime';
import { useCallback, useMemo } from 'react';
import { TIME_NEEDED_FOR_KITCHEN_TO_PREPARE, USER_CHECKOUT_INTERACTION } from '../../common/constants/timeslots';

interface UseOrderLocationHook {
    isPickUp: boolean;
    method: OrderLocationStore.OrderLocationMethod;
    pickupAddress: OrderLocationStore.PickupAddress;
    deliveryAddress: OrderLocationStore.DeliveryAddress;
    currentLocation: LocationWithDetailsModel;
    isCurrentLocationOAAvailable: boolean;
    pickupLocationTimeSlots: OrderLocationStore.LocationAvailableTimes;
    deliveryLocationTimeSlots: OrderLocationStore.LocationAvailableTimes;
    previousLocation: OrderLocationStore.PreviousLocation;
    getFilteredPickUpSlots: (now: Date) => OrderLocationStore.LocationAvailableTimes;
    condimentsLimit: number;
    actions: {
        setPickupLocation: (
            payload: OrderLocationStore.PickupAddress
        ) => PayloadAction<OrderLocationStore.PickupAddress>;
        setDeliveryLocation: (
            payload: OrderLocationStore.DeliveryAddress
        ) => PayloadAction<OrderLocationStore.DeliveryAddress>;
        setPickupLocationAvailableTimeSlots: (
            payload: OrderLocationStore.LocationAvailableTimes
        ) => PayloadAction<OrderLocationStore.LocationAvailableTimes>;
        setDeliveryLocationAvailableTimeSlots: (
            payload: OrderLocationStore.LocationAvailableTimes
        ) => PayloadAction<OrderLocationStore.LocationAvailableTimes>;
        flushSelectedLocation: () => PayloadAction;
        flushDeliveryLocation: () => void;
        setMethod: (
            payload: OrderLocationStore.OrderLocationMethod
        ) => PayloadAction<OrderLocationStore.OrderLocationMethod>;
        setPreviousLocation: (
            payload: OrderLocationStore.PreviousLocation
        ) => PayloadAction<OrderLocationStore.PreviousLocation>;
        updatePickupLocation: (
            payload: OrderLocationStore.PickupAddress
        ) => PayloadAction<OrderLocationStore.PickupAddress>;
        updateDeliveryLocation: (
            payload: OrderLocationStore.DeliveryAddress
        ) => PayloadAction<OrderLocationStore.DeliveryAddress>;
    };
}

export default function useOrderLocation(): UseOrderLocationHook {
    const dispatch = useDispatch();
    const {
        method,
        pickupAddress,
        deliveryAddress,
        pickupLocationTimeSlots,
        deliveryLocationTimeSlots,
        previousLocation,
    } = useAppSelector((state) => state.orderLocation);
    const { fetchAndSetLocationTimeSlots } = useLocationAvailableTimes();

    const getFilteredPickUpSlots = useCallback(
        (timeBuffer: Date) => {
            let filteredSlots = {};
            Object.entries(pickupLocationTimeSlots.pickup.byDay || {}).forEach(([key, timeRanges]) => {
                const filteredByDay = {
                    [key]: timeRanges.filter((timeRange) => {
                        const timeSlot = utcToZonedTime(new Date(timeRange.utc.toString()), pickupAddress?.timezone);
                        return timeSlot.getTime() > timeBuffer.getTime();
                    }),
                };
                filteredSlots = {
                    ...filteredSlots,
                    ...filteredByDay,
                };
            });

            return {
                pickup: {
                    byDay: filteredSlots,
                },
                delivery: {
                    byDay: deliveryLocationTimeSlots?.delivery?.byDay,
                },
            };
        },
        [deliveryLocationTimeSlots, pickupAddress, pickupLocationTimeSlots]
    );

    const filteredPickUpSlots = useMemo(() => {
        if (SHOULD_FILTER_TIME_SLOTS && pickupAddress && pickupLocationTimeSlots) {
            const twentyThreeMinutesFromNow = addMinutes(
                utcToZonedTime(new Date(Date.now()), pickupAddress?.timezone),
                TIME_NEEDED_FOR_KITCHEN_TO_PREPARE + USER_CHECKOUT_INTERACTION
            );
            return getFilteredPickUpSlots(twentyThreeMinutesFromNow);
        }

        return pickupLocationTimeSlots;
    }, [pickupLocationTimeSlots, pickupAddress, getFilteredPickUpSlots]);

    const currentLocation = useAppSelector(selectCurrenOrderLocation);
    const isCurrentLocationOAAvailable = useAppSelector(selectIsCurrentLocationOAAvailable);
    const isLocationMenuError = useAppSelector(selectIsLocationMenuError);
    const condimentsLimit = useAppSelector(selectCondimentsLimit);

    const setPickupLocation = (payload: OrderLocationStore.PickupAddress) => {
        fetchAndSetLocationTimeSlots({
            locationId: payload.id,
            setAvailableTimeSlots: setPickupLocationAvailableTimeSlots,
        });

        return dispatch(OrderLocationStore.actions.setPickupLocation(payload));
    };

    const setDeliveryLocation = (payload: OrderLocationStore.DeliveryAddress) => {
        fetchAndSetLocationTimeSlots({
            locationId: String(payload.pickUpLocation.id),
            setAvailableTimeSlots: setDeliveryLocationAvailableTimeSlots,
        });

        return dispatch(OrderLocationStore.actions.setDeliveryLocation(payload));
    };

    const flushSelectedLocation = () => dispatch(OrderLocationStore.actions.flushSelectedLocation());

    const flushDeliveryLocation = () => dispatch(OrderLocationStore.actions.flushDeliveryLocation());

    const setPickupLocationAvailableTimeSlots = (payload: OrderLocationStore.LocationAvailableTimes) =>
        dispatch(OrderLocationStore.actions.setPickupLocationAvailableTimeSlots(payload));

    const setDeliveryLocationAvailableTimeSlots = (payload: OrderLocationStore.LocationAvailableTimes) =>
        dispatch(OrderLocationStore.actions.setDeliveryLocationAvailableTimeSlots(payload));

    const setMethod = (payload: OrderLocationStore.OrderLocationMethod) =>
        dispatch(OrderLocationStore.actions.setMethod(payload));

    const setPreviousLocation = (payload: OrderLocationStore.PreviousLocation) =>
        dispatch(OrderLocationStore.actions.setPreviousLocation(payload));

    const updatePickupLocation = (payload: OrderLocationStore.PickupAddress) =>
        dispatch(OrderLocationStore.actions.updatePickupLocation(payload));

    const updateDeliveryLocation = (payload: OrderLocationStore.DeliveryAddress) =>
        dispatch(OrderLocationStore.actions.updateDeliveryLocation(payload));

    const isPickUp = method === OrderLocationMethod.PICKUP;

    return {
        isPickUp,
        method,
        pickupAddress,
        deliveryAddress,
        pickupLocationTimeSlots: filteredPickUpSlots,
        deliveryLocationTimeSlots,
        currentLocation,
        isCurrentLocationOAAvailable: isCurrentLocationOAAvailable && !isLocationMenuError,
        previousLocation,
        getFilteredPickUpSlots,
        condimentsLimit,
        actions: {
            setPickupLocation,
            setDeliveryLocation,
            flushSelectedLocation,
            flushDeliveryLocation,
            setPickupLocationAvailableTimeSlots,
            setDeliveryLocationAvailableTimeSlots,
            setMethod,
            setPreviousLocation,
            updatePickupLocation,
            updateDeliveryLocation,
        },
    };
}
