import logger from '../services/logger';

/**
 * @namespace helpersGetTimezone
 */

/**
 * @method
 * @memberOf getTimezone
 * @param {string} timezone Use for timezonee
 * @param {string} logSource Use for log info
 * @description this method checked timezone value and returns default timezone in no value
 * this method also adds information to the logging system in case if timezone value is missing
 * @returns {string} returns back timezone value or default timezone value
 */
export const getTimezone = (timezone?: string, logSource?: string): string => {
    const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // offset (ex. +03:00) or IANA time zone (ex. America/New_York)

    if (!timezone) {
        logger.logEvent('undefined_timezone_use_default', { source: logSource });
    }

    return timezone || defaultTimezone;
};
