import { useDispatch } from 'react-redux';
import { ILocationByStateOrProvinceDetailsModel } from '../../@generated/webExpApi';
import { useAppSelector } from '../../redux/store';
import * as LDPStore from '../ldp';
import { selectLocationDetails } from '../selectors/ldp';

export interface UsePDPHook {
    locationDetails: ILocationByStateOrProvinceDetailsModel;
    actions: {
        putLocationDetails: (payload: LDPStore.PutLocationDetailsPayload) => void;
        resetLocationDetails: () => void;
    };
}

export default function useLdp(): UsePDPHook {
    const dispatch = useDispatch();

    const locationDetails = useAppSelector(selectLocationDetails);

    const putLocationDetails = (payload: LDPStore.PutLocationDetailsPayload) => {
        dispatch(LDPStore.actions.putLocationDetails(payload));
    };

    const resetLocationDetails = () => {
        dispatch(LDPStore.actions.resetLocationDetails());
    };

    return {
        locationDetails,
        actions: {
            putLocationDetails,
            resetLocationDetails,
        },
    };
}
