import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    IPreferencesModel,
    ITransformedGetCustomerAccountResponseForClientModel,
} from '../@generated/webExpApi/models';

export type Account = ITransformedGetCustomerAccountResponseForClientModel | null;

export type AccountInfo = {
    firstName: string;
    lastName: string;
    phone?: string;
    phoneNumber?: string;
    email: string;
    birthDate?: string;
    postalCode?: string;
    preferences?: IPreferencesModel;
} | null;

export const initialState: Account = null;

const accountSlice = createSlice({
    name: 'account',
    initialState: initialState as Account,
    reducers: {
        setAccount: (_: Account, action: PayloadAction<Account>) => {
            return action.payload;
        },
    },
});

export const { actions, name } = accountSlice;

export default accountSlice.reducer;
