import { useBag, useOrderLocation } from '../../redux/hooks';
import { useSelectUnavailableCategories } from '../../redux/hooks/domainMenu';
import { OrderLocationMethod } from '../../redux/orderLocation';
import { getFirstTimeValue } from '../helpers/bagHelper';
import { useLocationTimeZone } from './useLocationTimeZone';

export const useUnavailableCategories = (): string[] => {
    const { orderTime, orderTimeType } = useBag();
    const locationTimeZone = useLocationTimeZone();
    const { method, pickupLocationTimeSlots, deliveryLocationTimeSlots } = useOrderLocation();

    const firstAvailableTimeSlot =
        method === OrderLocationMethod.DELIVERY
            ? getFirstTimeValue(deliveryLocationTimeSlots?.delivery)
            : getFirstTimeValue(pickupLocationTimeSlots?.pickup);

    const orderTimeValue = orderTimeType === 'asap' ? firstAvailableTimeSlot : orderTime;

    return useSelectUnavailableCategories(orderTimeValue, locationTimeZone);
};
