import {
    GetLocationAvailableTimesRequest,
    LocationPickupAndDeliveryResponseModel,
    WebExperienceApi,
} from '../../../@generated/webExpApi';
import createErrorWrapper from '../createErrorWrapper';

const expApi = new WebExperienceApi();

const getLocationAvailableTimesApi = createErrorWrapper<
    LocationPickupAndDeliveryResponseModel,
    GetLocationAvailableTimesRequest
>('getLocationAvailableTimes', expApi.getLocationAvailableTimes.bind(expApi));

const getLocationAvailableTimes = (
    payload: GetLocationAvailableTimesRequest
): Promise<LocationPickupAndDeliveryResponseModel> => {
    return getLocationAvailableTimesApi(payload);
};

export default getLocationAvailableTimes;
