import { useDispatch, useSelector } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import * as branchIOStore from '../branchIO';

export interface IUseBranchIO {
    branchIO: branchIOStore.IBranchIOState;
    actions: {
        setBranchIO: (payload: branchIOStore.IBranchIOState) => PayloadAction<branchIOStore.IBranchIOState>;
        setBranchIOError: (payload: string) => void;
    };
}

export default function useBranchIO(): IUseBranchIO {
    const dispatch = useDispatch();

    const branchIO = useSelector<RootState, branchIOStore.IBranchIOState>((state) => state.branchIO);

    const setBranchIO = (payload: branchIOStore.IBranchIOState) => dispatch(branchIOStore.actions.setBranchIO(payload));

    const setBranchIOError = (payload: string) => dispatch(branchIOStore.actions.setBranchIOError(payload));

    return {
        branchIO,
        actions: {
            setBranchIO,
            setBranchIOError,
        },
    };
}
