import { useState, useMemo } from 'react';
import { ItemModifierGroupModel, TallyModifierModel, TallyProductModel } from '../../@generated/webExpApi';
import { useBag, useOrderLocation } from '../../redux/hooks';
import { useProducts } from '../../redux/hooks/domainMenu';
import { IDomainProductItem, IProducts } from '../../redux/types';
import { checkIsUnavailableProductByCategories } from '../helpers/bagHelper';
import { useCheckIsCondiment } from './useCheckIsCondiment';
import { useUnavailableCategories } from './useUnavailableCategories';
import { checkProductIsAvailable } from '../helpers/checkProductIsAvailable';

export interface IUnavailableItem {
    productId: string;
    lineItemId: number;
    modifierIds: string[];
    subModifierIds: string[];
    childItemsIds: string[];
    isWholeProductUnavailable: boolean;
    isDefaultModifiersUnavailable: boolean;
    isTouched?: boolean;
    isUnavailableByTally?: boolean;
}

export interface IUseUnavailableItems {
    unavailableItems: IUnavailableItem[];
    setTouched: (lineItemId: number) => void;
}

/**
 * Hook to check unavailability of bag items by menu and tally response
 * @param unavailableIds tally unavaialble products and modifiers ids
 * @returns {IUseUnavailableItems} all bag unavailable items
 * @author Aliaksandr Dzemchyk <aliaksandr.dzemchyk@inspirebrands.com>
 * @added 2023-04-12
 * @version 1.0
 * @example
 * useBagUnavailableItems(['id1', 'id2']);
 * //result depending on menu
 * //[{ for case when whole product is unavailable
 * //     isDefaultModifiersUnavailable: false,
 * //     isWholeProductUnavailable: true,
 * //     modifierIds: [],
 * //     subModifierIds: [],
 * //     productId: 'id2',
 * //     lineItemId: 1,
 * //     isUnavailableByTally: true,
 * //     childItemsIds: [],
 * // },{ for case when child items or modifiers unavailable
 * //     isDefaultModifiersUnavailable: false,
 * //     isWholeProductUnavailable: false,
 * //     modifierIds: ['id2'],
 * //     subModifierIds: [],
 * //     productId: '',
 * //     lineItemId: 1,
 * //     isUnavailableByTally: true,
 * //     childItemsIds: ['id1'],
 * // }],
 */
export const useBagUnavailableItems = (unavailableIds: string[]): IUseUnavailableItems => {
    const { bagEntries } = useBag();
    const [touchedLineItemsIds, setTouchedLineItemsIds] = useState([]);
    const products = useProducts();
    const unavailableCategories = useUnavailableCategories();
    const { isCurrentLocationOAAvailable } = useOrderLocation();
    const checkIsCondiment = useCheckIsCondiment();

    const unavailableItems: IUnavailableItem[] = useMemo(
        () =>
            bagEntries.reduce((unavailableItems, bagEntry) => {
                const menuCheckResult = checkProductAvailabilityByMenu(
                    bagEntry,
                    products,
                    unavailableCategories,
                    isCurrentLocationOAAvailable,
                    checkIsCondiment
                );
                const tallyCheckResult = checkProductAvailabilityByTally(bagEntry, products, unavailableIds);

                const isUnavailableByMenu = !!menuCheckResult;
                const isUnavailableByTally = !!tallyCheckResult;

                if (isUnavailableByMenu || isUnavailableByTally) {
                    return [
                        ...unavailableItems,
                        {
                            productId: bagEntry.productId,
                            lineItemId: bagEntry.lineItemId,
                            isWholeProductUnavailable:
                                menuCheckResult?.isWholeProductUnavailable ||
                                tallyCheckResult?.isWholeProductUnavailable ||
                                false,
                            isDefaultModifiersUnavailable: tallyCheckResult?.isDefaultModifiersUnavailable || false,
                            modifierIds: tallyCheckResult?.modifierIds || [],
                            subModifierIds: tallyCheckResult?.subModifierIds || [],
                            childItemsIds: [
                                ...new Set([
                                    ...(tallyCheckResult?.childItemsIds || []),
                                    ...(menuCheckResult?.childItemsIds || []),
                                ]),
                            ],
                            isUnavailableByTally,
                            isTouched: touchedLineItemsIds.includes(bagEntry.lineItemId),
                        },
                    ];
                }

                return unavailableItems;
            }, []),
        [unavailableIds, bagEntries, products, isCurrentLocationOAAvailable, touchedLineItemsIds]
    );

    const setTouched = (lineItemId: number) => {
        setTouchedLineItemsIds([...touchedLineItemsIds, lineItemId]);
    };

    return {
        unavailableItems,
        setTouched,
    };
};

function isDefaultItemUnavailable(
    domainModifierGroup: ItemModifierGroupModel,
    bagEntity: TallyProductModel | TallyModifierModel,
    unavailableIds: string[]
): boolean {
    const bagModifierGroup = bagEntity.modifierGroups?.find(
        (group) => group.productId === domainModifierGroup.productGroupId
    );

    if (!bagModifierGroup) return false;

    const availableBagModifiers =
        bagModifierGroup.modifiers.filter((modifier) => !unavailableIds.includes(modifier.productId)) || [];

    const totalSubCount = availableBagModifiers.reduce(
        (totalCount, modifier) => totalCount + (modifier.quantity || 0),
        0
    );

    return totalSubCount < domainModifierGroup.min;
}

/**
 * Helper function that contains logic to check product availability by menu
 * @param {TallyProductModel} entry bag entry
 * @param {IProducts} products domain products
 * @param {string[]} unavailableCategories unavailable categories ids list
 * @param {boolean} isOAAvailable location order ahead
 * @param {Function} checkIsCondiment functions that check if product is condiment
 * @returns {Partial<IUnavailableItem>} returns unavailable item or undefine in case if product is available
 * @author Aliaksandr Dzemchyk <aliaksandr.dzemchyk@inspirebrands.com>
 * @added 2023-04-12
 * @version 1.0
 */
function checkProductAvailabilityByMenu(
    entry: TallyProductModel,
    products: IProducts = {},
    unavailableCategories: string[],
    isOAAvailable: boolean,
    checkIsCondiment: (id: string) => boolean
): Partial<IUnavailableItem> {
    const domainProduct = products[entry.productId];
    const categoryIds = domainProduct?.categoryIds;

    const isSingleProductAvailableByAvailabilityFlags = (domainProduct: IDomainProductItem) => {
        return domainProduct && domainProduct.isSaleable && checkProductIsAvailable(domainProduct);
    };

    // don't check availability flags for condiments
    if (checkIsCondiment(entry.productId)) {
        return;
    }

    let productHasPrice = !!entry.price || entry.price === 0;
    const otherPrices = Object.keys(domainProduct?.price?.otherPrices || {}).map(
        (key) => domainProduct.price.otherPrices[key]
    );

    otherPrices.forEach((item) => {
        if (!item.price && item.price !== 0) {
            productHasPrice = false;
        }
    });

    if (
        !isOAAvailable ||
        !domainProduct ||
        !productHasPrice ||
        checkIsUnavailableProductByCategories(categoryIds, unavailableCategories) ||
        !isSingleProductAvailableByAvailabilityFlags(domainProduct)
    ) {
        return {
            isWholeProductUnavailable: true,
        };
    }

    // check child items availability for combo
    if (entry.childItems?.length) {
        const entreeProduct = products[entry.childItems[0].productId];

        if (!isSingleProductAvailableByAvailabilityFlags(entreeProduct)) {
            return {
                isWholeProductUnavailable: true,
            };
        }

        const unavailableChildIds = entry.childItems
            .slice(1)
            .map(({ productId }) => productId)
            .filter((productId) => !isSingleProductAvailableByAvailabilityFlags(products[productId]));

        if (unavailableChildIds.length) {
            return { isWholeProductUnavailable: false, childItemsIds: unavailableChildIds };
        }
    }
}

/**
 * Helper function that contains logic to check product availability by tally response
 * @param {TallyProductModel} bagEntry bag entry
 * @param {IProducts} products domain products
 * @param {string[]} unavailableIds unavailable products/modifiers ids list
 * @returns {Partial<IUnavailableItem>} returns unavailable item or undefine in case if product is available
 * @author Aliaksandr Dzemchyk <aliaksandr.dzemchyk@inspirebrands.com>
 * @added 2023-04-12
 * @version 1.0
 */
function checkProductAvailabilityByTally(
    bagEntry: TallyProductModel,
    products: IProducts,
    unavailableIds: string[] = []
): Partial<IUnavailableItem> {
    if (!unavailableIds?.length) return;

    const isUnavailable = (id: string) => {
        return unavailableIds.includes(id);
    };

    const checkSingleProductModifiersAvailability = (entry: TallyProductModel) => {
        const unavailableModifierIds =
            entry.modifierGroups
                ?.flatMap((modifierGroup) =>
                    (modifierGroup.modifiers || []).filter((modifier) => isUnavailable(modifier.productId))
                )
                .map((item) => item.productId) || [];

        const unavailableSubModifierIds =
            entry.modifierGroups
                ?.flatMap((modifierGroup) =>
                    (modifierGroup.modifiers || [])
                        .filter((modifier) => modifier?.modifierGroups)
                        .flatMap((modifier) =>
                            modifier?.modifierGroups.flatMap((modifierGroup) =>
                                (modifierGroup.modifiers || []).filter((modifier) => isUnavailable(modifier.productId))
                            )
                        )
                )
                .map((item) => item.productId) || [];

        if (!(unavailableModifierIds.length || unavailableSubModifierIds.length)) {
            return;
        }

        const isDefaultModifiersUnavailable = products[bagEntry.productId]?.itemModifierGroups
            ?.filter((modifierGroup) => modifierGroup.min > 0)
            ?.some((domainModifierGroup) => {
                if (isDefaultItemUnavailable(domainModifierGroup, bagEntry, unavailableIds)) {
                    return true;
                }

                // check is product has unavailable default submodidiers
                const modifierGroup = bagEntry.modifierGroups?.find(
                    (bagModifierGroup) => bagModifierGroup.productId === domainModifierGroup.productGroupId
                );

                const modifierWithGroups =
                    modifierGroup?.modifiers.filter((modifier) => modifier?.modifierGroups) || [];

                const isDefaultSubModifiersUnavailable = modifierWithGroups.some((bagModifier) =>
                    products[bagModifier.productId]?.itemModifierGroups
                        ?.filter((domainSubModifierGroup) => domainSubModifierGroup.min > 0)
                        ?.some((domainSubModifierGroup) =>
                            isDefaultItemUnavailable(domainSubModifierGroup, bagModifier, unavailableIds)
                        )
                );

                return isDefaultSubModifiersUnavailable;
            });

        if (unavailableSubModifierIds.length || unavailableModifierIds.length) {
            return {
                isDefaultModifiersUnavailable,
                isWholeProductUnavailable: false,
                modifierIds: unavailableModifierIds.length ? unavailableModifierIds : [],
                subModifierIds: unavailableSubModifierIds.length ? unavailableSubModifierIds : [],
            };
        }
    };

    if (isUnavailable(bagEntry.productId)) {
        return {
            isWholeProductUnavailable: true,
        };
    }

    if (!bagEntry.childItems?.length) {
        return checkSingleProductModifiersAvailability(bagEntry);
    } else if (isUnavailable(bagEntry.childItems[0].productId)) {
        return {
            isWholeProductUnavailable: true,
        };
    }

    const unavailableChildItemsIds = bagEntry.childItems
        .slice(1)
        .map(({ productId }) => productId)
        .filter((id) => isUnavailable(id));

    const { unavailableModifierIds } = bagEntry.childItems.reduce(
        (acc, entry) => {
            const result = checkSingleProductModifiersAvailability(entry);

            if (result) {
                return {
                    ...acc,
                    unavailableModifierIds: [...acc.unavailableModifierIds, ...result.modifierIds],
                };
            }

            return acc;
        },
        { unavailableModifierIds: [] }
    );

    if (unavailableChildItemsIds.length || unavailableModifierIds.length) {
        return {
            isWholeProductUnavailable: false,
            childItemsIds: unavailableChildItemsIds,
            modifierIds: unavailableModifierIds,
        };
    }
}
