import {
    ITallyError500ExternalResponseModel,
    TTallyErrorCodeModel,
    TTallyErrorMessageModel,
} from '../../@generated/webExpApi/models';
import getBrandInfo from '../../lib/brandInfo';

export const getTallyError = (
    error: ITallyError500ExternalResponseModel,
    phoneNumber?: string
): ITallyError500ExternalResponseModel => {
    const { brandId } = getBrandInfo();

    const errorsMap: { [key in TTallyErrorCodeModel | string]: ITallyError500ExternalResponseModel } = {
        [TTallyErrorCodeModel.DeliveryProviderDeliveryTimeNotAvailable]: {
            ...error,
            message: (error.message as TTallyErrorMessageModel).replace('{earliestTime}', error.data?.earliestTime),
        },
        [TTallyErrorCodeModel.ExceededMaxOrderAmount]: {
            ...error,
            message: (error.message as TTallyErrorMessageModel)
                .replace('{orderMaxValue}', error.data?.max)
                .replace('{phoneNumber}', phoneNumber),
        },
        [TTallyErrorCodeModel.OrderTimeNotValid]: {
            ...error,
            message:
                brandId === 'Arbys'
                    ? (error.message as TTallyErrorMessageModel).replace(
                          ' or select a new delivery time to proceed with your order',
                          ''
                      )
                    : error.message,
        },
    };

    return errorsMap[error?.code as TTallyErrorCodeModel] || error;
};
