import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../store';

import { IPageLoaderState, actions } from '../pageLoader';

export interface IPageLoaderHook {
    show: boolean;
    actions: {
        showLoader: () => void;
        hideLoader: () => void;
    };
}

const usePageLoader = (): IPageLoaderHook => {
    const dispatch = useAppDispatch();
    const { show } = useSelector<RootState, IPageLoaderState>((state) => state.pageLoader);

    const showLoader = () => dispatch(actions.showLoader());
    const hideLoader = () => dispatch(actions.hideLoader());

    return { show, actions: { showLoader, hideLoader } };
};

export default usePageLoader;
