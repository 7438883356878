import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useAppSelector } from '../../redux/store';
import {
    selectDefaultTallyItem,
    selectTallyItemForNewSize,
    selectDefaultTallyItemForChildItem,
    selectAppliedTallyModifiers,
    selectProductById,
} from '../../redux/selectors/domainMenu';
import * as PDPStore from '../pdp';
import { hasUnsavedModifications } from '../../lib/tallyItem';

import useBag from './useBag';
import { selectPDPTallyItem } from '../selectors/pdp';
import { selectTallyPriceAndCalories } from '../selectors/tally';
import { useDefaultModifiers } from './domainMenu';

export interface UsePDPHook {
    pdpTallyItem: PDPStore.PDPTallyItem;
    useProductChild: (
        childIndex: number
    ) => {
        childItem: PDPStore.PDPTallyItem;
        setSelectedChildProduct: (productId: string) => void;
        editChildProductSize: (newSizeProductId: string) => void;
    };
    useInitTallyItem: (productId: string) => void;
    useHasUnsavedModifications: () => boolean;
    actions: {
        putTallyItem: (payload: PDPStore.PutTallyItemPayload) => PDPStore.PDPTallyItem;
        editTallyItemSize: (productId: string) => void;
        editTallyItemModifiers: (payload: PDPStore.EditTallyItemModifiersPayload) => void;
        editTallyItemCount: (payload: PDPStore.EditTallyItemCountPayload) => void;
        resetPdpState: () => void;
        resetPdpCondimentsState: () => void;
        onSauceOnSideChange: (payload: PDPStore.OnSauceOnSideChangePayload) => void;
        onModifierChange: (payload: PDPStore.OnModifierChangePayload) => void;
        onModifierSizeChange: (payload: PDPStore.OnModifierSizeChangePayload) => void;
        onCondimentChange: (payload: PDPStore.OnModifierChangePayload) => void;
    };
}

export default function usePdp(): UsePDPHook {
    const dispatch = useDispatch();

    const state = useAppSelector((state) => state);
    const pdpTallyItem = useAppSelector(selectPDPTallyItem);
    const defaultModifiers = useDefaultModifiers(pdpTallyItem.productId);

    const putTallyItem = (payload: PDPStore.PutTallyItemPayload) => {
        dispatch(PDPStore.actions.putTallyItem(payload));
        return payload.pdpTallyItem;
    };

    const resetPdpState = () => {
        dispatch(PDPStore.actions.resetPdpState());
    };

    const resetPdpCondimentsState = () => {
        dispatch(PDPStore.actions.resetPdpCondimentsState());
    };

    const onSauceOnSideChange = (payload: PDPStore.OnSauceOnSideChangePayload) => {
        dispatch(PDPStore.actions.onSauceOnSideChange(payload));
    };

    const onModifierChange = (payload: PDPStore.OnModifierChangePayload) => {
        dispatch(PDPStore.actions.onModifierChange({ ...payload, defaultModifiers }));
    };

    const onModifierSizeChange = (payload: PDPStore.OnModifierSizeChangePayload) => {
        dispatch(PDPStore.actions.onModifierSizeChange(payload));
    };

    const onCondimentChange = (payload: PDPStore.OnModifierChangePayload) => {
        dispatch(PDPStore.actions.onCondimentChange(payload));
    };

    const editTallyItemSize = (productId: string) => {
        const defaultTallyItem = selectTallyItemForNewSize(state, productId, pdpTallyItem);

        const newPdpTallyItem: PDPStore.PDPTallyItem = {
            ...defaultTallyItem,
            childItems: defaultTallyItem?.childItems?.map((el, i) => ({
                ...el,
                lineItemId: pdpTallyItem?.childItems[i]?.lineItemId,
            })),
        };

        const { lineItemId } = pdpTallyItem;

        return dispatch(PDPStore.actions.editTallyItemSize({ pdpTallyItem: { ...newPdpTallyItem, lineItemId } }));
    };

    const editTallyItemModifiers = (payload: PDPStore.EditTallyItemModifiersPayload) => {
        dispatch(PDPStore.actions.editTallyItemModifiers(payload));
    };

    const editTallyItemCount = (payload: PDPStore.EditTallyItemCountPayload) => {
        return dispatch(PDPStore.actions.editTallyItemCount(payload));
    };

    const useProductChild = (childIndex: number) => {
        const setSelectedChildProduct = (newProductId: string) => {
            const newChildTallyItem = selectDefaultTallyItemForChildItem(state, pdpTallyItem.productId, newProductId);

            const childItems = pdpTallyItem.childItems.map((childItem, i) =>
                i === childIndex ? { ...newChildTallyItem, lineItemId: childItem.lineItemId } : childItem
            );
            const newChildItems = childItems.length - 1 < childIndex ? [...childItems, newChildTallyItem] : childItems;

            const newPdpTallyItem: PDPStore.PDPTallyItem = {
                ...pdpTallyItem,
                childItems: newChildItems,
            };

            newPdpTallyItem.price = selectTallyPriceAndCalories(state, newPdpTallyItem).price;

            dispatch(
                PDPStore.actions.putTallyItem({ pdpTallyItem: newPdpTallyItem, lineItemId: newPdpTallyItem.lineItemId })
            );
        };

        const editChildProductSize = (newSizeProductId: string) => {
            const currentSelectedChild = pdpTallyItem.childItems[childIndex];
            const currentSelectedChildDomainProduct = selectProductById(state, currentSelectedChild.productId);

            const newChildTallyItem = selectDefaultTallyItemForChildItem(
                state,
                pdpTallyItem.productId,
                newSizeProductId
            );

            const modifiersToApply =
                currentSelectedChild.modifierGroups?.map((group) => {
                    const defaultCurrentChildModifiers = Object.values(
                        currentSelectedChildDomainProduct.itemModifierGroups.find(
                            ({ productGroupId }) => productGroupId === group.productId
                        ).itemModifiers
                    ).filter(({ defaultQuantity }) => defaultQuantity > 0);

                    // preserve deselected modifiers on new size
                    const deselectedDefaultModifiers = defaultCurrentChildModifiers
                        .filter(({ itemId }) => !group.modifiers.find(({ productId }) => productId === itemId))
                        .map(({ itemId }) => ({ productId: itemId, quantity: 0, price: 0 }));

                    return { ...group, modifiers: [...group.modifiers, ...deselectedDefaultModifiers] };
                }) || [];

            newChildTallyItem.modifierGroups = selectAppliedTallyModifiers(state, newChildTallyItem, modifiersToApply);

            const newChildItems = pdpTallyItem.childItems.map((childItem, i) =>
                i === childIndex ? { ...newChildTallyItem, lineItemId: childItem.lineItemId } : childItem
            );

            const newPdpTallyItem: PDPStore.PDPTallyItem = {
                ...pdpTallyItem,
                childItems: newChildItems,
            };

            newPdpTallyItem.price = selectTallyPriceAndCalories(state, newPdpTallyItem).price;

            dispatch(
                PDPStore.actions.putTallyItem({ pdpTallyItem: newPdpTallyItem, lineItemId: newPdpTallyItem.lineItemId })
            );
        };

        return {
            childItem: pdpTallyItem.childItems?.[childIndex],
            setSelectedChildProduct,
            editChildProductSize,
        };
    };

    const useHasUnsavedModifications = () => {
        const { bagEntries } = useBag();
        const defaultTallyItem = useAppSelector((state) => selectDefaultTallyItem(state, state.pdp.initialTallyItemId));

        const optionToCompare = pdpTallyItem?.lineItemId
            ? bagEntries?.find(({ lineItemId }) => lineItemId === pdpTallyItem.lineItemId)
            : defaultTallyItem;

        const result = hasUnsavedModifications(optionToCompare, pdpTallyItem);

        return result;
    };

    const useInitTallyItem = (productId: string) => {
        const defaultTallyItem = useAppSelector((state) => selectDefaultTallyItem(state, productId));

        useEffect(() => {
            dispatch(PDPStore.actions.putInitialTallyItemId({ defaultTallyItemId: defaultTallyItem.productId }));

            // set tally item on redirects, ignoring "modify" and "Make it a Meal"
            if (productId !== pdpTallyItem.productId) {
                dispatch(PDPStore.actions.putTallyItem({ pdpTallyItem: defaultTallyItem }));
            }
            // set tally item when land on the page first time
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [productId, defaultTallyItem?.productId]);
    };

    return {
        pdpTallyItem,
        useProductChild,
        useHasUnsavedModifications,
        useInitTallyItem,
        actions: {
            putTallyItem,
            resetPdpState,
            resetPdpCondimentsState,
            editTallyItemSize,
            editTallyItemModifiers,
            editTallyItemCount,
            onSauceOnSideChange,
            onModifierChange,
            onModifierSizeChange,
            onCondimentChange,
        },
    };
}
