import { Address, Coordinates } from './types';
import createErrorWrapper from '../createErrorWrapper';

const getCoordinates = async (address: Address): Promise<Coordinates> => {
    const { NEXT_PUBLIC_GOOGLE_MAP_API_KEY } = process.env;
    const googleApisURL = new URL('https://maps.googleapis.com/maps/api/geocode/json');
    const params = new URLSearchParams({
        address: `${address}`,
        key: NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
    });
    googleApisURL.search = params.toString();

    const googleApi = createErrorWrapper<Response, void>('getCoordinates', () => fetch(googleApisURL.href));

    const res = await googleApi();

    if (res.status === 200) {
        const result = await res.json();
        const { location } = result.results[0]?.geometry || {};

        if (!(location && location.lat && location.lng)) {
            throw Error(`ERROR locationSearch - Could not geocode ${address}`);
        }

        return location;
    }

    throw new Error(`${res.status} ${res.statusText}`);
};

export default getCoordinates;
