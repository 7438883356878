import { shallowEqual, useSelector } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { useState } from 'react';

import orderService from '../../common/services/orderService/orderService';

import { ICustomerInfo } from '../../components/organisms/checkout/customerInfo';

import { RootState, useAppDispatch } from '../store';

import { actions, IFullfilledOrderPayload, ISubmitOrderState } from '../submitOrder';
import { createSubmitOrderRequest } from '../../common/helpers/submitOrderHelper';
import { IDeliveryLocation, LocationWithDetailsModel } from '../../common/services/locationService/types';
import { IPaymentRequest, IGiftCard } from '../../components/clientOnly/paymentInfoContainer/types';
import { IPaymentState } from '../../components/clientOnly/paymentInfoContainer/paymentTypeDropdown/types';
import { CardInWalletInfo } from '../types';
import {
    TallyResponseModel,
    OrderRequest,
    OrderResponseModel,
    ITallyError500ExternalResponseModel,
    TTallyErrorCodeModel,
    TErrorCodeModel,
} from '../../@generated/webExpApi';
import { getTallyError } from '../../common/helpers/getTallyError';
import { isDealInValid, setTallyProductWithModifiers } from '../../common/helpers/tallyHelper';
import { useProducts } from './domainMenu';
import useSelectedSell from './useSelectedSell';
import { isHeadsUpMessageEnabled, isOrderV4Enabled, navigateToOrderFailure } from '../../lib/getFeatureFlags';
import { GtmErrorCategory } from '../../common/services/gtmService/types';
import { GTM_ERROR_EVENT } from '../../common/services/gtmService/constants';

export interface IOrderPayload {
    tallyOrder: TallyResponseModel;
    customerInfo: ICustomerInfo;
    customerId?: string;
    paymentRequest?: IPaymentRequest;
    paymentInfo: IPaymentState;
    giftCards?: IGiftCard[];
    location: LocationWithDetailsModel;
    orderTime: Date;
    instructions?: string[];
    deliveryLocation?: IDeliveryLocation;
    driverTip?: number;
    serverTip?: number;
    deviceIdentifier?: string;
    ssCorrelationId?: string;
}
export interface ISubmitOrderHook {
    error: Error;
    isLoading: boolean;
    lastOrder: OrderResponseModel;
    lastRequest: OrderRequest;
    unavailableItems: string[] | null;
    isShowAlertModal: boolean;
    cardInWalletInfo: CardInWalletInfo;
    submitOrder: (order: IOrderPayload) => Promise<PayloadAction<IFullfilledOrderPayload>>;
    resetSubmitOrder: () => void;
    hideAlertModal: () => void;
    setUnavailableItems: (payload: string[]) => void;
    setCardInWalletInfo: (payload?: CardInWalletInfo) => void;
    cartId: string;
}

const useSubmitOrder = (): ISubmitOrderHook => {
    const dispatch = useAppDispatch();
    const {
        error,
        isLoading,
        lastOrder,
        lastRequest,
        unavailableItems,
        isShowAlertModal,
        cardInWalletInfo,
    } = useSelector<RootState, ISubmitOrderState>((state) => state.submitOrder, shallowEqual);
    const domainProducts = useProducts();

    const { correlationId: ssCorrelationId } = useSelectedSell();

    const [cartId, setCartId] = useState(null);

    const dispatchSubmitOrderError = (errorMessage: string) => {
        const payload = {
            ErrorCategory: GtmErrorCategory.CHECKOUT_SUBMIT,
            ErrorDescription: errorMessage,
        };
        dispatch({ type: GTM_ERROR_EVENT, payload });
        return dispatch(actions.rejected(new Error(errorMessage)));
    };

    const submitOrder = (order: IOrderPayload): Promise<PayloadAction<IFullfilledOrderPayload>> => {
        dispatch(actions.pending(null));
        const orderPayload: IOrderPayload = {
            ...order,
            ssCorrelationId,
            tallyOrder: isHeadsUpMessageEnabled()
                ? {
                      ...order.tallyOrder,
                      products: setTallyProductWithModifiers(order.tallyOrder.products, domainProducts),
                      discounts: isDealInValid(order.tallyOrder.discounts) ? undefined : order.tallyOrder.discounts,
                  }
                : {
                      ...order.tallyOrder,
                      products: setTallyProductWithModifiers(order.tallyOrder.products, domainProducts),
                  },
        };

        const { orderV3Request, orderV4Request } = createSubmitOrderRequest(orderPayload);
        setCartId(orderV3Request.orderRequestModel.orderData.cartId);

        const submitOrderRequest = isOrderV4Enabled() ? orderV4Request : orderV3Request;

        return orderService.createOrder(submitOrderRequest).then((response) => {
            const isSuccessPayload = (
                response: OrderResponseModel | ITallyError500ExternalResponseModel
            ): response is OrderResponseModel => {
                return 'requestStatus' in response;
            };

            if (isSuccessPayload(response)) {
                // TODO refactor lastRequest object after get rid of order V3
                return dispatch(actions.fulfilled({ response, request: orderV3Request }));
            }

            if (response.code === TTallyErrorCodeModel.ProductsNotAvailable) {
                return dispatch(actions.setUnavailableItems(response.data.productIds));
            }

            const errorsMap = {
                [TErrorCodeModel.DeliveryOrderInvalidPhone]:
                    'We apologize. Our delivery partner does not recognize your phone number. Please use a valid phone number for this delivery order.',
                [TErrorCodeModel.PaymentAmountLower]:
                    'The payment amount does not match the total order amount. Please review the payments methods amounts and try again.',
                [TErrorCodeModel.PaymentAmountHigher]:
                    'The payment amount does not match the total order amount. Please review the payments methods amounts and try again.',
                [TErrorCodeModel.PaymentNotValid]:
                    'The selected payment method(s) are not valid. Please select different payment method(s) and try again.',
                [TErrorCodeModel.PaymentNotPresented]:
                    'Your payment was declined and your order was not submitted. Please try again.',
            };

            const errorMessage =
                response.code === TErrorCodeModel.PaymentFailure
                    ? response.message
                    : errorsMap[response.code as TErrorCodeModel];

            if (errorMessage) {
                return dispatchSubmitOrderError(errorMessage);
            }

            if ((response.code as TErrorCodeModel) === TErrorCodeModel.OrderFailed) {
                return dispatch(
                    actions.rejected({
                        closeByDefault: false,
                        title: 'Uh Oh! The Order Failed',
                        message: response.message,
                        code: response.code,
                        hideNotification: navigateToOrderFailure(),
                    })
                );
            }

            const tallyError = getTallyError(response, order.location.contactDetails.phone);

            return dispatchSubmitOrderError(`${tallyError.message}`);
        });
    };

    const resetSubmitOrder = (): void => {
        dispatch(actions.reset(null));
    };

    const hideAlertModal = (): void => {
        dispatch(actions.hideAlertModal(null));
    };

    const setUnavailableItems = (payload: string[]) => {
        dispatch(actions.setUnavailableItems(payload));
    };

    const setCardInWalletInfo = (payload?: CardInWalletInfo) => {
        dispatch(actions.setCardInWalletInfo(payload || CardInWalletInfo.NOT_SELECTED));
    };

    return {
        error,
        isLoading,
        lastOrder,
        lastRequest,
        unavailableItems,
        isShowAlertModal,
        cartId,
        cardInWalletInfo,
        submitOrder,
        resetSubmitOrder,
        hideAlertModal,
        setUnavailableItems,
        setCardInWalletInfo,
    };
};

export default useSubmitOrder;
