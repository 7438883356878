import React, { FC } from 'react';
import classNames from 'classnames';
import Modal from '@material-ui/core/Modal';
import { useScreen } from '../../../common/hooks/useScreen';
import BrandIcon from '../../atoms/BrandIcon';
import styles from './unavailableModal.module.css';

interface IUnavailableModal {
    open: boolean;
    title: string;
    description: string;
    footerText: string;
    onClose: () => void;
    renderButtons?: () => JSX.Element;
    renderLoader?: () => JSX.Element;
}

export const UnavailableModal: FC<IUnavailableModal> = ({
    open,
    onClose,
    description,
    title,
    footerText,
    children,
    renderButtons = () => null,
    renderLoader = () => null,
}) => {
    const { isDesktop } = useScreen();

    return (
        <Modal open={open} onClose={onClose}>
            <div className={styles.modalContainer}>
                <button aria-label="Close Icon" className={styles.closeButton} onClick={onClose}>
                    <BrandIcon icon="action-close" size={isDesktop ? 'm' : 's'} className={styles.closeIcon} />
                </button>
                <div className={styles.contentSection}>
                    <BrandIcon icon="info-error" size="xl" className={styles.warning} />
                    <div className={styles.titleSection}>
                        <div className="t-header-card-title">{title}</div>
                        <div className={classNames('t-paragraph', styles.subtitle)}>{description}</div>
                    </div>
                    <div className={styles.itemsBlock}>{children}</div>
                    <div className={classNames('t-paragraph-hint', styles.footerText)}>{footerText}</div>
                </div>
                {renderButtons()}
                {renderLoader()}
            </div>
        </Modal>
    );
};
