import { ItemModel } from '../../../@generated/webExpApi';
import { sortOrder } from '../../../common/constants/aboutProduct';
import { RootState } from '../../store';
import { IAboutSectionInfo, INutritionInfoSizeSelection, ISizeSelection, ModifierGroupType } from '../../types';
import {
    selectChildItemSizes,
    selectDefaultModifiers,
    selectProductById,
    selectProductGroupByProductId,
    selectProductIsCombo,
    selectProductIsPromo,
    selectProductSizes,
    selectSizeGroup,
} from '../domainMenu';
import { selectPDPProduct, selectPDPTallyItem } from '../pdp';

export const selectAboutSectionInfo = (state: RootState): IAboutSectionInfo | null => {
    const pdpTallyItem = selectPDPTallyItem(state);

    if (!pdpTallyItem?.productId) {
        return null;
    }

    const { productId: pdpProductId } = pdpTallyItem;

    const pdpProduct = selectPDPProduct(state);
    const isCombo = selectProductIsCombo(state, pdpProductId);
    const isPromo = selectProductIsPromo(state, pdpProductId);
    const defaultModifiers = selectDefaultModifiers()(state, pdpProductId);
    const productsFromModifiers = (pdpTallyItem.modifierGroups || []).reduce((acc, mg) => {
        if (mg.metadata?.MODIFIER_GROUP_TYPE === ModifierGroupType.SELECTIONS) {
            const products = mg?.modifiers
                ?.filter((modifier) => modifier.quantity > 0)
                .map((modifier) => selectProductById(state, modifier.productId));
            if (products) {
                return [...acc, ...products];
            }
        }

        return acc;
    }, []);

    const products =
        isCombo || isPromo
            ? pdpTallyItem.childItems.map(({ productId }) => selectProductById(state, productId))
            : [pdpProduct, ...productsFromModifiers];

    const productGroup = selectProductGroupByProductId(state, pdpProductId);
    if (!productGroup?.name) {
        return null;
    }

    const uniqById = (item: ItemModel, index: number, arr: ItemModel[]) =>
        arr.findIndex((it) => it.id === item.id) === index;

    return {
        productId: pdpProductId,
        productKind: productGroup.name,
        description: pdpProduct.description,
        whatsIncluded: defaultModifiers.map((it) => it.name),
        nutritionInfo: products.filter(uniqById).map((product) => {
            const productSizes =
                isCombo || isPromo
                    ? selectChildItemSizes(state, product.id, pdpProductId)
                    : selectProductSizes(state, product.id);
            const toProduct = (item: ISizeSelection) => item.product;

            const toNutritionInfoSizeSection = (product: ItemModel): INutritionInfoSizeSelection => {
                const { macroNutrients = {}, allergenInformation } = product.nutrition || {};
                const sortedFields = macroNutrients
                    ? Object.values(macroNutrients).sort((a, b) =>
                          sortOrder.indexOf(a.label) < sortOrder.indexOf(b.label) ? -1 : 1
                      )
                    : null;

                if (!sortedFields && !allergenInformation) {
                    return null;
                }

                const sizeGroup = selectSizeGroup(state, product.id);
                return {
                    name: sizeGroup?.name,
                    nutritionalFacts: sortedFields,
                    allergicInformation: allergenInformation,
                };
            };

            return {
                displayName: product.name,
                sizeSelections: productSizes
                    .map(toProduct)
                    .filter(Boolean)
                    .filter(uniqById)
                    .map(toNutritionInfoSizeSection)
                    .filter(Boolean),
            };
        }),
    };
};
