import isTapListMenuCategory from './isTapListMenuCategory';

//TODO this is a workaround for removing duplicated categories, this will need to be removed once Menu implements Inclusions on their end
const getCategoriesWithDefaultReasonFilter = (cmsAvailableCategories, domainCategories) => {
    return cmsAvailableCategories.filter((category) => {
        if (isTapListMenuCategory(category)) return true;

        const duplicatedCMSCategories = cmsAvailableCategories.filter(
            (cmsAvailableCategory) =>
                category.fields.categoryId !== cmsAvailableCategory.fields.categoryId &&
                cmsAvailableCategory.fields.categoryName === category.fields.categoryName
        );

        const categoryPriority = domainCategories[category.fields.categoryId]?.metadata?.DEFAULT_REASON;
        return duplicatedCMSCategories.length === 0
            ? true
            : categoryPriority
            ? duplicatedCMSCategories.every((duplicatedCMSCategory) => {
                  const duplicatedCategoryPriority =
                      domainCategories[duplicatedCMSCategory.fields.categoryId]?.metadata?.DEFAULT_REASON;
                  return duplicatedCategoryPriority ? categoryPriority > duplicatedCategoryPriority : true;
              })
            : true;
    });
};

export default getCategoriesWithDefaultReasonFilter;
