import { Dictionary } from '@reduxjs/toolkit';
import { Entry } from 'contentful';

import { IProductFields } from '../../../@generated/@types/contentful';
import {
    IClaimMissingPointsFromClientModel,
    ItemModel,
    OrderRequestModel,
    OrderResponseModel,
    PaymentTypeModel,
    TallyModifierGroupModel,
    TallyProductModel,
    TallyResponseModel,
} from '../../../@generated/webExpApi';

import { IProductItemById } from '../globalContentfulProps';
import { PickupAddress } from '../../../redux/orderLocation';
import { LocationWithDetailsModel } from '../locationService/types';
import { IGiftCard } from '../../../components/clientOnly/paymentInfoContainer/types';
import { IDomainProductItem, IProducts } from '../../../redux/types';

export enum GtmEventNames {
    PageEvent = 'PageEvent',
    ApiEvent = 'ApiEvent',
    ImpressionView = 'ImpressionView',
    ProductView = 'ProductView',
    AddToCart = 'AddToCart',
    RemoveFromCart = 'RemoveFromCart',
    Checkout = 'Checkout',
    BeginCheckout = 'begin_checkout',
    CheckoutTipping = 'checkout_tipping ',
    TransactionInfo = 'TransactionInfo',
    TransactionComplete = 'TransactionComplete',
    CartOpen = 'CartOpen',
    ComboSelection = 'ComboSelection',
    ModifyItem = 'ModifyItem',
    ModifyInCart = 'product_modify_cart',
    ModifierCustomization = 'ModifierCustomization',
    AddMoreItems = 'AddMoreItems',
    SearchNewLocation = 'SearchNewLocation',
    LocationShare = 'location_share',
    StartPickupOrder = 'StartPickupOrder',
    MakeMyStore = 'MakeMyStore',
    OnlineOrderingComingSoon = 'OnlineOrderingComingSoon',
    QtyDecrease = 'QtyDecrease',
    QtyIncrease = 'QtyIncrease',
    EmailSignUp = 'EmailSignUp',
    GiftCardBalanceFormSubmit = 'giftCardBalanceFormSubmit',
    MapClick = 'mapClick',
    MapDoubleClick = 'mapDblClick',
    MapDragStart = 'mapDragStart',
    MapDrag = 'mapDrag',
    MapDragEnd = 'mapDragEnd',
    MapLocationClick = 'locationClick',
    MapListLocationClick = 'listLocationClick',
    SignInSuccess = 'signInSuccess',
    SignInFailure = 'signInFailure',
    UserId = 'userId',
    AccountCreationSuccess = 'accountCreationSuccess',
    PickupTimeSelection = 'pickupTimeSelection',
    ModifierSelection = 'modifierSelection',
    Error = 'error',
    LocationOrderCTA = 'locationOrderCTA',
    LocationChange = 'locationChange',
    CheckIn = 'CheckIn',
    AccountDeletedSubmitted = 'accountDeletedSubmitted',
    AccountDeletedFeedback = 'accountDeletedFeedback',
    SendAMessage = 'sendAMessage',
    WalletEvent = 'walletEvent',
    Location = 'Location',
    LocationEvent = 'locationEvent',
    BannerEvent = 'bannerEvent',
    IngredientSelection = 'ingredient_selection',
    RewardsClaimEvent = 'rewards_claim',
    RewardsReceiptEvent = 'rewards_receipt',
    SelectLocation = 'select_location',
    RewardsAndDeals = 'rewards_and_deals',
    ViewDeal = 'View Deal',
    RedeemDealOnline = 'redeem_deal_online',
    RedeemDealInStore = 'redeem_deal_in_store',
    SwapToThisDeal = 'swap_deal',
    ProductViewDeal = 'product_view',
    AddToCartDeal = 'add_to_cart',
    ReAddToCart = 'add_to_cart',
    Purchase = 'purchase',
    Reorder = 'reorder',
    ApplyDeal = 'apply_deal',
    OrderHistory = 'order_history',
    OrderClick = 'order_click',
    TipSelection = 'tip_selection',
    ChangeAvatar = 'Change_Avatar',
    CtaClick = 'cta_click',
    ChangeLocationCheckout = 'change_location_checkout',
    HamburgerMenuOptionSelect = 'top_nav',
    CheckoutProductAddition = 'checkout_product_addition',
    CheckoutProductRemoval = 'checkout_product_removal',
    CondimentModification = 'condiment_modification',
    BackToTop = 'back_to_top',
    PaymentMethod = 'payment_method',
    ComboModify = 'combo_modify',
    ComboReplace = 'combo_replace',
    CrossSell = 'cross_sell',
    MakeItACombo = 'make_it_a_combo',
    CheckInClick = 'check_in_click',
    CheckInWrongLocation = 'check_in_wrong_location',
    JoinSonicRewards = 'join_sonic_rewards',
    WalletCardEvent = 'wallet',
    ExploreMore = 'explore_more',
    ViewItem = 'view_item',
    AddToCartEvent = 'add_to_cart',
    RemoveFromCartEvent = 'remove_from_cart',
    PickupTime = 'pickup_time',
    ModalEvent = 'modal',
    Order = 'order',
}

export enum GtmActionFields {
    Add = 'add',
    Remove = 'remove',
    Detail = 'detail',
    Checkout = 'checkout',
    Purchase = 'purchase',
    Impressions = 'impressions',
    Impression = 'Impression',
    Click = 'Click',
    TipSelection = 'Tip Selection',
    Delete = 'Delete',
    SelectALocation = 'Select A Location',
    ViewDeals = 'View Deals',
    RedeemOnline = 'Redeem Online',
    RedeemInStore = 'Redeem In Store',
    SwapDeal = 'Swap Deal',
    ProductView = 'Product View',
    AddToCart = 'Add to Cart',
    Reorder = 'Reorder',
    Apply = 'Apply',
    SonicRewards = 'sonic_rewards',
    CTAClick = 'CTA Click',
}

export interface IGtmBaseEventData {
    cid?: string;
}

export interface IGtmSignInData {
    signInType: string;
}

export interface GtmProductViewData {
    product: Entry<IProductFields>;
    price: number | string;
    category: string;
}

export interface GtmMakeMyStoreEventData {
    id?: string;
    name?: string;
}

export interface GtmRemoveFromCartData extends TallyProductModel {
    category: string;
    name: string;
    sauce?: { [key: string]: string };
}

export interface GtmItemData extends TallyProductModel {
    category?: string;
    relatedItemGroups?: Array<TallyModifierGroupModel>;
}
export type GtmAddItemData = GtmItemData;
export type GtmRemoveItemData = GtmItemData;
export interface GtmBeginCheckoutData {
    checkoutEntries: Array<GtmItemData>;
    domainProducts: Array<IDomainProductItem>;
}

export type GtmRestoreToCartData = GtmRemoveFromCartData;
export type GmtCheckoutViewData = GtmRemoveFromCartData;

export interface GtmCheckoutPaymentEvent {
    tallyOrder: TallyResponseModel;
    bagEntries: GmtCheckoutViewData[];
    step?: number;
    action?: string;
}

export interface GtmImpressionProduct extends IProductFields {
    price?: string | number;
}

export interface GtmImpressionViewData {
    products: GtmImpressionProduct[];
    category: string;
}
export interface GtmTransactionCompleteData {
    tallyOrder: TallyResponseModel;
    request: OrderRequestModel;
    domainProducts: Dictionary<ItemModel>;
    productsById: IProductItemById;
    orderId: string;
    bagEntries: GmtCheckoutViewData[];
    transactionId: string;
    coupon: string;
    productsByProductId: IProducts;
}

export interface GtmTransactionInfoEventData {
    order: OrderResponseModel;
    request: OrderRequestModel;
    tallyOrder: TallyResponseModel;
    location: LocationWithDetailsModel;
    loginType: string;
}

export interface GtmModifierCustomizationEventData {
    category: GtmModifierCustomizationCategory;
    productName: string;
    modifierName: string;
}

export enum GtmModifierCustomizationCategory {
    addition = 'modifier addition',
    removal = 'modifier removal',
}

export interface GtmComboSelectionEventData {
    comboName: string;
    comboSize: string;
    comboSide: string;
    comboDrink: string;
    comboPrice: number;
}

export interface GtmMapLocationClickData {
    location: PickupAddress;
}

export type GtmMapListLocationClickData = GtmMapLocationClickData;

export enum SignInTypes {
    FACEBOOK = 'facebook',
    GOOGLE = 'google',
    APPLE = 'apple',
}

export enum CrossSellEngagementTypes {
    CROSS_SELL_OPEN = 'cross_sell_open',
    CROSS_SELL_CLOSE = 'cross_sell_close',
    CROSS_SELL_ADD = 'cross_sell_add',
    CROSS_SELL_REMOVE = 'cross_sell_remove',
    CROSS_SELL_MODIFY = 'cross_sell_modify',
}

export enum SignInResultTypes {
    SUCCESS = 'signInSuccess',
    FAILURE = 'signInFailure',
}

export interface GtmUserIdData {
    userId: string;
    isLoggedIn: boolean;
    userAgent: string;
}

export interface IGtmPickUpTimeData {
    pickupDate: string;
    pickupTime: string;
}

export enum ModifierSelectionAction {
    ADD = 'add',
    REMOVE = 'remove',
    SIZE = 'size',
}
export interface ModifierSelectionData {
    action: ModifierSelectionAction;
    label: string;
}

export interface GtmErrorEvent {
    ErrorCategory: string;
    ErrorDescription: string;
}

export enum GtmErrorCategory {
    GENERAL_ERROR = 'General Error',
    BAG_ERROR = 'Bag Error',
    BAG_ERROR_MAX_TOTAL = 'Bag Error - Max Total Exceeds',
    CHECKOUT_PAYMENT = 'Checkout Error - Payment',
    CHECKOUT_UNAVAILABLE_ITEMS = 'Checkout Error - Unavailable items',
    CHECKOUT_SUBMIT = 'Checkout Error - Submit Order',
    CHECKOUT_UNAVAILABLE_LOCATION = 'Checkout Error - Location Unavailable',
    EDIT_ACCOUNT = 'Edit Account Error',
    REWARDS = 'Rewards Error',
}

export enum GtmCategory {
    ORDER = 'Order',
    ACCOUNT = 'Account',
    LOCATION = 'Location',
    REWARDS_AND_DEALS = 'Rewards & Deals',
    ECOMMERCE = 'Ecommerce',
    ORDER_HISTORY = 'Order history',
    CHECKOUT = 'Checkout',
    SIGN_UP = 'sign_up',
}

export enum GtmDeviceFields {
    DESKTOP = 'Desktop',
}

export interface LocationOrderEvent {
    category: GtmCategory.ORDER;
    action: string;
    label: string;
    date: string;
    time: string;
}

export interface LocationChange {
    category: GtmCategory.ORDER;
    action: string;
    label: string;
}

export interface AccountDeletedFeedbackData {
    category: GtmCategory.ACCOUNT;
    action: GtmActionFields.Delete;
    reason_1: string;
}
export interface AccountDeletedSubmittedData {
    category: GtmCategory.ACCOUNT;
    action: GtmActionFields.Delete;
    label: string;
}

export enum GtmWalletAction {
    ADD = 'Add',
    EDIT = 'Edit',
    REMOVE = 'Remove',
}

export enum GtmWalletLabelType {
    CREDIT_CARD = 'Credit Card',
    GIFT_CARD = 'Gift Card',
}

export interface GtmWalletData {
    action: GtmWalletAction;
    label: GtmWalletLabelType;
}

export enum PickupOrDeLiveryChangeAction {
    CHANGE_PICKUP_LOCATION = 'Change Pickup Location',
    CHANGE_DELIVERY_LOCATION = 'Change Delivery Location',
}

export interface PickupOrDeLiveryChangeEventData {
    action: PickupOrDeLiveryChangeAction;
    label: string;
}

export enum SearchLocationAction {
    SEARCH = 'Search',
}

export enum SearchLocationLabelType {
    NAVIGATION = 'Navigation',
}
export interface GtmSearchLocationData {
    action: SearchLocationAction;
    label: SearchLocationLabelType;
}

export interface GtmBannerSignUpData {
    category: string;
    action: string;
    label: string;
}

export interface GtmIngredientSelection {
    ingredient_name: string;
    ingredient_intensity?: 'Easy' | 'Extra';
}

export type GtmClaimReceiptRedeemData = IClaimMissingPointsFromClientModel;

export interface GtmSelectLocation {
    label: string;
    device: string;
}

export interface GtmViewDeal {
    label: string;
    offerName: string;
}

export interface GtmRedeemDeal {
    offerName: string;
    device: string;
}

export interface GtmProductViewDeal {
    productName: string;
}

export interface GtmAddToBagDeal {
    productNames: string;
}

export interface GtmAutoSuggestApplyDeal {
    offerName: string;
}

export interface GtmCompleteDealPurchase {
    tallyOrder: TallyResponseModel;
    orderId: string;
    productsById: IProductItemById;
}

export interface GtmOrderHistoryReorder {
    orderId: string;
    pageTitle: string;
}

export interface GtmRecentOrdersMainPageModal {
    ctaName: string;
    storeId: string;
}

export interface GtmOrderHistoryStartOrder {
    buttonText: string;
    pageTitle: string;
}

export interface GtmOrderHistoryItemCtaClick {
    label: string;
    pageTitle: string;
}

export interface GtmSignIn {
    event: string;
    sign_in_status: string;
    login_type: string;
}

export interface GtmCreateAccount {
    event: string;
    account_creation_step: string;
    auth_type: string;
}

export enum TippingChoice {
    RoundUp = 'RoundUp',
    oneDollar = '1Dollar',
    twoDollar = '2Dollar',
    custom = 'Custom',
}

export interface GtmCheckoutProductQuantityChangeData {
    eventType: GtmEventNames;
    productId: string;
    productName: string;
    quantity: number;
}

export enum GtmCondimentOptionAction {
    ADDITION = 'Addition',
    REMOVAL = 'Removal',
}

export interface GtmCondimentOptionSelect {
    condimentType: string;
    condimentModificaton: GtmCondimentOptionAction;
}

export interface GtmBackToTop {
    action: string;
    category: string;
    label: string;
}

export interface GtmCheckoutInitialPaymentMethodInfo {
    cardIssuer?: string;
    fulfillmentType: string;
    storeNumber: string;
    paymentMethod: PaymentTypeModel;
    appliedGiftCards: IGiftCard[];
}

export interface GtmPaymentMethodInfoEvent {
    storeNumber: string;
    paymentMethod: string;
    fulfillmentType: string;
    paymentType: string;
    additionalPaymentMethod?: string;
    split?: string;
}

export interface GtmComboModify {
    id: string;
    name: string;
    category: string;
    comboSize?: string;
    ingredientModified?: string;
    ingredientCategory?: string;
    drink?: string;
    drinkCategory?: string;
    side?: string;
    sideCategory?: string;
}

export interface GtmCompoReplace {
    id: string;
    name: string;
    category: string;
    comboSize: string;
    drink: string;
    drinkSize: string;
    side: string;
    sideSize: string;
}

export interface GtmMakeItACombo {
    productId: string;
    name: string;
    currency: string;
    price: number;
    quantity: number;
}

export interface GtmCheckInWrongLocation {
    event_action: string;
    checkIn_error?: string;
}

export interface GtmJoinSonicRewards {
    label: string;
}

export enum GtmWalletCardLabelType {
    CREDIT_CARD = 'Credit',
    GIFT_CARD = 'Gift',
}

export interface GTMWalletCardData {
    wallet_action: GtmWalletAction;
    wallet_type: GtmWalletCardLabelType;
    wallet_section: GtmCategory;
}

export interface GtmViewItemData {
    category: string;
    discount: number;
    productId: string;
    name: string;
    price: number;
    quantity: number;
    promotionId?: string;
    promotionName?: string;
}

export interface GtmTransactionCompleteEventBrandsData {
    event: GtmEventNames;
    gtmData: {
        [GtmActionFields.Purchase]: {
            actionField: {
                id: string;
                affiliation: string;
                revenue: number;
                tip: string | number;
                shipping: string | number;
                service: string | number;
                tax: number;
            };
            products: Array<{
                id: string;
                price: number;
                brand: string;
                quantity: number;
                name: string;
                category: string;
                sideFood: string;
                sideDrink: string;
            }>;
        };
    };
}

export interface GtmTransactionCompleteEventSonicData {
    event: GtmEventNames;
    gtmData: {
        transactionId: string;
        value: number;
        tax: number;
        tip: string | number;
        currency: string;
        items: Array<{
            item_id: string;
            item_name: string;
            item_category: string;
            price: number;
            discount: number;
            coupon: string;
            quantity: number;
            sauce: string[];
            toppings: string[];
            condiments: string[];
            combo_size: string;
            side_drink: string;
            side_food: string;
        }>;
    };
}

export type GtmTransactionCompleteEventData =
    | GtmTransactionCompleteEventBrandsData
    | GtmTransactionCompleteEventSonicData;

export interface GTMPickUpTimeAfterOrderSubmit {
    pickup_date: string;
    pickup_time: string;
}
export enum GtmPromotionalBannerType {
    PROMOTION = 'Promotion',
}

export enum IOrderInteraction {
    REORDER = 'Reorder',
    VIEW_PREVIOUS_ORDER = 'View Previous Order',
    CANCEL_ORDER = 'Cancel Order',
}

export interface GTMOrderFromOrderHistory {
    order_interaction: IOrderInteraction;
    order_id: string;
}
