import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import classNames from 'classnames';
import { getDay } from 'date-fns';
import { useRouter } from 'next/router';
import { useAppDispatch } from '../../../redux/store';

import { IPromotionModalFields } from '../../../@generated/@types/contentful';

import { InspireCmsEntry } from '../../../common/types';
import { useScreen } from '../../../common/hooks/useScreen';
import BrandIcon from '../../atoms/BrandIcon';
import ContentfulImage from '../../atoms/ContentfulImage';
import { InspireButton } from '../../atoms/button';
import ContentfulBackgroundImageContainer from '../../atoms/ContentfulBackgroundImageContainer/contentfulBackgroundImageContainer';

import styles from './promotionModal.module.css';
import {
    GTM_PROMOTIONAL_BANNER,
    GTM_PROMOTIONAL_IMPRESSION_BANNER,
} from '../../../common/services/gtmService/constants';

interface IPromotionModal {
    promotionModal: InspireCmsEntry<IPromotionModalFields>;
    pageLink: string;
}

enum DisplayTypes {
    ONE_TIME = 'One time',
    ONCE_PER_DAY = 'Once per day',
    EVERY_TIME = 'Every time',
    ONCE_PER_SESSION = 'Once per session',
}

const daysMap = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
};

const excludePages = ['/contactless-pay/', '/contactless-pay/confirmation/'];

export default function PromotionModal(props: IPromotionModal): JSX.Element {
    const { promotionModal } = props;

    const {
        header,
        subheader,
        image,
        description,
        cta1Text,
        cta1Link,
        cta2Text,
        cta2Link,
        disclaimerTogglerText,
        disclaimer,
        hideDisclaimer,
        displayForAllPages,
        displayType = [],
        displayOn,
        displayForPages,
        backgroundColor,
        backgroundImage,
        topIcon,
    } = promotionModal.fields;

    const { id } = promotionModal.sys;
    const day = getDay(new Date());

    const [showDisclaimer, setShowDisclaimer] = useState<boolean>(!hideDisclaimer);
    const [showModal, setShowModal] = useState<boolean>(true);
    const { isDesktop } = useScreen();
    const { asPath } = useRouter();
    const dispatch = useAppDispatch();

    const isBrowser = typeof window !== 'undefined';

    const includePages = displayForPages?.reduce(
        (acc, { fields }) => [...acc, fields?.nameInUrl ? `/${fields?.nameInUrl}/` : '/'],
        []
    );

    const NOT_TO_SHOW_PAGES = !displayForAllPages && (!includePages?.length || !includePages.includes(asPath));
    const canRender = !(NOT_TO_SHOW_PAGES || excludePages.includes(asPath));

    const shouldShowOneTime = (): boolean => {
        return isBrowser ? !localStorage.getItem(`${id}_promoModal`) : false;
    };

    const handleCloseShowOneTime = () => {
        localStorage.setItem(`${id}_promoModal`, 'true');
    };

    const shouldShowOncePerDay = (): boolean => {
        if (!displayOn.includes(daysMap[day])) return false;

        if (isBrowser) {
            const savedDay = localStorage.getItem(`${id}_promoModal`);
            return !(savedDay === `${day}`);
        }

        return false;
    };

    const handleCloseOncePerDay = () => {
        localStorage.setItem(`${id}_promoModal`, `${day}`);
    };

    const shouldShowEveryTime = (): boolean => true;

    const handleCloseEveryTime = () => {
        setShowModal(false);
    };

    const shouldShowOncePerSession = (): boolean => {
        return isBrowser ? !sessionStorage.getItem(`${id}_promoModal`) : false;
    };

    const handleCloseOncePerSession = () => {
        sessionStorage.setItem(`${id}_promoModal`, 'true');
    };

    const displayTypeMap = {
        [DisplayTypes.ONE_TIME]: shouldShowOneTime,
        [DisplayTypes.ONCE_PER_DAY]: shouldShowOncePerDay,
        [DisplayTypes.EVERY_TIME]: shouldShowEveryTime,
        [DisplayTypes.ONCE_PER_SESSION]: shouldShowOncePerSession,
    };

    const closeTypeMap = {
        [DisplayTypes.ONE_TIME]: handleCloseShowOneTime,
        [DisplayTypes.ONCE_PER_DAY]: handleCloseOncePerDay,
        [DisplayTypes.EVERY_TIME]: handleCloseEveryTime,
        [DisplayTypes.ONCE_PER_SESSION]: handleCloseOncePerSession,
    };

    const handleClose = (linkText: string) => {
        closeTypeMap[displayType[0]]();
        setShowModal(false);
        dispatch({
            type: GTM_PROMOTIONAL_BANNER,
            payload: {
                modalName: header,
                linkText,
            },
        });
    };

    const handleDisclaimerTogglerClick = (linkText: string) => {
        setShowDisclaimer(!showDisclaimer);
        dispatch({
            type: GTM_PROMOTIONAL_BANNER,
            payload: {
                modalName: header,
                linkText,
            },
        });
    };

    useEffect(() => {
        if (
            canRender &&
            showModal &&
            displayType?.length > 0 &&
            displayTypeMap[displayType[0]] &&
            displayTypeMap[displayType[0]]()
        ) {
            dispatch({
                type: GTM_PROMOTIONAL_IMPRESSION_BANNER,
                payload: header,
            });
        }
    }, [dispatch, showModal, displayType[0], canRender]);

    if (!canRender || !displayTypeMap[displayType[0]]()) return null;

    return (
        <Modal open={showModal} onClose={() => handleClose('close')}>
            <div className={classNames(styles.modalContainer, 'modalContainer')}>
                <ContentfulBackgroundImageContainer asset={backgroundImage} altText={'Promotion banner background'}>
                    <div className={styles.content}>
                        <button
                            aria-label="Close Icon"
                            className={styles.closeButton}
                            onClick={() => handleClose('close')}
                        >
                            <BrandIcon icon="action-close" size={isDesktop ? 'm' : 's'} className={styles.closeIcon} />
                        </button>
                        <div className={styles.contentSection}>
                            <ContentfulImage asset={topIcon} maxWidth={70} />
                            <div className="t-subheader-hero">{subheader}</div>
                            <div className="t-header-hero">{header}</div>
                            <div className={classNames('t-paragraph', styles.description)}>{description}</div>
                            <ContentfulImage asset={image} maxWidth={isDesktop ? 454 : 260} />
                            {cta1Text && cta1Link && (
                                <div className={styles.ctaSection} onClick={() => handleClose(cta1Text)}>
                                    <InspireButton
                                        link={cta1Link}
                                        text={cta1Text}
                                        type="primary"
                                        className={classNames(styles.cta, 'truncate')}
                                        linkClassName="truncate"
                                    />
                                </div>
                            )}
                            {cta2Text && cta2Link && (
                                <div className={styles.ctaSection} onClick={() => handleClose(cta2Text)}>
                                    <InspireButton
                                        link={cta2Link}
                                        text={cta2Text}
                                        type="primary"
                                        className={classNames(styles.cta, 'truncate')}
                                        linkClassName="truncate"
                                    />
                                </div>
                            )}

                            {hideDisclaimer && (
                                <div
                                    onClick={() => handleDisclaimerTogglerClick(disclaimerTogglerText)}
                                    className={classNames('t-paragraph-hint-strong', styles.disclaimerTogglerText)}
                                >
                                    {disclaimerTogglerText}
                                </div>
                            )}
                            {showDisclaimer && (
                                <div className={classNames('t-paragraph-hint', styles.disclaimer)}>{disclaimer}</div>
                            )}
                        </div>
                    </div>
                </ContentfulBackgroundImageContainer>
                <style jsx>{`
                    .modalContainer {
                        background-color: ${backgroundColor?.fields?.hexColor
                            ? `#${backgroundColor?.fields?.hexColor}`
                            : 'var(--col--light);'};
                    }
                `}</style>
            </div>
        </Modal>
    );
}
