import { ConfigurationValues } from '../../../redux/configuration';
import { GlobalConfigurationRequest, IConfigurationItemModel, WebExperienceApi } from '../../../@generated/webExpApi';
import { getLocale } from '../../helpers/locale';
import createErrorWrapper from '../createErrorWrapper';

const expApi = new WebExperienceApi();
const getConfigurationApi = createErrorWrapper<IConfigurationItemModel[], GlobalConfigurationRequest>(
    'getConfiguration',
    expApi.globalConfiguration.bind(expApi)
);
const getConfiguration = (): Promise<IConfigurationItemModel[]> => getConfigurationApi({ locale: getLocale() });

export const getContentfulConfigurationExpApi = async () => {
    const response = await getConfiguration();
    const configurationResponse: ConfigurationValues = response.reduce(
        (configuration, entry) => ({ ...configuration, [entry.name]: entry.value }),
        <ConfigurationValues>{}
    );

    return configurationResponse;
};
