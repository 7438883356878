import { THourDayOfWeekModel } from '../../@generated/webExpApi/models';

export const weekDayIndexMap = {
    [THourDayOfWeekModel.Sunday]: 0,
    [THourDayOfWeekModel.Monday]: 1,
    [THourDayOfWeekModel.Tuesday]: 2,
    [THourDayOfWeekModel.Wednesday]: 3,
    [THourDayOfWeekModel.Thursday]: 4,
    [THourDayOfWeekModel.Friday]: 5,
    [THourDayOfWeekModel.Saturday]: 6,
};
