import {
    GmtCheckoutViewData,
    GtmActionFields,
    GtmCheckoutPaymentEvent,
    GtmComboSelectionEventData,
    GtmEventNames,
    GtmImpressionViewData,
    GtmMakeMyStoreEventData,
    GtmMapListLocationClickData,
    GtmMapLocationClickData,
    GtmModifierCustomizationEventData,
    GtmProductViewData,
    GtmRemoveFromCartData,
    GtmRestoreToCartData,
    GtmTransactionCompleteData,
    GtmTransactionInfoEventData,
    GtmUserIdData,
    IGtmBaseEventData,
    IGtmPickUpTimeData,
    IGtmSignInData,
    ModifierSelectionData,
    GtmErrorEvent,
    LocationOrderEvent,
    LocationChange,
    AccountDeletedSubmittedData,
    AccountDeletedFeedbackData,
    GtmWalletData,
    GtmCategory,
    PickupOrDeLiveryChangeEventData,
    GtmSearchLocationData,
    GtmBannerSignUpData,
    GtmIngredientSelection,
    GtmClaimReceiptRedeemData,
    GtmSelectLocation,
    GtmViewDeal,
    GtmRedeemDeal,
    GtmProductViewDeal,
    GtmAddToBagDeal,
    GtmCompleteDealPurchase,
    GtmAutoSuggestApplyDeal,
    GtmRecentOrdersMainPageModal,
    GtmDeviceFields,
    GtmOrderHistoryStartOrder,
    GtmOrderHistoryReorder,
    GtmSignIn,
    GtmCreateAccount,
    GtmOrderHistoryItemCtaClick,
    TippingChoice,
    GtmCheckoutProductQuantityChangeData,
    GtmCondimentOptionSelect,
    GtmBackToTop,
    GtmCheckoutInitialPaymentMethodInfo,
    GtmPaymentMethodInfoEvent,
    GtmComboModify,
    GtmCompoReplace,
    CrossSellEngagementTypes,
    GtmMakeItACombo,
    GtmCheckInWrongLocation,
    GtmJoinSonicRewards,
    GTMWalletCardData,
    GtmViewItemData,
    GtmAddItemData,
    GtmRemoveItemData,
    GtmBeginCheckoutData,
    GTMPickUpTimeAfterOrderSubmit,
    GtmPromotionalBannerType,
    GTMOrderFromOrderHistory,
} from './types';
import { AddToBagPayload, PutToBagPayload, ToggleIsOpenPayload } from '../../../redux/bag';
import { format, utcToZonedTime } from '../../../common/helpers/dateTime';
import { PDPTallyItem } from '../../../redux/pdp';
import { RootState } from '../../../redux/store';
import {
    selectProductSize,
    selectProductGroups,
    selectSelectedModifiers,
    selectCategories,
    selectProductById,
} from '../../../redux/selectors/domainMenu';
import { gtmServicePush } from '../../../lib/gtmServicePush';

import { categoryNameModify } from '../../helpers/categoryNameModify';
import { getPaymentMethod, getPaymentType } from '../../helpers/submitOrderHelper';
import { PaymentTypeModel } from '../../../@generated/webExpApi';
import { getTimezone } from '../../helpers/getTimezone';
import getGtmTransactionCompleteData from '../../helpers/getGtmTransactionCompleteData';
import { getGtmItemData } from '../../helpers/getGtmItemData';

export const getPrice = (childItems: PDPTallyItem[], price: number) => {
    const childItemsTotalPrice = childItems && childItems.reduce((acc, item) => acc + item.price, 0);

    return price || childItemsTotalPrice;
};

export const mapperTipByTipAmount = (tipAmount: number, percentage: number | null): TippingChoice => {
    if (percentage === null) {
        return TippingChoice.custom;
    }

    if (tipAmount < 1) {
        return TippingChoice.RoundUp;
    }

    if (tipAmount === 1) {
        return TippingChoice.oneDollar;
    }

    return TippingChoice.twoDollar;
};

const gtmService = {
    pushSignInSuccessEvent: (data: IGtmSignInData): void => {
        gtmServicePush(GtmEventNames.SignInSuccess, data);
    },

    pushSignInFailureEvent: (data: IGtmSignInData): void => {
        gtmServicePush(GtmEventNames.SignInFailure, data);
    },
    pushPageEvent: (data: IGtmBaseEventData): void => {
        gtmServicePush(GtmEventNames.PageEvent, data);
    },

    pushApiEvent: (data: IGtmBaseEventData): void => {
        gtmServicePush(GtmEventNames.ApiEvent, data);
    },

    pushImpressionViewEvent: (data: GtmImpressionViewData): void => {
        const products = data.products || [];

        gtmServicePush(GtmEventNames.ImpressionView, {
            ecommerce: {
                [GtmActionFields.Impressions]: products.map(({ productId, name, price }, index) => {
                    return {
                        id: productId,
                        name,
                        category: data?.category,
                        position: index + 1,
                        price: typeof price === 'string' ? price.replace(/\$/g, '') : price,
                    };
                }),
            },
        });
    },

    pushProductViewEvent: (data: GtmProductViewData): void => {
        const { product, price, category } = data;

        gtmServicePush(GtmEventNames.ProductView, {
            ecommerce: {
                [GtmActionFields.Detail]: {
                    actionField: {
                        list: 'Product View',
                    },
                    products: [
                        {
                            id: product?.fields?.productId,
                            name: product?.fields?.name,
                            quantity: 1,
                            price, // TODO: should check all price loading scripts
                            category,
                        },
                    ],
                },
            },
        });
    },

    pushRestoreItemToCardEvent: (data: GtmRestoreToCartData, state: RootState): void => {
        const { category } = data;

        gtmService.pushAddToCardEvent(
            {
                bagEntry: data,
                category: categoryNameModify(category),
                name: data.name,
            },
            state
        );
    },

    /**
     * Gathers and transforms menu item/ combo item data into the format required by 'add_to_cart' event when restoring the item to the bag.
     * Pushes event and data to window.dataLayer for Google Tag Manager
     * @function pushReAddItemToCardEvent
     * @param {GtmRestoreToCartData} props - name, category and sauces(bww only) extends TallyProductModel
     * @param {RootState} state
     * @property {string} category - the item's category
     * @property {string} name - the item's name
     * @property {string} productId - the item's productId
     * @property {number} quantity - quantity of item in bag
     * @property {number} price - the price of the item or combo
     * @property {array} childItems - combo items if bag entry is a combo
     * @property {Array<TallyModifierGroupModel>} modifierGroups - from the item or from the first combo item
     * @property {"[key: productId]: IProductGroupModel"} productGroups - all productGroups with names included
     * @property {Array<ISelectedSubModifier>} selectedModifiers - selectedModifiers with names included
     * @property {TallyModifierGroupModel} sauceModifierGroup - the modifier group with a name that includes 'sauce'
     * @property {Array<string>} sauces - names of sauces applied to the item
     * @property {TallyModifierGroupModel} toppingModifierGroup - the modifier group with a name that includes 'topping'
     * @property {Array<string>} toppings - names of additional toppings applied to the item
     * @returns undefined
     * @added 2023-01-26
     * @version 1.0
     * @author J.R. Misitano <jmisitano@inspirebrands.com>
     * @example <caption> Push Re-Add Item To Cart Event </caption>
     * pushReAddItemToCardEvent({
     *     name: 'Hamburger',
     *     category: 'Burger',
     *     productId:'idp-sdi-itm-12345-123',
     *     quantity: 1,
     *     price: 1.99,
     *     childItems: []},
     *     state)
     * if a single item, at a minimum, it can push to GTM
     * 'add_to_cart', {
            user_behaviour: 'readdition',
            ecommerce: {
                items: [
                    {
                        item_id: 'idp-sdi-itm-12345-123',
                        item_name: 'Hamburger',
                        item_category: 'Burger',
                        quantity: 1,
                        price: '1.99',
                        currency: 'USD',
                        comboSize: 'none',
                        side_food: 'none',
                        side_drink: 'none',
                        toppings: [],
                        sauce: [],
                    },
                ],
            },
        });
     * if a combo, fullyloaded, it can push to GTM
     * pushReAddItemToCardEvent({
           name: 'Hamburger Combo',
           category: 'Combo',
           productId:'idp-sdi-itm-12345-123',
           quantity: 1,
           price: '1.1',
           childItems: [ {
            "name": "Hamburger",
            "productId": "idp-sdi-itm-11111-111",
            "price": 0,
            "quantity": 1,
            "modifierGroups": [
                {
                    "productId": "idp-sdi-mdg-000-000",
                    "modifiers": [
                        {
                            "productId": "idp-sdi-mod-000-000",
                            "price": .23,
                            "quantity": 1
                        },
                    ]
                },
                {
                    "productId": "idp-sdi-mdg-000-001",
                    "modifiers": [
                        {
                            "productId": "idp-sdi-mod-0000-001",
                            "quantity": 1,
                            "price": 0,
                            "itemGroupId": "idp-sdi-itg-000-001"
                        },
                        {
                            "productId": "idp-sdi-mod-0000-002",
                            "quantity": 1,
                            "price": 0,
                            "itemGroupId": "idp-sdi-itg-000-002"
                        }
                    ]
                }
            ],
        },
        {   "name": "Fries"
            "productId": "idp-sdi-itm-22222-222",
            "price": 2.22,
            "quantity": 1,
            "modifierGroups": []
        },
        {
            "name": "Shake",
            "productId": "idp-sdi-itm-000-003",
            "price": 3.33,
            "quantity": 1,
            "modifierGroups": [],
        },
           state)

            'add_to_cart', {
            user_behaviour: 'readdition',
            ecommerce: {
                items: [
                    {
                        item_id: 'idp-sdi-itm-12345-123',
                        item_name: 'Hamburger Combo',
                        item_category: 'Combo',
                        quantity: 1,
                        price: 6.78,
                        currency: 'USD',
                        comboSize: 'none',
                        side_food: 'Fries',
                        side_drink: 'Shake',
                        toppings: ['Chili','Cheese']
                        sauce: ['Mustard']
                    },
                ],
            },
        });
     */
    pushReAddItemToCardEvent: (data: GtmRestoreToCartData, state: RootState): void => {
        const { category, name, productId, quantity, price, childItems } = data;
        const modifierGroups = childItems?.length ? childItems[0].modifierGroups : data.modifierGroups;
        const productGroups = selectProductGroups(state);
        const selectedModifiers = selectSelectedModifiers()(state, childItems?.length ? childItems[0] : data);

        const sauceModifierGroup = modifierGroups?.find((modifierGroup) =>
            productGroups[modifierGroup.productId]?.name?.toLowerCase().includes('sauce')
        );
        const sauces = selectedModifiers
            .filter((selectedModifier) => {
                return sauceModifierGroup?.modifiers.find((modifier) => {
                    return selectedModifier.productId === modifier.productId;
                });
            })
            .map((selectedModifier) => selectedModifier.name);

        const toppingModifierGroup = modifierGroups?.find((modifierGroup) =>
            productGroups[modifierGroup.productId]?.name?.toLowerCase().includes('topping')
        );

        const toppings = selectedModifiers
            .filter((selectedModifier) => {
                return toppingModifierGroup?.modifiers.find((modifier) => {
                    return selectedModifier.productId === modifier.productId;
                });
            })
            .map((selectedModifier) => selectedModifier.name);

        gtmServicePush(GtmEventNames.ReAddToCart, {
            user_behaviour: 'readdition',
            ecommerce: {
                items: [
                    {
                        item_id: productId,
                        item_name: name,
                        item_category: categoryNameModify(category),
                        quantity: quantity,
                        price: getPrice(childItems, price),
                        currency: 'USD',
                        comboSize: childItems?.length ? selectProductSize(state, productId) : 'none',
                        side_food: childItems?.length >= 2 ? childItems[1].name : 'none',
                        side_drink: childItems?.length >= 3 ? childItems[2].name : 'none',
                        toppings: toppings,
                        sauce: sauces,
                    },
                ],
            },
        });
    },

    pushAddToCardEvent: (data: AddToBagPayload | PutToBagPayload, state: RootState): void => {
        const { category, name, sauce } = data;
        const cartItem: PDPTallyItem = 'bagEntry' in data ? data.bagEntry : data.pdpTallyItem;
        const { productId, quantity, price, childItems, reAddCart } = cartItem;
        if (childItems) {
            const sideFood: string = childItems.length >= 2 ? childItems[1].name : '';
            const sideDrink: string = childItems.length >= 3 ? childItems[2].name : '';
            gtmServicePush(GtmEventNames.AddToCart, {
                ecommerce: {
                    currencyCode: 'USD',
                    [GtmActionFields.Add]: {
                        products: [
                            {
                                id: productId,
                                quantity: quantity,
                                onceRemoved: !!reAddCart,
                                price: getPrice(childItems, price),
                                name,
                                category: categoryNameModify(category),
                                sideFood,
                                sideDrink,
                                comboSize: selectProductSize(state, productId),
                                ...sauce,
                            },
                        ],
                    },
                },
            });
        } else {
            gtmServicePush(GtmEventNames.AddToCart, {
                ecommerce: {
                    currencyCode: 'USD',
                    [GtmActionFields.Add]: {
                        products: [
                            {
                                id: productId,
                                quantity: quantity,
                                onceRemoved: !!reAddCart,
                                price: getPrice(childItems, price),
                                name,
                                category: categoryNameModify(category),
                                sideFood: 'none',
                                sideDrink: 'none',
                                ...sauce,
                            },
                        ],
                    },
                },
            });
        }
    },

    pushRemoveFromCartEvent: (data: GtmRemoveFromCartData): void => {
        const { price, quantity, productId, name, category, childItems, sauce } = data;

        gtmServicePush(GtmEventNames.RemoveFromCart, {
            ecommerce: {
                currencyCode: 'USD',
                [GtmActionFields.Remove]: {
                    products: [
                        {
                            id: productId,
                            quantity: quantity,
                            onceRemoved: false,
                            price: getPrice(childItems, price),
                            name,
                            category: categoryNameModify(category),
                            ...sauce,
                        },
                    ],
                },
            },
        });
    },

    pushCheckoutViewEvent: (data: GmtCheckoutViewData[], state: RootState): void => {
        const products = data || [];
        gtmServicePush(GtmEventNames.Checkout, {
            ecommerce: {
                [GtmActionFields.Checkout]: {
                    actionField: {
                        step: 1,
                        action: 'Checkout',
                    },
                    products: products.map(({ quantity, price, productId, name, category, childItems }) => {
                        if (childItems) {
                            const sideFood: string = childItems.length >= 2 ? childItems[1].name : '';
                            const sideDrink: string = childItems.length >= 3 ? childItems[2].name : '';
                            return {
                                id: productId,
                                price: getPrice(childItems, price),
                                quantity,
                                category: categoryNameModify(category),
                                name,
                                sideFood,
                                sideDrink,
                                comboSize: selectProductSize(state, productId),
                            };
                        } else {
                            return {
                                id: productId,
                                price: getPrice(childItems, price),
                                quantity,
                                category: categoryNameModify(category),
                                name,
                                sideFood: 'none',
                                sideDrink: 'none',
                            };
                        }
                    }),
                },
            },
        });
    },

    pushBeginCheckoutViewEvent: (data: GtmBeginCheckoutData, state: RootState): void => {
        const { checkoutEntries, domainProducts } = data;
        gtmServicePush(GtmEventNames.BeginCheckout, {
            currency: 'USD',
            value: checkoutEntries.reduce((acc, curr) => acc + curr.price, 0),
            ecommerce: {
                items: checkoutEntries.map((entry) => {
                    const { productId, price, quantity, discounts, category } = entry;
                    const { sauces, toppings, condiments, comboSize, sideFood, sideDrink } = getGtmItemData(
                        entry,
                        state
                    );
                    const product = domainProducts[entry.productId];
                    const { name: itemCategory, parentCategoryId } = selectCategories(state)?.[product.categoryIds[0]];
                    const listName = selectCategories(state)?.[parentCategoryId]?.name;

                    return {
                        item_id: productId,
                        item_name: product.name,
                        item_category: itemCategory,
                        item_list_name: category || listName,
                        price,
                        discount: discounts?.reduce((acc, curr) => acc + curr.amount, 0) || 0,
                        quantity,
                        sauce: sauces,
                        toppings,
                        condiments,
                        combo_size: comboSize,
                        side_drink: sideDrink,
                        side_food: sideFood,
                    };
                }),
            },
        });
    },

    pushCheckoutPaymentEvent: (data: GtmCheckoutPaymentEvent): void => {
        const {
            tallyOrder: { products = [] },
            bagEntries,
            step,
            action,
        } = data;

        const entries = bagEntries || [];

        gtmServicePush(GtmEventNames.Checkout, {
            ecommerce: {
                [GtmActionFields.Checkout]: {
                    actionField: {
                        step,
                        action,
                    },
                    products: products.map(({ quantity, price, productId, childItems }) => {
                        const entry = entries.reduce((acc, curr) => {
                            if (curr?.productId === productId) {
                                return {
                                    category: curr && categoryNameModify(curr.category),
                                    name: curr?.name,
                                };
                            }
                            return acc;
                        }, {});
                        return {
                            id: productId,
                            price: getPrice(childItems, price),
                            quantity,
                            ...entry,
                        };
                    }),
                },
            },
        });
    },

    pushTransactionCompleteEvent: (data: GtmTransactionCompleteData, state: RootState): void => {
        const { event, gtmData } = getGtmTransactionCompleteData(data, state);

        gtmServicePush(event, { ecommerce: gtmData });
    },

    pushCartOpenEvent: (data: ToggleIsOpenPayload): void => {
        const { isOpen } = data;

        isOpen && gtmServicePush(GtmEventNames.CartOpen);
    },

    pushModifyItemEvent: (): void => {
        gtmServicePush(GtmEventNames.ModifyItem);
    },

    pushModifyInCartEvent: (): void => {
        gtmServicePush(GtmEventNames.ModifyInCart, { label: 'modify_item' });
    },

    pushAddMoreItemsEvent: (): void => {
        gtmServicePush(GtmEventNames.AddMoreItems);
    },

    pushSearchNewLocationEvent: (): void => {
        gtmServicePush(GtmEventNames.SearchNewLocation);
    },

    pushLocationShareEvent: (): void => {
        gtmServicePush(GtmEventNames.LocationShare, { label: 'Attempt' });
    },

    pushStartPickupOrderEvent: (): void => {
        gtmServicePush(GtmEventNames.StartPickupOrder);
    },

    pushMakeMyStoreEvent: (data: GtmMakeMyStoreEventData): void => {
        gtmServicePush(GtmEventNames.MakeMyStore, {
            event_category: GtmCategory.LOCATION,
            event_action: GtmActionFields.Click,
            storeNumber: data.id,
            storeName: data.name,
        });
    },

    pushMakeOnlineOrderingComingSoonEvent: (data: GtmMakeMyStoreEventData): void => {
        gtmServicePush(GtmEventNames.OnlineOrderingComingSoon, {
            event_category: GtmCategory.LOCATION,
            event_action: GtmActionFields.Click,
            storeNumber: data.id,
            storeName: data.name,
        });
    },
    pushEmailSignUp: (): void => {
        gtmServicePush(GtmEventNames.EmailSignUp);
    },

    pushGiftCardBalanceFormSubmitSuccess: (): void => {
        gtmServicePush(GtmEventNames.GiftCardBalanceFormSubmit);
    },

    pushUserId: (data: GtmUserIdData): void => {
        gtmServicePush(GtmEventNames.UserId, data);
    },

    pushTransactionInfoEvent: (data: GtmTransactionInfoEventData): void => {
        const locationTimezone = getTimezone(data.location?.timezone, 'pushTransactionInfoEvent');
        const locationLocalTime = utcToZonedTime(
            new Date(data.order?.fulfillment?.time || data.tallyOrder.fulfillment.time),
            locationTimezone
        );

        gtmServicePush(GtmEventNames.TransactionInfo, {
            fulfillmentDate: format(locationLocalTime, 'MM/dd/yyyy'),
            fulfillmentTime: format(locationLocalTime, 'HH:mm'),
            fulfillmentType: data.tallyOrder.fulfillment.type,
            isAsap: data.request.orderData.isAsap,
            storeNumber: data.request.orderData.locationId,
            paymentMethod: getPaymentMethod(
                data.request.payments[0].type,
                data.request.payments[0].details?.cardIssuer
            ),
            paymentType: getPaymentType(data.request.payments[0].type),
            loginType: data.loginType,
        });
    },

    pushModifierCustomizationEvent: (data: GtmModifierCustomizationEventData): void => {
        gtmServicePush(GtmEventNames.ModifierCustomization, {
            category: data.category,
            label: data.productName,
            action: data.modifierName,
        });
    },

    pushComboSelectionEvent: (data: GtmComboSelectionEventData): void => {
        gtmServicePush(GtmEventNames.ComboSelection, data);
    },

    pushMapClickEvent: (): void => {
        gtmServicePush(GtmEventNames.MapClick);
    },

    pushMapDoubleClickEvent: (): void => {
        gtmServicePush(GtmEventNames.MapDoubleClick);
    },

    pushMapDragStartEvent: (): void => {
        gtmServicePush(GtmEventNames.MapDragStart);
    },

    pushMapDragEvent: (): void => {
        gtmServicePush(GtmEventNames.MapDrag);
    },

    pushMapDragEndEvent: (): void => {
        gtmServicePush(GtmEventNames.MapDragEnd);
    },

    pushMapLocationClickEvent: (data: GtmMapLocationClickData): void => {
        gtmServicePush(GtmEventNames.MapLocationClick, { storeID: data.location.id });
    },

    pushMapListLocationClickEvent: (data: GtmMapListLocationClickData): void => {
        gtmServicePush(GtmEventNames.MapListLocationClick, { storeID: data.location.id });
    },

    pushPickupTimeSelection: (data: IGtmPickUpTimeData): void => {
        gtmServicePush(GtmEventNames.PickupTimeSelection, data);
    },

    pushModifierSelection: (data: ModifierSelectionData): void => {
        gtmServicePush(GtmEventNames.ModifierSelection, data);
    },

    pushError: (error: GtmErrorEvent): void => {
        gtmServicePush(GtmEventNames.Error, {
            error,
        });
    },

    pushTipSelection: (data: { tipPercentage: string; tipAmount: number }): void => {
        gtmServicePush(GtmEventNames.TipSelection, {
            category: GtmCategory.CHECKOUT,
            action: GtmActionFields.TipSelection,
            label: data.tipPercentage,
            tipAmount: data.tipAmount,
            device: GtmDeviceFields.DESKTOP,
        });
    },

    pushTipSelectionWithTippingChoice: (data: { tipAmount: number; percentage: number | null }): void => {
        const { tipAmount, percentage } = data;
        gtmServicePush(GtmEventNames.CheckoutTipping, {
            tipping_amount: tipAmount,
            tipping_choice: mapperTipByTipAmount(tipAmount, percentage),
        });
    },

    pushLocationOrder: (data: LocationOrderEvent): void => {
        gtmServicePush(GtmEventNames.LocationOrderCTA, {
            data,
        });
    },

    pushLocationChange: (data: LocationChange): void => {
        gtmServicePush(GtmEventNames.LocationChange, {
            data,
        });
    },

    pushCheckInEvent: (storeId: number): void => {
        gtmServicePush(GtmEventNames.CheckIn, {
            action: GtmActionFields.Click,
            label: storeId,
        });
    },

    pushAccountDeletedFeedback: (data: AccountDeletedFeedbackData): void => {
        gtmServicePush(GtmEventNames.AccountDeletedFeedback, data);
    },

    pushAccountDeletedSubmitted: (data: AccountDeletedSubmittedData): void => {
        gtmServicePush(GtmEventNames.AccountDeletedSubmitted, data);
    },

    pushSendAMessage: (): void => {
        gtmServicePush(GtmEventNames.SendAMessage);
    },

    pushWalletEvent: (data: GtmWalletData): void => {
        gtmServicePush(GtmEventNames.WalletEvent, {
            ...data,
            category: GtmCategory.ACCOUNT,
        });
    },

    /**
     * Google Tag Manager Service For Wallet Card Event
     * @function pushWalletCardEvent
     * @param {GTMWalletCardData} data - GTM Wallet Data
     * @property {gtmServicePush} - Formats data to send to window.dataLayer
     * @returns void - no return value
     * @added 2023-02-27
     * @author Benjamin Hawker <benjamin.hawker@inspirebrands.com>
     * @version 1.0
     */
    pushWalletCardEvent: (data: GTMWalletCardData): void => {
        gtmServicePush(GtmEventNames.WalletCardEvent, {
            ...data,
        });
    },

    pushPickupOrDeliveryAddressChange: (data: PickupOrDeLiveryChangeEventData): void => {
        gtmServicePush(GtmEventNames.Location, data);
    },

    pushSearchNavigationLocation: (data: GtmSearchLocationData): void => {
        gtmServicePush(GtmEventNames.LocationEvent, {
            ...data,
            category: GtmCategory.LOCATION,
        });
    },

    pushBannerSignUp: (data: GtmBannerSignUpData): void => {
        gtmServicePush(GtmEventNames.BannerEvent, data);
    },

    pushIngredientSelection: (data: GtmIngredientSelection): void => {
        gtmServicePush(GtmEventNames.IngredientSelection, data);
    },

    pushClaimReceiptRedeem: (data: GtmClaimReceiptRedeemData): void => {
        if (data.claimNumber) {
            gtmServicePush(GtmEventNames.RewardsClaimEvent, {
                reward_claim_number: data.claimNumber,
            });
        } else {
            gtmServicePush(GtmEventNames.RewardsReceiptEvent, {
                reward_receipt_store_number: data.locationId,
                reward_receipt_date: data.date,
                reward_receipt_check: data.checkNumber,
                reward_receipt_total: data.subTotal,
            });
        }
    },

    pushASelectLocation: (data: GtmSelectLocation): void => {
        gtmServicePush(GtmEventNames.SelectLocation, {
            event_category: GtmCategory.LOCATION,
            event_action: GtmActionFields.SelectALocation,
            event_label: data.label,
            device_type: data.device,
        });
    },

    pushAViewDeals: (): void => {
        gtmServicePush(GtmEventNames.RewardsAndDeals, {
            event_category: GtmCategory.LOCATION,
            event_action: GtmActionFields.ViewDeals,
        });
    },

    pushAViewDeal: (data: GtmViewDeal): void => {
        gtmServicePush(GtmEventNames.ViewDeal, {
            event_category: GtmCategory.REWARDS_AND_DEALS,
            event_action: GtmActionFields.Click,
            event_label: data.label,
            view_deal: data.offerName,
        });
    },

    pushRedeemOnline: (data: GtmRedeemDeal): void => {
        gtmServicePush(GtmEventNames.RedeemDealOnline, {
            event_category: GtmCategory.REWARDS_AND_DEALS,
            event_action: GtmActionFields.RedeemOnline,
            event_label: data.offerName,
            deal_redeemed: data.offerName,
            device: data.device,
        });
    },

    pushRedeemInStore: (data: GtmRedeemDeal): void => {
        gtmServicePush(GtmEventNames.RedeemDealInStore, {
            event_category: GtmCategory.REWARDS_AND_DEALS,
            event_action: GtmActionFields.RedeemInStore,
            event_label: data.offerName,
            deal_redeemed: data.offerName,
            device: data.device,
        });
    },

    pushRedeemFromBag: (data: GtmRedeemDeal): void => {
        gtmServicePush(GtmEventNames.RedeemDealOnline, {
            event_category: GtmCategory.REWARDS_AND_DEALS,
            event_action: GtmActionFields.RedeemOnline,
            event_label: data.offerName,
            deal_redeemed: data.offerName,
            device: data.device,
        });
    },

    pushSwapToThisDeal: (data: GtmRedeemDeal): void => {
        gtmServicePush(GtmEventNames.SwapToThisDeal, {
            event_category: GtmCategory.REWARDS_AND_DEALS,
            event_action: GtmActionFields.SwapDeal,
            event_label: data.offerName,
            deal_swapped: data.offerName,
            device: data.device,
        });
    },

    pushProductViewDeal: (data: GtmProductViewDeal): void => {
        gtmServicePush(GtmEventNames.ProductViewDeal, {
            event_category: GtmCategory.ECOMMERCE,
            event_action: GtmActionFields.ProductView,
            event_label: data.productName,
            product_viewed: data.productName,
        });
    },

    pushAddToBagDeal: (data: GtmAddToBagDeal): void => {
        gtmServicePush(GtmEventNames.AddToCartDeal, {
            event_category: GtmCategory.ORDER,
            event_action: GtmActionFields.AddToCart,
            event_label: data.productNames,
            item_added_to_cart: data.productNames,
        });
    },

    pushCompleteDealPurchase: (data: GtmCompleteDealPurchase): void => {
        const { tallyOrder, orderId, productsById } = data || {};
        const { tax, total, discounts, products } = tallyOrder || {};

        const discountDetails = discounts?.discountDetails[0];
        const appliedDiscountId = discountDetails?.code;
        const appliedDiscountName = discountDetails?.name;

        gtmServicePush(GtmEventNames.Purchase, {
            transaction_id: orderId,
            value: total,
            tax: tax,
            currency: 'USD',
            ecommerce: {
                items: products.map((item) => ({
                    item_id: item.productId,
                    item_name: productsById[item.productId]?.fields?.name,
                    price: getPrice(item.childItems, item.price),
                    promotion_id: item.discounts?.length > 0 ? appliedDiscountId : null,
                    promotion_name: item.discounts?.length > 0 ? appliedDiscountName : null,
                })),
            },
        });
    },

    pushOrderHistoryReorder: (data: GtmOrderHistoryReorder): void => {
        gtmServicePush(GtmEventNames.Reorder, {
            event_category: GtmCategory.ORDER_HISTORY,
            event_action: GtmActionFields.Reorder,
            label: data.orderId,
            order_id: data.orderId,
            page_title: data.pageTitle,
        });
    },

    pushAvatarImageUpload: (): void => {
        gtmServicePush(GtmEventNames.ChangeAvatar, {
            event_category: GtmCategory.ACCOUNT,
            event_action: GtmActionFields.Click,
            avatarUpdate: 'Upload from Photo Library',
        });
    },

    pushAutoSuggestApplyDeal: (data: GtmAutoSuggestApplyDeal): void => {
        gtmServicePush(GtmEventNames.ApplyDeal, {
            event_category: GtmCategory.REWARDS_AND_DEALS,
            event_action: GtmActionFields.Apply,
            event_label: data.offerName,
            deal_applied: data.offerName,
        });
    },

    pushRecentOrdersHaveQuestion: (data: GtmRecentOrdersMainPageModal): void => {
        gtmServicePush(GtmEventNames.OrderHistory, {
            event_category: GtmCategory.ORDER_HISTORY,
            cta_clicked: data.ctaName,
            store_id: data.storeId,
        });
    },

    pushRecentOrdersCallStore: (data: GtmRecentOrdersMainPageModal): void => {
        gtmServicePush(GtmEventNames.OrderHistory, {
            event_category: GtmCategory.ORDER_HISTORY,
            cta_clicked: data.ctaName,
            store_id: data.storeId,
        });
    },

    pushOrderHistoryStartOrder: (data: GtmOrderHistoryStartOrder): void => {
        gtmServicePush(GtmEventNames.OrderClick, {
            event_category: GtmCategory.ORDER,
            event_action: GtmActionFields.Click,
            event_label: data.buttonText,
            page_title: data.pageTitle,
        });
    },

    pushOrderHistoryItemCtaClick: (data: GtmOrderHistoryItemCtaClick): void => {
        gtmServicePush(GtmEventNames.CtaClick, {
            event_label: data.label,
            page_title: data.pageTitle,
        });
    },

    pushSignInCreateAccount: ({ event, ...options }: GtmSignIn | GtmCreateAccount): void => {
        gtmServicePush(event, options);
    },

    pushChangeLocationCheckout: (): void => {
        gtmServicePush(GtmEventNames.ChangeLocationCheckout);
    },

    pushHamburgerMenuOptionSelect: (option: string): void => {
        gtmServicePush(GtmEventNames.HamburgerMenuOptionSelect, {
            menuOption: option,
        });
    },

    pushCheckoutProductQuantityChangeEvent: (data: GtmCheckoutProductQuantityChangeData) => {
        const { eventType, productId, productName, quantity } = data;

        gtmServicePush(eventType, {
            id: productId,
            name: productName,
            quantity: quantity,
        });
    },

    pushCondimentOptionSelect: (data: GtmCondimentOptionSelect): void => {
        gtmServicePush(GtmEventNames.CondimentModification, {
            condiment_type: data.condimentType,
            condiment_modificaton: data.condimentModificaton,
        });
    },

    pushBackToTop: (data: GtmBackToTop): void => {
        gtmServicePush(GtmEventNames.BackToTop, {
            event_category: data.category,
            event_action: data.action,
            event_label: data.label,
        });
    },

    pushCheckoutPaymentMethodInfoEvent: (data: GtmCheckoutInitialPaymentMethodInfo): void => {
        const { cardIssuer, fulfillmentType, storeNumber, paymentMethod, appliedGiftCards } = data;
        const additionalPaymentMethod =
            paymentMethod !== PaymentTypeModel.GiftCard && appliedGiftCards.length ? 'GiftCard' : undefined;

        const gtmData: GtmPaymentMethodInfoEvent = {
            storeNumber,
            paymentMethod: getPaymentMethod(paymentMethod, cardIssuer),
            fulfillmentType,
            paymentType: getPaymentType(paymentMethod),
        };

        if (additionalPaymentMethod) {
            gtmData.additionalPaymentMethod = additionalPaymentMethod;
            gtmData.split = 'yes';
        }

        gtmServicePush(GtmEventNames.PaymentMethod, gtmData);
    },

    pushComboModify: (data: GtmComboModify): void => {
        gtmServicePush(GtmEventNames.ComboModify, data);
    },

    pushCompoReplace: (data: GtmCompoReplace): void => {
        gtmServicePush(GtmEventNames.ComboReplace, data);
    },

    pusCrossSellEvent: (type: CrossSellEngagementTypes): void => {
        gtmServicePush(GtmEventNames.CrossSell, {
            cross_sell_engagement: type,
        });
    },

    pushMakeItACombo: (data: GtmMakeItACombo): void => {
        gtmServicePush(GtmEventNames.MakeItACombo, {
            items: [
                {
                    item_id: data.productId,
                    item_name: data.name,
                    currency: data.currency,
                    price: data.price,
                    quantity: data.quantity,
                },
            ],
        });
    },

    pusCheckInClick: (link: string): void => {
        gtmServicePush(GtmEventNames.CheckInClick, {
            event_category: 'Orders',
            checkIn: 'true',
            event_action: link,
        });
    },

    pusCheckInWrongLocation: (data: GtmCheckInWrongLocation): void => {
        gtmServicePush(GtmEventNames.CheckInWrongLocation, {
            event_category: 'Orders',
            ...data,
        });
    },

    pushJoinSonicRewards: (data: GtmJoinSonicRewards): void => {
        GtmCategory.LOCATION,
            gtmServicePush(GtmEventNames.JoinSonicRewards, {
                event_category: GtmCategory.SIGN_UP,
                event_action: GtmActionFields.SonicRewards,
                ...data,
            });
    },

    pushExploreMoreEvent: (linkText: string): void => {
        gtmServicePush(GtmEventNames.ExploreMore, {
            link_text: linkText,
        });
    },

    pushViewItemEvent: (data: GtmViewItemData, state: RootState): void => {
        const { productId, name, category, price, quantity, discount, promotionId, promotionName } = data;
        const product = selectProductById(state, productId);
        const itemCategory = selectCategories(state)?.[product.categoryIds[0]]?.name;

        gtmServicePush(GtmEventNames.ViewItem, {
            currency: 'USD',
            value: price,
            ecommerce: {
                items: [
                    {
                        item_id: productId,
                        item_name: name,
                        item_category: itemCategory,
                        item_list_name: category,
                        price,
                        discount,
                        quantity,
                        promotion_id: promotionId,
                        promotion_name: promotionName,
                    },
                ],
            },
        });
    },

    pushAddToCartEvent: (data: GtmAddItemData, state: RootState): void => {
        const { productId, category, quantity, price, discounts } = data;
        const { sauces, toppings, condiments, comboSize, sideFood, sideDrink } = getGtmItemData(data, state);
        const product = selectProductById(state, productId);
        const { name: itemCategory, parentCategoryId } = selectCategories(state)?.[product.categoryIds[0]];
        const listName = selectCategories(state)?.[parentCategoryId]?.name;

        gtmServicePush(GtmEventNames.AddToCartEvent, {
            currency: 'USD',
            value: price,
            ecommerce: {
                items: [
                    {
                        item_id: productId,
                        item_name: product.name,
                        item_category: itemCategory,
                        item_list_name: category || listName,
                        price,
                        discount: discounts?.reduce((acc, curr) => acc + curr.amount, 0) || 0,
                        quantity,
                        sauce: sauces,
                        toppings,
                        condiments,
                        combo_size: comboSize,
                        side_drink: sideDrink,
                        side_food: sideFood,
                    },
                ],
            },
        });
    },

    pushRemoveFromCart: (data: GtmRemoveItemData, state: RootState): void => {
        const { productId, category, quantity, price, discounts } = data;
        const { sauces, toppings, condiments, comboSize, sideFood, sideDrink } = getGtmItemData(data, state);
        const product = selectProductById(state, productId);
        const { name: itemCategory, parentCategoryId } = selectCategories(state)?.[product.categoryIds[0]];
        const listName = selectCategories(state)?.[parentCategoryId]?.name;

        gtmServicePush(GtmEventNames.RemoveFromCartEvent, {
            currency: 'USD',
            value: price,
            ecommerce: {
                items: [
                    {
                        item_id: productId,
                        item_name: product.name,
                        item_category: itemCategory,
                        item_list_name: category || listName,
                        price,
                        discount: discounts?.reduce((acc, curr) => acc + curr.amount, 0) || 0,
                        quantity,
                        sauce: sauces,
                        toppings,
                        condiments,
                        combo_size: comboSize,
                        side_drink: sideDrink,
                        side_food: sideFood,
                    },
                ],
            },
        });
    },

    pushPickUpTimeAfterOrderSubmit: (data: GTMPickUpTimeAfterOrderSubmit): void => {
        gtmServicePush(GtmEventNames.PickupTime, {
            ...data,
            handoff_type: 'Pickup',
        });
    },

    pushPromotionalBanner: ({ linkText, modalName }: { linkText: string; modalName: string }): void => {
        gtmServicePush(GtmEventNames.ModalEvent, {
            modal_interaction: GtmActionFields.CTAClick,
            modal_name: modalName,
            link_text: linkText,
        });
    },

    pushPromotionalImpressionBanner: (modal_name: string): void => {
        gtmServicePush(GtmEventNames.ModalEvent, {
            modal_interaction: GtmActionFields.Impression,
            modal_type: GtmPromotionalBannerType.PROMOTION,
            modal_name,
        });
    },

    pushOrderFromOrderHistory: (data: GTMOrderFromOrderHistory): void => {
        gtmServicePush(GtmEventNames.Order, data);
    },
};

export default gtmService;
