import React, { FC, useEffect, useRef, useState } from 'react';
import { ContentCards, Card } from '@braze/web-sdk';
import brazeContext, { IBrazeContext } from '../common/contexts/brazeContext';
import { BrazeEventKey, BrazePurchaseEvent } from '../common/hooks/braze/types';
import { useExternalBrazeId } from '../common/hooks/braze/useExternalBrazeId';
import { isBrazeEnabled } from '../lib/getFeatureFlags';
import { useAccount } from '../redux/hooks';

const { Provider } = brazeContext;

const BrazeProvider: FC = ({ children }) => {
    const brazeRef = useRef(null);
    const { account } = useAccount();
    const [cards, setCards] = useState<IBrazeContext['cards']>([]);
    const [isBrazeReady, setIsBrazeReady] = useState<boolean>(false);

    const Braze = brazeRef.current;

    const { externalBrazeId } = useExternalBrazeId();

    const isBrazeAvailable = isBrazeEnabled() && isBrazeReady && !!externalBrazeId && !!account;

    const initializeBraze = (braze) => {
        const isReady = braze.initialize(process.env.NEXT_PUBLIC_BRAZE_API_KEY, {
            baseUrl: process.env.NEXT_PUBLIC_BRAZE_SDK_ENDPOINT,
        });

        if (isReady) {
            brazeRef.current = braze;
        }
        setIsBrazeReady(isReady);
    };

    const changeUser = () => {
        Braze.changeUser(externalBrazeId);
    };

    const setCustomEvent = (eventName: BrazeEventKey, eventProperties?: object | undefined) => {
        if (isBrazeAvailable) {
            Braze.logCustomEvent(eventName, eventProperties);
        }
    };

    const contentCardClick = (card: Card) => {
        if (isBrazeAvailable) {
            Braze.logContentCardClick(card);
        }
    };

    const contentCardImpressions = (contentCards: Card[]) => {
        if (isBrazeAvailable) {
            Braze.logContentCardImpressions(contentCards);
        }
    };

    const setPurchaseEvent = (event: BrazePurchaseEvent) => {
        if (isBrazeAvailable) {
            Braze.logPurchase(event.productId, event.price, event.currencyCode, event.quantity, event.properties);
        }
    };

    const updateContentCard = (contentCards: ContentCards) => {
        let cards = [];

        const user = Braze.getUser();
        user?.setEmail(account?.email);

        cards = contentCards.cards;

        setCards(cards);
    };

    const getContentCards = () => {
        Braze.requestContentCardsRefresh();
        Braze.subscribeToContentCardsUpdates((cards: ContentCards) => updateContentCard(cards));

        return () => {
            Braze.removeAllSubscriptions();
        };
    };

    useEffect(() => {
        if (isBrazeEnabled() && typeof window !== 'undefined') {
            if (!isBrazeReady) {
                import('@braze/web-sdk').then(initializeBraze);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBrazeReady]);

    useEffect(() => {
        if (isBrazeAvailable) {
            Braze.wipeData();
            changeUser();
            getContentCards();
            Braze.openSession();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBrazeAvailable]);

    return (
        <Provider
            value={{
                cards,
                isBrazeAvailable,
                setPurchaseEvent,
                setCustomEvent,
                contentCardClick,
                contentCardImpressions,
            }}
        >
            {children}
        </Provider>
    );
};

export default BrazeProvider;
