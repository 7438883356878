import throttle from 'lodash/throttle';
import { Unsubscribe } from 'redux';

import { AppStore } from './store';
import { saveState } from './localStorage';

const PERSIST_DELAY = 1000; // ms

const getState = (store: AppStore, keys: string[]) => {
    const result = {};
    const state = store.getState();

    keys.forEach((key) => (result[key] = state[key]));

    return result;
};

const createPersistSubscription = (store: AppStore, keys: string[]): Unsubscribe => {
    return store.subscribe(
        throttle(() => {
            // IMPORTANT: If we only want to persist parts of state in the future follow this pattern:
            // saveState({
            //     todos: store.getState().todo
            // })

            // We could also be clever about persisting over time
            // Something like the following pseudocode:
            // const persistedState = {}
            // const cart = store.getState().cart
            // if (cart && cart.lastUpdated < 24_hours_ago) {
            //     persistedState.cart = cart
            // }
            // saveState(persistedState)

            if (process.browser) {
                saveState(getState(store, keys));
            }
        }, PERSIST_DELAY)
    );
};

export default createPersistSubscription;
