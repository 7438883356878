import { useDispatch } from 'react-redux';
import { useAppSelector } from '../store';
import * as ConfigurationStore from '../configuration';
import { selectConfiguration, selectLastSyncedAt } from '../selectors/configuration';
import { useCallback } from 'react';

export interface UseConfigurationHook {
    configuration: ConfigurationStore.ConfigurationState['configuration'];
    lastSyncedAt: ConfigurationStore.ConfigurationState['lastSyncedAt'];
    actions: {
        setConfiguration: (configuration: ConfigurationStore.ConfigurationValues) => void;
    };
}

export default function useConfiguration(): UseConfigurationHook {
    const dispatch = useDispatch();
    const configuration = useAppSelector(selectConfiguration);
    const lastSyncedAt = useAppSelector(selectLastSyncedAt);

    const setConfiguration = useCallback(
        (configuration: ConfigurationStore.ConfigurationValues) => {
            dispatch(ConfigurationStore.actions.setConfiguration(configuration));
        },
        [dispatch]
    );

    return {
        configuration,
        lastSyncedAt,
        actions: {
            setConfiguration,
        },
    };
}
