import { DiscountRequestModel, DiscountDetailsTypeModel, TallyDiscountTypeModel } from '../../../@generated/webExpApi';
import { IBagLineItem } from '../../../redux/bag';

export const createTallyDiscountsRequest = ({
    dealId,
}: {
    dealId?: string;
    lineItems?: IBagLineItem[];
}): DiscountRequestModel | null => {
    if (dealId) {
        return {
            discountType: TallyDiscountTypeModel.Provided,
            discountDetails: [
                {
                    code: dealId,
                    type: DiscountDetailsTypeModel.OfferCode,
                },
            ],
        };
    }

    return null;
};
