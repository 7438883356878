import { shallowEqual } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from '../store';
import { actions as submitActions } from '../submitOrder';
import { actions as tallyActions } from '../tallyOrder';

import { tallyService } from '../../common/services/orderService';
import { createTallyRequest, setTallyProductWithModifiers } from '../../common/helpers/tallyHelper';
import useSelectedSell from './useSelectedSell';
import {
    SellingChannelNamesModel,
    TallyFulfillmentTypeModel,
    TallyProductModel,
    TallyResponseModel,
    TTallyErrorCodeModel,
    ITallyError500ExternalResponseModel,
    TOfferTypeModel,
    IRewardApplicabilityModel,
} from '../../@generated/webExpApi';
import useOrderLocation from './useOrderLocation';
import { mergeTallyWithBag } from '../../common/helpers/mergeTallyWithBag';
import { RequestError } from '../../common/services/createErrorWrapper';
import { getTallyError } from '../../common/helpers/getTallyError';
import { useTallyErrorNotification } from '../../common/hooks/useTallyErrorNotification';
import { useProducts } from './domainMenu';
import { useBag, useRewards } from '.';
import { createTallyDiscountsRequest } from '../../common/helpers/tallyHelper/createTallyDiscountsRequest';

export interface ITallyOrderResponse extends TallyResponseModel {
    appliedDiscount?: { offerType?: TOfferTypeModel; applicability?: IRewardApplicabilityModel };
}

interface ITallyOrderHook {
    error: RequestError | ITallyError500ExternalResponseModel;
    isLoading: boolean;
    tallyOrder: ITallyOrderResponse;
    unavailableItems: string[];
    resetTallyOrder: () => void;
    submitTallyOrder: (
        bagEntries: TallyProductModel[],
        orderTime?: Date,
        dealId?: string,
        customerId?: string
    ) => Promise<PayloadAction<TallyResponseModel & ITallyError500ExternalResponseModel>>;
    setUnavailableTallyItems: (payload: string[]) => void;
}

export default function useTallyOrder(): ITallyOrderHook {
    const dispatch = useAppDispatch();

    const { deliveryAddress, isPickUp, pickupAddress, currentLocation } = useOrderLocation();

    const locationId = isPickUp ? pickupAddress.id : deliveryAddress?.pickUpLocation?.id;

    const fulfillmentType = isPickUp ? TallyFulfillmentTypeModel.PickUp : TallyFulfillmentTypeModel.Delivery;

    const deliveryLocation = !isPickUp && deliveryAddress?.deliveryLocation;

    const { error, isLoading, tallyOrder, unavailableItems } = useAppSelector((state) => state.tally, shallowEqual);
    const { correlationId: ssCorrelationId } = useSelectedSell();

    const showTallyErrorNotification = useTallyErrorNotification();

    const isSuccessPayload = (
        response: TallyResponseModel | ITallyError500ExternalResponseModel
    ): response is TallyResponseModel => {
        return 'products' in response;
    };

    const domainProducts = useProducts();
    const { getOfferById } = useRewards();

    const { bagEntries } = useBag();

    const submitTallyOrder = (
        products: TallyProductModel[],
        orderTime?: Date,
        dealId?: string,
        customerId?: string
    ): Promise<PayloadAction<ITallyOrderResponse & ITallyError500ExternalResponseModel>> => {
        dispatch(tallyActions.pending(null));

        return tallyService
            .validate({
                tallyRequestModel: createTallyRequest({
                    products: setTallyProductWithModifiers(products, domainProducts),
                    locationId: String(locationId),
                    fulfillmentType,
                    orderTime,
                    discounts: createTallyDiscountsRequest({ dealId, lineItems: bagEntries }),
                    customerId,
                    ssCorrelationId,
                    deliveryLocation,
                }),
                sellingChannel: SellingChannelNamesModel.Weboa,
                fulfillmentType,
            })
            .then((response) => {
                if (isSuccessPayload(response)) {
                    /*
                     * -- DS Team requirement --
                     * Tally call is expecting the modifier price to be the same as menu in request.
                     * Tally response for an “included free” modifier will be $0,
                     * but when you send that in the tally request, it will fail validation because the menu price is actually not $0.
                     * Need to use initial products data.
                     *
                     */
                    const decoratedResponse = mergeTallyWithBag(response, products);
                    const offerId = decoratedResponse.discounts?.discountDetails[0]?.code;
                    const offer = getOfferById(String(offerId));

                    const action = dispatch(
                        tallyActions.fulfilled({
                            ...decoratedResponse,
                            appliedDiscount: {
                                offerType: offer?.type,
                                applicability: offer?.applicability,
                            },
                        })
                    );

                    dispatch(submitActions.reset(null));

                    return action;
                }

                if (response.code === TTallyErrorCodeModel.ProductsNotAvailable) {
                    return dispatch(tallyActions.setUnavailableItems(response.data.productIds));
                }

                const tallyError = getTallyError(response, currentLocation.contactDetails.phone);
                showTallyErrorNotification(tallyError);
                return dispatch(tallyActions.invalid(tallyError));
            })
            .catch((e) => {
                showTallyErrorNotification(e);
                return dispatch(tallyActions.invalid(e));
            });
    };

    const resetTallyOrder = (): void => {
        dispatch(tallyActions.reset(null));
    };

    const setUnavailableTallyItems = (payload: string[]): void => {
        dispatch(tallyActions.setUnavailableItems(payload));
    };

    return {
        error,
        isLoading,
        tallyOrder,
        unavailableItems,
        submitTallyOrder,
        resetTallyOrder,
        setUnavailableTallyItems,
    };
}
