import { Banner, CaptionedImage, ClassicCard } from '@braze/web-sdk';
import { createContext } from 'react';
import { noop } from '../helpers/noop';
import { BrazeEventKey, BrazePurchaseEvent } from '../hooks/braze/types';

export type IContentCard = CaptionedImage | ClassicCard | Banner;

export interface IBrazeContext {
    cards: IContentCard[];
    isBrazeAvailable: boolean;
    setCustomEvent: (eventName: BrazeEventKey, eventProperties?: object | undefined) => void;
    setPurchaseEvent: (event: BrazePurchaseEvent) => void;
    contentCardClick: (card: IContentCard) => void;
    contentCardImpressions: (contentCards: IContentCard[]) => void;
}

const brazeContext = createContext({
    cards: [],
    isBrazeAvailable: false,
    setCustomEvent: noop,
    setPurchaseEvent: noop,
    contentCardClick: noop,
    contentCardImpressions: noop,
} as IBrazeContext);

export default brazeContext;
