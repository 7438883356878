import RouterInstance, { Router } from 'next/router';

import { requestNavIntercept } from '../../redux/navIntercept';
import { AppDispatch } from '../../redux/store';

const restoreUrl = (url, as) => {
    window.history.pushState({ ...window.history.state, url, as }, document.title, as);

    const title = document.title;
    document.title = ''; // force title refresh, without this title doesn't change
    document.title = title;
};

export const untrack = (): void => {
    // eslint-disable-next-line no-prototype-builtins
    const isTracking = RouterInstance.router?.hasOwnProperty('change');

    if (isTracking) {
        // @ts-ignore
        delete RouterInstance.router.change;
    }
};

export const track = (dispatch: AppDispatch): void => {
    // eslint-disable-next-line no-prototype-builtins
    const isTracking = RouterInstance.router?.hasOwnProperty('change');

    if (!isTracking && RouterInstance.router) {
        // @ts-ignore
        RouterInstance.router.change = (...args) => {
            if (window.location.pathname !== RouterInstance.router.asPath) {
                restoreUrl(RouterInstance.router.route, RouterInstance.router.asPath);
            }
            return dispatch(requestNavIntercept()).then(({ payload: result }) => {
                if (result) {
                    // @ts-ignore
                    return Router.prototype.change.apply(RouterInstance.router, args);
                }
                return Promise.resolve(result);
            });
        };
    }
};
