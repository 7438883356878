import { createSelector, Dictionary } from '@reduxjs/toolkit';

import {
    ItemModel,
    ItemModifierModel,
    ItemGroupModel,
    ItemModifierGroupModel,
    SizeGroupModel,
    CategoryModel,
    IProductGroupModel,
    IOrderProductModel,
} from '../../@generated/webExpApi';

import {
    getCaloriesFromProduct,
    getCheapestPriceFromProduct,
    getDefaultNestedModifier,
    getModifierPrice,
    getProductItemsFromMenu,
    getProductItemsWithRootIdFromMenu,
    INTERNAL_REGULAR_MODIFIER_ID,
    isModifierItemGroup,
    isModifierVisible,
} from '../../lib/domainProduct';
import {
    ISelectedModifier,
    IDefaultModifier,
    ISizeSelection,
    IDomainProductItem,
    IDisplayProduct,
    ISelectedSubModifier,
    ActionCodesEnum,
    PDPTallyItemModifierGroup,
    PDPTallyItemModifier,
    IProducts,
} from '../types';

import { PDPTallyItem } from '../pdp';
import { RootState } from '../store';
import { getEmptyTallyItem } from '../../lib/tallyItem';
import { selectCurrenOrderLocation } from './orderLocation';
import getBrandInfo from '../../lib/brandInfo';
import { NO_SAUCE_CATEGORY_ID, ON_SIDE_SIZE_GROUP_ID, RECOMMENDED_CATEGORY_ID } from '../../common/constants/product';
import { getUnavailableDomainMenuCategories } from '../../common/helpers/getUnavailableDomainMenuCategories';
import { isWhatsOnItModifierGroup } from '../../common/helpers/isWhatsOnItModifierGroup';
import { getDefaultChild } from './domainMenu/getDefaultChild';
import { isCombo } from './domainMenu/isCombo';
import { checkProductIsAvailable } from '../../common/helpers/checkProductIsAvailable';

// all item groups that are grouped NOT by size
export const otherItemGroupingNames = ['Protein', 'Flavor'];

const selectDomainMenu = (state: RootState) => state.domainMenu;

export const selectUnavailableCategories = (state: RootState, orderTime: string, timeZone: string): string[] => {
    const domainMenu = selectDomainMenu(state);
    if (!domainMenu.payload) {
        return [];
    }
    return getUnavailableDomainMenuCategories(domainMenu.payload.categories, orderTime, timeZone);
};

export const selectLoading = createSelector(selectDomainMenu, (state) => state.loading);

export const selectError = createSelector(selectDomainMenu, (state) => state.error);
export const selectIsLocationMenuError = createSelector(selectDomainMenu, (state) => state.isLocationMenuError);

export const selectRootProducts = createSelector(selectDomainMenu, (state) => state?.payload?.products);

export type IProductsWithRootProductId = { [key: string]: ItemModelRootProductId };

export interface ItemModelRootProductId extends ItemModel {
    rootProductId: string;
    menuItemName: string;
    isSaleable: boolean;
}

export const selectProducts = createSelector(
    selectDomainMenu,
    (state): IProducts => {
        if (!state.payload) {
            return {};
        }

        return getProductItemsFromMenu(state.payload);
    }
);

// TODO consider moving to web-exp-api
export const selectProductsWithRootProductId = createSelector(
    selectDomainMenu,
    (state): IProductsWithRootProductId => {
        if (!state.payload) {
            return {};
        }

        return getProductItemsWithRootIdFromMenu(state.payload);
    }
);

export const selectProductById = (state: RootState, id: string | null): IDomainProductItem | null => {
    if (!id) return null;
    const products = selectProducts(state);

    return products[id];
};

export const selectProductWithRootIdById = (state: RootState, id: string): ItemModelRootProductId => {
    const products = selectProductsWithRootProductId(state);

    return products[id];
};

export const selectProductsByIds = (state: RootState, ids: string[]): Dictionary<IDomainProductItem> => {
    const products = selectProducts(state);
    if (!products) {
        return {};
    }
    return ids.reduce((acc, item) => {
        return {
            ...acc,
            [item]: products[item],
        };
    }, {});
};

export const selectProductsByItemGroupId = (state: RootState, itemGroupId: string): IDomainProductItem[] => {
    const products = selectProducts(state);
    if (!products) {
        return [];
    }

    return Object.values(products).reduce<IDomainProductItem[]>((acc, item) => {
        if (item.itemGroupId === itemGroupId) {
            return [...acc, item];
        }
        return acc;
    }, []);
};

export const selectProductByIds = (state: RootState, ids: string[]): IDomainProductItem | null => {
    const products = selectProducts(state);

    for (const id of ids) {
        if (products[id]) {
            return products[id];
        }
    }

    return null;
};

export const selectProductByIdsWithRootId = (state: RootState, ids: string[]): ItemModelRootProductId | null => {
    const products = selectProductsWithRootProductId(state);

    for (const id of ids) {
        if (products[id]) {
            return products[id];
        }
    }
    return null;
};

export const selectNoSauceDomainProduct = (
    state: RootState,
    modifiers: IDisplayProduct[]
): IDomainProductItem | undefined => {
    const domainProducts = selectProductsByIds(
        state,
        modifiers.map((item) => item.displayProductDetails.productId)
    );

    return Object.values(domainProducts).find((item) =>
        item?.categoryIds?.some((categoryId) => categoryId === NO_SAUCE_CATEGORY_ID)
    );
};

export const selectNoItemProductIds = (state: RootState, modifiersIds: string[]): string[] => {
    const domainProducts = selectProductsByIds(state, modifiersIds);

    return Object.values(domainProducts)
        .filter((item) => item?.categoryIds?.some((categoryId) => categoryId === NO_SAUCE_CATEGORY_ID))
        .map((i) => i.id);
};

export const selectProductGroups = createSelector(selectDomainMenu, (menu) => menu.payload?.productGroups || {});

export const selectProductGroupById = (state: RootState, productGroupId: string): IProductGroupModel => {
    const productGroups = selectProductGroups(state);
    return productGroups[productGroupId];
};

export const selectProductGroupByName = (state: RootState, productGroupName: string): IProductGroupModel => {
    const productGroups = selectProductGroups(state);
    return Object.values(productGroups).find((productGroup) => productGroup.name === productGroupName);
};

export const selectProductGroup = createSelector(
    selectProductById,
    selectProductGroups,
    (product, productGroups): IProductGroupModel => {
        return productGroups[product?.productGroupId];
    }
);

export const selectProductGroupByProductId = createSelector(
    selectProductById,
    selectProductGroups,
    (product, productGroups) => {
        return productGroups[product?.productGroupId];
    }
);

export const selectModifierGroupsByProductId = createSelector(
    selectProductById,
    (product): ItemModifierGroupModel[] => {
        return product?.itemModifierGroups || [];
    }
);

export const selectProductDrinkIds = (state: RootState): string[] | [] => {
    const products = selectProducts(state);

    return Object.values(products)
        .filter((product) => product.tags?.['PRODUCT'] === 'DRINK')
        .map((item) => item.id);
};

export const selectItemGroups = createSelector(selectDomainMenu, (menu) => menu.payload?.itemGroups || {});

const selectSizeGroups = createSelector(selectDomainMenu, (menu) => menu.payload?.sizeGroups || {});

export const selectItemGroup = createSelector(
    selectProductById,
    selectItemGroups,
    (product, groups): ItemGroupModel => {
        return groups[product?.itemGroupId];
    }
);

export const selectItemGroupById = (state: RootState, id: string) => {
    return selectItemGroups(state)[id];
};

export const selectSizeGroup = createSelector(
    selectProductById,
    selectSizeGroups,
    (product, groups): SizeGroupModel => {
        return groups[product?.sizeGroupId];
    }
);

export const selectCategories = createSelector(selectDomainMenu, (menu) => menu.payload?.categories || {});

export const selectCategoriesList = createSelector(selectCategories, (categories): CategoryModel[] =>
    categories ? Object.values(categories) : []
);

export const selectCategoriesByProductId = createSelector(
    selectProductById,
    selectCategoriesList,
    (product, categories): CategoryModel[] => {
        const categoryArray = categories.filter((category) => product?.categoryIds?.includes(category.id));
        return categoryArray;
    }
);

export const selectTopCategory = createSelector(selectCategoriesByProductId, (categories) => {
    categories.sort((firstCategory, secondCategory) => firstCategory?.sequence - secondCategory?.sequence);
    return categories?.[0];
});

export const selectProductIsCombo = (state: RootState, productId: string) => {
    return isCombo(selectProductById(state, productId), selectProductGroupByProductId(state, productId));
};

export const selectProductIsPromo = createSelector(selectProductGroupByProductId, (productGroup) => {
    return productGroup?.isPromo;
});

export const selectProductList = createSelector(selectProducts, (products): ItemModel[] =>
    products ? Object.values(products) : []
);

const selectInitialIsSauceOnSide = (state: RootState, productId: string, modifierGroupId: string): boolean => {
    const group = selectItemModifierGroup(state, productId, modifierGroupId);

    const itemModifiers = Object.values(group?.itemModifiers || {});

    return itemModifiers.some((it) => {
        const isSauceOnSide = selectBwwIsModifierOnSide(state, it.itemId);
        if (!isSauceOnSide) {
            return false;
        }

        return it.defaultQuantity > 0;
    });
};

export const selectDefaultTallyModifierGroups = (state: RootState, productId: string) => {
    const products = selectProducts(state);
    const itemModifierGroups = products[productId]?.itemModifierGroups;

    const { brandId } = getBrandInfo();

    const hasDefaultQuantity = (item: ItemModifierModel) => {
        return item.defaultQuantity > 0;
    };

    // TODO add a way to toggle files other than components and have a two separate functions
    if (brandId === 'Bww') {
        return itemModifierGroups?.map((it) => {
            return {
                productId: it?.productGroupId,
                metadata: it?.metadata,
                isOnSideChecked: selectInitialIsSauceOnSide(state, productId, it.productGroupId),
                modifiers: Object.values(it.itemModifiers || [])
                    ?.filter(hasDefaultQuantity)
                    ?.map((modifier) => {
                        const productItemModifier = products[modifier?.itemId];
                        const price = getModifierPrice(modifier, productItemModifier);

                        return {
                            productId: modifier?.itemId,
                            price,
                            quantity: modifier?.defaultQuantity,
                            modifierGroups: selectDefaultTallyModifierGroups(state, modifier.itemId),
                        };
                    }),
            };
        });
    }

    // TODO: add a way to toggle files other than components and have a two separate functions
    if (brandId === 'Sdi') {
        const hasDefaultQuantity = (item: ItemModifierModel) => {
            if (!isModifierItemGroup(item)) {
                return item.defaultQuantity > 0;
            }

            return Object.values(item.itemModifiers).find((modifier) => modifier.defaultQuantity > 0);
        };

        return itemModifierGroups?.map((it) => {
            return {
                productId: it?.productGroupId, // modifier productId is productGroupId of modifiers (OMC name convension)
                metadata: it?.metadata,
                modifiers: Object.values(it.itemModifiers || [])
                    ?.filter(hasDefaultQuantity)
                    ?.map((modifier) => {
                        if (isModifierItemGroup(modifier)) {
                            const defaultNestedModifier = getDefaultNestedModifier(modifier.itemModifiers);

                            return {
                                itemGroupId: modifier.itemGroupId,
                                quantity: 1,
                                productId: defaultNestedModifier?.itemId,
                                price: getModifierPrice(defaultNestedModifier, products[defaultNestedModifier?.itemId]),
                            };
                        }

                        const productItemModifier = products[modifier.itemId];
                        const price = getModifierPrice(modifier, productItemModifier);

                        return {
                            productId: modifier.itemId,
                            price,
                            quantity: modifier.defaultQuantity,
                        };
                    }),
            };
        });
    }

    return itemModifierGroups?.map((it) => {
        return {
            productId: it?.productGroupId, // modifier productId is productGroupId of modifiers (OMC name convension)
            metadata: it?.metadata,
            modifiers: Object.values(it.itemModifiers || [])
                ?.filter(hasDefaultQuantity)
                ?.map((modifier) => {
                    const productItemModifier = products[modifier?.itemId];
                    const price = getModifierPrice(modifier, productItemModifier);

                    return {
                        productId: modifier?.itemId,
                        price,
                        quantity: modifier?.defaultQuantity,
                    };
                }),
        };
    });
};

export const selectHistoryTallyModifierGroups = (
    state: RootState,
    historyProducts: IOrderProductModel[]
): PDPTallyItemModifierGroup[][] => {
    const modifierGroups = historyProducts?.reduce((acc, histProduct) => {
        const filterModifiers = (item) => {
            return histProduct.modifiers?.find((id) => id.id === item.itemId);
        };
        const modifierGroupsFromProduct = histProduct.modifierGroups;
        const domainProduct = selectProductById(state, histProduct.productId);
        if (domainProduct?.itemModifierGroups) {
            const itemModifierGroups = domainProduct?.itemModifierGroups;
            const itemModifierGroup = itemModifierGroups?.map((it) => {
                const modifierGroupFromProduct = modifierGroupsFromProduct?.find(
                    (group) => group.productId === it.productGroupId
                );

                return {
                    productId: it.productGroupId,
                    metadata: it.metadata,
                    isOnSideChecked: selectInitialIsSauceOnSide(state, histProduct.productId, it.productGroupId),
                    modifiers: Object.values(it.itemModifiers || [])
                        ?.filter(filterModifiers)
                        ?.map((modifier) => {
                            const productItemModifier = domainProduct[modifier?.itemId];
                            const price = getModifierPrice(modifier, productItemModifier);
                            const subModifierGroups = modifierGroupFromProduct?.modifiers?.find(
                                (m) => m.productId === modifier.itemId
                            )?.modifierGroups;
                            return {
                                productId: modifier?.itemId,
                                price: price,
                                quantity: histProduct.modifiers.find((id) => id.id === modifier.itemId).quantity,
                                ...(subModifierGroups && {
                                    modifierGroups: subModifierGroups.map((group) => ({
                                        ...group,
                                        isOnSideChecked: selectInitialIsSauceOnSide(
                                            state,
                                            modifier.itemId,
                                            group.productId
                                        ),
                                        modifiers:
                                            group.modifiers?.map(({ productId, quantity, price }) => ({
                                                productId,
                                                quantity,
                                                price,
                                            })) || [],
                                    })),
                                }),
                            };
                        }),
                };
            });
            return [...acc, itemModifierGroup];
        }
        return acc;
    }, []);

    return modifierGroups;
};

export const selectDefaultTallyItemForChildItem = (
    state: RootState,
    comboId: string,
    childItemId: string
): PDPTallyItem => {
    const itemModifier = selectItemModifierFromProduct(state, comboId, childItemId);
    const childProduct = selectProductById(state, childItemId);

    return {
        productId: childProduct.id,
        price: getModifierPrice(itemModifier, childProduct),
        quantity: 1,
        modifierGroups: selectDefaultTallyModifierGroups(state, childProduct.id),
        name: childProduct.name,
    };
};

export const selectDefaultTallyItem = (state: RootState, productId: string): PDPTallyItem => {
    const product = selectProductById(state, productId);
    const location = selectCurrenOrderLocation(state);
    if (!product) {
        return getEmptyTallyItem();
    }
    const isMeal = selectProductIsCombo(state, productId);
    const isPromo = selectProductIsPromo(state, productId);

    if (isMeal || isPromo) {
        const childItems = product?.itemModifierGroups
            ?.map((itemModifierGroup) => {
                const defaultItemModifier = getDefaultChild(itemModifierGroup);

                if (!defaultItemModifier) {
                    return undefined;
                }

                return selectDefaultTallyItemForChildItem(state, product.id, defaultItemModifier.itemId);
            })
            .filter((child) => child);

        return {
            name: product.name,
            productId: product.id,
            price: getCheapestPriceFromProduct(product, location),
            quantity: 1,
            relatedItemGroups: selectDefaultTallyRelatedItemGroups(state, productId),
            childItems,
        };
    }

    return {
        name: product.name,
        quantity: 1,
        productId: product?.id,
        price: getCheapestPriceFromProduct(product, location),
        modifierGroups: selectDefaultTallyModifierGroups(state, productId),
        relatedItemGroups: selectDefaultTallyRelatedItemGroups(state, productId),
    };
};

export const selectProductDiscountPriceAndCalories = createSelector(
    selectCurrenOrderLocation,
    selectProductById,
    (location, product) => {
        const price = getCheapestPriceFromProduct(product, location);
        const calories = getCaloriesFromProduct(product);

        return {
            price,
            calories,
        };
    }
);

export const selectIsProductEditable = createSelector(selectProductById, (product): boolean => {
    const hasEditable = product?.itemModifierGroups?.some((group) => {
        return Object.values(group.itemModifiers || {}).find(isModifierVisible);
    });

    return hasEditable || false;
});

export const selectProductSize = createSelector(selectProductById, selectSizeGroups, (product, sizeGroups) => {
    return sizeGroups[product?.sizeGroupId]?.name || '';
});

export const selectRootProductByItemId = createSelector(
    selectProductWithRootIdById,
    selectRootProducts,
    (product, rootProducts) => {
        return rootProducts?.[product?.rootProductId];
    }
);

export const selectProductNutrition = createSelector(selectProductById, (product) => {
    return product?.nutrition;
});

export const selectComboMainProduct = (state: RootState, id: string): ItemModel => {
    const isCombo = selectProductIsCombo(state, id);
    const isPromo = selectProductIsPromo(state, id);
    if (!isCombo && !isPromo) {
        return null;
    }
    const product = selectProductById(state, id);

    const mainGroup = product.itemModifierGroups?.find((it) => it.sequence === 1);
    const defaultItem = getDefaultChild(mainGroup);

    return selectProductById(state, defaultItem.itemId);
};

const selectIsModifierQuantityBased = (state: RootState, productId: string) => {
    const product = selectProductById(state, productId);

    return product?.sizeGroupId === 'arb-sig-001-009';
};

export const selectModifierRelatedSelections = (
    state: RootState,
    productId: string,
    modifierId: string
): ItemModifierModel[] | null => {
    const modifierGroup = selectItemModifierGroupFromProduct(state, productId, modifierId);
    if (!modifierGroup) {
        return null;
    }

    const modifierItem = modifierGroup.itemModifiers[modifierId];
    const modifierItemProduct = selectProductWithRootIdById(state, modifierId);

    const selections = Object.values(modifierGroup.itemModifiers).filter((item) => {
        if (item.itemId === modifierItem.itemId) {
            return false;
        }
        const isModifierQuantityBased = selectIsModifierQuantityBased(state, item.itemId);
        if (isModifierQuantityBased) {
            return false;
        }

        const itemProduct = selectProductWithRootIdById(state, item.itemId);
        return itemProduct?.rootProductId === modifierItemProduct?.rootProductId;
    });

    if (!selections.length) {
        return null;
    }
    return selections;
};

export const selectDefaultModifiers = () => (state: RootState, productId: string): IDefaultModifier[] => {
    const product = selectProductById(state, productId);
    const isMeal = selectProductIsCombo(state, productId);
    const products = selectProducts(state);
    const comboMainDefaultProduct = selectComboMainProduct(state, productId);

    const getWhatsIncluded = (product: ItemModel, products: IProducts) => {
        const included: IDefaultModifier[] = [];

        product?.itemModifierGroups?.forEach((group) => {
            Object.values(group.itemModifiers || {}).forEach((modifier) => {
                if (isModifierItemGroup(modifier)) {
                    const itemGroup = selectItemGroupById(state, modifier.itemGroupId);
                    const defaultModifier = modifier.itemModifiers.find(({ defaultQuantity }) => defaultQuantity > 0);

                    if (defaultModifier) {
                        const modifierProduct = products[defaultModifier.itemId];

                        included.push({
                            name: itemGroup?.name,
                            defaultQuantity: defaultModifier.defaultQuantity,
                            minQuantity: modifier.min,
                            maxQuantity: modifier.max,
                            productId: defaultModifier.itemId,
                            calories: getCaloriesFromProduct(modifierProduct),
                            metadata: group.metadata,
                            itemGroupId: modifier.itemGroupId,
                        });
                    } else if (isWhatsOnItModifierGroup(group)) {
                        included.push({
                            name: itemGroup?.name,
                            defaultQuantity: 1,
                            minQuantity: modifier.min,
                            maxQuantity: modifier.max,
                            productId: INTERNAL_REGULAR_MODIFIER_ID,
                            calories: null,
                            metadata: group.metadata,
                            itemGroupId: modifier.itemGroupId,
                        });
                    }
                } else if (modifier.defaultQuantity) {
                    const selections = selectModifierRelatedSelections(state, product.id, modifier.itemId);
                    const selectionAttributes: Pick<IDefaultModifier, 'selection' | 'relatedSelections'> = {};

                    if (selections?.length) {
                        const sizeGroup = selectSizeGroup(state, modifier.itemId);

                        selectionAttributes.selection = sizeGroup?.name;
                        selectionAttributes.relatedSelections = selections.map((it) => it.itemId);
                    }
                    const modifierProduct = products[modifier.itemId];

                    included.push({
                        name: modifierProduct?.name,
                        defaultQuantity: modifier.defaultQuantity,
                        minQuantity: modifier.min,
                        maxQuantity: modifier.max,
                        productId: modifier.itemId,
                        calories: getCaloriesFromProduct(modifierProduct),
                        metadata: group.metadata,
                        ...selectionAttributes,
                    });
                }
            });
        });

        return included;
    };

    if (isMeal) {
        return getWhatsIncluded(comboMainDefaultProduct, products);
    }

    return getWhatsIncluded(product, products);
};

const selectSdiSelectedModifiers = (state: RootState, tallyItem: PDPTallyItem): ISelectedModifier[] => {
    const getSelectedModifiers = (item: PDPTallyItem) => {
        const itemModifierGroups = selectModifierGroupsByProductId(state, item.productId);

        const selectedModifiers: ISelectedModifier[] = [];
        itemModifierGroups.forEach((group) => {
            const tallyModifierGroup = item?.modifierGroups.find((it) => it.productId === group.productGroupId);
            if (!tallyModifierGroup) {
                return;
            }

            const isWhatsOnItGroup = isWhatsOnItModifierGroup(group);

            Object.values(group.itemModifiers || {}).forEach((itemModifier) => {
                const tallyModifier = tallyModifierGroup.modifiers?.find(
                    (modifier) => modifier.productId === itemModifier.itemId
                );
                const modifierProduct = selectProductById(state, itemModifier.itemId);

                if (isWhatsOnItGroup && isModifierItemGroup(itemModifier)) {
                    const itemGroupModifiersIds = itemModifier.itemModifiers.map(({ itemId }) => itemId);
                    const tallyIntensityModifier = tallyModifierGroup.modifiers?.find((modifier) =>
                        itemGroupModifiersIds.includes(modifier.productId)
                    );

                    const itemGroup = selectItemGroupById(state, itemModifier.itemGroupId);

                    if (tallyIntensityModifier) {
                        const intensityItemModifier = Object.values(itemModifier.itemModifiers).find(
                            (it) => it.itemId === tallyIntensityModifier.productId
                        );
                        const intensityProduct = selectProductById(state, intensityItemModifier.itemId);

                        selectedModifiers.push({
                            name: itemGroup?.name,
                            quantity: tallyIntensityModifier.quantity,
                            productId: tallyIntensityModifier.productId,
                            price: tallyIntensityModifier.price,
                            calories: getCaloriesFromProduct(intensityProduct),
                            metadata: tallyModifierGroup?.metadata,
                            actionCodes: intensityItemModifier.actionCodes,
                            itemGroupId: itemModifier.itemGroupId,
                            isWhatsOnItModifierGroup: true,
                        });
                    } else {
                        selectedModifiers.push({
                            name: itemGroup?.name,
                            quantity: 1,
                            productId: INTERNAL_REGULAR_MODIFIER_ID,
                            price: 0,
                            calories: null,
                            metadata: tallyModifierGroup?.metadata,
                            actionCodes: [ActionCodesEnum.REGULAR],
                            itemGroupId: itemModifier.itemGroupId,
                            isWhatsOnItModifierGroup: true,
                        });
                    }

                    return;
                } else if (isModifierItemGroup(itemModifier)) {
                    const itemGroupModifiersIds = itemModifier.itemModifiers.map(({ itemId }) => itemId);
                    const tallyIntensityModifier = tallyModifierGroup.modifiers?.find((modifier) =>
                        itemGroupModifiersIds.includes(modifier.productId)
                    );

                    if (tallyIntensityModifier) {
                        const intensityItemModifier = Object.values(itemModifier.itemModifiers).find(
                            (it) => it.itemId === tallyIntensityModifier.productId
                        );
                        const intensityProduct = selectProductById(state, intensityItemModifier.itemId);
                        const itemGroup = selectItemGroupById(state, itemModifier.itemGroupId);
                        const isModififerAdded =
                            getDefaultNestedModifier(itemModifier.itemModifiers)?.itemId ===
                            tallyIntensityModifier.productId;

                        selectedModifiers.push({
                            name: itemGroup?.name,
                            quantity: tallyIntensityModifier.quantity,
                            productId: tallyIntensityModifier.productId,
                            price: tallyIntensityModifier.price,
                            calories: getCaloriesFromProduct(intensityProduct),
                            metadata: tallyModifierGroup?.metadata,
                            actionCodes: isModififerAdded
                                ? [ActionCodesEnum.ADD, ...intensityItemModifier.actionCodes]
                                : intensityItemModifier.actionCodes,
                            itemGroupId: itemModifier.itemGroupId,
                        });
                    }

                    return;
                }

                if (tallyModifier?.quantity > 0) {
                    selectedModifiers.push({
                        name: modifierProduct?.name,
                        quantity: tallyModifier.quantity,
                        productId: tallyModifier.productId,
                        price: tallyModifier.price,
                        calories: getCaloriesFromProduct(modifierProduct),
                        metadata: tallyModifierGroup?.metadata,
                    });
                }
            });
        });

        return selectedModifiers;
    };

    if (tallyItem?.modifierGroups) {
        return getSelectedModifiers(tallyItem);
    }

    return [];
};

export const selectSelectedModifiers = () => (state: RootState, tallyItem: PDPTallyItem): ISelectedModifier[] => {
    const { brandId } = getBrandInfo();
    if (brandId === 'Sdi') {
        return selectSdiSelectedModifiers(state, tallyItem);
    }

    const getSelectedModifiers = (item: PDPTallyItem, products: IProducts) => {
        return (item?.modifierGroups || []).reduce<ISelectedModifier[]>((selected, tallyModifierGroup) => {
            const selectedModifiers = tallyModifierGroup?.modifiers?.filter((modifier) => modifier.quantity) || [];
            const isGroupWithFreeModifiers = isModifierGroupFree(
                state,
                tallyItem.productId,
                tallyModifierGroup.productId
            );

            const selectedModifierData = selectedModifiers.map((selectedModifier) => {
                const modifierProduct = products[selectedModifier.productId];
                const selections = selectModifierRelatedSelections(state, item.productId, selectedModifier.productId);

                const selectionAttributes: Pick<IDefaultModifier, 'selection' | 'relatedSelections'> = {};
                if (selections?.length) {
                    const sizeGroup = selectSizeGroup(state, selectedModifier.productId);
                    selectionAttributes.selection = sizeGroup?.name;
                    selectionAttributes.relatedSelections = selections.map((it) => it.itemId);
                }

                const selectedSubModifiers: ISelectedSubModifier[] = selectedModifier.modifierGroups?.flatMap(
                    (subModifierGroup) => {
                        return subModifierGroup.modifiers.map((subModifiersItem) => {
                            const subModifierProduct = products[subModifiersItem.productId];

                            const isSubGroupWithFreeModifiers = isModifierGroupFree(
                                state,
                                selectedModifier.productId,
                                subModifierGroup.productId
                            );

                            const subModifierPrice = isSubGroupWithFreeModifiers ? 0 : subModifiersItem?.price;

                            return {
                                name: subModifierProduct?.name,
                                quantity: subModifiersItem?.quantity,
                                productId: subModifiersItem?.productId,
                                price: subModifierPrice,
                                calories: getCaloriesFromProduct(subModifierProduct),
                            };
                        });
                    },
                    []
                );

                const price = isGroupWithFreeModifiers ? 0 : selectedModifier?.price;

                return {
                    name: modifierProduct?.name,
                    quantity: selectedModifier?.quantity,
                    productId: selectedModifier?.productId,
                    price,
                    calories: getCaloriesFromProduct(modifierProduct),
                    metadata: tallyModifierGroup?.metadata,
                    modifiers: selectedSubModifiers,
                    ...selectionAttributes,
                };
            });

            return [...selected, ...selectedModifierData];
        }, []);
    };

    const products = selectProducts(state);

    if (tallyItem?.childItems?.length > 0) {
        const mainProduct = selectComboMainProduct(state, tallyItem.productId);
        const tallyMain = tallyItem.childItems.find((it) => it.productId === mainProduct?.id);

        return getSelectedModifiers(tallyMain, products);
    }

    if (tallyItem?.modifierGroups) {
        return getSelectedModifiers(tallyItem, products);
    }

    return [];
};

export const selectSelectedSideAndDrinks = (state: RootState, tallyItem: PDPTallyItem): string[] => {
    const selectedSides = [];
    const mainProduct = selectComboMainProduct(state, tallyItem.productId);
    const isPromo = selectProductIsPromo(state, tallyItem.productId);

    if (!mainProduct) {
        return selectedSides;
    }

    if (tallyItem?.childItems) {
        tallyItem?.childItems.forEach((item) => {
            if (isPromo || item.productId !== mainProduct.id) {
                const product = selectProductById(state, item.productId);

                selectedSides.push(product.name);
            }
        });
    }

    return selectedSides;
};

export const selectProductSizes = (state: RootState, id: string): ISizeSelection[] | null => {
    const product = selectProductById(state, id);
    const rootProduct = selectRootProductByItemId(state, product?.id);

    if (!(product && rootProduct)) {
        return null;
    }

    const isGroupedBySize = isProductSizeGrouped(state, id);

    const byProductItemGroupId = (it: ItemModel) => it.itemGroupId === product.itemGroupId;
    const toSizeSelection = (product: ItemModel) => {
        const disabled = !checkProductIsAvailable(product);

        return { sizeGroup: selectSizeGroup(state, product.id), product, disabled, isGroupedBySize };
    };
    const bySequence = (a: ISizeSelection, b: ISizeSelection) => a?.sizeGroup?.sequence - b?.sizeGroup?.sequence;

    return Object.values(rootProduct.items).filter(byProductItemGroupId).map(toSizeSelection).sort(bySequence);
};

export const selectProductSizeFromChildItemGroup = (
    state: RootState,
    id: string,
    rootProductId: string,
    productGroupId: string
): ISizeSelection[] | null => {
    const rootProduct = selectProductById(state, rootProductId);
    const isEntriesProduct = rootProduct.itemModifierGroups?.[0]?.productGroupId === productGroupId;
    const productId = isEntriesProduct ? rootProductId : id;

    const sizes = selectProductSizes(state, productId);

    const actualItemModifiers =
        Object.values(
            rootProduct.itemModifierGroups.find((item) => item.productGroupId === productGroupId)?.itemModifiers
        ).find((item) => item.itemModifiers?.some((modifier) => modifier.itemId === id))?.itemModifiers || [];
    return isEntriesProduct
        ? sizes
        : sizes?.filter((item) => actualItemModifiers.some((modifier) => modifier.itemId === item.product.id));
};

export const isProductSizeGrouped = (state: RootState, id: string): boolean => {
    const productGroup = selectItemGroup(state, id);

    return !otherItemGroupingNames.some((i) => i === productGroup?.name);
};

export const selectRelatedComboByMainProductIdAndSizeId = (
    state: RootState,
    id: string,
    comboSizeId: string
): ItemModel => {
    const rootProduct = selectRootProductByItemId(state, id);

    const relatedCombo = Object.values(rootProduct?.items || []).filter(
        (item) => !!Object.values(item?.itemModifierGroups[0]?.itemModifiers || []).find((item) => item.itemId === id)
    );

    return relatedCombo.find((combo) => combo.sizeGroupId === comboSizeId);
};

export const selectChildItemSizes = (state: RootState, childId: string, comboId: string): ISizeSelection[] | null => {
    const sizes = selectProductSizes(state, comboId);
    if (!sizes) {
        return null;
    }

    return sizes.map(({ sizeGroup, product, isGroupedBySize }) => {
        const productModifier = product.itemModifierGroups
            .flatMap((it) => Object.values(it.itemModifiers))
            .find((modifier) => {
                if (childId === modifier.itemId) {
                    return true;
                }

                const rootProduct = selectRootProductByItemId(state, modifier.itemId);

                return !!rootProduct?.items[childId];
            });

        const productModifierItem = productModifier ? selectProductById(state, productModifier.itemId) : null;

        return {
            sizeGroup: sizeGroup,
            product: productModifierItem,
            disabled: !checkProductIsAvailable(productModifierItem),
            isGroupedBySize,
        };
    });
};

export const selectItemModifierGroup = (
    state: RootState,
    productId: string,
    productGroupId: string
): ItemModifierGroupModel => {
    const product = selectProductById(state, productId);

    return product.itemModifierGroups?.find((it) => it.productGroupId === productGroupId);
};

const selectItemModifierGroupFromProduct = (
    state: RootState,
    productId: string,
    itemModifierId: string
): ItemModifierGroupModel => {
    const product = selectProductById(state, productId);
    if (product?.itemModifierGroups) {
        for (const group of product.itemModifierGroups) {
            if (group.itemModifiers) {
                const selectedItemModifiers = Object.values(group.itemModifiers).reduce((acc, item) => {
                    const itemModifiers = item.itemModifiers?.filter((el) => el.itemId === itemModifierId);

                    if (itemModifiers?.length) {
                        return [...acc, ...itemModifiers];
                    }
                    return acc;
                }, []);

                if (selectedItemModifiers?.length) {
                    return group;
                }
                for (const modifierId in group.itemModifiers) {
                    if (modifierId === itemModifierId) {
                        return group;
                    }
                }
            }
        }
    }
    return null;
};

export const isModifierGroupFree = (state: RootState, productId: string, modifierGroupId): boolean => {
    const domainProduct = selectProductById(state, productId);
    const domainGroup = domainProduct?.itemModifierGroups?.find(
        (itemModifierGroup) => itemModifierGroup.productGroupId === modifierGroupId
    );
    return !!domainGroup?.freeQuantity;
};

export const selectItemModifierFromProduct = (
    state: RootState,
    productId: string,
    itemModifierId: string
): ItemModifierModel | null => {
    const product: IDomainProductItem = selectProductById(state, productId);

    if (product?.itemModifierGroups) {
        for (const group of product.itemModifierGroups) {
            const { itemModifiers } = group;

            if (itemModifiers) {
                for (const modifier of Object.values(itemModifiers)) {
                    if (isModifierItemGroup(modifier)) {
                        const foundModifier = modifier.itemModifiers.find(({ itemId }) => itemId === itemModifierId);

                        if (foundModifier) {
                            return { ...foundModifier, itemGroupId: modifier.itemGroupId };
                        }
                    } else if (modifier.itemId === itemModifierId) {
                        return itemModifiers[itemModifierId];
                    }
                }
            }
        }
    }

    return null;
};
export const selectAppliedTallyModifiers = (
    state: RootState,
    tallyItem: PDPTallyItem,
    modifiersToApply: PDPTallyItemModifierGroup[]
): PDPTallyItemModifierGroup[] => {
    const verifiedModifiersToApply: { [productGroupId: string]: PDPTallyItemModifier[] } = {};

    if (!modifiersToApply) {
        return null;
    }

    modifiersToApply.forEach((group) => {
        group.modifiers?.forEach((modifier) => {
            const itemModifier = selectItemModifierFromProduct(state, tallyItem.productId, modifier.productId);

            if (itemModifier) {
                // prevent modifier selection when modifier is outside/inside of itemGroup in old/new product size
                if (itemModifier.itemGroupId && !modifier.itemGroupId) {
                    return;
                }

                const isVisible = isModifierVisible(itemModifier);
                if (isVisible) {
                    const verifiedGroup = verifiedModifiersToApply[group.productId];

                    if (verifiedGroup) {
                        verifiedGroup.push(modifier);
                    } else {
                        verifiedModifiersToApply[group.productId] = [modifier];
                    }
                }
            }
        });
    });

    return tallyItem?.modifierGroups?.map((group) => {
        const itemsToApply = verifiedModifiersToApply[group.productId];

        if (itemsToApply) {
            const newGroup = {
                ...group,
                modifiers: group.modifiers,
            };

            while (itemsToApply.length) {
                const item = itemsToApply.pop();
                const index = newGroup.modifiers.findIndex((it) => it.productId === item.productId);

                if (index !== -1) {
                    if (item.quantity === 0) {
                        newGroup.modifiers.splice(index, 1);
                    } else {
                        newGroup.modifiers[index] = item;
                    }
                } else {
                    if (item.itemGroupId) {
                        newGroup.modifiers = newGroup.modifiers.filter(
                            ({ itemGroupId }) => itemGroupId !== item.itemGroupId
                        );
                    }
                    newGroup.modifiers.push(item);
                }
            }

            return newGroup;
        }
        return group;
    });
};

export const selectTallyItemForNewSize = (
    state: RootState,
    newSizeProductId: string,
    currentSizeTallyItem: PDPTallyItem
): PDPTallyItem => {
    const { brandId } = getBrandInfo();
    const newSizeDefaultTallyItem = selectDefaultTallyItem(state, newSizeProductId);

    if (newSizeDefaultTallyItem.childItems) {
        const newSizeProduct = selectProductById(state, newSizeProductId);

        const modifierGroupIdCurrentChildItemMap: {
            [productGroupId: string]: PDPTallyItem;
        } = currentSizeTallyItem.childItems.reduce((acc, item) => {
            const currentChildItemModifierGroup = selectItemModifierGroupFromProduct(
                state,
                currentSizeTallyItem.productId,
                item.productId
            );

            return {
                ...acc,
                [currentChildItemModifierGroup.productGroupId]: item,
            };
        }, {});

        const currentChildItemNewSizeProductMap: {
            [currentChildProductId: string]: ItemModel;
        } = currentSizeTallyItem.childItems.reduce((acc, item) => {
            const allSizes = selectChildItemSizes(state, item.productId, currentSizeTallyItem.productId);

            const newSize = allSizes.find((it) => it.sizeGroup.id === newSizeProduct.sizeGroupId);

            return { ...acc, [item.productId]: newSize.product };
        }, {});

        if (newSizeDefaultTallyItem.childItems.length !== currentSizeTallyItem.childItems.length) {
            currentSizeTallyItem.childItems.slice(newSizeDefaultTallyItem.childItems.length).forEach((item) => {
                newSizeDefaultTallyItem.childItems.push(item);
            });
        }

        return {
            ...newSizeDefaultTallyItem,
            quantity: currentSizeTallyItem.quantity,
            childItems: newSizeDefaultTallyItem.childItems.map((newSizeDefaultChildTallyItem) => {
                const newChildItemModifiersGroup = selectItemModifierGroupFromProduct(
                    state,
                    newSizeProductId,
                    newSizeDefaultChildTallyItem.productId
                );

                const currentChildItem = modifierGroupIdCurrentChildItemMap[newChildItemModifiersGroup.productGroupId];

                if (currentChildItem) {
                    const newChildProduct = currentChildItemNewSizeProductMap[currentChildItem.productId];

                    if (newChildProduct) {
                        const tally = selectDefaultTallyItemForChildItem(state, newSizeProductId, newChildProduct.id);

                        return {
                            ...tally,
                            quantity: currentChildItem.quantity,
                            modifierGroups: selectAppliedTallyModifiers(state, tally, currentChildItem.modifierGroups),
                        };
                    }

                    return currentChildItem;
                }

                return newSizeDefaultChildTallyItem;
            }),
        };
    }

    // TODO add a way to toggle files other than components and have a two separate functions
    if (brandId === 'Bww') {
        return {
            ...newSizeDefaultTallyItem,
            quantity: currentSizeTallyItem.quantity,
        };
    }

    return {
        ...newSizeDefaultTallyItem,
        quantity: currentSizeTallyItem.quantity,
        modifierGroups:
            selectAppliedTallyModifiers(state, newSizeDefaultTallyItem, currentSizeTallyItem.modifierGroups) || [],
    };
};

export const selectBwwIsModifierOnSide = (state: RootState, modifierId: string): boolean => {
    const product = selectProductById(state, modifierId);

    return product?.sizeGroupId === ON_SIDE_SIZE_GROUP_ID;
};

export const selectBwwIsModifierNoSauce = (state: RootState, modifierId: string): boolean => {
    const product = selectProductById(state, modifierId);

    return product?.categoryIds?.some((it) => it === NO_SAUCE_CATEGORY_ID);
};

export const selectBwwIsRecommendedProduct = (state: RootState, modifierId: string): boolean => {
    const product = selectProductById(state, modifierId);

    return product?.categoryIds?.some((it) => it === RECOMMENDED_CATEGORY_ID);
};

export const selectIsProductHasRequiredModifiers = (state: RootState, productId: string) => {
    const products = selectProducts(state);

    const product = products[productId];

    const isModifierGroupHasRequiredModifiers = (modifierGroup: ItemModifierGroupModel) => {
        return (
            Object.keys(modifierGroup.itemModifiers || {}).reduce(
                (acc, key) => acc + modifierGroup.itemModifiers[key].defaultQuantity,
                0
            ) < modifierGroup.min
        );
    };

    const modifierGroups = product?.itemModifierGroups || [];
    for (const modifierGroup of modifierGroups) {
        if (isModifierGroupHasRequiredModifiers(modifierGroup)) {
            return true;
        }

        // check nested default modifiers
        for (const modifierId of Object.keys(modifierGroup.itemModifiers || {})) {
            if (modifierGroup.itemModifiers[modifierId]?.defaultQuantity) {
                const nestedModifierGroups = products[modifierId]?.itemModifierGroups || [];
                for (const nestedModifierGroup of nestedModifierGroups) {
                    if (isModifierGroupHasRequiredModifiers(nestedModifierGroup)) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
};

export const selectDefaultTallyRelatedItemGroups = (state: RootState, productId: string) => {
    const products = selectProducts(state);
    const isCombo = selectProductIsCombo(state, productId);
    const lineItems = state.bag.LineItems || [];
    let relatedItemGroups: ItemModifierGroupModel[];

    if (isCombo) {
        const entreeProductId = Object.values(products[productId].itemModifierGroups[0].itemModifiers)[0].itemId;
        const entreeProduct = products[entreeProductId];

        relatedItemGroups = entreeProduct?.relatedItemGroups;
    } else {
        relatedItemGroups = products[productId]?.relatedItemGroups;
    }

    const lineItemsById = lineItems.reduce((prev, condiment) => {
        return { ...prev, [condiment.productId]: condiment };
    }, {});

    return relatedItemGroups?.map((it) => ({
        productId: it?.productGroupId,
        metadata: it?.metadata,
        isOnSideChecked: selectInitialIsSauceOnSide(state, productId, it.productGroupId),
        modifiers: Object.values(it.itemModifiers || [])
            ?.filter((modifier) => lineItemsById[modifier.itemId])
            ?.map((modifier) => {
                const productItemModifier = products[modifier?.itemId];
                const price = getModifierPrice(modifier, productItemModifier);

                return {
                    productId: modifier?.itemId,
                    price,
                    quantity: lineItemsById[modifier.itemId].quantity,
                    modifierGroups: selectDefaultTallyRelatedItemGroups(state, modifier.itemId),
                };
            }),
    }));
};

export const selectAllCondiments = createSelector(
    selectProducts,
    selectCurrenOrderLocation,
    (products, location): IDisplayProduct[] => {
        const rawCondiments = Object.values(products || []).filter(
            (product) => product.tags?.MAX_LIMIT_TYPE === 'CONDIMENT'
        );
        const condiments = rawCondiments.map((condiment) => {
            const productItemCondiment = products[condiment.id];
            const price = getCheapestPriceFromProduct(condiment, location);
            const calories = getCaloriesFromProduct(productItemCondiment);
            return {
                displayName: productItemCondiment.name,
                displayProductDetails: {
                    displayName: productItemCondiment.name,
                    price,
                    productId: condiment.id,
                    quantity: 0,
                    productGroupId: productItemCondiment.productGroupId,
                    calories,
                },
            };
        });

        return condiments;
    }
);

export const selectAllCondimentsIds = createSelector(selectAllCondiments, (condiments) =>
    condiments.map((condiment) => condiment.displayProductDetails.productId)
);

export const selectDrinkCarrierCondiment = createSelector(
    selectProducts,
    selectAllCondiments,
    (products, condiments) => {
        const drinkCarrier = Object.values(products).find(
            (product) => product.tags?.PRODUCT === 'DRINK_CARRIER' && product.tags?.MAX_LIMIT_TYPE === 'CONDIMENT'
        );
        return condiments.find((item) => item.displayProductDetails.productId === drinkCarrier.id);
    }
);
