/** @namespace logger*/

export const isNewRelicAvailable = () => typeof window !== 'undefined' && !!window.newrelic;

const init = (): void => {
    const version = process.env.NEXT_PUBLIC_ARTIFACT_VERSION
        ? process.env.NEXT_PUBLIC_ARTIFACT_VERSION.split('-')[0]
        : 'no version';

    window.newrelic.setCustomAttribute('environment', process.env.NEXT_PUBLIC_ENVIRONMENT_NAME);
    window.newrelic.setCustomAttribute('labels.environment', process.env.NEXT_PUBLIC_ENVIRONMENT_NAME);
    window.newrelic.setCustomAttribute('labels.revision', version);

    window.newrelic.addRelease(version, version);
};
/**@typedef Error
 * @property {string} name the name of the error
 * @property {string} message details information about the message
 * @property {string} [stack] stacktrace of the origin of the error
 * @memberOf logger
 * */

/**@typedef Info
 * @property {string | number} key could be any string or number representing the topic of the message
 * @memberOf logger
 * */

/**
 * @method logError
 * @param {Error | string}  error error type or code
 * @param {Info} info description of the error provided
 * @author Daniel Ramos <dramos@inspirebrands.com>
 * @added 2022-09-14
 * @version 1.0
 * @description Sends error and description of error to New Relic
 * @memberOf logger
 * @example
 * const getProducts = () => {
 *     try {
 *         await fetchServerProducts();
 *     } catch (e) {
 *         logError(e, info: { 'details': 'Unable to get products' })
 *     }
 * }
 */
const logError = (error: Error | string, info?: { [key: string]: string | number }): void => {
    isNewRelicAvailable() && window.newrelic?.noticeError(error, info);
    console.error(error, info);
};

/**
 * @method logEvent
 * @param {string} name Name of the event
 * @param {Info} attributes detailed description of the event
 * @author Daniel Ramos <dramos@inspirebrands.com>
 * @added 2022-09-14
 * @version 1.0
 * @description Sends an action performed from the page to NewRelic
 * @memberOf logger
 * @example
 * const getProducts = () => {
 *     logEvent('getProducts', attributes: {'DETAILS': 'Sending request to server to retrieve all products for main page'});
 *     try {
 *         await fetchServerProducts();
 *     } catch (e) {
 *         logError(e, info: { 'details': 'Unable to get products' });
 *     }
 * }
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logEvent = (name: string, attributes: { [key: string]: string | number | any }): void => {
    isNewRelicAvailable() && window.newrelic?.addPageAction(name, attributes);
};

export default {
    init,
    logError,
    logEvent,
};
