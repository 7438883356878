/** @namespace useLogout*/
import { useAuth0 } from '@auth0/auth0-react';

import { clearCookie } from '../helpers/cookieHelper';
import { BrazeEventKey } from './braze/types';
import { useBrazeIntegration } from './braze/useBrazeIntegration';
import {
    HASHED_BLAZIN_REWARDS_COOKIE_NAME,
    RAW_BLAZIN_REWARDS_COOKIE_NAME,
} from '../../components/organisms/play/constants';

interface IUseLogout {
    logoutAndClearCookies: (returnTo?: string) => void;
}

/**
 * @method useLogout
 * @added 18-04-2023
 * @description Hook for managing logout functionality
 * @returns {IUseLogout} - function to logout and clear cookies
 * @version 1.0.0
 * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
 * @memberOf useLogout
 * @example const {logoutAndClearCookies} = useLogout();
 */
export const useLogout = (): IUseLogout => {
    const { logout } = useAuth0();
    const { setCustomEvent, isBrazeAvailable } = useBrazeIntegration();

    // redirectUrl?: string argument could be passed additionally in future
    const logoutAndClearCookies = (returnTo: string = window.location.origin): void => {
        logout({ returnTo });
        if (isBrazeAvailable) {
            setCustomEvent(BrazeEventKey.USER_LOG_OUT);
        }
        clearCookie(RAW_BLAZIN_REWARDS_COOKIE_NAME);
        clearCookie(HASHED_BLAZIN_REWARDS_COOKIE_NAME);
        // more cookies to clean could be added here
    };

    return { logoutAndClearCookies };
};
