import { createNextState, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { getEmptyTallyItem } from '../lib/tallyItem';
import {
    selectDefaultTallyItem,
    selectProductWithRootIdById,
    selectProductById,
    selectDefaultTallyModifierGroups,
    selectDefaultTallyRelatedItemGroups,
} from './selectors/domainMenu';
import {
    selectBwwNewModifierGroups,
    selectBwwOnSideSauces,
    selectBwwRegularSauces,
    selectPDPTallyItem,
    selectSdiNewModifierGroups,
} from './selectors/pdp';

import { RootState } from './store';
import { getCheapestPriceFromProduct } from '../lib/domainProduct';
import { selectCurrenOrderLocation } from './selectors/orderLocation';
import { TallyProductModel } from '../@generated/webExpApi';
import { IDefaultModifier, PDPTallyItemModifier, PDPTallyItemModifierGroup } from './types';
import getBrandInfo, { BRANDS } from '../lib/brandInfo';
import { isUndefined } from '../common/helpers/isUndefined';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// Don't add lineItemId unless in bag!
export interface PDPTallyItem extends Omit<TallyProductModel, 'lineItemId' | 'childItems'> {
    lineItemId?: number;
    childItems?: PDPTallyItem[];
    madeAMeal?: boolean;
    name?: string;
    modifierGroups?: PDPTallyItemModifierGroup[];
    reAddCart?: boolean;
    relatedItemGroups?: PDPTallyItemModifierGroup[];
}

export interface PDPState {
    tallyItem?: PDPTallyItem;
    initialTallyItemId?: string;
    modifyingFromBag?: boolean;
}

export type PutTallyItemPayload = { pdpTallyItem: PDPTallyItem; madeAMeal?: boolean; lineItemId?: number };
export type PutInitialTallyItemIdPayload = { defaultTallyItemId: PDPTallyItem['productId'] };

export type EditTallyItemSizePayload = {
    pdpTallyItem: PDPTallyItem;
};

export type EditTallyItemModifiersPayload = {
    pdpTallyItem: PDPTallyItem;
};

export type EditTallyItemCountPayload = {
    pageProductId: string;
    lineItemId: number;
    value: number;
};

export type OnSauceOnSideChangePayload = { productGroupId: string };

export type OnModifierChangePayload = {
    modifierGroupId: string;
    modifierId: string;
    parentModifierGroupId?: string;
    parentModifierId?: string;
    itemGroupId?: string;
    quantity: number;
    defaultModifiers?: IDefaultModifier[];
    childIndex?: number; // will change modifier in combo child item
};

export const initialState: PDPState = {
    tallyItem: getEmptyTallyItem(),
};

const pdpSlice = createSlice({
    name: 'pdp',
    initialState,
    reducers: {
        putTallyItem: (state, action: PayloadAction<PutTallyItemPayload>) => {
            const { pdpTallyItem, madeAMeal, lineItemId } = action.payload;

            if (lineItemId) {
                // "Modify From Bag" clicked, lineItemId passed
                state.tallyItem = { ...pdpTallyItem, madeAMeal };
                state.tallyItem.lineItemId = lineItemId;
                state.modifyingFromBag = true;
            } else {
                // exclude case with initialization when land on PDP from bag from other page
                if (!state.modifyingFromBag) {
                    state.tallyItem = { ...pdpTallyItem, madeAMeal };
                }
                state.modifyingFromBag = false;
            }
        },
        putInitialTallyItemId: (state, action: PayloadAction<PutInitialTallyItemIdPayload>) => {
            const { defaultTallyItemId } = action.payload;
            state.initialTallyItemId = defaultTallyItemId;
        },
        resetTallyItem: (state, action: PayloadAction<PutTallyItemPayload>) => {
            state.tallyItem = action.payload.pdpTallyItem;
            state.modifyingFromBag = false;
        },
        editTallyItemSize: (state, action: PayloadAction<EditTallyItemSizePayload>) => {
            const { pdpTallyItem } = action.payload;

            state.tallyItem = pdpTallyItem;
        },
        editTallyItemModifiers: (state, action: PayloadAction<EditTallyItemModifiersPayload>) => {
            const { pdpTallyItem } = action.payload;

            state.tallyItem = pdpTallyItem;
        },
        editTallyRelatedItemGroups: (state, action: PayloadAction<EditTallyItemModifiersPayload>) => {
            const { pdpTallyItem } = action.payload;

            state.tallyItem = pdpTallyItem;
        },
        editTallyItemCount: (
            state,
            { payload: { pageProductId, value, lineItemId } }: PayloadAction<EditTallyItemCountPayload>
        ) => {
            const pageTallyItem = state.tallyItem;

            if (pageTallyItem?.productId !== pageProductId) {
                return state;
            }

            if (pageTallyItem?.lineItemId && pageTallyItem.lineItemId === lineItemId) {
                pageTallyItem.quantity += value;
            }
        },
    },
});

function resetPdpState() {
    return (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();
        const defaultTalyItem = selectDefaultTallyItem(state, state.pdp.initialTallyItemId);

        dispatch(
            pdpSlice.actions.resetTallyItem({
                pdpTallyItem: defaultTalyItem,
            })
        );
    };
}

function resetPdpCondimentsState() {
    return (dispatch: Dispatch, getState: () => RootState): void => {
        const state = getState();
        const talyItem = selectPDPTallyItem(state);

        dispatch(
            pdpSlice.actions.resetTallyItem({
                pdpTallyItem: {
                    ...talyItem,
                    relatedItemGroups: selectDefaultTallyRelatedItemGroups(state, talyItem.productId),
                },
            })
        );
    };
}

function onSauceOnSideChange(payload: OnSauceOnSideChangePayload) {
    return (dispatch: Dispatch, getState: () => RootState): void => {
        const { productGroupId } = payload;
        const state = getState();
        const tallyItem = selectPDPTallyItem(state);

        const newTallyItem = {
            ...tallyItem,
            modifierGroups: tallyItem.modifierGroups.map((group) => {
                if (group.productId !== productGroupId) {
                    return group;
                }

                const newIsOnSideChecked = !group.isOnSideChecked;
                const location = selectCurrenOrderLocation(state);

                const oppositModifiers = (newIsOnSideChecked ? selectBwwOnSideSauces : selectBwwRegularSauces)(
                    state,
                    productGroupId
                ).map((it) => selectProductWithRootIdById(state, it.itemId));

                return {
                    ...group,
                    isOnSideChecked: newIsOnSideChecked,
                    modifiers: group.modifiers
                        .filter((it) => it.quantity)
                        .reduce((acc, item) => {
                            const { rootProductId } = selectProductWithRootIdById(state, item.productId);
                            const opposite = oppositModifiers.find((it) => it.rootProductId === rootProductId);

                            if (opposite) {
                                return [
                                    ...acc,
                                    {
                                        productId: opposite.id,
                                        quantity: 1, // we persist only 1 item
                                        price: getCheapestPriceFromProduct(opposite, location),
                                        sizeGroupId: opposite.sizeGroupId,
                                    },
                                ];
                            }
                            return acc;
                        }, []),
                };
            }),
        };

        dispatch(
            pdpSlice.actions.editTallyItemModifiers({
                pdpTallyItem: newTallyItem,
            })
        );
    };
}

function onModifierChange(payload: OnModifierChangePayload) {
    return (dispatch: Dispatch, getState: () => RootState): void => {
        switch (getBrandInfo().brandId) {
            case BRANDS.bww.brandId:
                return onBwwModifierChange(dispatch, getState, payload);
            case BRANDS.sdi.brandId:
                return onSdiModifierChange(dispatch, getState, payload);
        }
    };
}

function onBwwModifierChange(dispatch: Dispatch, getState: () => RootState, payload: OnModifierChangePayload) {
    const { modifierGroupId, modifierId, parentModifierGroupId, parentModifierId, quantity } = payload;
    const state = getState();
    const tallyItem = selectPDPTallyItem(state);

    if (parentModifierGroupId && parentModifierId) {
        const newModifierGroups = tallyItem.modifierGroups.map((group) => {
            if (group.productId !== parentModifierGroupId) {
                return group;
            }

            return {
                ...group,
                modifiers: group?.modifiers.map((modifier) => {
                    if (modifier.productId !== parentModifierId) {
                        return modifier;
                    }

                    return {
                        ...modifier,
                        modifierGroups: selectBwwNewModifierGroups(
                            state,
                            modifierGroupId,
                            modifierId,
                            quantity,
                            modifier.modifierGroups,
                            payload.defaultModifiers,
                            parentModifierGroupId,
                            parentModifierId
                        ),
                    };
                }),
            };
        });

        dispatch(
            pdpSlice.actions.editTallyItemModifiers({
                pdpTallyItem: {
                    ...tallyItem,
                    modifierGroups: newModifierGroups,
                },
            })
        );
    } else {
        dispatch(
            pdpSlice.actions.editTallyItemModifiers({
                pdpTallyItem: {
                    ...tallyItem,
                    modifierGroups: selectBwwNewModifierGroups(
                        state,
                        modifierGroupId,
                        modifierId,
                        quantity,
                        tallyItem.modifierGroups,
                        payload.defaultModifiers,
                        parentModifierGroupId,
                        parentModifierId
                    ),
                },
            })
        );
    }
}

function onSdiModifierChange(dispatch: Dispatch, getState: () => RootState, payload: OnModifierChangePayload) {
    const { modifierGroupId, modifierId, itemGroupId, quantity, childIndex } = payload;
    const state = getState();
    const tallyItem = selectPDPTallyItem(state);

    if (!isUndefined(childIndex)) {
        const newChildItems = tallyItem.childItems.map((item, i) => {
            if (i !== childIndex) {
                return item;
            }

            return {
                ...item,
                modifierGroups: selectSdiNewModifierGroups(
                    state,
                    modifierGroupId,
                    modifierId,
                    quantity,
                    item,
                    itemGroupId
                ),
            };
        });

        dispatch(
            pdpSlice.actions.editTallyItemModifiers({
                pdpTallyItem: {
                    ...tallyItem,
                    childItems: newChildItems,
                },
            })
        );
    } else {
        dispatch(
            pdpSlice.actions.editTallyItemModifiers({
                pdpTallyItem: {
                    ...tallyItem,
                    modifierGroups: selectSdiNewModifierGroups(
                        state,
                        modifierGroupId,
                        modifierId,
                        quantity,
                        tallyItem,
                        itemGroupId
                    ),
                },
            })
        );
    }
}
export type OnModifierSizeChangePayload = {
    modifierGroupId: string;
    modifierId: string;
    newModifierSizeId: string;
};

export function onModifierSizeChange(payload: OnModifierSizeChangePayload) {
    return (dispatch: Dispatch, getState: () => RootState): void => {
        const { modifierGroupId, modifierId, newModifierSizeId } = payload;
        const state = getState();
        const tallyItem = selectPDPTallyItem(state);

        const location = selectCurrenOrderLocation(state);
        const modifierProduct = selectProductById(state, newModifierSizeId);

        const newTallyItem = createNextState(tallyItem, (nextState) => {
            const modifierGroup = nextState.modifierGroups.find((group) => group.productId === modifierGroupId);
            const modifierIndex = modifierGroup.modifiers.findIndex((it) => it.productId === modifierId);
            if (modifierIndex === -1) {
                return;
            }

            modifierGroup.modifiers[modifierIndex] = {
                productId: newModifierSizeId,
                quantity: 1,
                price: getCheapestPriceFromProduct(modifierProduct, location),
                modifierGroups: selectDefaultTallyModifierGroups(state, newModifierSizeId),
            };
        });

        dispatch(
            pdpSlice.actions.editTallyItemModifiers({
                pdpTallyItem: newTallyItem,
            })
        );
    };
}

function onCondimentChange(payload: OnModifierChangePayload) {
    return (dispatch: Dispatch, getState: () => RootState): void => {
        const { modifierGroupId, modifierId, quantity } = payload;
        const state = getState();
        const tallyItem = selectPDPTallyItem(state);

        if (!tallyItem.productId || !tallyItem.relatedItemGroups) return;

        const modifierProduct = selectProductById(state, modifierId);
        const location = selectCurrenOrderLocation(state);

        const newRelatedItemGroups = tallyItem.relatedItemGroups.map((group) => {
            if (group.productId !== modifierGroupId) {
                return group;
            }

            const groupModifiersByModifierId = group.modifiers.reduce((prev, current) => {
                return { ...prev, [current.productId]: current };
            }, {});

            let modifiers: PDPTallyItemModifier[];
            if (groupModifiersByModifierId[modifierId]) {
                modifiers = group.modifiers.map((modifier) => {
                    if (modifier.productId === modifierId) {
                        return { ...modifier, quantity };
                    }

                    return modifier;
                });
            } else {
                modifiers = [
                    ...group.modifiers,
                    { productId: modifierId, quantity, price: getCheapestPriceFromProduct(modifierProduct, location) },
                ];
            }

            return { ...group, modifiers };
        });

        dispatch(
            pdpSlice.actions.editTallyRelatedItemGroups({
                pdpTallyItem: {
                    ...tallyItem,
                    relatedItemGroups: newRelatedItemGroups,
                },
            })
        );
    };
}

export const actions = {
    ...pdpSlice.actions,
    onSauceOnSideChange,
    onModifierChange,
    onModifierSizeChange,
    onCondimentChange,
    resetPdpState,
    resetPdpCondimentsState,
};

export default pdpSlice.reducer;
