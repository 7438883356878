import React, { FC } from 'react';
import Script from 'next/script';
import { getPerimeterXScript } from '../common/helpers/getPerimeterXScript';

export const PerimeterXSnippet: FC = () => {
    const perimeterXKey = process.env.NEXT_PUBLIC_PERIMETER_X_KEY;

    if (perimeterXKey) {
        return (
            <Script
                id="perimeter-x-snippet"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: getPerimeterXScript(perimeterXKey),
                }}
            ></Script>
        );
    }

    return null;
};
