/** @namespace useBrazeIntegration*/
import { useContext } from 'react';
import brazeContext, { IBrazeContext } from '../../contexts/brazeContext';

/**
 * @method useBrazeIntegration
 * @added 17-04-2023
 * @description Provide braze related context
 * @returns {IBrazeContext} - braze context
 * @version 1.0.0
 * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
 * @memberOf useBrazeIntegration
 * @example const brazeContext = useBrazeIntegration();
 */
export const useBrazeIntegration = (): IBrazeContext => {
    const {
        cards,
        isBrazeAvailable,
        setCustomEvent,
        setPurchaseEvent,
        contentCardClick,
        contentCardImpressions,
    } = useContext(brazeContext);

    return {
        cards,
        isBrazeAvailable,
        setCustomEvent,
        setPurchaseEvent,
        contentCardClick,
        contentCardImpressions,
    };
};
