import React, { FC, RefObject } from 'react';
import classNames from 'classnames';

import styles from './brandLoader.module.css';
import Icon from '../BrandIcon';

interface ILoaderProps {
    className?: string;
}

const BrandLoader: FC<ILoaderProps> = React.forwardRef(function BrandLoader(
    { className },
    ref: RefObject<HTMLDivElement>
) {
    return (
        <div role="progressbar" className={classNames(styles.container, className)} ref={ref} aria-label="progressbar">
            <div className={styles.icons}>
                <Icon icon="logo" className={styles.icon} />
                <Icon icon="logo" className={styles.icon} />
            </div>
        </div>
    );
});

export default BrandLoader;
