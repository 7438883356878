import {
    selectBwwDisplayProduct,
    selectBwwDisplayModifierGroup,
    selectBwwSauceDisplayModifierGroup,
    selectDisplayModifierGroupsByProductIdAndProductGroupId,
    selectDisplayProduct,
    selectExtrasDisplayProducts,
    selectMainComboDisplayProductById,
    selectPDPTallyItem,
    selectBwwDisplayModifiersGroups,
    selectBwwSelectionsText,
    selectSelectedExtras,
    selectDisplayRelatedItemGroups,
    selectSdiDisplayProduct,
    selectSdiDisplayModifierGroup,
} from '../../selectors/pdp';
import {
    selectDealDisplayProductsByMenuIds,
    selectDealAboutSectionInfo,
    selectDealTallyItemById,
} from '../../selectors/dealPdp';
import { useAppSelector } from '../../store';
import {
    IAboutSectionInfo,
    IDealDisplayProduct,
    IDefaultModifier,
    IDisplayFullProduct,
    IDisplayModifierGroup,
    IDisplayProduct,
    IExtraProductGroup,
    ISaucesDisplayModifierGroup,
    ISelectedExtraItem,
    IUseSdiDisplayModifierGroup,
    IUseSdiDisplayModifierGroups,
    PDPTallyItemModifierGroup,
} from '../../types';
import { getCheapestPriceFromProduct } from '../../../lib/domainProduct';
import { useDispatch } from 'react-redux';
import * as PDPStore from '../../pdp';
import * as DealPDPStore from '../../dealPdp';
import { selectProducts } from '../../selectors/domainMenu';

import { useDefaultModifiers, useSelectedModifiers } from '../domainMenu';
import { getChangedModifiers, IGetChangedModifiers } from '../../../common/helpers/getChangedModifiers';
import { useOrderLocation } from '..';
import { IRewardMenuIdModel } from '../../../@generated/webExpApi';
import { selectAboutSectionInfo } from '../../selectors/pdp/selectAboutSectionInfo';
import { IProductDetailsPagePath } from '../../../lib/contentfulDelivery';
import { IProductItemById } from '../../../common/services/globalContentfulProps';
import { isUndefined } from '../../../common/helpers/isUndefined';

export const useDisplayProduct = (): IDisplayFullProduct => useAppSelector(selectDisplayProduct);

export const useBwwDisplayProduct = (): IDisplayFullProduct => useAppSelector(selectBwwDisplayProduct);

/**
 * @param childIndex pass this to select displayProduct for combo item (tallyItem.childItems[childIndex])
 */
export const useSdiDisplayProduct = (childIndex?: number): IDisplayFullProduct =>
    useAppSelector((state) => selectSdiDisplayProduct(state, childIndex));

export const useBwwDisplayModifierGroup = (
    productGroupId: string,
    parentProductGroupId?: string,
    parentId?: string
): IDisplayModifierGroup =>
    useAppSelector((state) => {
        const tallyItem = selectPDPTallyItem(state);
        return selectBwwDisplayModifierGroup(state, tallyItem, productGroupId, parentProductGroupId, parentId);
    });

/**
 * Hook that contains logic for selecting a display modifier group specifically for SONIC brand
 * in depending on what TallyItem is in the store, "deal" or "pdp".
 * @method useSdiDisplayModifierGroup
 * @added  2022-01-31
 * @version  1.0.0
 * @param {IUseSdiDisplayModifierGroup} payload - The payload containing the data to select correct modifier groups
 * @returns {IDisplayModifierGroup} returns display modifier group
 */
export const useSdiDisplayModifierGroup = ({
    stage,
    productId,
    childIndex,
    sectionIndex,
    productGroupId,
    productsById,
}: IUseSdiDisplayModifierGroup): IDisplayModifierGroup =>
    useAppSelector((state) => {
        const dealTallyItem = selectDealTallyItemById(state, sectionIndex, productId);
        const pdpTallyItem = !isUndefined(childIndex)
            ? selectPDPTallyItem(state).childItems[childIndex]
            : selectPDPTallyItem(state);

        const tallyItem = dealTallyItem || pdpTallyItem;

        return selectSdiDisplayModifierGroup({
            state,
            tallyItem,
            modifierGroupId: productGroupId,
            stage,
            productId,
            productsById,
        });
    });

/**
 * Hook that contains logic for selecting a display modifier groups specifically for SONIC brand
 * in depending on what TallyItem is in the store, "deal" or "pdp".
 * @method useSdiDisplayModifierGroups
 * @added  2022-01-31
 * @version  1.0.0
 * @param {IUseSdiDisplayModifierGroups} payload - The payload containing the data to select correct modifier groups
 * @returns {Array<IDisplayModifierGroup>} returns display modifier groups
 */
export const useSdiDisplayModifierGroups = ({
    stage,
    productId,
    childIndex,
    sectionIndex,
    productGroupIds,
    productsById,
}: IUseSdiDisplayModifierGroups): IDisplayModifierGroup[] =>
    useAppSelector((state) => {
        const dealTallyItem = selectDealTallyItemById(state, sectionIndex, productId);
        const pdpTallyItem = !isUndefined(childIndex)
            ? selectPDPTallyItem(state).childItems[childIndex]
            : selectPDPTallyItem(state);

        const tallyItem = dealTallyItem || pdpTallyItem;

        return productGroupIds?.map((productGroupId) =>
            selectSdiDisplayModifierGroup({
                state,
                tallyItem,
                modifierGroupId: productGroupId,
                stage,
                productId,
                productsById,
            })
        );
    });

export const useBwwSaucesDisplayModifierGroup = (productGroupId: string): ISaucesDisplayModifierGroup =>
    useAppSelector((state) => selectBwwSauceDisplayModifierGroup(state, productGroupId));

export const useBwwDisplayModifierGroupsForModifier = (
    modifierGroupId: string,
    modifierId: string
): IDisplayModifierGroup[] =>
    useAppSelector((state) => {
        const tallyItem = selectPDPTallyItem(state);
        return selectBwwDisplayModifiersGroups(state, tallyItem, modifierGroupId, modifierId);
    });

export const useBwwSelectionsText = (pdpTallyItem: PDPStore.PDPTallyItem, productItemGroupName: string): string =>
    useAppSelector((state) => selectBwwSelectionsText(state, pdpTallyItem, productItemGroupName));

export const useDisplayModifierGroupsByProductId = (
    productId: string,
    productGroupId: string,
    sectionIndex: number
): IDisplayModifierGroup[] =>
    useAppSelector((state) =>
        selectDisplayModifierGroupsByProductIdAndProductGroupId(state, productId, productGroupId, sectionIndex)
    );

export const useMainComboDisplayProductByProductId = (productId: string): IDisplayProduct =>
    useAppSelector((state) => selectMainComboDisplayProductById(state, productId));

interface ISelectedItem {
    quantity: number;
}

interface ISelectedItems {
    [id: string]: ISelectedItem;
}

export const useProductTallyModifierGroup = (
    productGroupId: string,
    childProductId?: string,
    sectionIndex?: number,
    defaultModifiers?: IDefaultModifier[]
): {
    setSelectedItems: (selectedItems: ISelectedItems) => void;
    tallyModifierGroup: PDPTallyItemModifierGroup;
    totalCount: number;
    selectedItems: ISelectedItems;
} => {
    const dispatch = useDispatch();
    const rootTallyItem = useAppSelector(selectPDPTallyItem);
    const dealTallyItem = useAppSelector((state) => selectDealTallyItemById(state, sectionIndex, childProductId));
    const products = useAppSelector(selectProducts);
    const { currentLocation } = useOrderLocation();

    // Use root if not combo or child if combo
    const childItem = rootTallyItem?.childItems?.find(
        (childItem, index) => childItem.productId === childProductId && index === sectionIndex
    );

    const pdpTallyItem = childProductId ? childItem || dealTallyItem : rootTallyItem;

    const tallyModifierGroup = pdpTallyItem?.modifierGroups?.find(
        (modifierGroup) => modifierGroup.productId === productGroupId
    );

    const selectedItems: ISelectedItems =
        tallyModifierGroup?.modifiers?.reduce((selected, mod) => {
            return { ...selected, [mod.productId]: { quantity: mod.quantity || 0 } };
        }, {}) || {};

    const isModifierDefault = (modifierId: string, defaultModifiers: IDefaultModifier[]) => {
        return defaultModifiers.some((defaultModifier) => modifierId === defaultModifier.productId);
    };
    const setSelectedItems = (selectedItems: ISelectedItems) => {
        const idList = Object.keys(selectedItems).map((id) => id);
        const selectedModifiers = idList
            .map((id) => products[id])
            ?.reduce((dict, product) => {
                return { ...dict, [product?.id]: product };
            }, {});

        const newModifierGroup: PDPTallyItemModifierGroup = {
            isOnSideChecked: tallyModifierGroup.isOnSideChecked,
            metadata: tallyModifierGroup.metadata,
            productId: productGroupId,
            modifiers: idList.reduce((acc, id) => {
                if (selectedItems[id].quantity === 0 && !isModifierDefault(id, defaultModifiers)) return acc; // TODO conflict critical 69410 vs 27733 - need generic fix
                return [
                    ...acc,
                    {
                        productId: id,
                        quantity: selectedItems[id].quantity,
                        price: getCheapestPriceFromProduct(selectedModifiers[id], currentLocation), // TODO double check with menu team
                    },
                ];
            }, []),
        };
        const newModifierGroups = pdpTallyItem?.modifierGroups?.map((mg) =>
            mg.productId === productGroupId ? newModifierGroup : mg
        );

        if (dealTallyItem) {
            dispatch(
                DealPDPStore.actions.editOfferProductModifiers({
                    modifierGroups: newModifierGroups,
                    pickIndex: sectionIndex,
                    productId: childProductId,
                })
            );
        } else {
            const newTallyItemState: PDPStore.PDPTallyItem = {
                ...rootTallyItem,
                modifierGroups: childProductId ? rootTallyItem.modifierGroups : newModifierGroups, // Set root mod group if not combo
                childItems:
                    rootTallyItem.childItems &&
                    rootTallyItem.childItems.map(
                        (child, index) =>
                            child.productId === childProductId && index === sectionIndex
                                ? { ...child, modifierGroups: newModifierGroups }
                                : child // Set child mod group if combo
                    ),
            };

            dispatch(PDPStore.actions.editTallyItemModifiers({ pdpTallyItem: newTallyItemState }));
        }
    };

    const totalCount = tallyModifierGroup?.modifiers?.reduce((res, modifier) => {
        return res + modifier.quantity || 0;
    }, 0);

    return {
        tallyModifierGroup,
        totalCount,
        selectedItems,
        setSelectedItems,
    };
};

export const useAboutSection = (): IAboutSectionInfo | null => useAppSelector(selectAboutSectionInfo);
export const useDealAboutSection = (pickIndex: number): IAboutSectionInfo | null =>
    useAppSelector((state) => selectDealAboutSectionInfo(state, pickIndex));

export const useChangedModifiersForChildItem = (childItemIndex: number): IGetChangedModifiers => {
    const pdpTallyItem = useAppSelector(selectPDPTallyItem);
    const tallyItem = pdpTallyItem.childItems[childItemIndex];
    const selectedModifiers = useSelectedModifiers(tallyItem);
    const defaultModifiers = useDefaultModifiers(tallyItem?.productId);

    return getChangedModifiers(selectedModifiers, defaultModifiers);
};

export const useChangedModifiersForDealProduct = (pickIndex: number, productId: string): IGetChangedModifiers => {
    const tallyItem = useAppSelector((state) => selectDealTallyItemById(state, pickIndex, productId));
    const selectedModifiers = useSelectedModifiers(tallyItem);
    const defaultModifiers = useDefaultModifiers(tallyItem?.productId);

    return getChangedModifiers(selectedModifiers, defaultModifiers);
};

export const useExtraProducts = (): IExtraProductGroup[] => useAppSelector(selectExtrasDisplayProducts);

export const useSelectedExtras = (): ISelectedExtraItem[] => useAppSelector(selectSelectedExtras);

export const useDealDisplayProductsByMenuIds = (
    menuIds: IRewardMenuIdModel[],
    productsById: IProductItemById,
    productDetailsPagePaths: IProductDetailsPagePath[]
): IDealDisplayProduct[] =>
    useAppSelector((state) =>
        selectDealDisplayProductsByMenuIds(state, menuIds, productsById, productDetailsPagePaths)
    );

export const useDisplayRelatedGroups = (): IDisplayModifierGroup[] =>
    useAppSelector((state) => selectDisplayRelatedItemGroups(state));
