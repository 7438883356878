import {
    IExternalLink,
    IPageLink,
    IMenuCategoryLink,
    IPhoneNumberLink,
    IProduct,
    IDocumentLink,
    ISocialMediaLink,
} from '../@generated/@types/contentful';
import { getContentfulProductIdsByFields } from '../common/helpers/getContentfulProductIdsByFields';
import { IProductDetailsPagePath } from './contentfulDelivery';

type ILink =
    | IExternalLink
    | IProduct
    | IPhoneNumberLink
    | IPageLink
    | IMenuCategoryLink
    | IDocumentLink
    | ISocialMediaLink
    | undefined;

const getNameFromLink = (link: ILink): string => {
    if (!link?.fields) return '';

    return link.fields.name;
};

interface ILinkDetails {
    href: string;
    isExternal: boolean;
    isPhone: boolean;
    name?: string;
}

type ILinkOptions = {
    productDetailsPagePaths: IProductDetailsPagePath[];
    currentCategoryUrl?: string;
};

export const getLinkDetails = (link: ILink | string, options?: ILinkOptions): ILinkDetails => {
    if (typeof link === 'string') return { href: link, isExternal: false, isPhone: false };

    let href = '/';
    let isExternal = false;
    let isPhone = false;
    const name = getNameFromLink(link);
    switch (link?.sys?.contentType?.sys.id) {
        case 'externalLink': {
            const externalLink = link as IExternalLink;
            href = externalLink?.fields?.nameInUrl || '/';
            isExternal = true;
            break;
        }
        case 'menuCategoryLink': {
            const menuCategoryLink = link as IMenuCategoryLink;
            href = `/menu/${menuCategoryLink?.fields?.nameInUrl || ''}`;
            break;
        }
        case 'phoneNumberLink': {
            const phoneNumberLink = link as IPhoneNumberLink;
            href = phoneNumberLink?.fields?.phoneNumber || '/';
            isPhone = true;
            break;
        }
        case 'product': {
            const productLink = link as IProduct;
            const productId = getContentfulProductIdsByFields(productLink?.fields)?.[0];
            const productDetailsPath = options?.productDetailsPagePaths?.find((path) => {
                if (options?.currentCategoryUrl) {
                    return (
                        path.productIds.includes(productId) &&
                        (path.nestedCategoryOrPDPPageUrl?.includes(options?.currentCategoryUrl) ||
                            path.menuCategoryUrl === options?.currentCategoryUrl)
                    );
                }
                return path.productIds.includes(productId);
            });
            href = productDetailsPath?.productPath || '/';
            break;
        }
        case 'documentLink': {
            const documentLink = link as IDocumentLink;

            href = documentLink?.fields?.document?.fields?.file?.url || '/';
            isExternal = true;
            break;
        }
        case 'socialMediaLink': {
            const socialMediaLink = link as ISocialMediaLink;
            href = socialMediaLink?.fields?.url || '/';
            isExternal = true;
            break;
        }
        default: {
            const pageLink = link as IPageLink;
            href = pageLink?.fields?.nameInUrl ? `/${pageLink.fields.nameInUrl}` : '/';
        }
    }

    return { href, isExternal, name, isPhone };
};
