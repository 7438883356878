import React, { FC } from 'react';
import globalPropsContext from '../common/contexts/globalPropsContext';
import contentfulData from '../contentfulData.json';
import { IGlobalContentfulProps } from '../common/services/globalContentfulProps';
import { useAppSelector } from '../redux/store';

const { Provider } = globalPropsContext;

const GlobalPropsProvider: FC = ({ children }) => {
    const globalPropsRedux = useAppSelector((state) => state.globalProps.data);

    return (
        <Provider value={globalPropsRedux || ((contentfulData as unknown) as IGlobalContentfulProps)}>
            {children}
        </Provider>
    );
};

export default GlobalPropsProvider;
