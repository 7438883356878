import { IDefaultModifier, ISelectedModifier } from '../../redux/types';
import { isWingTypeModifierGroup } from './isWingTypeModifierGroup';

interface ModificationParams {
    addedModifiers: ISelectedModifier[];
    removedDefaultModifiers: IDefaultModifier[];
    includeQuantity?: boolean;
    noItemProductIds: string[];
}

export const getModifications = ({
    addedModifiers,
    removedDefaultModifiers,
    includeQuantity,
    noItemProductIds = [],
}: ModificationParams): string => {
    const added = addedModifiers.map((i) => {
        if (isWingTypeModifierGroup(i) || noItemProductIds.includes(i.productId)) {
            return i.name;
        }
        const selectionPart = i.selection ? `(${i.selection})` : '';
        const quantityPart = includeQuantity ? ` x ${i.quantity}` : '';
        return `add ${i.name}${selectionPart}${quantityPart}`;
    });
    const removed = removedDefaultModifiers
        .filter((removedModifier) => {
            if (!removedModifier.relatedSelections) {
                return true;
            }

            const notPresentInAdded = (relatedSizeId: string): boolean =>
                !addedModifiers.find((added) => added.productId === relatedSizeId);

            return removedModifier.relatedSelections.every(notPresentInAdded);
        })
        .map((i) => `remove ${i.name}` + (i.selection ? `(${i.selection})` : ''));

    const result = added.concat(removed).join(', ');
    return result.charAt(0).toUpperCase() + result.slice(1);
};
