import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPersonalizedContent } from '../common/services/customerService/personalization';
import { FACTS_DEPENDENCIES } from '../common/constants/personalization';
import { getInitialDependencies } from '../common/helpers/personalization';
import { ICustomerPersonalizationResponseModel, ICustomerPersonalizationFactModel } from '../@generated/webExpApi';

const SLICE_NAME = 'personalization';

export interface IPersonalizationState {
    loading: boolean;
    error: boolean;
    actions: ICustomerPersonalizationResponseModel['actions']; // <actionRef, actionParamRef>
    dependencies: { [key in FACTS_DEPENDENCIES]?: { initialized: boolean } };
}

const initialState: IPersonalizationState = {
    loading: false,
    error: false,
    actions: {},
    dependencies: getInitialDependencies(),
};

const getActionsMap = createAsyncThunk(
    `${SLICE_NAME}/getActionsMap`,
    async (facts: Array<ICustomerPersonalizationFactModel>) => {
        return getPersonalizedContent(facts);
    }
);

const setActionsMapReducer = (
    state: IPersonalizationState,
    action: { payload: ICustomerPersonalizationResponseModel }
) => {
    return { ...state, loading: false, error: false, actions: action.payload.actions || {} };
};

const personalizationSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setLoading(state: IPersonalizationState, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        initializeDependency(state: IPersonalizationState, action: PayloadAction<FACTS_DEPENDENCIES>) {
            state.dependencies[action.payload].initialized = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getActionsMap.fulfilled, setActionsMapReducer);
        builder.addCase(getActionsMap.rejected, (state: IPersonalizationState, action) => {
            if (action?.error?.name !== 'AbortError') {
                state.error = true;
                state.loading = false;
            }
        });
        builder.addCase(getActionsMap.pending, (state: IPersonalizationState) => {
            state.loading = true;
        });
    },
});

export const actions = {
    ...personalizationSlice.actions,
    getActionsMap,
};

export default personalizationSlice.reducer;
