import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IMenuModel } from '../@generated/webExpApi';
import { getLocationMenu, getNationalMenu } from '../common/services/domainMenu';
import { GTM_ERROR_EVENT } from '../common/services/gtmService/constants';
import { GtmErrorCategory, GtmErrorEvent } from '../common/services/gtmService/types';
import * as NotificationsStore from './notifications';
import { saveDomainMenu } from './localStorage';

interface DomainMenuState {
    payload?: IMenuModel;
    loading: boolean;
    error: boolean;
    isLocationMenuError: boolean;
}

export const initialState: DomainMenuState = { loading: false, error: false, isLocationMenuError: false };
const sliceName = 'domainMenu';

interface FulfilledMeta {
    isLocationMenuError: boolean;
}

export const getPosMenuByLocation = createAsyncThunk<IMenuModel, string, { fulfilledMeta: FulfilledMeta }>(
    'menu/fetchByLocationId',
    async (locationId: string | undefined, { dispatch, fulfillWithValue }) => {
        if (locationId) {
            try {
                const response = await getLocationMenu(locationId);
                saveDomainMenu(response);
                return fulfillWithValue(response, { isLocationMenuError: false });
            } catch (e) {
                if (e.status === 404) {
                    const errorMessage =
                        'Online ordering is not available at this location. Please select another location.';
                    dispatch(
                        NotificationsStore.createEnqueueNotificationAction({
                            message: errorMessage,
                        })
                    );
                    const payload = {
                        ErrorCategory: GtmErrorCategory.CHECKOUT_UNAVAILABLE_LOCATION,
                        ErrorDescription: errorMessage,
                    } as GtmErrorEvent;

                    dispatch({ type: GTM_ERROR_EVENT, payload });
                } else {
                    dispatch(NotificationsStore.createEnqueueNotificationAction(e));
                }
            }
        }
        const response = await getNationalMenu();
        saveDomainMenu(response);
        return fulfillWithValue(response, { isLocationMenuError: !!locationId });
    }
);

const setDomainMenuReducer = (_: DomainMenuState, action: { payload: IMenuModel }) => {
    const payload = action.payload;

    return {
        payload,
        loading: false,
        error: false,
        isLocationMenuError: false,
    };
};

const domainMenuSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setDomainMenu: setDomainMenuReducer,
    },
    extraReducers: (builder) => {
        builder.addCase(getPosMenuByLocation.pending, () => {
            return {
                loading: true,
                error: false,
                isLocationMenuError: false,
            };
        });
        builder.addCase(getPosMenuByLocation.rejected, () => {
            return {
                loading: false,
                error: true,
                isLocationMenuError: true,
            };
        });
        builder.addCase(
            getPosMenuByLocation.fulfilled,
            (_: DomainMenuState, action: { payload: IMenuModel; meta: FulfilledMeta }) => {
                return {
                    payload: action.payload,
                    loading: false,
                    error: false,
                    isLocationMenuError: action.meta.isLocationMenuError,
                };
            }
        );
    },
});

export const { actions, name } = domainMenuSlice;

export default domainMenuSlice.reducer;
