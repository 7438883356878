import { addDays } from './dateTime';

export const getCookieValue = (cookieName: string): string => {
    const cookieValue = document.cookie
        ?.split('; ')
        ?.find((row) => row.startsWith(`${cookieName}=`))
        ?.split('=')[1];
    return cookieValue;
};

export const setCookieValue = (cookieName: string, cookieValue: string, expireInDays?: number) => {
    let cookie = `${cookieName}=${cookieValue}; path=/`;
    if (expireInDays) {
        const date = new Date();
        cookie = `${cookie};expires=${addDays(date, expireInDays).toUTCString()}`;
    }

    document.cookie = cookie;
};

export const clearCookie = (cookieName: string) => {
    setCookieValue(cookieName, '', 0);
};
