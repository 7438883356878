import { PDPTallyItem } from '../../redux/pdp';
import { PDPTallyItemModifier } from '../../redux/types';
import { isExtrasModifierGroup } from './isExtrasModifierGroup';

export const getSelectedExtrasTallyModifiers = (tallyItem: PDPTallyItem): PDPTallyItemModifier[] => {
    if (!tallyItem?.modifierGroups) {
        return [];
    }

    return tallyItem.modifierGroups
        .filter(isExtrasModifierGroup)
        .map((gr) => gr.modifiers)
        .flat()
        .filter((modifier) => modifier.quantity > 0);
};
