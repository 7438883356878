import { FetchRecommendedItemsRequest, WebExperienceApi } from '../../../@generated/webExpApi/apis/index';

import {
    ProductsRecommendationRequestModel,
    ProductsRecommendationResponseModel,
    SellingChannelNamesModel,
} from '../../../@generated/webExpApi';
import createErrorWrapper from '../createErrorWrapper';

const fetchRecommendedProducts = async (
    payload: ProductsRecommendationRequestModel
): Promise<ProductsRecommendationResponseModel> => {
    const expApi = new WebExperienceApi();

    const fetchRecommendedItemsApi = createErrorWrapper<
        ProductsRecommendationResponseModel,
        FetchRecommendedItemsRequest
    >('fetchRecommendedItems', expApi.fetchRecommendedItems.bind(expApi));

    return fetchRecommendedItemsApi({
        sellingChannel: SellingChannelNamesModel.Weboa,
        menuType: 'ALLDAY',
        productsRecommendationRequestModel: payload,
    });
};

export default fetchRecommendedProducts;
