import { ItemModel } from '../../@generated/webExpApi';
import { IDomainProductItem } from '../../redux/types';

/**
 * @method checkProductIsAvailable
 * @param {IDomainProductItem | ItemModel} domainProduct domain product
 * @author Andrei Shubich <ashubich@inspirebrands.com>
 * @added 2023-19-04
 * @version 1.0
 * @returns {boolean} IsAvailable
 */
export const checkProductIsAvailable = (domainProduct: IDomainProductItem | ItemModel): boolean => {
    return Boolean(domainProduct?.availability?.isAvailable);
};
