import {
    ItemModel,
    ItemModifierGroupModel,
    ItemModifierModel,
    TallyModifierGroupModel,
    TallyModifierModel,
    TallyProductModel,
} from '../../@generated/webExpApi';
import {
    getCalorieRangeFromProduct,
    getCaloriesFromProduct,
    getCheapestPriceFromProduct,
    getModifierPrice,
    isModifierItemGroup,
} from '../../lib/domainProduct';
import { RootState } from '../store';
import { selectDeal, selectLineItems } from './bag';
import getBrandInfo from '../../lib/brandInfo';
import {
    selectDefaultModifiers,
    selectItemGroup,
    selectItemModifierFromProduct,
    selectProductById,
    selectProducts,
} from './domainMenu';
import { selectCurrenOrderLocation } from './orderLocation';
import { PDPTallyItem } from '../pdp';
import { IDefaultModifier, ItemGroupEnum } from '../types';
import { selectOfferById } from './rewards';
import { DealsTypesMap } from '../../common/helpers/dealHelper';
import { selectComboPrice } from './domainMenu/selectComboPrice';

const findDomainModifierGroup = (
    tallyItemGroup: TallyModifierGroupModel,
    domainProduct: ItemModel
): ItemModifierGroupModel => {
    return domainProduct?.itemModifierGroups?.find((group) => group.productGroupId === tallyItemGroup.productId);
};

/**
 * Search for modifier inside modifier group by id
 * Works for plain modifiers as well as for modifiers inside item group
 * @param modifierId
 * @param domainModifierGroup
 * @returns found modifier
 */
const findDomainModifier = (modifierId: string, domainModifierGroup: ItemModifierGroupModel): ItemModifierModel => {
    const modifiers = domainModifierGroup?.itemModifiers || {};

    if (modifiers[modifierId]) {
        return modifiers[modifierId];
    }

    const flattenedModifiers = Object.values(modifiers).flatMap((mod) =>
        isModifierItemGroup(mod) ? mod.itemModifiers : [mod]
    );

    return flattenedModifiers.find((m) => m.itemId === modifierId);
};

const createTallyItemMap = (state: RootState, tallyItem: PDPTallyItem): Map<TallyProductModel, ItemModel> => {
    return (tallyItem?.childItems || [tallyItem]).reduce(
        (map: Map<TallyProductModel, ItemModel>, tallyItem: TallyProductModel) => {
            map.set(tallyItem, selectProductById(state, tallyItem?.productId));

            return map;
        },
        new Map<TallyProductModel, ItemModel>()
    );
};

interface ExtendedItemModifierModel extends ItemModifierModel {
    isFreeModifier?: boolean;
}

const createTallyItemModifiersMap = (
    tallyItem: TallyProductModel,
    domainProduct: ItemModel,
    defaultModifiers: IDefaultModifier[]
): Map<TallyModifierModel, ExtendedItemModifierModel> => {
    const modifiersMap = new Map<TallyModifierModel, ExtendedItemModifierModel>();

    tallyItem?.modifierGroups?.forEach((modifierGroup) => {
        const domainGroup = findDomainModifierGroup(modifierGroup, domainProduct);

        modifierGroup.modifiers?.forEach((modifier) => {
            const domainModifier: ItemModifierModel = findDomainModifier(modifier?.productId, domainGroup);

            if (domainModifier) {
                modifiersMap.set(modifier, {
                    ...domainModifier,
                    isFreeModifier: !!domainGroup?.freeQuantity,
                });
            }
        });
    });

    /*
     * handle case for unselected default modifiers
     * default modifiers should be presented on map as they affect calories calculation
     */
    const usedModifierIds = [...modifiersMap.keys()].map((modifier) => modifier.productId);
    defaultModifiers.forEach((defaultModifier) => {
        if (!usedModifierIds.includes(defaultModifier.productId)) {
            modifiersMap.set({ quantity: 0, price: 0, productId: defaultModifier.productId }, defaultModifier);
        }
    });
    return modifiersMap;
};

export const selectOfferProductPrice = (state: RootState, priceBeforeDiscount: number) => {
    const dealIdInBag = selectDeal(state);
    const selectOffer = selectOfferById(dealIdInBag);
    const offer = selectOffer(state);
    const dealType = DealsTypesMap[offer?.type];

    if (!dealType?.mainType) {
        return null;
    }

    return dealType.calculatePrice({
        regularPrice: priceBeforeDiscount,
        price: offer.applicability.price,
        percent: offer.applicability.percent,
        buyCount: offer.applicability.buyCount,
    });
};

const selectTallyItemPriceAndCalories = (
    state: RootState,
    tallyItem: PDPTallyItem,
    applyDiscount?: boolean,
    isComboParent?: boolean
): {
    price: number;
    calories: number;
    totalPrice: number;
    offerPrice?: number;
    calorieRange?: { min: string; max: string };
} => {
    let price = 0;
    let calories = 0;
    let totalPrice = 0;
    const location = selectCurrenOrderLocation(state);
    const domainProduct = selectProductById(state, tallyItem?.productId);

    if (tallyItem?.childItems?.length > 0) {
        price = selectComboPrice(state, tallyItem);

        totalPrice = price;

        if (price === 0) {
            totalPrice = tallyItem.price;
            price = tallyItem.price;
        }

        calories = tallyItem.childItems.reduce((calories, item) => {
            const product = selectProductById(state, item.productId);
            return calories + (product?.nutrition?.totalCalories || 0);
        }, 0);
    } else {
        const productPrice = isComboParent ? tallyItem?.price : getCheapestPriceFromProduct(domainProduct, location);
        price = applyDiscount ? productPrice || tallyItem?.price : domainProduct?.price?.currentPrice;
        calories = getCaloriesFromProduct(domainProduct);
        totalPrice = tallyItem?.price;
    }

    const offerPrice = selectOfferProductPrice(state, price);

    return { price, calories, totalPrice, offerPrice, calorieRange: getCalorieRangeFromProduct(domainProduct) };
};

const selectTallyItemModifiersPriceAndCalories = (
    state: RootState,
    tallyItem: PDPTallyItem
): { price: number; calories: number } => {
    const result = { price: 0, calories: 0 };
    const tallyItemMap = createTallyItemMap(state, tallyItem);

    tallyItemMap.forEach((domainProduct, item) => {
        const defaultModifiers = selectDefaultModifiers()(state, domainProduct?.id);
        const modifiersMap = createTallyItemModifiersMap(item, domainProduct, defaultModifiers);

        const domainProducts = selectProducts(state);
        modifiersMap.forEach((domainModifier, modifier) => {
            if (!domainModifier.isFreeModifier) {
                result.price += modifier.price * Math.max(modifier.quantity - (domainModifier.defaultQuantity || 0), 0);
            }
            result.calories +=
                domainProducts[modifier.productId]?.nutrition?.totalCalories *
                    (modifier.quantity - (domainModifier.defaultQuantity || 0)) || 0;
        });
    });

    return result;
};

export const selectTallyPriceAndCalories = (
    state: RootState,
    tallyItem: PDPTallyItem,
    applyDiscount?: boolean,
    isComboParent?: boolean
): {
    price: number;
    calories: number;
    totalPrice: number;
    offerPrice?: number;
    calorieRange?: { min: string; max: string };
} => {
    const { brandId } = getBrandInfo();
    if (brandId === 'Bww') return selectBwwTallyPriceAndCalories(state, tallyItem, applyDiscount);

    const {
        price: itemPrice,
        calories: itemCalories,
        calorieRange,
        totalPrice: itemTotalPice,
        offerPrice: itemOfferPrice,
    } = selectTallyItemPriceAndCalories(state, tallyItem, applyDiscount, isComboParent);
    const { price: modifiersPrice, calories: modifiersCalories } = selectTallyItemModifiersPriceAndCalories(
        state,
        tallyItem
    );

    const totalCalories = Number.isFinite(itemCalories) ? itemCalories + modifiersCalories : undefined;
    const offerPrice = Number.isFinite(itemOfferPrice) ? itemOfferPrice + modifiersPrice : itemOfferPrice;

    return {
        price: itemPrice + modifiersPrice,
        calories: totalCalories,
        calorieRange,
        totalPrice: itemTotalPice + modifiersPrice,
        offerPrice,
    };
};

export const selectBwwTallyPriceAndCalories = (
    state: RootState,
    tallyItem: PDPTallyItem,
    applyDiscount?: boolean
): { price: number; calories: number; totalPrice: number } => {
    const { price: itemPrice, calories: itemCalories, totalPrice: itemTotalPice } = selectTallyItemPriceAndCalories(
        state,
        tallyItem,
        applyDiscount
    );

    const { price: modifiersPrice, calories: modifiersCalories } = selectTallyItemModifiersPriceAndCalories(
        state,
        tallyItem
    );

    const nestedModifiersPriceAndCalories: {
        price: number;
        calories: number;
    } = tallyItem?.modifierGroups?.reduce(
        (result, group) => {
            const { calories: nestedModifiersCalories, price: nestedModifiersPrice } = group?.modifiers?.reduce(
                (resultForGroup, modifier) => {
                    const {
                        price: groupModifiersPrice,
                        calories: groupModifiersCalories,
                    } = selectTallyItemModifiersPriceAndCalories(state, modifier);

                    return {
                        calories: resultForGroup.calories + groupModifiersCalories,
                        price: resultForGroup.price + groupModifiersPrice,
                    };
                },
                { price: 0, calories: 0 }
            );

            return {
                calories: result.calories + nestedModifiersCalories,
                price: result.price + nestedModifiersPrice,
            };
        },
        { price: 0, calories: 0 }
    );

    const nestedModifiersCalories = nestedModifiersPriceAndCalories?.calories || 0;
    const nestedModifiersPrice = nestedModifiersPriceAndCalories?.price || 0;

    const itemGroupName = selectItemGroup(state, tallyItem.productId)?.name;

    const isBogo =
        itemGroupName &&
        (itemGroupName === ItemGroupEnum.BOGO ||
            itemGroupName === ItemGroupEnum.BOGO_50_OFF ||
            itemGroupName === ItemGroupEnum.BOG6);

    const totalCalories = Number.isFinite(itemCalories)
        ? (isBogo ? Number(itemCalories) * 2 : itemCalories) + modifiersCalories + nestedModifiersCalories
        : undefined;

    return {
        price: itemPrice + modifiersPrice + nestedModifiersPrice,
        calories: totalCalories,
        totalPrice: itemTotalPice + modifiersPrice + nestedModifiersPrice,
    };
};

interface ISelectTallyItemsWithPricesAndCalories extends TallyProductModel {
    productData: {
        price: number;
        calories: number;
        totalPrice: number;
        offerPrice?: number;
    };
}

export const selectTallyItemsWithPricesAndCalories = (
    state: RootState,
    tallyItems: TallyProductModel[],
    applyDiscount?: boolean
): ISelectTallyItemsWithPricesAndCalories[] => {
    return (tallyItems || []).map((item) => ({
        ...item,
        productData: {
            ...selectTallyPriceAndCalories(state, item, applyDiscount),
        },
    }));
};

const selectTallyItemGroupModifiersUpdatePrices = (
    state: RootState,
    tallyItem: TallyProductModel
): TallyModifierGroupModel[] => {
    const domainProducts = selectProducts(state);

    return tallyItem?.modifierGroups?.map(
        (group): TallyModifierGroupModel => ({
            ...group,
            modifiers: group.modifiers?.map(
                (modifier): TallyModifierModel => {
                    const domainModifier = selectItemModifierFromProduct(
                        state,
                        tallyItem.productId,
                        modifier.productId
                    );

                    return { ...modifier, price: getModifierPrice(domainModifier, domainProducts[modifier.productId]) };
                }
            ),
        })
    );
};

const selectTallyItemUpdatePriceForChild = (
    state: RootState,
    tallyItem: TallyProductModel,
    childTallyItem: TallyProductModel
): TallyProductModel => {
    const childProduct = selectProductById(state, childTallyItem.productId);
    const childItemModifier = selectItemModifierFromProduct(state, tallyItem.productId, childTallyItem.productId);

    return {
        ...childTallyItem,
        price: getModifierPrice(childItemModifier, childProduct),
        modifierGroups: selectTallyItemGroupModifiersUpdatePrices(state, childTallyItem),
    };
};

export function selectTallyItemUpdatePrices(state: RootState, tallyItem: TallyProductModel): TallyProductModel {
    const domainProduct = selectProductById(state, tallyItem.productId);
    const location = selectCurrenOrderLocation(state);

    if (tallyItem?.childItems?.length > 0) {
        return {
            ...tallyItem,
            price: getCheapestPriceFromProduct(domainProduct, location),
            childItems: tallyItem.childItems.map((item) => selectTallyItemUpdatePriceForChild(state, tallyItem, item)),
        };
    }

    return {
        ...tallyItem,
        price: getCheapestPriceFromProduct(domainProduct, location),
        modifierGroups: selectTallyItemGroupModifiersUpdatePrices(state, tallyItem),
    };
}

export const selectTallyUpdateBagItems = (state: RootState): TallyProductModel[] => {
    const LineItems = selectLineItems(state);

    return LineItems?.map((LineItem) => selectTallyItemUpdatePrices(state, LineItem));
};
