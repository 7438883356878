import { DeepPartial } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
// Follows Dan Abramov's (Redux author) recommendation:
// https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage

import { RootState } from './store';
import { MENU_VERSION } from '../common/constants/menuVersion';
import { OrderLocationMethod } from './orderLocation';
import isEmpty from 'lodash/isEmpty';
import { getCountryCodeFromLocale, getLocale } from '../common/helpers/locale';
import { US_DEFAULT_LOCALE } from '../common/constants/configDefaults';
import { compress, decompress } from 'lz-string';
import logger from '../common/services/logger';

// TODO add versioning inside to use updating logging/mechanism to prevent edge cases to grow uncontrollably
export const loadState = (initialState): DeepPartial<RootState> => {
    try {
        const state = localStorage.getItem(getStateKeyName());

        if (state === null) {
            return undefined;
        }

        const localStorageState = JSON.parse(state);

        const decompressedDomainMenu = localStorageState.domainMenu
            ? JSON.parse(decompress(localStorageState.domainMenu))
            : initialState.domainMenu;

        localStorageState.domainMenu = decompressedDomainMenu || initialState.domainMenu;

        // TODO: remove later
        if (Array.isArray(localStorageState?.domainMenu?.payload?.products)) {
            delete localStorageState.domainMenu.payload;
        }

        // @depricated - TODO: remove later, menu 2.0 migration has been completed
        if (localStorageState?.bag?.LineItems?.some((item) => /^ARBYS-WEBOA/i.test(item.productId))) {
            localStorageState.bag.LineItems = [];
        }

        // migrating old localStorageState location info to new state(after adding delivery address)
        if (localStorageState?.location) {
            // persist saved store after location v2 migration
            if (localStorageState?.location.storeId) {
                localStorageState.location.id = localStorageState.location.storeId;
            }

            localStorageState.orderLocation = {
                /**
                 * previously we have only pickup address.
                 * So "localStorageState.location" existence means user selected PICKUP address previously.
                 */
                method: OrderLocationMethod.PICKUP,
                pickupAddress: { ...localStorageState.location },
                deliveryAddress: null,
            };
            localStorageState.location = null;
        }

        //cleanup obsolete version of delivery location structure
        const deliveryLocationHoursByDay =
            localStorageState?.orderLocation?.deliveryAddress?.pickUpLocation?.hoursByDay;
        if (deliveryLocationHoursByDay && !isEmpty(deliveryLocationHoursByDay)) {
            const daysOfWeek = Object.keys(deliveryLocationHoursByDay);

            if (daysOfWeek.length > 0 && deliveryLocationHoursByDay[daysOfWeek[0]].startTime) {
                localStorageState.orderLocation.deliveryAddress = null;
                localStorageState.orderLocation.method = localStorageState.orderLocation.pickupAddress
                    ? OrderLocationMethod.PICKUP
                    : OrderLocationMethod.NOT_SELECTED;
            }
        }

        // clean up bag if menu version changed
        const version = localStorageState?.bag?.version;
        if (typeof version !== 'undefined') {
            const menuVersion = parseInt(version);

            localStorageState.bag.version = isNaN(menuVersion) ? MENU_VERSION : menuVersion;
            if (localStorageState.bag.version < MENU_VERSION) {
                localStorageState.bag.LineItems = [];
                localStorageState.bag.version = MENU_VERSION;
            }
        }

        // merge with new empty object to avoid rewriting initialState object
        return merge({}, initialState, localStorageState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const currentState = localStorage.getItem(getStateKeyName());
        const stateToSave = !currentState
            ? state
            : {
                  ...state,
                  domainMenu: JSON.parse(currentState).domainMenu,
              };

        const serializedState = JSON.stringify(stateToSave);
        localStorage.setItem(getStateKeyName(), serializedState);
    } catch (err) {
        logger.logError('Error while saving state into localStorage', err);
    }
};

/*TODO This is a temporal workaround so it will work with Browsers that support less storage in Local Storage in example Safari.
    Final Solution will be provided in this story DBBP-68414, once the long term solution is implemented this code needs to be removed as
    well as all its usages and unit test.
*/
export const saveDomainMenu = (domainMenu) => {
    try {
        const currentState = localStorage.getItem(getStateKeyName());
        let stateToSave = {
            domainMenu: compress(JSON.stringify({ error: false, loading: false, payload: domainMenu })),
        };
        if (currentState !== null) {
            const parsedCurrentState = JSON.parse(currentState);
            stateToSave = {
                ...parsedCurrentState,
                ...stateToSave,
            };
        }
        localStorage.setItem(getStateKeyName(), JSON.stringify(stateToSave));
    } catch (error) {
        logger.logEvent('Error trying to save compressed Menu into localStorage', error);
    }
};

const getStateKeyName = (): string => {
    const locale = getLocale();
    return locale === US_DEFAULT_LOCALE ? 'state' : `state_${getCountryCodeFromLocale(locale)}`;
};
