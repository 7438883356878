import { createSelector } from '@reduxjs/toolkit';
import {
    getCaloriesFromProduct,
    getModifierPrice,
    getQuantityByProductIdAndTallyItem,
    getModifierQuantityForSingleProduct,
    isModifierVisible,
    getCheapestPriceFromProduct,
    getTallyModifierGroupModifiersCount,
    getRelatedModifierQuantityForSingleProduct,
    isModifierItemGroup,
    getDefaultNestedModifier,
    isNoneActionCode,
    INTERNAL_REGULAR_MODIFIER_ID,
    getDefaultSizeModifier,
} from '../../lib/domainProduct';
import { RootState } from '../store';
import {
    IDisplayFullProduct,
    IDisplayModifierGroup,
    IDisplayProductSection,
    IDisplayProduct,
    ProductTypesEnum,
    IExtraProductGroup,
    IExtraItemModel,
    ProductIntensityEnum,
    ISaucesDisplayModifierGroup,
    ISelectedExtraItem,
    ModifierGroupType,
    IDomainProductItem,
    IDefaultModifier,
    ItemGroupEnum,
    ActionCodesEnum,
    PDPTallyItemModifierGroup,
    PDPTallyItemModifier,
    IDealTallyItem,
    IProducts,
} from '../types';
import {
    selectProducts,
    selectCategoriesByProductId,
    selectProductGroups,
    selectProductIsCombo,
    selectProductById,
    selectProductGroupById,
    selectProductGroupByProductId,
    selectProductSizes,
    selectSizeGroup,
    selectComboMainProduct,
    selectProductIsPromo,
    selectModifierRelatedSelections,
    selectProductGroup,
    selectItemModifierGroup,
    selectBwwIsModifierOnSide,
    selectModifierGroupsByProductId,
    selectNoSauceDomainProduct,
    selectDefaultTallyModifierGroups,
    selectBwwIsModifierNoSauce,
    selectBwwIsRecommendedProduct,
    selectProductsByIds,
    isProductSizeGrouped,
    selectProductsWithRootProductId,
    ItemModelRootProductId,
    selectItemGroupById,
    selectRootProductByItemId,
} from './domainMenu';

import { selectCurrenOrderLocation } from './orderLocation';

import { PDPState, PDPTallyItem } from '../pdp';
import { IProductGroupModel, ItemModel, ItemModifierGroupModel, ItemModifierModel } from '../../@generated/webExpApi';
import { selectState } from './state';
import { selectTallyPriceAndCalories, selectBwwTallyPriceAndCalories } from './tally';
import { isWingTypeModifierGroup } from '../../common/helpers/isWingTypeModifierGroup';
import { selectDeal } from './bag';
import { selectOfferById } from './rewards';
import { DealsTypesMap, MainDealsTypesByStructure } from '../../common/helpers/dealHelper';
import { selectDealTallyItemById } from './dealPdp';
import { IPriceAndCalories } from '../../lib/tallyItem';
import { isWhatsOnItModifierGroup } from '../../common/helpers/isWhatsOnItModifierGroup';
import { getSelectedExtrasTallyModifiers } from '../../common/helpers/getSelectedExtrasTallyModifiers';
import { isExtrasModifierGroup } from '../../common/helpers/isExtrasModifierGroup';
import { isNotExtrasModifierGroup } from '../../common/helpers/isNotExtrasModifierGroup';
import { selectDefaultChildPriceForCombo } from '../../multiBrand/redux/selectors/domainMenu/sdi.selectDefaultChildPriceForCombo';
import { IGlobalContentfulProps } from '../../common/services/globalContentfulProps';
import { isUndefined } from '../../common/helpers/isUndefined';
import { checkProductIsAvailable } from '../../common/helpers/checkProductIsAvailable';

const selectPdp = (state: RootState): PDPState => state.pdp;

const selectModifierIsAvailable = (state: RootState, id: string): boolean =>
    checkProductIsAvailable(selectProductById(state, id));

const selectModifierIsAvailableWithPlu = (state: RootState, id: string): boolean => {
    const product = selectProductById(state, id);
    return product?.metadata?.PLU !== undefined ? checkProductIsAvailable(product) : true;
};

export const selectPDPTallyItem = createSelector(selectPdp, (pdp: PDPState): PDPTallyItem => pdp.tallyItem);

export const getProductGroupType = (
    displayName: string,
    group?: IProductGroupModel
): IDisplayProductSection['productSectionType'] => {
    if (isWingTypeModifierGroup(group)) {
        return ModifierGroupType.WINGTYPE;
    }

    const lowerName = displayName.toLowerCase();
    if (lowerName.includes('drink') || lowerName.includes('shake')) {
        return 'drink';
    }
    if (lowerName.includes('side')) {
        return 'side';
    }

    // do not remove space from 'sandwich '
    if (lowerName.includes('promo') || lowerName.includes('sandwich ')) {
        return 'promo';
    }

    if (lowerName.includes('complimentary')) {
        return 'complimentary';
    }

    if (lowerName.includes('item')) {
        return 'item';
    }

    if (lowerName.includes('sauce')) {
        return 'sauce';
    }

    return 'main';
};

export const selectPDPProduct = createSelector(selectPDPTallyItem, selectProducts, (tallyItem, products) => {
    return products[tallyItem?.productId] || ({} as ItemModel); // TODO figure out how better handle empty returns (SSR)
});

const selectPDPProductWithMenuItem = createSelector(
    selectPDPTallyItem,
    selectProductsWithRootProductId,
    (tallyItem, products) => {
        return products[tallyItem?.productId] || ({} as ItemModelRootProductId);
    }
);

export type DisplayModifierGroupMap = { [key: string]: IDisplayModifierGroup };

export const selectDisplayModifierGroupsByProductIdAndProductGroupId = createSelector(
    (state: RootState, productId: string, productGroupId: string, sectionIndex: number): IDisplayModifierGroup[] => {
        const product = selectProductById(state, productId);
        const productGroup = selectProductGroupById(state, productGroupId);
        const products = selectProducts(state);
        const productGroups = selectProductGroups(state);
        const isCombo = selectProductIsCombo(state, productId);
        const isPromo = selectProductIsPromo(state, productId);
        const pdpTallyItem = selectPDPTallyItem(state);
        const dealTallyItem = selectDealTallyItemById(state, sectionIndex, productId);

        const tallyItem = dealTallyItem || pdpTallyItem;

        if (!productGroup || !product) return [];

        const bySequence = (a: { sequence: number }, b: { sequence: number }) => a.sequence - b.sequence;

        const groupToDisplayGroup = (productId, group: ItemModifierGroupModel): IDisplayModifierGroup => {
            return {
                displayName: productGroups[group.productGroupId].name,
                minQuantity: group.min,
                maxQuantity: group.max,
                sequence: productGroups[group.productGroupId].sequence,
                modifierGroupId: group.productGroupId,
                modifiers: Object.values(group.itemModifiers || {})
                    .filter(isModifierVisible)
                    .filter((item) => selectModifierIsAvailable(state, item.itemId))
                    .sort(bySequence)
                    .reduce<IDisplayProduct[]>((acc, itemModifier) => {
                        const currentProduct = products[itemModifier.itemId];
                        const relatedSelections = selectModifierRelatedSelections(
                            state,
                            productId,
                            itemModifier.itemId
                        );

                        if (!relatedSelections) {
                            return acc.concat({
                                displayName: currentProduct.name,
                                displayProductDetails: {
                                    calories: getCaloriesFromProduct(currentProduct),
                                    defaultQuantity: itemModifier.defaultQuantity,
                                    displayName: currentProduct.name,
                                    minQuantity: itemModifier.min,
                                    maxQuantity: itemModifier.max,
                                    price: getModifierPrice(itemModifier, currentProduct),
                                    productId: currentProduct.id,
                                    quantity: getQuantityByProductIdAndTallyItem(
                                        currentProduct.id,
                                        tallyItem,
                                        sectionIndex
                                    ),
                                },
                            });
                        }

                        const allSelections = relatedSelections.concat(itemModifier).sort(bySequence);
                        const isPresent = acc.find((item) =>
                            allSelections.some((it) => it.itemId === item.displayProductDetails.productId)
                        );
                        if (isPresent) {
                            return acc;
                        }

                        const selectedModifier = allSelections.find((it) =>
                            getQuantityByProductIdAndTallyItem(it.itemId, tallyItem, sectionIndex)
                        );
                        const defaultModifier = allSelections.find((it) => it.defaultQuantity) || allSelections[0];

                        const activeSelectionItemModifier = selectedModifier || defaultModifier;
                        const activeSelectionProduct = selectProductById(state, activeSelectionItemModifier.itemId);

                        return acc.concat({
                            displayName: activeSelectionProduct.name,
                            displayProductDetails: {
                                calories: getCaloriesFromProduct(activeSelectionProduct),
                                defaultQuantity: activeSelectionItemModifier.defaultQuantity,
                                displayName: activeSelectionProduct.name,
                                minQuantity: activeSelectionItemModifier.min,
                                maxQuantity: activeSelectionItemModifier.max,
                                price: getModifierPrice(activeSelectionItemModifier, activeSelectionProduct),
                                productId: activeSelectionProduct.id,
                                quantity: getQuantityByProductIdAndTallyItem(
                                    activeSelectionProduct.id,
                                    tallyItem,
                                    sectionIndex
                                ),
                                selections: allSelections
                                    .map((item) => {
                                        const product = selectProductById(state, item.itemId);
                                        return {
                                            sizeGroup: selectSizeGroup(state, product.id),
                                            product,
                                            disabled: !checkProductIsAvailable(product),
                                            isGroupedBySize: isProductSizeGrouped(state, product.id),
                                        };
                                    })
                                    .sort((a, b) => a.sizeGroup.sequence - b.sizeGroup.sequence),
                            },
                        });
                    }, []),
            };
        };

        const byModifiersLength = (group: IDisplayModifierGroup) => group.modifiers.length;

        if (!isCombo && !isPromo) {
            return product.itemModifierGroups
                ?.map((group) => groupToDisplayGroup(product.id, group))
                .filter(byModifiersLength)
                .sort(bySequence);
        } else {
            const groupToProcess = product?.itemModifierGroups.find(
                (group) => group.productGroupId === productGroup.id
            );
            const productGroupType = getProductGroupType(productGroup?.name || '');

            if (productGroupType === 'main') {
                const mainProduct = products[groupToProcess.defaultItemModifierId];
                return mainProduct.itemModifierGroups
                    .map((group) => groupToDisplayGroup(mainProduct.id, group))
                    .filter(byModifiersLength)
                    .sort(bySequence);
            } else {
                const result = Object.values(groupToProcess.itemModifiers)
                    .sort(bySequence)
                    .filter(
                        (item) => (isPromo || isModifierVisible(item)) && selectModifierIsAvailable(state, item.itemId)
                    )
                    .reduce<DisplayModifierGroupMap>((prev, current) => {
                        const currentProduct = products[current.itemId];
                        const displayProduct = {
                            displayName: currentProduct.name,
                            displayProductDetails: {
                                calories: getCaloriesFromProduct(currentProduct),
                                defaultQuantity: 0,
                                displayName: currentProduct.name,
                                minQuantity: current.min,
                                maxQuantity: current.max,
                                price: getModifierPrice(current, currentProduct),
                                productId: currentProduct.id,
                                quantity: getQuantityByProductIdAndTallyItem(
                                    currentProduct.id,
                                    tallyItem,
                                    sectionIndex
                                ),
                            },
                        };

                        if (!prev[currentProduct.productGroupId]) {
                            return {
                                ...prev,
                                [currentProduct.productGroupId]: {
                                    displayName: productGroups[currentProduct.productGroupId].name,
                                    minQuantity: 0,
                                    sequence: productGroups[currentProduct.productGroupId].sequence,
                                    maxQuantity: 20,
                                    modifierGroupId: productGroups[currentProduct.productGroupId].id,
                                    modifiers: [displayProduct],
                                },
                            };
                        }

                        return {
                            ...prev,
                            [currentProduct.productGroupId]: {
                                ...prev[currentProduct.productGroupId],
                                modifiers: [...prev[currentProduct.productGroupId].modifiers, displayProduct],
                            },
                        };
                    }, {});

                return Object.values(result).filter(byModifiersLength);
            }
        }
    },
    (displayModifierGroups) => displayModifierGroups
);

const selectMainProductSectionName = (state: RootState): string => {
    const pdpProduct = selectPDPProduct(state);

    if (!pdpProduct?.id) {
        return null;
    }

    const isCombo = selectProductIsCombo(state, pdpProduct.id);
    if (isCombo) {
        const mainProduct = selectComboMainProduct(state, pdpProduct.id);
        const productGroup = selectProductGroupByProductId(state, mainProduct.id);

        return productGroup.name;
    }

    const productGroup = selectProductGroupByProductId(state, pdpProduct.id);

    return productGroup?.name;
};

export const selectMainComboDisplayProductById = (state: RootState, id: string): IDisplayProduct => {
    if (!id) return null;
    const products = selectProducts(state);
    const product = products[id];
    const selections = selectProductSizes(state, id);

    return {
        displayName: product.name,
        displayProductDetails: {
            productId: product.id,
            calories: product?.nutrition?.totalCalories || 0,
            displayName: product.name,
            quantity: 1, // by default for main combo product
            selections,
        },
    };
};

export const selectProductType = (state: RootState): ProductTypesEnum => {
    const tallyItem = selectPDPTallyItem(state);
    const isCombo = selectProductIsCombo(state, tallyItem?.productId);
    const isPromo = selectProductIsPromo(state, tallyItem?.productId);

    if (isCombo) {
        return ProductTypesEnum.Meal;
    }

    if (isPromo) {
        return ProductTypesEnum.Promo;
    }

    return ProductTypesEnum.Single;
};

export const selectDisplayProduct = createSelector(
    selectState,
    selectPDPTallyItem,
    selectPDPProduct,
    selectProducts,
    selectProductGroups,
    selectMainProductSectionName,
    selectProductType,
    (state, tallyItem, product, products, productGroups, mainProductSectionName, productType): IDisplayFullProduct => {
        const { price, calories, totalPrice, offerPrice } = selectTallyPriceAndCalories(state, tallyItem, true);
        const dealIdInBag = selectDeal(state);
        const selectOffer = selectOfferById(dealIdInBag);
        const offer = selectOffer(state);
        const dealType = DealsTypesMap[offer?.type]?.mainType;

        const isEligibleForSingleProductOffer =
            dealType === MainDealsTypesByStructure.BuyX &&
            offer?.applicability?.eligibleIds?.length === 1 &&
            offer?.applicability?.eligibleIds[0]?.menuId === tallyItem?.productId &&
            offer?.applicability?.buyCount === 1;

        const result = {
            productType,
            displayName: product.name,
            productId: product.id,
            mainProductId: product.id,
            mainProductSectionName,
            productSections: [],
            price,
            totalPrice,
            calories,
            offerPrice: isEligibleForSingleProductOffer ? offerPrice : null,
        };

        if (productType === ProductTypesEnum.Meal || productType === ProductTypesEnum.Promo) {
            const productSections: IDisplayProductSection[] =
                product.itemModifierGroups &&
                product.itemModifierGroups.map((item) => ({
                    productSectionType: getProductGroupType(productGroups[item.productGroupId].name),
                    productSectionDisplayName: productGroups[item.productGroupId].name,
                    productGroupId: item.productGroupId,
                }));

            return {
                ...result,
                productSections,
            };
        }

        if (productType === ProductTypesEnum.Single) {
            const productSections: IDisplayProductSection[] = [
                {
                    productSectionType: 'main',
                    productSectionDisplayName: mainProductSectionName,
                    productGroupId: product.productGroupId,
                },
            ];

            return {
                ...result,
                productSections,
            };
        }

        return result;
    }
);

const bySequence = (a: { sequence: number }, b: { sequence: number }) => a.sequence - b.sequence;
const isRequired = (a: { min: number }, b: { min: number }) => b.min - a.min;

export const selectBwwDisplayProduct = createSelector(
    selectState,
    selectPDPTallyItem,
    selectPDPProductWithMenuItem,
    selectProductGroups,
    selectMainProductSectionName,
    selectProductType,
    (state, tallyItem, product, productGroups, mainProductSectionName, productType): IDisplayFullProduct => {
        const { price, calories, totalPrice } = selectBwwTallyPriceAndCalories(state, tallyItem, true);

        const result = {
            productType,
            displayName: product.menuItemName || product.name,
            productId: product.id,
            mainProductId: product.id,
            mainProductSectionName,
            productSections: [],
            price,
            totalPrice,
            calories,
        };

        const productSections: IDisplayProductSection[] =
            product.itemModifierGroups &&
            product.itemModifierGroups
                .filter(isNotExtrasModifierGroup)
                .sort(bySequence)
                .map((item) => ({
                    productSectionType: getProductGroupType(productGroups[item.productGroupId].name, item),
                    productSectionDisplayName: productGroups[item.productGroupId].name,
                    productGroupId: item.productGroupId,
                }));

        return {
            ...result,
            productSections,
        };
    }
);

export const selectSdiDisplayProduct = createSelector(
    selectState,
    (_state: RootState, childIndex?: number) => childIndex,
    selectPDPTallyItem,
    selectProductsWithRootProductId,
    selectProductGroups,
    selectMainProductSectionName,
    (state, childIndex, tallyItem, products, productGroups, mainProductSectionName): IDisplayFullProduct => {
        const isCombo = selectProductIsCombo(state, tallyItem.productId);

        if (!isUndefined(childIndex) && !isCombo) return null;

        const tallyProduct = !isUndefined(childIndex) ? tallyItem.childItems[childIndex] : tallyItem;

        if (!tallyProduct) return null;

        const product = products[tallyProduct.productId] || ({} as ItemModelRootProductId);

        const { price, calories, totalPrice, calorieRange } = selectTallyPriceAndCalories(state, tallyProduct, true);

        const productType = product.hasChildItems ? ProductTypesEnum.Meal : ProductTypesEnum.Single;
        const result = {
            productType,
            displayName: product.name,
            productId: product.id,
            mainProductId: product.id,
            mainProductSectionName,
            productSections: [],
            price,
            totalPrice,
            calories,
            calorieRange,
        };

        const itemModifierGroups = [...(product.itemModifierGroups || [])];
        const relatedItemGroups = product.relatedItemGroups || [];

        const productSections: IDisplayProductSection[] = itemModifierGroups
            .sort(bySequence)
            .map((item) => ({
                productSectionType: getProductGroupType(productGroups[item.productGroupId]?.name, item),
                productSectionDisplayName: productGroups[item.productGroupId]?.name,
                productGroupId: item.productGroupId,
            }))
            .concat(
                relatedItemGroups.map((item) => ({
                    productSectionType: ModifierGroupType.CONDIMENTS,
                    productSectionDisplayName: productGroups[item.productGroupId]?.name,
                    productGroupId: item.productGroupId,
                }))
            );

        return {
            ...result,
            productSections,
        };
    }
);

export const selectBwwRegularSauces = (state: RootState, modifierGroupId: string): ItemModifierModel[] => {
    const tallyItem = selectPDPTallyItem(state);
    const group = selectItemModifierGroup(state, tallyItem.productId, modifierGroupId);

    return Object.values(group.itemModifiers || {}).filter((it) => !selectBwwIsModifierOnSide(state, it.itemId));
};

export const selectBwwOnSideSauces = (state: RootState, modifierGroupId: string): ItemModifierModel[] => {
    const tallyItem = selectPDPTallyItem(state);
    const group = selectItemModifierGroup(state, tallyItem.productId, modifierGroupId);

    return Object.values(group.itemModifiers || {}).filter(
        (it) => selectBwwIsModifierOnSide(state, it.itemId) || selectBwwIsModifierNoSauce(state, it.itemId)
    );
};

export const selectBwwSauceDisplayModifierGroup = (
    state: RootState,
    modifierGroupId: string
): ISaucesDisplayModifierGroup => {
    const tallyItem = selectPDPTallyItem(state);
    const { productId } = tallyItem;
    const group = selectItemModifierGroup(state, productId, modifierGroupId);
    const productGroup = selectProductGroupById(state, group.productGroupId);

    const allItemModifiers = Object.values(group.itemModifiers || {});

    const hasSauceOnTheSideOption = allItemModifiers.some((it) => {
        return selectBwwIsModifierOnSide(state, it.itemId);
    });

    const currentModifierGroup = tallyItem.modifierGroups?.find((it) => it.productId === modifierGroupId);

    const isOnSideChecked = currentModifierGroup?.isOnSideChecked;

    let itemModifiers: ItemModifierModel[] = [];

    if (hasSauceOnTheSideOption) {
        if (isOnSideChecked) {
            itemModifiers = selectBwwOnSideSauces(state, modifierGroupId);
        } else {
            itemModifiers = selectBwwRegularSauces(state, modifierGroupId);
        }
    } else {
        itemModifiers = allItemModifiers;
    }

    const numberOfFreeModifiers = group.freeQuantity;
    const isGroupWithFreeModifiers = !!numberOfFreeModifiers;
    const maxQuantity =
        isGroupWithFreeModifiers && group.max > numberOfFreeModifiers ? numberOfFreeModifiers : group.max;

    return {
        displayName: productGroup.name,
        minQuantity: group.min,
        maxQuantity,
        sequence: productGroup.sequence,
        modifierGroupId: group.productGroupId,
        hasOnSideOption: hasSauceOnTheSideOption,
        isOnSideChecked,
        isOnSideOptionDisabled: currentModifierGroup?.isOnSideOptionDisabled,
        modifiers: itemModifiers
            .filter(isModifierVisible)
            .filter((item) => selectModifierIsAvailable(state, item.itemId))
            .sort(bySequence)
            .map((itemModifier) => {
                const modifierProduct = selectProductById(state, itemModifier.itemId);
                const isRecommended = selectBwwIsRecommendedProduct(state, itemModifier.itemId);

                const price = isGroupWithFreeModifiers ? 0 : getModifierPrice(itemModifier, modifierProduct);

                return {
                    displayName: modifierProduct.name,
                    displayProductDetails: {
                        calories: getCaloriesFromProduct(modifierProduct),
                        defaultQuantity: itemModifier.defaultQuantity,
                        displayName: modifierProduct.name,
                        minQuantity: itemModifier.min,
                        maxQuantity: itemModifier.max,
                        price,
                        productId: modifierProduct.id,
                        quantity: getModifierQuantityForSingleProduct(modifierProduct.id, tallyItem),
                        intensity: selectSauceIntensity(state, itemModifier.itemId),
                        isRecommended,
                    },
                };
            }),
    };
};

export const selectBwwDisplayModifierGroup = (
    state: RootState,
    tallyItem: PDPTallyItem,
    modifierGroupId: string,
    parentModifierGroupId?: string,
    parentModifierId?: string
): IDisplayModifierGroup => {
    const isNestedGroup = parentModifierGroupId && parentModifierId;

    const modifierId = isNestedGroup ? parentModifierId : tallyItem.productId;

    const group = selectItemModifierGroup(state, modifierId, modifierGroupId);

    const productGroup = selectProductGroupById(state, group?.productGroupId);

    const numberOfFreeModifiers = group.freeQuantity;
    const isGroupWithFreeModifiers = !!numberOfFreeModifiers;
    const maxQuantity =
        isGroupWithFreeModifiers && group.max > numberOfFreeModifiers ? numberOfFreeModifiers : group.max;

    return {
        displayName: productGroup.name,
        minQuantity: group.min,
        maxQuantity,
        sequence: productGroup.sequence,
        modifierGroupId: group.productGroupId,
        modifiers: Object.values(group.itemModifiers || {})
            .filter((item) => selectModifierIsAvailable(state, item.itemId))
            .sort(bySequence)
            .map((itemModifier) => {
                const modifierProduct = selectProductById(state, itemModifier.itemId);

                const tallyModifierGroup = tallyItem?.modifierGroups.find(
                    (group) => group.productId === modifierGroupId
                );

                const tallyModifier = tallyModifierGroup?.modifiers.find(
                    (modifier) => modifier.productId === itemModifier.itemId
                );

                const priceAndCalories = tallyModifier
                    ? selectBwwTallyPriceAndCalories(state, tallyModifier, true)
                    : {
                          price: getModifierPrice(itemModifier, modifierProduct),
                          calories: getCaloriesFromProduct(modifierProduct),
                      };

                const price = isGroupWithFreeModifiers ? 0 : priceAndCalories.price;

                return {
                    displayName: modifierProduct.name,
                    displayProductDetails: {
                        calories: priceAndCalories.calories,
                        defaultQuantity: itemModifier.defaultQuantity,
                        displayName: modifierProduct.name,
                        minQuantity: itemModifier.min,
                        maxQuantity: itemModifier.max,
                        price,
                        productId: modifierProduct.id,
                        quantity: getModifierQuantityForSingleProduct(
                            modifierProduct.id,
                            tallyItem,
                            parentModifierGroupId,
                            parentModifierId
                        ),
                    },
                };
            }),
    };
};

export const selectBwwDisplayModifiersGroups = (
    state: RootState,
    tallyItem: PDPTallyItem,
    parentModifierGroupId?: string,
    parentModifierId?: string
): IDisplayModifierGroup[] => {
    const isNestedGroups = parentModifierGroupId && parentModifierId;
    const groups = selectModifierGroupsByProductId(state, isNestedGroups ? parentModifierId : tallyItem.productId);

    return groups
        .filter(isNotExtrasModifierGroup)
        .map((group) =>
            selectBwwDisplayModifierGroup(
                state,
                tallyItem,
                group.productGroupId,
                parentModifierGroupId,
                parentModifierId
            )
        )
        .filter((group) => group.modifiers.length)
        .sort(bySequence);
};

const isModifierADefault = (modifierId: string, defaultModifiers: IDefaultModifier[]) => {
    return !!defaultModifiers.find((defaultModifier) => modifierId === defaultModifier.productId);
};

export const selectBwwNewModifierGroups = (
    state: RootState,
    modifierGroupId: string,
    modifierId: string,
    quantity: number,
    modifierGroups: PDPTallyItemModifierGroup[],
    defaultModifiers: IDefaultModifier[],
    parentModifierGroupId?: string,
    parentModifierId?: string
): PDPTallyItemModifierGroup[] => {
    const location = selectCurrenOrderLocation(state);
    const tallyItem = selectPDPTallyItem(state);
    const modifierGroup = selectBwwDisplayModifierGroup(
        state,
        tallyItem,
        modifierGroupId,
        parentModifierGroupId,
        parentModifierId
    );

    const { minQuantity, maxQuantity } = modifierGroup;
    const noSauceDomainProduct = selectNoSauceDomainProduct(state, modifierGroup.modifiers);

    const products = selectProducts(state);

    return modifierGroups.map((group) => {
        if (group.productId !== modifierGroupId) {
            return group;
        }

        const currentModifer = group.modifiers?.find((modifier) => modifier.productId === modifierId);
        const isRadioButton = minQuantity <= 1 && maxQuantity === 1;
        const isCheckBox = maxQuantity > 1;
        const isCurrentExist = !!currentModifer;
        const isNoSauce = modifierId === noSauceDomainProduct?.id;
        const isNoSauceSelected = !!group.modifiers?.find(
            (modifier) => modifier.productId === noSauceDomainProduct?.id
        );
        const modifierProduct = products[modifierId];

        if (isWingTypeModifierGroup(group)) {
            if (!modifierId) {
                return {
                    ...group,
                    modifiers: [],
                };
            }

            return {
                ...group,
                modifiers: [
                    {
                        productId: modifierId,
                        quantity: 1,
                        price: getCheapestPriceFromProduct(modifierProduct, location),
                        modifierGroups: selectDefaultTallyModifierGroups(state, modifierId),
                    },
                ],
            };
        }

        if (isRadioButton) {
            return {
                ...group,
                modifiers:
                    quantity === 0
                        ? []
                        : [
                              {
                                  productId: modifierId,
                                  quantity: 1,
                                  price: getCheapestPriceFromProduct(modifierProduct, location),
                                  modifierGroups: selectDefaultTallyModifierGroups(state, modifierId),
                              },
                          ],
            };
        }

        if (isCheckBox) {
            const modifiers =
                group.modifiers
                    ?.filter((modifier) => {
                        // deselect NO SAUCE and quantity = 0 for non default modifiers
                        if (isModifierADefault(modifier.productId, defaultModifiers)) return true;
                        if (isNoSauceSelected && !isNoSauce) {
                            return modifier.productId !== noSauceDomainProduct?.id && modifier.quantity;
                        }

                        return modifier.quantity;
                    })
                    ?.map((modifier) => {
                        const modifierProduct = products[modifier.productId];

                        return {
                            productId: modifier.productId,
                            quantity: modifier.quantity,
                            price: getCheapestPriceFromProduct(modifierProduct, location),
                            modifierGroups: modifier.modifierGroups,
                            sizeGroupId: modifierProduct.sizeGroupId,
                        };
                    }) || [];

            if (isNoSauce) {
                return {
                    ...group,
                    isOnSideChecked: false,
                    isOnSideOptionDisabled: quantity > 0,
                    modifiers: [
                        {
                            productId: modifierId,
                            quantity,
                            price: getCheapestPriceFromProduct(modifierProduct, location),
                            modifierGroups: selectDefaultTallyModifierGroups(state, modifierId),
                        },
                    ],
                };
            }

            if (isCurrentExist) {
                return quantity === 0 && !isModifierADefault(modifierId, defaultModifiers)
                    ? {
                          ...group,
                          modifiers: modifiers.filter((modifier) => modifier.productId !== modifierId),
                      }
                    : {
                          ...group,
                          modifiers: modifiers.map((modifier) => {
                              if (modifier.productId !== modifierId) {
                                  return modifier;
                              }

                              return {
                                  ...modifier,
                                  quantity,
                              };
                          }),
                      };
            }

            return {
                ...group,
                isOnSideOptionDisabled: false,
                modifiers: [
                    ...modifiers,
                    {
                        productId: modifierId,
                        quantity,
                        price: getCheapestPriceFromProduct(modifierProduct, location),
                        modifierGroups: selectDefaultTallyModifierGroups(state, modifierId),
                        sizeGroupId: modifierProduct.sizeGroupId,
                    },
                ],
            };
        }

        return group;
    });
};

export const selectSdiNewModifierGroups = (
    state: RootState,
    modifierGroupId: string,
    modifierId: string,
    quantity: number,
    tallyItem: PDPTallyItem | IDealTallyItem,
    itemGroupId?: string
): PDPTallyItemModifierGroup[] => {
    const products = selectProducts(state);
    const domainModifier = products[modifierId];

    const createModifier = (
        productId: string,
        quantity: number,
        price: number,
        itemGroupId?: string
    ): PDPTallyItemModifier => ({
        productId,
        quantity,
        price,
        itemGroupId,
    });

    return tallyItem.modifierGroups.map((group) => {
        if (group.productId === modifierGroupId) {
            const productId = tallyItem.productId;
            if (itemGroupId) {
                const modifierGroupFromProduct = products[productId].itemModifierGroups.find(
                    ({ productGroupId }) => productGroupId === modifierGroupId
                );
                const modifierFromModifierGroup = modifierGroupFromProduct.itemModifiers[
                    itemGroupId
                ].itemModifiers.find(({ itemId }) => modifierId === itemId);

                if (quantity === 0) {
                    return {
                        ...group,
                        modifiers: group.modifiers.filter((m) => m.itemGroupId !== itemGroupId),
                    };
                }

                return {
                    ...group,
                    modifiers: [
                        ...group.modifiers.filter((m) => m.itemGroupId !== itemGroupId),
                        createModifier(
                            modifierId,
                            quantity,
                            getModifierPrice(modifierFromModifierGroup, domainModifier),
                            itemGroupId
                        ),
                    ],
                };
            }

            const modifierGroupFromProduct = products[productId].itemModifierGroups.find(
                ({ productGroupId }) => productGroupId === modifierGroupId
            );
            const modifierFromModifierGroup = modifierGroupFromProduct.itemModifiers[modifierId];
            const tallyModifer = group.modifiers?.find((modifier) => modifier.productId === modifierId);
            const isTallyContainsModifier = !!tallyModifer;

            if (isTallyContainsModifier) {
                return quantity === 0
                    ? {
                          ...group,
                          modifiers: group.modifiers.filter((modifier) => modifier.productId !== modifierId),
                      }
                    : {
                          ...group,
                          modifiers: group.modifiers.map((modifier) => {
                              if (modifier.productId !== modifierId) {
                                  return modifier;
                              }

                              return {
                                  ...modifier,
                                  quantity,
                              };
                          }),
                      };
            }

            return {
                ...group,
                modifiers: [
                    ...group.modifiers,
                    createModifier(modifierId, quantity, getModifierPrice(modifierFromModifierGroup, domainModifier)),
                ],
            };
        }

        return group;
    });
};

export const selectExtrasDisplayProducts = createSelector(
    (state: RootState): IExtraProductGroup[] => {
        const tallyItem = selectPDPTallyItem(state);

        const groups = selectModifierGroupsByProductId(state, tallyItem.productId);

        const result = groups
            .filter(isExtrasModifierGroup)
            .map((group) => {
                const productGroup = selectProductGroupById(state, group?.productGroupId);
                const tallyModifiers =
                    tallyItem.modifierGroups.find((it) => it.productId === group.productGroupId)?.modifiers || [];
                const selectedTallyModifiers = tallyModifiers.filter((it) => it.quantity > 0);
                const totalSelectedCount = selectedTallyModifiers.reduce((acc, item) => acc + item.quantity, 0);

                return {
                    displayName: productGroup.name,
                    productGroupId: group.productGroupId,
                    minQuantity: group.min,
                    maxQuantity: group.max,
                    isMaxAmountReached: totalSelectedCount === group.max,
                    products: Object.values(group.itemModifiers || {})
                        .filter(isModifierVisible)
                        .filter((item) => selectModifierIsAvailable(state, item.itemId))
                        .sort(bySequence)
                        .reduce<IExtraItemModel[]>((acc, itemModifier) => {
                            const relatedSelections = selectModifierRelatedSelections(
                                state,
                                tallyItem.productId,
                                itemModifier.itemId
                            );

                            if (!relatedSelections) {
                                const currentProduct = selectProductById(state, itemModifier.itemId);
                                const tallyExtrasItem = selectedTallyModifiers.find(
                                    (it) => it?.productId === currentProduct.id
                                );

                                const priceAndCalories = tallyExtrasItem
                                    ? selectBwwTallyPriceAndCalories(state, tallyExtrasItem, true)
                                    : {
                                          price: getModifierPrice(itemModifier, currentProduct),
                                          calories: getCaloriesFromProduct(currentProduct),
                                      };

                                return acc.concat({
                                    ...currentProduct,
                                    resultPrice: priceAndCalories.price,
                                    resultCalories: priceAndCalories.calories,
                                    quantity: tallyExtrasItem?.quantity || 0,
                                });
                            }

                            const allSelections = relatedSelections.concat(itemModifier).sort(bySequence);
                            const isPresent = acc.find((item) => allSelections.some((it) => it.itemId === item.id));
                            if (isPresent) {
                                return acc;
                            }

                            const selectedModifier = allSelections.find((it) =>
                                selectedTallyModifiers.find((extra) => extra.productId === it.itemId)
                            );
                            const defaultModifier =
                                allSelections.find((it) => it.defaultQuantity) ||
                                allSelections.find((item) => {
                                    const product = selectProductById(state, item.itemId);

                                    return product?.availability.isAvailable;
                                });

                            const activeSelectionItemModifier = selectedModifier || defaultModifier;
                            if (!activeSelectionItemModifier) {
                                return acc;
                            }
                            const activeSelectionProduct = selectProductById(state, activeSelectionItemModifier.itemId);

                            const tallyExtrasItem = selectedTallyModifiers.find(
                                (it) => it?.productId === activeSelectionProduct.id
                            );

                            const priceAndCalories = tallyExtrasItem
                                ? selectBwwTallyPriceAndCalories(state, tallyExtrasItem, true)
                                : {
                                      price: getModifierPrice(itemModifier, activeSelectionProduct),
                                      calories: getCaloriesFromProduct(activeSelectionProduct),
                                  };

                            return acc.concat({
                                ...activeSelectionProduct,
                                resultPrice: priceAndCalories.price,
                                resultCalories: priceAndCalories.calories,
                                quantity: tallyExtrasItem?.quantity || 0,
                                sizeSelections: allSelections
                                    .map((item) => {
                                        const product = selectProductById(state, item.itemId);
                                        return {
                                            sizeGroup: selectSizeGroup(state, product.id),
                                            product,
                                            disabled: !checkProductIsAvailable(product),
                                            isGroupedBySize: isProductSizeGrouped(state, product.id),
                                        };
                                    })
                                    .sort((a, b) => a.sizeGroup.sequence - b.sizeGroup.sequence),
                            });
                        }, []),
                    sequence: group.sequence,
                };
            })
            .filter((group) => group.products.length)
            .sort(bySequence);

        return result;
    },
    (extrasDisplayProducts) => extrasDisplayProducts
);

export const selectSauceIntensity = createSelector(
    selectProductById,
    selectProductGroup,
    selectCategoriesByProductId,
    (product, productGroup, productCategories): ProductIntensityEnum | null => {
        if (productGroup?.name === 'SAUCE') {
            const INTENSITY_CATEGORIES = Object.keys(ProductIntensityEnum);
            const intensityCategory = productCategories.find((category) =>
                INTENSITY_CATEGORIES.includes(category?.name.toUpperCase())
            );

            return intensityCategory ? ProductIntensityEnum[intensityCategory.name.toUpperCase()] : null;
        }

        return null;
    }
);

export const selectBwwSelectionsText = (
    state: RootState,
    pdpTallyItem: PDPTallyItem,
    productItemGroupName: string
): string => {
    const sizeSelections = selectProductSizes(state, pdpTallyItem.productId);
    const modifierGroups = pdpTallyItem.modifierGroups || [];
    const selections = modifierGroups.reduce((acc, { modifiers, metadata }) => {
        if (modifiers && metadata) {
            if (metadata.MODIFIER_GROUP_TYPE === ModifierGroupType.SELECTIONS) {
                return [...acc, ...modifiers];
            }
        }
        return acc;
    }, []);

    const modifiersProductsIds = selections.map(({ productId }) => productId);
    const modifiersProducts = selectProductsByIds(state, modifiersProductsIds);

    const formatModifiersNames = (modifiers: PDPTallyItemModifier[]) => {
        return modifiers.reduce((acc, modifier) => {
            const name = modifiersProducts[modifier.productId]?.name;

            if (name) {
                return [
                    ...acc,
                    modifier.quantity && modifier.quantity != 1 ? `${modifier.quantity} ${name}` : `${name}`,
                ];
            }

            return acc;
        }, [] as string[]);
    };

    const formatMainProductName = () => {
        if (sizeSelections && sizeSelections.length > 1) {
            const sizeSelection = sizeSelections.find(({ product: { id } }) => id === pdpTallyItem.productId);
            if (sizeSelection && sizeSelection.sizeGroup) {
                if (productItemGroupName === ItemGroupEnum.BOGO || productItemGroupName === ItemGroupEnum.BOGO_50_OFF) {
                    return `${pdpTallyItem.name} (${Number(sizeSelection.sizeGroup.name) * 2} Total)`;
                }
                return sizeSelection.isGroupedBySize && sizeSelection.sizeGroup?.name?.toLowerCase() !== 'none'
                    ? `${sizeSelection.sizeGroup.name} ${pdpTallyItem.name}`
                    : pdpTallyItem.name;
            }
        }
        return pdpTallyItem.name;
    };

    return [formatMainProductName(), ...formatModifiersNames(selections)].join(', ');
};

export const selectSelectedExtras = createSelector(
    (state: RootState): ISelectedExtraItem[] | null => {
        const pdpTallyItem = selectPDPTallyItem(state);
        const selectedExtras = getSelectedExtrasTallyModifiers(pdpTallyItem);
        if (!selectedExtras.length) {
            return null;
        }

        const result = selectedExtras.map((it) => {
            const product = selectProductById(state, it.productId);
            const { price, calories } = selectBwwTallyPriceAndCalories(state, it, true);

            return {
                name: product.name,
                price,
                calories,
            };
        });

        return result;
    },
    (selectedExtras) => selectedExtras
);

export const selectIsPDPProductReadyToAddToBag = createSelector(
    selectPDPTallyItem,
    selectProducts,
    (tallyItem: PDPTallyItem, products: IProducts) => {
        const tallyModifierGroups = tallyItem.modifierGroups?.filter(isNotExtrasModifierGroup) || [];

        const selectMenuModifierGroup = (product: IDomainProductItem, productGroupId: string) => {
            return product?.itemModifierGroups?.find((it) => it.productGroupId === productGroupId);
        };

        const isMinModifiersAmountIsReached = (
            menuModifierGroup: ItemModifierGroupModel,
            tallyModifierGroup: PDPTallyItemModifierGroup
        ) => {
            return getTallyModifierGroupModifiersCount(tallyModifierGroup) >= (menuModifierGroup?.min || 0);
        };

        for (const tallyModifierGroup of tallyModifierGroups) {
            if (
                !isMinModifiersAmountIsReached(
                    selectMenuModifierGroup(products[tallyItem.productId], tallyModifierGroup.productId),
                    tallyModifierGroup
                )
            ) {
                return false;
            }

            // handle nested modifiers
            for (const modifier of tallyModifierGroup.modifiers) {
                const nestedTallyModifierGroups = modifier.modifierGroups || [];
                for (const nestedTallyModifierGroup of nestedTallyModifierGroups) {
                    if (
                        !isMinModifiersAmountIsReached(
                            selectMenuModifierGroup(products[modifier.productId], nestedTallyModifierGroup.productId),
                            nestedTallyModifierGroup
                        )
                    ) {
                        return false;
                    }
                }
            }
        }

        return true;
    }
);

export const selectDisplayRelatedItemGroups = (state: RootState): IDisplayModifierGroup[] | null => {
    const pdpTallyItem = selectPDPTallyItem(state);
    const { productId } = pdpTallyItem;
    const isCombo = selectProductIsCombo(state, productId);
    const products = selectProducts(state);

    let relatedItemGroups: ItemModifierGroupModel[];
    if (isCombo) {
        const entreeProduct = products[pdpTallyItem.childItems[0].productId];

        relatedItemGroups = entreeProduct.relatedItemGroups;
    } else {
        relatedItemGroups = products[productId]?.relatedItemGroups;
    }

    return (relatedItemGroups || []).map((it) => ({
        displayName: it.productGroupId,
        minQuantity: it.min,
        maxQuantity: it.max,
        sequence: it.sequence,
        modifierGroupId: it.productGroupId,
        modifiers: Object.values(it.itemModifiers || [])?.map((modifier) => {
            const productItemModifier = products[modifier?.itemId];
            const price = getModifierPrice(modifier, productItemModifier);
            const calories = getCaloriesFromProduct(productItemModifier);
            return {
                displayName: productItemModifier.name,
                displayProductDetails: {
                    displayName: productItemModifier.name,
                    minQuantity: modifier.min,
                    maxQuantity: modifier.max,
                    price,
                    productId: modifier?.itemId,
                    quantity: getRelatedModifierQuantityForSingleProduct(modifier?.itemId, pdpTallyItem),
                    calories,
                    sequence: modifier.sequence,
                },
            };
        }),
    }));
};

export const selectSdiDisplayModifierGroup = ({
    state,
    stage,
    tallyItem,
    productId,
    modifierGroupId,
    productsById,
}: {
    state: RootState;
    stage?: number;
    tallyItem: PDPTallyItem | IDealTallyItem;
    productId?: string;
    modifierGroupId: string;
    productsById: IGlobalContentfulProps['productsById'];
}): IDisplayModifierGroup => {
    const modifierId = tallyItem?.productId || productId;

    const group = selectItemModifierGroup(state, modifierId, modifierGroupId);
    const productGroup = selectProductGroupById(state, group?.productGroupId);
    const maxQuantity = group.max;
    const isCombo = selectProductIsCombo(state, modifierId);
    const isComboSideOrDrink = !!stage;
    const selectedChildItemId = (tallyItem as PDPTallyItem)?.childItems?.[stage]?.productId;

    return {
        displayName: productGroup.name,
        minQuantity: group.min,
        maxQuantity,
        sequence: group.sequence,
        modifierGroupId: group.productGroupId,
        metadata: group.metadata,
        modifiers: Object.values(group.itemModifiers || {})
            .filter((item) => selectModifierIsAvailableWithPlu(state, item.itemId))
            .sort(bySequence)
            .sort(isRequired)
            .map((itemModifier) => {
                const modifierProduct = selectProductById(state, itemModifier.itemId);
                const tallyModifierGroup = tallyItem?.modifierGroups?.find(
                    (group) => group.productId === modifierGroupId
                );

                if (isModifierItemGroup(itemModifier)) {
                    const allNestedModifiers = Object.values(itemModifier.itemModifiers).sort(bySequence);
                    const availableNestedModifiers = allNestedModifiers.filter((item) =>
                        selectModifierIsAvailableWithPlu(state, item.itemId)
                    );
                    // for the correct price and calories calculation when where is no available modifiers
                    const nestedModifiers =
                        availableNestedModifiers.length > 0 ? availableNestedModifiers : allNestedModifiers;

                    const isWhatsOnItGroup = isWhatsOnItModifierGroup(group);

                    const getDefaultNestedModifierPriceAndCalories = (nestedModifiers: ItemModifierModel[]) => {
                        if (!isWhatsOnItGroup) {
                            const nestedModifier = getDefaultNestedModifier(nestedModifiers);

                            const nestedModifierProduct = selectProductById(state, nestedModifier?.itemId);
                            const isDefaultModifier = nestedModifier?.defaultQuantity > 0 && !isComboSideOrDrink;

                            return {
                                price: !isDefaultModifier ? getModifierPrice(nestedModifier, nestedModifierProduct) : 0,
                                calories: getCaloriesFromProduct(nestedModifierProduct),
                            };
                        } else {
                            return {
                                price: 0,
                                calories: null,
                            };
                        }
                    };

                    const tallyModifier: PDPTallyItemModifier = tallyModifierGroup?.modifiers?.find(
                        (modifier) => modifier.itemGroupId === itemModifier.itemGroupId
                    );

                    const isSelectedChildItem = nestedModifiers?.some((item) => item.itemId === selectedChildItemId);

                    const itemGroup = selectItemGroupById(state, itemModifier.itemGroupId);

                    let priceAndCalories: IPriceAndCalories;
                    let tallyModifierQuantity = tallyModifier?.quantity || 0;
                    let tallySelectedModifierId = isComboSideOrDrink ? selectedChildItemId : tallyModifier?.productId;

                    const intensityModifiers = nestedModifiers.map((modifier) => {
                        if (isComboSideOrDrink) {
                            const size = selectSizeGroup(state, modifier?.itemId);
                            const defaultSizeModifier = getDefaultSizeModifier(nestedModifiers, productsById);
                            const actionCodes = modifier.actionCodes?.length ? modifier.actionCodes : [size?.name];

                            return {
                                ...modifier,
                                actionCodes,
                                defaultQuantity:
                                    defaultSizeModifier?.itemId === modifier.itemId ? 1 : modifier.defaultQuantity,
                            };
                        }
                        return modifier;
                    });

                    if (tallyModifier) {
                        const isNoneModifier = isNoneActionCode(
                            itemModifier.itemModifiers.find(({ itemId }) => itemId === tallyModifier.productId)
                                ?.actionCodes?.[0]
                        );

                        if (!isNoneModifier) {
                            priceAndCalories = selectTallyPriceAndCalories(state, tallyModifier, true);
                        } else {
                            priceAndCalories = getDefaultNestedModifierPriceAndCalories(intensityModifiers);
                        }
                    } else if (isWhatsOnItGroup) {
                        // Regular option is not returned by the menu, ui need to show Regular in What's on it section in case if no change made by user
                        tallyModifierQuantity = 1;
                        tallySelectedModifierId = INTERNAL_REGULAR_MODIFIER_ID;

                        priceAndCalories = getDefaultNestedModifierPriceAndCalories(intensityModifiers);
                    } else if (isSelectedChildItem) {
                        tallyModifierQuantity = 1;
                        priceAndCalories = selectTallyPriceAndCalories(
                            state,
                            (tallyItem as PDPTallyItem).childItems[stage],
                            true,
                            isCombo
                        );
                    } else {
                        priceAndCalories = getDefaultNestedModifierPriceAndCalories(intensityModifiers);
                    }

                    if (isWhatsOnItGroup) {
                        intensityModifiers.push({
                            sequence: 1,
                            itemId: INTERNAL_REGULAR_MODIFIER_ID,
                            defaultQuantity: 1,
                            actionCodes: [ActionCodesEnum.REGULAR],
                        });
                    }

                    if (isComboSideOrDrink) {
                        const defaultChildPrice = selectDefaultChildPriceForCombo(
                            state,
                            selectPDPTallyItem(state).productId,
                            stage
                        );

                        priceAndCalories = {
                            ...priceAndCalories,
                            price: priceAndCalories.price - defaultChildPrice,
                        };
                    }

                    // added fallback in case there is no itemGroup in menu
                    const displayName =
                        itemGroup?.name || selectRootProductByItemId(state, intensityModifiers?.[0]?.itemId)?.name;

                    return {
                        displayName,
                        tags: itemModifier?.tags,
                        displayProductDetails: {
                            calories: priceAndCalories.calories,
                            defaultQuantity: itemModifier.defaultQuantity,
                            displayName,
                            minQuantity: itemModifier.min,
                            maxQuantity: itemModifier.max,
                            price: priceAndCalories.price,
                            productId: itemGroup?.id,
                            quantity: tallyModifierQuantity,
                            modifiers: intensityModifiers
                                // at this moment price and calories is calculated and we can hide unavailable modifiers
                                .filter((item) => selectModifierIsAvailableWithPlu(state, item.itemId))
                                .sort(bySequence)
                                .map(({ itemId, defaultQuantity, actionCodes }) => ({
                                    productId: itemId,
                                    displayName: selectProductById(state, itemId)?.name,
                                    quantity: tallySelectedModifierId === itemId ? tallyModifierQuantity : 0,
                                    defaultQuantity,
                                    actionCodes,
                                })),
                        },
                    };
                }
                const modifiers: PDPTallyItem[] = isCombo
                    ? (tallyItem as PDPTallyItem)?.childItems
                    : tallyModifierGroup?.modifiers;

                const tallyModifier = modifiers?.find((modifier) => modifier.productId === itemModifier.itemId);

                const priceAndCalories = tallyModifier
                    ? selectTallyPriceAndCalories(state, tallyModifier, true, isCombo)
                    : {
                          price: getModifierPrice(itemModifier, modifierProduct),
                          calories: getCaloriesFromProduct(modifierProduct),
                      };

                return {
                    displayName: modifierProduct.name,
                    displayProductDetails: {
                        calories: priceAndCalories.calories,
                        defaultQuantity: itemModifier.defaultQuantity,
                        displayName: modifierProduct.name,
                        minQuantity: itemModifier.min,
                        maxQuantity: itemModifier.max,
                        price: priceAndCalories.price,
                        productId: modifierProduct.id,
                        quantity:
                            tallyModifier?.quantity ||
                            getModifierQuantityForSingleProduct(modifierProduct.id, tallyItem),
                    },
                };
            })
            .filter((item) => {
                if (isComboSideOrDrink) {
                    return true;
                }
                return item.displayProductDetails.modifiers ? item.displayProductDetails.modifiers.length > 0 : true;
            }),
    };
};
