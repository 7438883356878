import { IDeliveryLocation, LocationWithDetailsModel } from '../../common/services/locationService/types';
import { ILocationAddressDetailsModel, ILocationAddressModel } from '../../@generated/webExpApi';

export const getString = (arr: string[], separator: string): string => arr.filter(Boolean).join(separator);

/**
 * @method getLocationAddressLine
 * @param {ILocationAddressModel} address location address
 * @author Andrei Shubich <ashubich@inspirebrands.com>
 * @added 2023-07-03
 * @version 1.0
 * @returns {string} address line
 */
export function getLocationAddressLine(address: ILocationAddressModel): string {
    if (!address) return '';
    return getString([address.line1, address.line2, address.line3], ' ');
}

/**
 * @method getLocationCityWithCodes
 * @param {ILocationAddressModel} address location address
 * @author Andrei Shubich <ashubich@inspirebrands.com>
 * @added 2023-07-03
 * @version 1.0
 * @returns {string} city, stateProvinceCode postalCode
 */
export function getLocationCityWithCodes(address: ILocationAddressModel): string {
    if (!address) return '';
    return getString([address.city, getString([address.stateProvinceCode, address.postalCode], ' ')], ', ');
}

export function getLocationAddressString(location: LocationWithDetailsModel, showAddressLine = true): string {
    const address = location?.contactDetails?.address;

    if (!address) return '';

    return getString([showAddressLine && getLocationAddressLine(address), getLocationCityWithCodes(address)], ', ');
}

export function getLocationCityProvince(location: LocationWithDetailsModel): string {
    const address = location?.contactDetails?.address;

    if (!address) return '';

    return getString([address.city, address.stateProvinceCode], ', ');
}

export function getDeliveryAddressString(location: IDeliveryLocation): string {
    if (!location) return '';

    return getString(
        [
            getString([location.addressLine1, location.addressLine2], ' '),
            location.city,
            getString([location.state, location.zipCode], ' '),
        ],
        ', '
    );
}

export function getLocationAddressDetailsString(props: {
    address: ILocationAddressDetailsModel;
    showAddressLine: boolean;
}): string {
    const { address, showAddressLine } = props || {};
    if (!address) return '';

    return getString(
        [
            showAddressLine && address.line,
            address.cityName,
            getString([address.stateProvinceCode, address.postalCode], ' '),
        ],
        ', '
    );
}
