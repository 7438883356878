import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { ITallyError500ExternalResponseModel, TallyResponseModel } from '../@generated/webExpApi';
import { RequestError } from '../common/services/createErrorWrapper';

const SUBMIT_TALLY = 'tally/submit';

export interface ITallyState {
    tallyOrder: TallyResponseModel;
    isLoading: boolean;
    isSubmitted: boolean;
    error: RequestError | ITallyError500ExternalResponseModel;
    unavailableItems: string[];
}

const tallySlice = createSlice<ITallyState, SliceCaseReducers<ITallyState>>({
    name: SUBMIT_TALLY,
    initialState: { tallyOrder: null, isLoading: false, isSubmitted: false, error: null, unavailableItems: [] },
    reducers: {
        pending: (state: ITallyState): ITallyState => ({
            ...state,
            isLoading: true,
        }),
        invalid: (
            state: ITallyState,
            action: PayloadAction<RequestError | ITallyError500ExternalResponseModel>
        ): ITallyState => ({
            ...state,
            error: action.payload,
            isLoading: false,
            isSubmitted: false,
        }),
        fulfilled: (state: ITallyState, action: PayloadAction<TallyResponseModel>): ITallyState => ({
            error: null,
            isLoading: false,
            isSubmitted: true,
            tallyOrder: action.payload,
            unavailableItems: [],
        }),
        reset: (): ITallyState => ({
            error: null,
            isLoading: false,
            isSubmitted: false,
            tallyOrder: null,
            unavailableItems: [],
        }),
        setUnavailableItems: (state: ITallyState, action: PayloadAction<string[]>): ITallyState => ({
            error: null,
            isLoading: false,
            isSubmitted: false,
            tallyOrder: action.payload.length > 0 ? null : state.tallyOrder,
            unavailableItems: action.payload,
        }),
    },
});

export const { actions } = tallySlice;

export default tallySlice.reducer;
