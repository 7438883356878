/**
 * @namespace isUndefined
 */

/**
 * @method isUndefined
 * @memberOf isUndefined
 * @description Checks if `value` is `undefined`.
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is `undefined`, else `false`.
 * @example
 *
 * isUndefined(undefined)
 * // => true
 *
 * isUndefined('example')
 * // => false
 */

export const isUndefined = (value) => value === undefined;
