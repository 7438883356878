import { useOrderLocation, useTallyOrder } from '../../redux/hooks';
import { getTallyBagProductIds } from '../helpers/bagHelper';
import { getDiscountBanner, IBagRenderItem } from '../../components/organisms/bag/bagModel';
import { getProductPath, getRelatedProduct } from '../../lib/domainProduct';
import { useDomainProducts } from '../../redux/hooks/domainMenu';
import useBag from '../../redux/hooks/useBag';
import useGlobalProps from '../../redux/hooks/useGlobalProps';
import { useTallyItemIsPromoList } from '../../redux/hooks/useTally';
import { useCheckIsCondiment } from './useCheckIsCondiment';
import { useBagUnavailableItems } from './useBagUnavailableItems';

export const useBagRenderItems = (): IBagRenderItem[] => {
    const { bagEntries, entriesMarkedAsRemoved } = useBag();
    const globalProps = useGlobalProps();
    const allItemsIds = getTallyBagProductIds(bagEntries);
    const domainProducts = useDomainProducts(allItemsIds);
    const tallyPromoMap = useTallyItemIsPromoList(bagEntries);
    const { currentLocation: location } = useOrderLocation();
    const checkIsCondiment = useCheckIsCondiment();
    const { unavailableItems: unavailableItemsIds } = useTallyOrder();
    const unavailableItems = useBagUnavailableItems(unavailableItemsIds).unavailableItems;

    return (
        bagEntries
            ?.map((entry) => {
                const isCondiment = checkIsCondiment(entry.productId);
                const isAvailable = !unavailableItems.map(({ lineItemId }) => lineItemId).includes(entry.lineItemId);

                return {
                    entry,
                    isAvailable,
                    markedAsRemoved: entriesMarkedAsRemoved.includes(entry.lineItemId),
                    contentfulProduct: isCondiment
                        ? globalProps.modifierItemsById[entry.productId] || globalProps.modifierItemsById['default']
                        : globalProps.productsById[entry.productId] ||
                          getRelatedProduct(domainProducts[entry.productId], globalProps.productsById),
                    entryPath: getProductPath(domainProducts[entry.productId], globalProps.productDetailsPagePaths),
                    discountBanner: getDiscountBanner(
                        entry,
                        location,
                        domainProducts[entry.productId],
                        tallyPromoMap[entry.productId]
                    ),
                };
            })
            .filter((entry) => !!entry.contentfulProduct) || []
    );
};
