import { ITallyError500ExternalResponseModel, TTallyErrorCodeModel } from '../../@generated/webExpApi/models';
import { useNotifications } from '../../redux/hooks';
import { experiencingTechnicalDifficultiesError, handledErrors, RequestError } from '../services/createErrorWrapper';

type TError = ITallyError500ExternalResponseModel | RequestError;

export const useTallyErrorNotification = (): ((error: TError) => void) => {
    const {
        actions: { enqueueError },
    } = useNotifications();

    const isTallyExternalError = (error: TError): error is ITallyError500ExternalResponseModel => {
        return 'code' in error;
    };

    const showTallyErrorNotification = (error: TError) => {
        if (isTallyExternalError(error) && handledErrors.includes(error.code as TTallyErrorCodeModel)) {
            enqueueError({
                message: error.message as string,
            });
        } else {
            enqueueError({
                message: experiencingTechnicalDifficultiesError,
            });
        }
    };
    return showTallyErrorNotification;
};
