import {
    SellingChannelNamesModel,
    WebExperienceApi,
    GetPersonalizationActionsRequest,
    ICustomerPersonalizationResponseModel,
    ICustomerPersonalizationFactModel,
} from '../../../@generated/webExpApi';
import { getLocale } from '../../helpers/locale';
import createErrorWrapper from '../createErrorWrapper';

const expApi = new WebExperienceApi();

const getPersonalizedContentApi = createErrorWrapper<
    ICustomerPersonalizationResponseModel,
    GetPersonalizationActionsRequest
>('getPersonalizedContent', expApi.getPersonalizationActions.bind(expApi));

export const getPersonalizedContent = async (
    facts: Array<ICustomerPersonalizationFactModel>
): Promise<ICustomerPersonalizationResponseModel> => {
    return getPersonalizedContentApi({
        sellingChannel: SellingChannelNamesModel.Weboa,
        locale: getLocale(),
        iCustomerPersonalizationRequestModel: { facts },
    });
};
