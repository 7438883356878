import { PayloadAction } from '@reduxjs/toolkit';
import { GetLocationAvailableTimesRequest, LocationPickupAndDeliveryResponseModel } from '../../@generated/webExpApi';
import { locationTimeSlotsEnabled } from '../../lib/getFeatureFlags';
import { LocationAvailableTimes } from '../../redux/orderLocation';
import getLocationAvailableTimes from '../services/locationService/getLocationAvailableTimes';

export interface UseLocationAvailableTimesHook {
    getLocationAvailableTimeSlots: (
        payload: GetLocationAvailableTimesRequest
    ) => Promise<LocationPickupAndDeliveryResponseModel>;
    fetchAndSetLocationTimeSlots: (payload: IFetchLocationAvailableTimeSlots) => Promise<void>;
}

interface IFetchLocationAvailableTimeSlots {
    locationId: string;
    setAvailableTimeSlots: (payload: LocationAvailableTimes) => PayloadAction<LocationAvailableTimes>;
}

export default function useLocationAvailableTimes(): UseLocationAvailableTimesHook {
    const getLocationAvailableTimeSlots = async (
        payload: GetLocationAvailableTimesRequest
    ): Promise<LocationPickupAndDeliveryResponseModel> => {
        return await getLocationAvailableTimes(payload);
    };

    const isLocationTimeSlotsEnabled = locationTimeSlotsEnabled();

    const fetchAndSetLocationTimeSlots = async ({
        locationId,
        setAvailableTimeSlots,
    }: IFetchLocationAvailableTimeSlots): Promise<void> => {
        if (isLocationTimeSlotsEnabled && locationId) {
            try {
                const availableTimeSlots = await getLocationAvailableTimeSlots({
                    locationId,
                });
                setAvailableTimeSlots(availableTimeSlots);
            } catch {
                setAvailableTimeSlots(null);
            }
        }
    };

    return {
        getLocationAvailableTimeSlots,
        fetchAndSetLocationTimeSlots,
    };
}
