import { createSelector } from '@reduxjs/toolkit';
import { DiscountDetailsTypeModel, TCertificateStatusModel, TRewardOfferStatusModel } from '../../@generated/webExpApi';
import { RootState } from '../store';
import { isBefore } from '../../common/helpers/dateTime';
import { isOfferModel } from '../../common/helpers/isOfferModel';
import { selectDeal } from './bag';

export const selectRewards = (state: RootState): RootState['rewards'] => state.rewards;

export const selectOffers = createSelector(selectRewards, (rewards) => rewards.offers);
export const selectAutoDiscounts = createSelector(selectRewards, (rewards) => rewards.autodiscounts);

export const selectCertificates = createSelector(selectRewards, (rewards) => rewards.certificates);
export const selectCertificatesSortedByStatus = createSelector(selectRewards, (rewards) => {
    const certificatesCopy = rewards.certificates.slice();
    const sortingFunction = (first, second) => {
        if (first.status === second.status) return 0;
        if (first.status === TCertificateStatusModel.PendingCancellation) return 1;
        return -1;
    };

    return certificatesCopy.sort(sortingFunction);
});
export const selectLastPurchasedCertificate = createSelector(
    selectRewards,
    (rewards) => rewards.lastPurchasedCertificate
);

export const selectTotalCount = createSelector(selectRewards, (rewards) => rewards.totalCount);

export const selectInactiveOffers = createSelector(selectOffers, (offers) =>
    offers.filter((offer) => !isOfferModel(offer) && offer.status === TRewardOfferStatusModel.Inactive)
);

export const selectRegularOffers = createSelector(selectOffers, (offers) =>
    offers.filter((offer) => offer.status !== TRewardOfferStatusModel.Inactive)
);

export const selectOfferById = (userOfferId: string) =>
    createSelector(selectRegularOffers, (offers) => offers.find((item) => item.userOfferId === userOfferId));

export const selectFirstExpiringOffer = createSelector(selectRewards, ({ offers }) => {
    const filteredOffers = offers.filter((offer) => offer?.discountDetailsType !== DiscountDetailsTypeModel.PromoCode);
    let firstExpiringOffer = filteredOffers[0];

    for (let i = 1, len = filteredOffers.length; i < len; i += 1) {
        const item = filteredOffers[i];
        const firstOfferEndDateTime = new Date(firstExpiringOffer.endDateTime);
        const currentOfferEndDateTime = new Date(item.endDateTime);

        if (isBefore(currentOfferEndDateTime, firstOfferEndDateTime)) {
            firstExpiringOffer = item;
        }
    }

    return firstExpiringOffer;
});

export const selectRewardsActivityHistory = createSelector(selectRewards, (rewards) => rewards.rewardsActivityHistory);

export const selectRewardsCatalog = createSelector(selectRewards, (rewards) => rewards.rewardsCatalog);
export const selectRewardsRecommendations = createSelector(selectRewards, (rewards) => rewards.rewardsRecommendations);
export const selectAppliedPromocode = createSelector(selectDeal, selectRegularOffers, (dealId, offers) =>
    offers.find(
        (offer) => offer?.userOfferId === dealId && offer?.discountDetailsType === DiscountDetailsTypeModel.PromoCode
    )
);
