import { ILocationWithDetailsModel } from '../../@generated/webExpApi';
import { useNotifications } from '../../redux/hooks';
import { locationUnavailableError } from '../helpers/locationUnavailableError';
import { GtmErrorCategory } from '../services/gtmService/types';
import { useGtmErrorEvent } from './useGtmErrorEvent';
import { NotificationType } from '../../redux/notifications';

export const useLocationUnavailableError = () => {
    const { pushGtmErrorEvent } = useGtmErrorEvent();
    const {
        actions: { enqueue },
    } = useNotifications();

    const pushLocationUnavailableError = (location: ILocationWithDetailsModel) => {
        const locationPhone = location.contactDetails?.phone;
        const errorMessage = locationUnavailableError(locationPhone);
        const payload = {
            ErrorCategory: GtmErrorCategory.CHECKOUT_UNAVAILABLE_LOCATION,
            ErrorDescription: errorMessage,
        };

        pushGtmErrorEvent(payload);
        enqueue({
            htmlMessage: errorMessage,
            type: NotificationType.ERROR,
        });
    };

    return {
        pushLocationUnavailableError,
    };
};
