import { TServiceTypeModel, IServicesModel } from '../../@generated/webExpApi';
import { PickupAddress } from '../../redux/orderLocation';

const getLocationService = (location: PickupAddress, serviceType: TServiceTypeModel): IServicesModel | undefined => {
    return location?.services?.find((service) => {
        return service.type === serviceType;
    });
};

export default getLocationService;
