import { PDPTallyItem } from '../redux/pdp';
import { CalorieRangeModel, TallyModifierGroupModel } from '../@generated/webExpApi';

export interface IPriceAndCalories {
    price?: number;
    offerPrice?: number;
    calories?: number;
    name?: string;
    calorieRange?: CalorieRangeModel;
}

export const applyModifiersToMeal = (
    singleTallyItem: PDPTallyItem,
    mealTallyItem: PDPTallyItem
): PDPTallyItem | undefined => {
    return {
        ...mealTallyItem,
        relatedItemGroups: singleTallyItem.relatedItemGroups,
        childItems: mealTallyItem.childItems?.map((childItem) => {
            if (childItem.productId === singleTallyItem.productId) {
                return {
                    ...childItem,
                    modifierGroups: singleTallyItem.modifierGroups,
                };
            }

            return childItem;
        }),
    };
};

const isModifiersEqual = (firstItem: TallyModifierGroupModel[], secondItem: TallyModifierGroupModel[]) => {
    if (firstItem.length !== secondItem.length) {
        return false;
    }

    return firstItem.every((firstItemGroup) => {
        const secondItemGroup = secondItem.find(({ productId }) => productId === firstItemGroup.productId);

        if (!secondItemGroup) {
            return false;
        }

        const { modifiers: firstItemModifiers = [] } = firstItemGroup;
        const { modifiers: secondItemModifiers = [] } = secondItemGroup;

        if (
            firstItemModifiers.filter((item) => item.quantity > 0).length !==
            secondItemModifiers.filter((item) => item.quantity > 0).length
        ) {
            return false;
        }

        return firstItemModifiers.every((firstItemModifier) => {
            const secondItemModifier = secondItemModifiers.find(
                ({ productId }) => productId === firstItemModifier.productId
            );
            if (!secondItemModifier) {
                return false;
            }

            return firstItemModifier.quantity === secondItemModifier.quantity;
        });
    });
};

export const hasUnsavedModifications = (defaultTallyItem?: PDPTallyItem, currentTallyItem?: PDPTallyItem): boolean => {
    if (!(defaultTallyItem && currentTallyItem)) {
        return false;
    }

    if (
        defaultTallyItem.productId !== currentTallyItem.productId ||
        defaultTallyItem.quantity !== currentTallyItem.quantity
    ) {
        return true;
    }

    if (currentTallyItem.childItems) {
        const { childItems: defaultItemChildItems = [] } = defaultTallyItem;
        const { childItems: currentItemChildItems } = currentTallyItem;

        if (defaultItemChildItems.length !== currentItemChildItems.length) {
            return true;
        }

        return defaultItemChildItems.some((defaultChildItem, index) => {
            const currentChildItem = currentItemChildItems[index];

            if (!currentChildItem) {
                return true;
            }

            return hasUnsavedModifications(defaultChildItem, currentChildItem);
        });
    }

    return !isModifiersEqual(defaultTallyItem.modifierGroups || [], currentTallyItem.modifierGroups || []);
};

export const getEmptyTallyItem = (): PDPTallyItem => ({
    lineItemId: null,
    productId: null,
    price: null,
    quantity: 1,
    modifierGroups: null,
});
