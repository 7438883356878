import { getDealWarning } from '../../common/helpers/dealHelper';
import { useBagRenderItems } from '../../common/hooks/useBagRenderItems';
import { getCheckoutEntries } from '../../components/organisms/bag/bagModel';
import { useTallyItemsWithPricesAndCalories } from './domainMenu';
import useOrderLocation from './useOrderLocation';
import useRewards from './useRewards';

const useDealWarning = (userOfferId: string, isShowExpiredMessage = false) => {
    // eslint-disable-next-line testing-library/render-result-naming-convention
    const bagRenderItems = useBagRenderItems();
    const checkoutEntries = getCheckoutEntries(bagRenderItems);
    const bagItemPrices = useTallyItemsWithPricesAndCalories(checkoutEntries);

    const subTotalBeforeDiscounts = bagItemPrices.reduce(
        (acc, curr) => acc + curr.productData.totalPrice * curr.quantity,
        0
    );
    const { currentLocation } = useOrderLocation();

    const { getOfferById, getApplicableOffers } = useRewards();
    const offer = getOfferById(userOfferId);
    const applicableOffers = getApplicableOffers(currentLocation?.id, true);
    const dealWarning = getDealWarning({
        offer,
        bagRenderItems,
        subTotalBeforeDiscounts,
        applicableOffers,
        isShowExpiredMessage,
        locationTimezone: currentLocation?.timezone,
    });

    return dealWarning;
};

export default useDealWarning;
