import { useAppDispatch } from '../../redux/store';
import { GTM_ERROR_EVENT } from '../../common/services/gtmService/constants';
import { GtmErrorEvent } from '../../common/services/gtmService/types';

export const useGtmErrorEvent = () => {
    const dispatch = useAppDispatch();

    const pushGtmErrorEvent = (payload: GtmErrorEvent) => {
        dispatch({ type: GTM_ERROR_EVENT, payload });
    };

    return { pushGtmErrorEvent };
};
