import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import * as OrderHistoryStore from '../orderHistory';
import { IOrderModel } from '../../@generated/webExpApi';
import { useOrderHistoryService } from '../../common/hooks/useOrderHistoryService';

interface IUseOrderHistory {
    orderHistory: OrderHistoryStore.IOrderHistory;
    orderHistoryFulfilled: OrderHistoryStore.IOrderHistory;
    isLoading: boolean;
    getOrderById: (id: string) => IOrderModel;
    actions: {
        setOrderHistory: (
            payload: OrderHistoryStore.ISetOrderHistoryPayload
        ) => PayloadAction<OrderHistoryStore.ISetOrderHistoryPayload>;
        setOrderHistoryLoading: (payload: boolean) => void;
        setOrderStatusById: (payload: OrderHistoryStore.ISetOrderStatusById) => void;
        refreshOrderHistory: () => void;
        cancelOrder: (id: string) => Promise<{ errorCode: string }>;
    };
}

export default function useOrderHistory(): IUseOrderHistory {
    const dispatch = useDispatch();
    const orderHistoryService = useOrderHistoryService();
    const orderHistory = useSelector<RootState, OrderHistoryStore.IOrderHistoryState>((state) => state.orderHistory);

    const setOrderHistory = useCallback(
        (payload: OrderHistoryStore.ISetOrderHistoryPayload) =>
            dispatch(OrderHistoryStore.actions.setOrderHistory(payload)),
        [dispatch]
    );
    const setOrderHistoryLoading = (payload: boolean) =>
        dispatch(OrderHistoryStore.actions.setOrderHistoryLoading(payload));

    const setOrderStatusById = (payload: OrderHistoryStore.ISetOrderStatusById) =>
        dispatch(OrderHistoryStore.actions.setOrderStatusById(payload));

    const refreshOrderHistory = useCallback(() => {
        orderHistoryService
            .getOrderHistory()
            .then((res) => {
                setOrderHistory(res);
            })
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .catch(() => {});
    }, [orderHistoryService, setOrderHistory]);

    const cancelOrder = (id: string) => orderHistoryService.cancelOrder(id);

    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 90));
    const orderHistoryFulfilled = orderHistory?.orderHistory?.filter((order) => order.dateTime > priorDate);

    const getOrderById = (id: string): IOrderModel => {
        return orderHistory?.orderHistory?.find((item) => item.id === id);
    };

    return {
        getOrderById,
        orderHistory: orderHistory.orderHistory,
        isLoading: orderHistory.loading,
        orderHistoryFulfilled,
        actions: {
            setOrderHistory,
            setOrderHistoryLoading,
            setOrderStatusById,
            refreshOrderHistory,
            cancelOrder,
        },
    };
}
