/** @namespace checkoutHelpers*/

import {
    add,
    differenceInMinutes,
    isAfter,
    isEqual,
    isToday,
    isWithinInterval,
    format,
    isValid,
    isSameDay,
    differenceInCalendarDays,
    parseISO,
    utcToZonedTime,
} from './dateTime';
import { IDateRange } from '../../lib/locations';
import { WorkingHours } from '../../redux/bag';
import getBrandInfo from '../../lib/brandInfo';
import { TIME_INTERVAL } from '../constants/checkoutHelper';

/**
 * @method prepareWorkingHours
 * @param {Array<IDateRange>} ranges - time ranges
 * @param {string} timezone - current timezone
 * @param {number|undefined} prepTime - preparation time
 * @author Pvel Shpakovich <pavel.shpakovich@inspirebrands.com>
 * @added 2023-04-10
 * @version 1.0.0
 * @memberOf checkoutHelpers
 * @returns {WorkingHours} - transformed working hours
 */
export function prepareWorkingHours(ranges: IDateRange[], timezone: string, prepTime?: number): WorkingHours {
    const dayRange = prepareDays(ranges, timezone);
    const pickupTimeValues = dayRange
        .map((day) => ({
            day: prepareTimes(ranges, day, prepTime)[0],
            timeRange: prepareTimes(ranges, day, prepTime),
        }))
        .filter((prepTime) => {
            return prepTime.day && prepTime.timeRange.length > 0;
        });
    return pickupTimeValues.reduce((resultDays, currentDay, index) => {
        const nextDay = index < pickupTimeValues.length - 1 && pickupTimeValues[index + 1];
        const previousDay = index > 0 && pickupTimeValues[index - 1];

        if (nextDay && isToday(new Date(currentDay.day)) && isToday(new Date(nextDay.day))) {
            return [
                ...resultDays,
                {
                    day: currentDay.day,
                    timeRange: [...currentDay.timeRange, ...nextDay.timeRange],
                },
            ];
        }
        if (previousDay && isToday(new Date(currentDay.day)) && isToday(new Date(previousDay.day))) {
            return resultDays;
        }
        return [...resultDays, currentDay];
    }, []);
}

export function prepareDays(ranges: IDateRange[], timezone: string): string[] {
    const now = new Date(Date.now());
    const nowTimezoned = utcToZonedTime(now, timezone);

    const validRanges = ranges.filter((range) => {
        return isAfter(range.end, now);
    });

    const days = validRanges.map((range) => range.start);

    const weekRange = days
        .filter((date) => {
            const dateTimezoned = utcToZonedTime(date, timezone);

            return differenceInCalendarDays(dateTimezoned, nowTimezoned) < 7;
        })
        .map((date) => date.toISOString());

    return weekRange;
}

/**
 * @method prepareTimes
 * @param {Array<IDateRange>} ranges - time ranges
 * @param {string} selectedDay - selected day
 * @param {number|undefined} prepTime - preparation time
 * @author Pvel Shpakovich <pavel.shpakovich@inspirebrands.com>
 * @added 2023-04-10
 * @version 1.0.0
 * @memberOf checkoutHelpers
 * @returns {Array<string>} - prepared time ranges
 */
export function prepareTimes(ranges: IDateRange[], selectedDay: string, prepTime?: number): string[] {
    const day = new Date(selectedDay);
    const selectedDayRange = ranges.find((range) => isEqual(day, range.start));

    if (!selectedDayRange) return [];

    const start = selectedDayRange.start;
    const end = Number.isFinite(prepTime) ? add(selectedDayRange.end, { minutes: -prepTime }) : selectedDayRange.end;

    const timeRanges: Date[] = [];

    let nextTime, currentTime;
    do {
        const lastTime = timeRanges[timeRanges.length - 1] || start;

        currentTime = add(lastTime, { minutes: TIME_INTERVAL });
        nextTime = add(currentTime, { minutes: TIME_INTERVAL });

        timeRanges.push(currentTime);
    } while (isWithinInterval(nextTime, { start, end }));

    const filterTimeAfterDay = (time) => {
        if (isToday(day)) {
            return isAfter(time, new Date(Date.now()));
        }
        return isAfter(time, selectedDayRange.start);
    };

    const filterTimeLessThanTimeMins = (time) => {
        return differenceInMinutes(time, new Date(Date.now())) >= (prepTime || TIME_INTERVAL);
    };

    const filteredRanges = timeRanges
        .filter(filterTimeAfterDay)
        .filter(filterTimeLessThanTimeMins)
        .map((time) => time.toISOString());

    return filteredRanges;
}

export function enhanceDaysWithLabel(days: string[], timezone: string): { label: string; value: string }[] {
    const now = new Date(Date.now());
    const nowTimezoned = utcToZonedTime(now, timezone);

    return days.map((day, i) => {
        const dayTimezoned = utcToZonedTime(day, timezone);
        const dayFormat = format(dayTimezoned, 'MM/dd/yyyy');
        if (i === 0 && isSameDay(nowTimezoned, dayTimezoned)) {
            return {
                label: `Today (${dayFormat})`,
                value: day,
            };
        }
        return {
            label: dayFormat,
            value: day,
        };
    });
}

export function enhanceTimesWithLabel(times: string[], timezone: string): { label: string; value: string }[] {
    return times.map((time) => ({
        label: format(utcToZonedTime(time, timezone), 'hh:mm a'),
        value: time,
    }));
}

export function isInRange(pickupTime: string, workingHours: WorkingHours): boolean {
    if (!isValid(parseISO(pickupTime))) return false;

    const pickupTimeAsDate = Date.parse(pickupTime); // in milliseconds UTC

    const selectedWorkingHourDay = workingHours.find(
        (day) => parseISO(pickupTime).getUTCDate() === parseISO(day.day).getUTCDate()
    );

    if (!selectedWorkingHourDay) return false;

    const selectedWorkingHoursPickupTime = selectedWorkingHourDay.timeRange.find(
        (pickupTime) => pickupTimeAsDate === Date.parse(pickupTime) // in milliseconds UTC
    );

    if (!selectedWorkingHoursPickupTime) return false;

    return true;
}

type TimeRange = { label: string; value: string; hint?: string };
interface IAddASAPFieldToRange {
    timeRange: TimeRange[];
    selectedDay: string;
    timezone: string;
    prepTime?: number;
    asapWithoutBrackets?: boolean;
    isAdditionalASAP?: boolean;
}

export function addASAPFieldToRange({
    timeRange,
    selectedDay,
    timezone,
    prepTime,
    asapWithoutBrackets,
    isAdditionalASAP,
}: IAddASAPFieldToRange): TimeRange[] {
    const now = new Date(Date.now());
    const nowTimezoned = utcToZonedTime(now, timezone);
    const { brandId } = getBrandInfo();
    const parsedDay = new Date(selectedDay);
    const parsedDayTimezoned = utcToZonedTime(parsedDay, timezone);

    if (!isSameDay(parsedDayTimezoned, nowTimezoned)) return timeRange;

    const copy = timeRange.slice();

    if (!isAdditionalASAP) {
        const asaplabel = timeRange[0]?.label;
        const asapValue = timeRange[0]?.value;
        const asapOption =
            brandId === 'Arbys'
                ? getArbysAsapOption()
                : {
                      label: !asapWithoutBrackets ? `ASAP (${asaplabel})` : `ASAP ${asaplabel}`,
                      value: asapValue,
                      hint: undefined,
                  };

        if (prepTime) asapOption.hint = `(Ready in ~${prepTime} minutes)`;
        const deleteCount = brandId === 'Arbys' ? 0 : 1;
        copy.splice(0, deleteCount, asapOption);
    } else {
        copy.unshift({ label: 'ASAP', value: null });
    }

    return copy;
}

const getArbysAsapOption = () => ({
    label: `ASAP `,
    value: 'asap',
    hint: undefined,
});
