import { SellingChannelNamesModel, IError400ResponseModel } from '../../../@generated/webExpApi';
import {
    WebExperienceApi,
    UpdateCustomerAccountRequest,
    DeleteCustomerAccountRequest,
    GetCustomerAccountRequest,
    UploadCustomerAvatarRequest,
    GetProfileIdRequest,
} from '../../../@generated/webExpApi/apis/index';
import {
    ITransformedGetCustomerAccountResponseForClientModel,
    ITransformedCustomerIdResponseForClientModel,
    ICustomerAvatarResponseModel,
    IGetProfileIdResponseModel,
    IGetProfileIdRequestModel,
} from '../../../@generated/webExpApi/models';
import createErrorWrapper from '../createErrorWrapper';
import { getAuthorizationConfig } from '../../helpers/getAuthorizationConfig';

const initAccountService = (jwt) => {
    const expApi = new WebExperienceApi(getAuthorizationConfig(jwt));
    const getAccount = createErrorWrapper<
        ITransformedGetCustomerAccountResponseForClientModel,
        GetCustomerAccountRequest
    >('getCustomerAccount', expApi.getCustomerAccount.bind(expApi));

    const updateAccount = createErrorWrapper<
        ITransformedCustomerIdResponseForClientModel,
        UpdateCustomerAccountRequest
    >('updateCustomerAccount', expApi.updateCustomerAccount.bind(expApi));

    const getDeleteAccountApi = createErrorWrapper<
        ITransformedGetCustomerAccountResponseForClientModel,
        DeleteCustomerAccountRequest
    >('deleteCustomerAccount', expApi.deleteCustomerAccount.bind(expApi));

    const getExternalId = createErrorWrapper<IGetProfileIdResponseModel, GetProfileIdRequest>(
        'getProfileId',
        expApi.getProfileId.bind(expApi)
    );

    const deleteAccount = async (): Promise<
        ITransformedGetCustomerAccountResponseForClientModel | IError400ResponseModel
    > => {
        return getDeleteAccountApi({
            sellingChannel: SellingChannelNamesModel.Weboa,
        });
    };

    const uploadAvatar = createErrorWrapper<ICustomerAvatarResponseModel, UploadCustomerAvatarRequest>(
        'uploadCustomerAvatar',
        expApi.uploadCustomerAvatar.bind(expApi)
    );

    const getProfileExternalId = ({ email }: IGetProfileIdRequestModel) => {
        return getExternalId({ iGetProfileIdRequestModel: { email }, sellingChannel: SellingChannelNamesModel.Weboa });
    };

    return { getAccount, updateAccount, deleteAccount, uploadAvatar, getProfileExternalId };
};
export { initAccountService };
