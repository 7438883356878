import Script from 'next/script';
import React, { useEffect, FC } from 'react';
import useBranchIO from '../redux/hooks/useBranchIO';
import { isBranchIOEnabled } from '../lib/getFeatureFlags';
import * as branchIOStore from '../redux/branchIO';

const BranchIOSnippet: FC = () => {
    const BRANCH_IO_KEY = process.env.NEXT_PUBLIC_BRANCH_KEY;
    const {
        actions: { setBranchIO, setBranchIOError },
    } = useBranchIO();

    useEffect(() => {
        typeof window !== 'undefined' &&
            window.branch?.init &&
            window.branch.init(BRANCH_IO_KEY, (err: { message: string }, data: branchIOStore.IBranchIOState) => {
                if (err) setBranchIOError(err.message);
                else setBranchIO(data);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isBranchIOEnabled() && (
                <Script
                    id="branch-io-sdk"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
                    // load Branch
                    (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode".split(" "), 0);
                    `,
                    }}
                />
            )}
        </>
    );
};

export default BranchIOSnippet;
