import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IGlobalContentfulProps } from '../common/services/globalContentfulProps';

const GLOBAL_PROPS = 'globalProps';

export interface IGlobalPropsState {
    data?: IGlobalContentfulProps;
    loading: 'idle' | 'pending' | 'succeeded' | 'failed';
}

const initialState = {
    loading: 'idle',
} as IGlobalPropsState;

const fetchGlobalProps = createAsyncThunk(
    `${GLOBAL_PROPS}/fetch`,
    async () => {
        const dataRaw = await fetch('/api/global-props');
        const data = (await dataRaw.json()) as IGlobalContentfulProps;

        return data;
    },
    {
        condition: (_, { getState }) => {
            const { globalProps } = getState() as { globalProps: IGlobalPropsState };
            const { loading } = globalProps;

            if (loading !== 'idle') {
                return false;
            }
        },
    }
);

const globalPropsSlice = createSlice({
    name: GLOBAL_PROPS,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGlobalProps.pending, (state) => {
            state.loading = 'pending';
        });
        builder.addCase(fetchGlobalProps.rejected, (state) => {
            state.loading = 'failed';
        });
        builder.addCase(fetchGlobalProps.fulfilled, (state, action) => {
            state.loading = 'succeeded';
            state.data = action.payload;
        });
    },
});

export const actions = { ...globalPropsSlice.actions, fetchGlobalProps };

export default globalPropsSlice.reducer;
