import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetCustomerOrderHistoryResponseModel, ISurveyModel } from '../@generated/webExpApi';

export type IMyTeams = ISurveyModel[];

export type ISetOrderHistoryPayload = IGetCustomerOrderHistoryResponseModel;

export type IMyTeamsState = ISurveyModel & {
    loading: boolean;
    error: string | null;
};

export const initialState: IMyTeamsState = {
    hasResponded: false,
    id: '',
    description: '',
    questions: [],
    replies: [],
    loading: false,
    error: null,
};

const myTeamsSlice = createSlice({
    name: 'myTeams',
    initialState,
    reducers: {
        setMyTeams: (state, action: PayloadAction<IMyTeamsState>) => {
            const { hasResponded, id, description, questions, replies } = action.payload;
            state.hasResponded = hasResponded;
            state.id = id;
            state.description = description;
            state.questions = questions;
            state.replies = replies;
            state.loading = false;
            state.error = null;
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (_, action: PayloadAction<string>) => ({
            ...initialState,
            error: action.payload,
        }),
    },
});

export const { actions, name } = myTeamsSlice;

export default myTeamsSlice.reducer;
