import { getWeek, isWithinInterval } from './dateTime';

import { TServiceTypeModel, OtherPriceTypeEnumModel, ItemModel, TallyPriceTypeModel } from '../../@generated/webExpApi';

import { resolveOpeningHours } from '../../lib/locations';
import { LocationWithDetailsModel } from '../services/locationService/types';
import getLocationService from '../../lib/locations/getLocationService';
import { getWeekOpenedTimeRanges } from '../../lib/locations/resolveOpeningHours';

export enum DiscountType {
    HappyHour = TallyPriceTypeModel.HappyHour,
    Promo = TallyPriceTypeModel.Promo,
}

export type DiscountAvailability = {
    [value in DiscountType]: boolean;
};

export const DiscountStringMap = {
    [DiscountType.HappyHour]: 'Happy Hour',
};

const discountTypeToTallyPriceTypeMap = new Map<DiscountType, TallyPriceTypeModel>([
    [DiscountType.HappyHour, TallyPriceTypeModel.HappyHour],
    [DiscountType.Promo, TallyPriceTypeModel.Promo],
]);

const discountTypeMap = new Map<DiscountType, OtherPriceTypeEnumModel>([
    [DiscountType.HappyHour, OtherPriceTypeEnumModel.HappyHour],
    [DiscountType.Promo, OtherPriceTypeEnumModel.Promo],
]);

const getOpenedTimeRanges = (location: LocationWithDetailsModel, serviceType: TServiceTypeModel, date: Date) => {
    const orderWeek = getWeek(date);
    const currentWeek = getWeek(new Date());
    const targetWeek = orderWeek - currentWeek;

    const locationService = getLocationService(location, serviceType);
    const hours = locationService?.hours;

    const { timezone: storeTimezone } = location;

    const openedTimeRanges = getWeekOpenedTimeRanges(hours, targetWeek, storeTimezone);

    return openedTimeRanges;
};

export const getDiscountAvailability = (location: LocationWithDetailsModel, date?: Date): DiscountAvailability => ({
    [DiscountType.HappyHour]: date
        ? getOpenedTimeRanges(location, TServiceTypeModel.HappyHour, date)?.some((range) =>
              isWithinInterval(date, range)
          )
        : !!resolveOpeningHours(location, TServiceTypeModel.HappyHour)?.isOpen,
    [DiscountType.Promo]: true,
});

export const getDiscountType = (
    product: ItemModel,
    location: LocationWithDetailsModel,
    date?: Date
): DiscountType | null => {
    const discountAvailability = getDiscountAvailability(location, date);
    const otherPrices = Object.keys(product?.price?.otherPrices || {})
        .map((key) => product.price.otherPrices[key])
        .sort((a, b) => a.price - b.price);
    const otherPriceType = otherPrices?.[0]?.priceType;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const discountType = [...discountTypeMap].find(([_, value]) => value === otherPriceType)?.[0];

    return discountAvailability[discountType] ? discountType : null;
};

export const getDiscountPriceByType = (product: ItemModel, discountType?: DiscountType): number | null => {
    if (discountType) {
        const price = product?.price?.otherPrices?.[discountTypeMap.get(discountType)]?.price;

        return price === undefined ? null : price;
    }

    return null;
};

export const getDiscountPrice = (
    product: ItemModel,
    location: LocationWithDetailsModel,
    date?: Date
): number | null => {
    const discountType = getDiscountType(product, location, date);

    return getDiscountPriceByType(product, discountType);
};

export const getTallyPriceType = (discountType: DiscountType): TallyPriceTypeModel => {
    return discountTypeToTallyPriceTypeMap.get(discountType);
};
