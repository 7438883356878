import { WebExperienceApi } from '../../../@generated/webExpApi';
import { getCountryCodeFromLocale, getLocale } from '../../helpers/locale';
import createErrorWrapper from '../createErrorWrapper';
import { AllLocationsListModel, ISearchLocationsRequest } from './types';

const expApi = new WebExperienceApi();

const searchLocationsApi = createErrorWrapper<AllLocationsListModel, ISearchLocationsRequest>(
    'searchLocationsByCountryState',
    expApi.searchLocations.bind(expApi)
);

const searchLocations = (countryCode?: string, stateOrProvinceCode?: string): Promise<AllLocationsListModel> => {
    const countryCodeParam = countryCode || getCountryCodeFromLocale(getLocale());

    return searchLocationsApi({
        countryCode: countryCodeParam,
        stateOrProvinceCode,
    });
};

export default searchLocations;
