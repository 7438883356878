import { Brand, BrandId } from '../common/constants/brands';

export interface IBrandInfo {
    brandId: BrandId;
    brandName: string;
}

export const BRANDS: { [key in Brand]: IBrandInfo } = {
    arbys: {
        brandId: 'Arbys',
        brandName: "Arby's",
    },
    bww: {
        brandId: 'Bww',
        brandName: 'Buffalo Wild Wings',
    },
    jje: {
        brandId: 'Jje',
        brandName: 'Jimmy Johns',
    },
    sdi: {
        brandId: 'Sdi',
        brandName: 'Sonic',
    },
};

const getBrandInfo = (): IBrandInfo => BRANDS[process.env.NEXT_PUBLIC_BRAND];

export default getBrandInfo;
