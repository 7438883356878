import { Configuration } from '../../@generated/webExpApi';

export const getAuthorizationConfig = (JWT: string) =>
    new Configuration({
        middleware: [
            {
                pre: ({ url, init }) => {
                    const fetchParams = {
                        url,
                        init: {
                            ...init,
                            headers: {
                                ...init.headers,
                                ['Authorization']: JWT,
                            },
                        },
                    };

                    return Promise.resolve(fetchParams);
                },
            },
        ],
    });
