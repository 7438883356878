import { IMenuCategoryFields } from '../../@generated/@types/contentful';
import { CategoryModel } from '../../@generated/webExpApi';

export const getContentfulMenuCategoryIdsByFields = (fields: IMenuCategoryFields): string[] => {
    return fields.categoryIdList && fields.categoryIdList.length ? fields.categoryIdList : [fields.categoryId];
};

export const getDomainMenuCategoryIdByIds = (
    ids: string[],
    categories: { [key: string]: CategoryModel },
    unavailableCategoriesIds: string[] = []
) => {
    const filteredIds = ids.filter((id) => !unavailableCategoriesIds.includes(id));

    for (const id of filteredIds) {
        if (categories[id]) return id;
    }

    return null;
};
