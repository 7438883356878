import { Dictionary } from '@reduxjs/toolkit';
import {
    CategoryModel,
    ItemGroupModel,
    IOrderProductModel,
    IProductGroupModel,
    ItemModel,
    TallyProductModel,
    NutritionModel,
} from '../../../@generated/webExpApi';

import { useAppSelector } from '../../store';
import {
    selectCategories,
    selectComboMainProduct,
    selectDefaultModifiers,
    selectDefaultTallyItem,
    selectIsProductEditable,
    selectItemGroup,
    selectProductById,
    selectProductDiscountPriceAndCalories,
    selectProductGroupByName,
    selectProductGroupByProductId,
    selectProductIsCombo,
    selectProducts,
    selectProductsByIds,
    selectProductSize,
    selectRootProductByItemId,
    selectProductSizes,
    selectRelatedComboByMainProductIdAndSizeId,
    selectSelectedModifiers,
    selectSelectedSideAndDrinks,
    selectTopCategory,
    selectProductNutrition,
    selectNoSauceDomainProduct,
    selectHistoryTallyModifierGroups,
    selectUnavailableCategories,
    selectNoItemProductIds,
    selectProductByIds,
    selectProductByIdsWithRootId,
    ItemModelRootProductId,
    selectProductSizeFromChildItemGroup,
    selectAllCondiments,
    selectAllCondimentsIds,
    selectProductDrinkIds,
    selectIsLocationMenuError,
    selectDrinkCarrierCondiment,
    selectItemGroupById,
    selectProductsByItemGroupId,
} from '../../selectors/domainMenu';
import { PDPTallyItem } from '../../pdp';
import {
    IDefaultModifier,
    IDisplayProduct,
    IDomainProductItem,
    ISelectedModifier,
    ISizeSelection,
    PDPTallyItemModifierGroup,
} from '../../types';
import { useMemo } from 'react';
import { selectTallyItemsWithPricesAndCalories, selectTallyPriceAndCalories } from '../../selectors/tally';
import { IProductFields } from '../../../@generated/@types/contentful';
import { getContentfulProductIdsByFields } from '../../../common/helpers/getContentfulProductIdsByFields';
import { selectChildItemsWithDisplayPrices } from '../../selectors/domainMenu/selectChildItemsWithDisplayPrices';

export type IProducts = { [key: string]: IDomainProductItem };

export const useProducts = (): IProducts =>
    useAppSelector((state) => {
        const products = selectProducts(state);
        return products;
    });

export const useDomainProduct = (productId: string): IDomainProductItem | null =>
    useAppSelector((state) => selectProductById(state, productId));

export const useDomainProductByContentfulFields = (fields: IProductFields): IDomainProductItem | null =>
    useAppSelector((state) => selectProductByIds(state, getContentfulProductIdsByFields(fields)));

export const useDomainProductWithRootIdByContentfulFields = (fields: IProductFields): ItemModelRootProductId | null =>
    useAppSelector((state) => selectProductByIdsWithRootId(state, getContentfulProductIdsByFields(fields)));

export const useDomainProducts = (productIds: string[]): Dictionary<IDomainProductItem> =>
    useAppSelector((state) => selectProductsByIds(state, productIds));

export const useDomainMenuCategories = () => useAppSelector(selectCategories);

export const useDomainProductDrinkIds = () => useAppSelector(selectProductDrinkIds);

export const useDrinkCarrierCondiment = () => useAppSelector(selectDrinkCarrierCondiment);

export const useNoSauceDomainProduct = (modifiers: IDisplayProduct[]): IDomainProductItem | undefined =>
    useAppSelector((state) => selectNoSauceDomainProduct(state, modifiers));

export const useNoItemProductIds = (modifiers: string[]): string[] =>
    useAppSelector((state) => selectNoItemProductIds(state, modifiers));

export const useCategories = (): { [p: string]: CategoryModel } => useAppSelector((state) => selectCategories(state));

export const useProductGroup = (productId: string): IProductGroupModel =>
    useAppSelector((state) => selectProductGroupByProductId(state, productId));

export const useDiscountPriceAndCalories = (productId: string): { calories?: number; price?: number } =>
    useAppSelector((state) => selectProductDiscountPriceAndCalories(state, productId));

export const useProductIsCombo = (productId: string): boolean =>
    useAppSelector((state) => selectProductIsCombo(state, productId));

export const useProductCategory = (productId: string): CategoryModel =>
    useAppSelector((state) => selectTopCategory(state, productId));

export const useIsProductEditable = (productId: string): boolean =>
    useAppSelector((state) => selectIsProductEditable(state, productId));

export const useProductItemGroup = (productId: string): ItemGroupModel =>
    useAppSelector((state) => selectItemGroup(state, productId));

export const useNutrition = (productId: string): NutritionModel =>
    useAppSelector((state) => selectProductNutrition(state, productId));

export const useTallyPriceAndCalories = (
    tallyItem: TallyProductModel,
    applyDiscount = true
): { price: number; calories: number; totalPrice: number; calorieRange?: { min: string; max: string } } => {
    return useAppSelector((state) => selectTallyPriceAndCalories(state, tallyItem, applyDiscount));
};

interface IUseTallyItemsWithPricesAndCalories extends TallyProductModel {
    productData: {
        price: number;
        calories: number;
        totalPrice: number;
        offerPrice?: number;
    };
}

export const useTallyItemsWithPricesAndCalories = (
    tallyItems: TallyProductModel[],
    applyDiscount = true
): IUseTallyItemsWithPricesAndCalories[] => {
    return useAppSelector((state) => selectTallyItemsWithPricesAndCalories(state, tallyItems, applyDiscount));
};

export const useProductSizes = (productId: string): ISizeSelection[] =>
    useAppSelector((state) => selectProductSizes(state, productId));

export const useProductSizesFromChildItemGroup = (
    productId: string,
    rootProductId: string,
    productGroupId: string
): ISizeSelection[] =>
    useAppSelector((state) => selectProductSizeFromChildItemGroup(state, productId, rootProductId, productGroupId));

export const useDefaultModifiers = (productId: string): IDefaultModifier[] => {
    const selector = useMemo(() => selectDefaultModifiers(), []);

    return useAppSelector((state) => selector(state, productId));
};

export const useDefaultModifiersByModifierGroupType = (
    productId: string,
    modifierGroupTypes: string[]
): IDefaultModifier[] => {
    const modifiers = useDefaultModifiers(productId);
    return modifiers.filter((item) => modifierGroupTypes.includes(item.metadata?.MODIFIER_GROUP_TYPE));
};

export const useSelectedModifiers = (tallyItem: PDPTallyItem): ISelectedModifier[] => {
    const selector = selectSelectedModifiers();
    return useAppSelector((state) => selector(state, tallyItem));
};

export const useSelectedModifiersByModifierGroupTypes = (
    tallyItem: PDPTallyItem,
    modifierGroupTypes: string[]
): ISelectedModifier[] => {
    const modifiers = useSelectedModifiers(tallyItem);
    return modifiers.filter((item) => modifierGroupTypes.includes(item.metadata?.MODIFIER_GROUP_TYPE));
};

export const useDefaultTallyItem = (id: string): PDPTallyItem => {
    return useAppSelector((state) => selectDefaultTallyItem(state, id));
};

export const useSelectedSideAndDrinks = (tallyItem: PDPTallyItem): string[] => {
    return useAppSelector((state) => selectSelectedSideAndDrinks(state, tallyItem));
};

export const useComboMainDefaultProduct = (id: string): ItemModel => {
    return useAppSelector((state) => selectComboMainProduct(state, id));
};

export const useSelectHistoryModifierGroups = (tallyItem: IOrderProductModel[]): PDPTallyItemModifierGroup[][] => {
    return useAppSelector((state) => selectHistoryTallyModifierGroups(state, tallyItem));
};

export const useSelectUnavailableCategories = (orderTime: string, timeZone: string): string[] => {
    return useAppSelector((state) => selectUnavailableCategories(state, orderTime, timeZone));
};

export const useAllCondiments = (): IDisplayProduct[] => useAppSelector(selectAllCondiments);

export const useAllCondimentsIds = (): string[] => useAppSelector(selectAllCondimentsIds);

export const useIsLocationMenuError = () => useAppSelector(selectIsLocationMenuError);

export const useDomainMenuSelectors = (): {
    selectProductSize: (productId: string) => string;
    selectRootProductByItemId: (productId: string) => ItemModel;
    selectProductSizes: (productId: string) => ISizeSelection[];
    selectProductById: (productId: string) => ItemModel;
    selectDefaultModifiers: (productId: string) => IDefaultModifier[];
    selectProductGroupByProductId: (productId: string) => IProductGroupModel;
    selectProductGroupByName: (productGroupName: string) => IProductGroupModel;
    selectSelectedModifiers: (tallyItem: PDPTallyItem) => ISelectedModifier[];
    selectRelatedComboByMainProductIdAndSizeId: (id: string, comboSize: string) => ItemModel;
    selectDefaultTallyItem: (id: string) => PDPTallyItem;
    selectChildItemsWithDisplayPrices: (childItems: PDPTallyItem[], comboId: string) => PDPTallyItem[];
    selectItemGroupById: (id: string) => ItemGroupModel;
    selectProductsByItemGroupId: (itemGroupId: string) => IDomainProductItem[];
} => {
    const state = useAppSelector((state) => state);
    return {
        selectProductSize: (productId: string) => selectProductSize(state, productId),
        selectRootProductByItemId: (productId: string) => selectRootProductByItemId(state, productId),
        selectProductSizes: (productId: string) => selectProductSizes(state, productId),
        selectProductById: (productId: string) => selectProductById(state, productId),
        selectDefaultModifiers: (productId: string) => selectDefaultModifiers()(state, productId),
        selectSelectedModifiers: (tallyItem: PDPTallyItem) => selectSelectedModifiers()(state, tallyItem),
        selectProductGroupByProductId: (productId: string) => selectProductGroupByProductId(state, productId),
        selectProductGroupByName: (productGroupName: string) => selectProductGroupByName(state, productGroupName),
        selectRelatedComboByMainProductIdAndSizeId: (id: string, comboSize: string) =>
            selectRelatedComboByMainProductIdAndSizeId(state, id, comboSize),
        selectDefaultTallyItem: (productId: string) => selectDefaultTallyItem(state, productId),
        selectChildItemsWithDisplayPrices: (childItems: PDPTallyItem[], comboId: string) =>
            selectChildItemsWithDisplayPrices(state, childItems, comboId),
        selectItemGroupById: (id: string) => selectItemGroupById(state, id),
        selectProductsByItemGroupId: (itemGroupId: string) => selectProductsByItemGroupId(state, itemGroupId),
    };
};
