import { BeerMenuResponseModel } from '../../@generated/webExpApi';
import { GetBeerMenuRequest, WebExperienceApi } from '../../@generated/webExpApi/apis/index';

import createErrorWrapper from './createErrorWrapper';
import HttpStatusCode from './httpStatusCode';

const experiencingTechnicalDifficultiesError = 'We are experiencing technical difficulties. Please try again later.';

const errorMap = {
    [HttpStatusCode.NOT_FOUND]: experiencingTechnicalDifficultiesError,
    [HttpStatusCode.INTERNAL_SERVER_ERROR]: experiencingTechnicalDifficultiesError,
};

const expApi = new WebExperienceApi();

const getBeerMenuByLocationIdApi = createErrorWrapper<BeerMenuResponseModel, GetBeerMenuRequest>(
    'getBeerMenuByLocationId',
    expApi.getBeerMenu.bind(expApi),
    errorMap
);

export async function getBeerMenuByLocationId(locationId: string): Promise<BeerMenuResponseModel> {
    return getBeerMenuByLocationIdApi({ locationId });
}
