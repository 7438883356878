import { useContext } from 'react';
import { IGlobalContentfulProps } from '../../common/services/globalContentfulProps';
import globalPropsContext from '../../common/contexts/globalPropsContext';

// TODO move this to /common/hooks as it doesn't connect to redux
export default function useGlobalProps(): IGlobalContentfulProps | null {
    const globalProps = useContext(globalPropsContext);

    return globalProps;
}
