import { ActionReducerMapBuilder, AsyncThunk, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

/**
 * Use this function to automatically add loading and error states to a thunk that fetches a value from
 * the remote source.
 *
 * @param builder
 * @param thunk
 * @param reducerOnFulfilled
 */
export function InspireThunk<R, P, S = R>(
    builder: ActionReducerMapBuilder<{ loading: boolean; error: boolean } | S>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thunk: AsyncThunk<R, P, any>,
    reducer: CaseReducer<S, PayloadAction<R>>
): ActionReducerMapBuilder<{ loading: boolean; error: boolean } | S> {
    builder.addCase(thunk.pending, () => ({
        loading: true,
        error: false,
    }));
    builder.addCase(thunk.rejected, () => ({
        loading: false,
        error: true,
    }));
    builder.addCase(thunk.fulfilled, reducer);
    return builder;
}
