export enum FACTS {
    DATE = 'date', // MM/DD/YYYY
    TIME = 'time', // HH:MM
    REWARDS_POINTS = 'rewards_points',
    DAY_OF_THE_WEEK = 'day_of_the_week', // Monday, Friday ...
    LOCATION_ID = 'location_id',
    LOCATION_DETAILS_PAGE_ID = 'location_details_page_id',
    IS_OA_LOCATION = 'is_oa_location',
    IS_LOCATION_SELECTED = 'is_location_selected',
    AUTHENTICATED_USER = 'is_user_authenticated',
    APPLICATION = 'application',
}

export const DAYS_OF_WEEK: Record<number, string> = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
};

export enum FACTS_DEPENDENCIES {
    ACCOUNT = 'account',
    REWARDS = 'rewards',
}

export const DATE_FACT_FORMAT = 'MM/dd/yyyy';
export const TIME_FACT_FORMAT = 'HH:mm';
