import { useEffect } from 'react';
import { Router } from 'next/router';

const useRouteChangeComplete = (callback: EventListener): void => {
    useEffect(() => {
        Router.events.on('routeChangeComplete', callback);

        return () => {
            Router.events.off('routeChangeComplete', callback);
        };
    }, [callback]);
};

export default useRouteChangeComplete;
