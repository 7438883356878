import { PayloadAction } from '@reduxjs/toolkit';
import { IMenuModel, ItemModel } from '../../@generated/webExpApi';
import { useAppDispatch, useAppSelector } from '../store';
import { actions, getPosMenuByLocation } from '../domainMenu';
import { isLocationOrderAheadAvailable, isLocationDeliveryAvailable } from '../../lib/locations';
import { LocationWithDetailsModel } from '../../common/services/locationService/types';
import * as featureFlags from '../../lib/getFeatureFlags';
import { IMenuCategory, ITapListMenuCategory } from '../../@generated/@types/contentful';
import { selectCategories, selectError, selectLoading, selectProductList } from '../selectors/domainMenu';
import useLocalTapList from './useLocalTapList';
import isTapListMenuCategory from '../../common/helpers/isTapListMenuCategory';
import { selectConfiguration } from '../selectors/configuration';
import { OrderLocationMethod } from '../orderLocation';
import {
    getContentfulMenuCategoryIdsByFields,
    getDomainMenuCategoryIdByIds,
} from '../../common/helpers/menuCategoryHelper';
import { useUnavailableCategories } from '../../common/hooks/useUnavailableCategories';
import getCategoriesWithDefaultReasonFilter from '../../common/helpers/getCategoriesWithDefaultReasonFilter';

interface IGetAvailableCategories {
    (catergories: (IMenuCategory | ITapListMenuCategory)[]): (IMenuCategory | ITapListMenuCategory)[];
}

interface UseDomainMenuHook {
    products: ItemModel[];
    loading: boolean;
    error: boolean;
    actions: {
        getDomainMenu: (location?: LocationWithDetailsModel, orderMethod?: OrderLocationMethod) => void;
        setDomainMenu: (payload: IMenuModel) => PayloadAction<IMenuModel>;
        getAvailableCategories: IGetAvailableCategories;
    };
}

export default function useDomainMenu(): UseDomainMenuHook {
    const dispatch = useAppDispatch();
    const locationSpecificMenuCategoriesEnabled = featureFlags.locationSpecificMenuCategoriesEnabled();

    const loading = useAppSelector(selectLoading);
    const error = useAppSelector(selectError);
    const products = useAppSelector(selectProductList);
    const categories = useAppSelector(selectCategories);
    const unavailableCategoriesIds = useUnavailableCategories();
    const { isOAEnabled } = useAppSelector(selectConfiguration);
    const {
        available: localTapListAvailable,
        actions: { getLocalTapList, setLocalTapList },
    } = useLocalTapList();

    const getDomainMenu = (
        location?: LocationWithDetailsModel,
        orderMethod: OrderLocationMethod = OrderLocationMethod.PICKUP
    ) => {
        let targetStoreId;
        if (orderMethod === OrderLocationMethod.PICKUP) {
            const isOrderAheadAvailable = isLocationOrderAheadAvailable(location, isOAEnabled);
            targetStoreId = isOrderAheadAvailable ? location.id : undefined;
        }
        if (orderMethod === OrderLocationMethod.DELIVERY) {
            const isDeliveryAvailable = isLocationDeliveryAvailable(location, isOAEnabled);
            targetStoreId = isDeliveryAvailable ? location.id : undefined;
        }

        dispatch(getPosMenuByLocation(targetStoreId));

        if (featureFlags.isLocalTapListOn() && location) {
            location.id ? getLocalTapList(location.id) : setLocalTapList(null);
        }
    };

    const getAvailableCategories: IGetAvailableCategories = (cmsCategories) => {
        let availableCmsCategories = cmsCategories;

        // not filter cms categories in case of location and national menu error
        if (!error) {
            availableCmsCategories = cmsCategories.filter((category) => {
                if (isTapListMenuCategory(category)) {
                    return localTapListAvailable;
                }

                if (!locationSpecificMenuCategoriesEnabled) {
                    return true;
                }

                const categoryId = getDomainMenuCategoryIdByIds(
                    getContentfulMenuCategoryIdsByFields(category.fields),
                    categories,
                    unavailableCategoriesIds
                );

                return categories[categoryId];
            });
        }

        return getCategoriesWithDefaultReasonFilter(availableCmsCategories, categories);
    };

    const setDomainMenu = (payload: IMenuModel) => dispatch(actions.setDomainMenu(payload));

    return {
        products,
        loading,
        error,
        actions: {
            getDomainMenu,
            setDomainMenu,
            getAvailableCategories,
        },
    };
}
