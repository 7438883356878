import React, { ErrorInfo, ReactNode } from 'react';
import { GtmErrorCategory, GtmEventNames } from '../../../common/services/gtmService/types';
import logger from '../../../common/services/logger';
import { gtmServicePush } from '../../../lib/gtmServicePush';

import styles from './errorBoundary.module.css';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error: string;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: '' };
    }

    static getDerivedStateFromError(error: string): ErrorBoundaryState {
        return { hasError: true, error: error.toString() };
    }

    static pageTitle = 'Something went wrong.';

    componentDidCatch(error: Error, info: ErrorInfo): void {
        logger.logError(error, { ...info, source: 'error_boundary' });
        logger.logEvent('error_boundary', {
            error,
            ...info,
        });

        gtmServicePush(GtmEventNames.Error, {
            error: {
                ErrorCategory: GtmErrorCategory.GENERAL_ERROR,
                ErrorDescription: ErrorBoundary.pageTitle,
                ErrorDescriptionVendor: error.message,
            },
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className={styles.container}>
                    <h4 className="t-header-h3">{ErrorBoundary.pageTitle}</h4>
                    <p>We are working on a solution to fix this problem.</p>
                </div>
            );
        }

        return this.props.children;
    }
}
