import { createSlice } from '@reduxjs/toolkit';
import { actions as tallyActions } from './tallyOrder';
const PAGE_LOADER = 'pageLoader';

export interface IPageLoaderState {
    show: boolean;
}

const pageLoaderSlice = createSlice({
    name: PAGE_LOADER,
    initialState: { show: false },
    reducers: {
        showLoader: (state) => {
            state.show = true;
        },
        hideLoader: (state) => {
            state.show = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(tallyActions.invalid, (state) => {
                state.show = false;
            })
            .addCase(tallyActions.reset, (state) => {
                state.show = false;
            });
    },
});

export const { actions } = pageLoaderSlice;

export default pageLoaderSlice.reducer;
