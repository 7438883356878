import { ConfigurationValues } from '../../../redux/configuration';
import { TInitialPaymentTypes } from '../../clientOnly/paymentInfoContainer/paymentTypeDropdown/types';

import { isPlaceholderPaymentOptionEnabled } from '../../../lib/getFeatureFlags';

// Disabled eslint check since configuration parameter is used in injected versions for other brands (see bww.constants);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getInitialPaymentType(configuration?: ConfigurationValues) {
    return isPlaceholderPaymentOptionEnabled()
        ? TInitialPaymentTypes.PLACEHOLDER
        : TInitialPaymentTypes.CREDIT_OR_DEBIT;
}

export const DISCOUNT_AMOUNT_FROM_SESSIONM = false;
export const PICKUP_SHEVRON_SIZE = 'xs';
export const SHOW_PICKUP_TIME_ASTERISK = false;

export const SHOULD_FILTER_TIME_SLOTS = false;
export const SHOULD_ADD_TIME_FOR_ASAP_PICKUP = false;

export const PICKUP_INFO_HEADER_TEXT = 'Pickup Info';
export const DELIVERY_INFO_HEADER_TEXT = '';
export const CUSTOMER_INFO_HEADER_TEXT = 'Customer Info';

export const SHOULD_ADD_GIFT_CARDS_TO_WALLET = false;
export const IS_PRODUCTS_AND_MODIFIERS_COLLAPSIBLE = true;

export const SHOULD_USE_ADDITIONAL_KOUNT_DATA_COLLECTION_LOGIC = false;

export const IS_LOCATION_NAME_LINK = false;

export const IS_GTM_PICKUP_TIME_AFTER_ORDER_SUBMIT = false;
