import { useState, useMemo, useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../store';
import * as DealPDPStore from '../dealPdp';
import { IRewardMenuIdModel, IRewardMenuIdsRuleEnumModel, TOffersUnionModel } from '../../@generated/webExpApi';
import { useDealDisplayProductsByMenuIds } from './pdp';
import { IDealDisplayProduct, IDefaultModifier, IEditSDIOfferProductModifiers, ISelectedModifier } from '../types';
import useBag from './useBag';
import useRewards from './useRewards';
import { DealsTypesMap, MainDealsTypesByStructure } from '../../common/helpers/dealHelper';
import { DealPDPSelectionByPicks } from '../dealPdp';
import {
    useDealInfoBySelectedIds,
    useDealPDPOffer,
    useDealPDPOfferPicks,
    useDealTallyItems,
    useGetDealTallyItemById,
} from './dealPdp';
import useGlobalProps from './useGlobalProps';
import { GTM_ADD_TO_BAG_DEAL } from '../../common/services/gtmService/constants';
import { selectDealTallyItemById } from '../selectors/dealPdp';
import { selectSdiNewModifierGroups } from '../selectors/pdp';

export enum PickTypesEnum {
    Buy = 'BUY',
    Get = 'GET',
}

export type IDealInfo = {
    price: number;
    calories: number;
    selections: string[];
    addedModifiers: ISelectedModifier[];
    removedDefaultModifiers: IDefaultModifier[];
};

export interface UseDealPDPHook {
    offer: TOffersUnionModel;
    pickIndex: number;
    selectedIdsByPicks: DealPDPSelectionByPicks[];
    dealInfo: IDealInfo;
    onlyOnePick: boolean;
    isLastPick: boolean;
    displayProducts: IDealDisplayProduct[];
    visibleDisplayProducts: IDealDisplayProduct[];
    goNext: () => void;
    goBack: () => void;
    handleProductSelect: (id: string) => void;
    handleAddToBag: () => void;
    actions: {
        putOffer: (payload: DealPDPStore.PutOfferPayload) => void;
        resetDealPdpState: () => void;
        editSDIOfferProductModifiers: (payload: IEditSDIOfferProductModifiers) => void;
    };
}

/**
 * Hook that contains logic related to Deals functionality
 * @method useDealPdp
 * @added  2022-01-31
 * @version  1.0.0
 * @param {boolean} preventReset if passed prevents reset of dealPdpState
 * @returns {UseDealPDPHook} returns a deal related data and actions
 */
export default function useDealPdp(preventReset = false): UseDealPDPHook {
    const dispatch = useAppDispatch();
    const state = useAppSelector((state) => state);

    const { offers } = useRewards();
    const bag = useBag();
    const { dealId } = bag;

    const { productsById, productDetailsPagePaths } = useGlobalProps();

    const [pickIndex, setPickIndex] = useState(0);

    const goNext = () => {
        setPickIndex(pickIndex + 1);
    };

    const goBack = () => {
        setPickIndex(pickIndex - 1);
    };

    const offer = useDealPDPOffer();
    const selectedIdsByPicks = useDealPDPOfferPicks();
    const dealTallyItems = useDealTallyItems();

    const dealInfo = useDealInfoBySelectedIds(selectedIdsByPicks, pickIndex);
    const selectedProducts = dealInfo?.selections;

    const getDealTallyItemById = useGetDealTallyItemById();

    const dealType = DealsTypesMap[offer?.type];

    let maxPickIndex = 0;

    if (dealType?.mainType === MainDealsTypesByStructure.BuyX) {
        maxPickIndex = offer?.applicability?.buyCount - 1;
    }

    if (dealType?.mainType === MainDealsTypesByStructure.BuyX_GetY) {
        if (offer?.applicability?.buyCount > offer?.applicability?.buyIds?.length) {
            maxPickIndex = offer?.applicability?.buyCount - 1;
        } else {
            maxPickIndex = offer?.applicability?.buyCount; // buyCount + 1 (getIds collapse to 1 pick) - 1
        }
    }

    const isLastPick = pickIndex === maxPickIndex;

    const activeMenuIds: IRewardMenuIdModel[] = useMemo(() => {
        if (dealType?.mainType === MainDealsTypesByStructure.BuyX_GetY) {
            const { buyCount, buyIds, getIds } = offer?.applicability || {};

            if (
                (pickIndex < buyCount && buyCount === buyIds?.length) ||
                (pickIndex < buyCount - buyIds?.length && buyCount > buyIds?.length)
            ) {
                return buyIds?.[pickIndex]?.menuIds;
            }

            return getIds[0].menuIds;
        }

        if (dealType?.mainType === MainDealsTypesByStructure.BuyX) {
            const { eligibleIds } = offer?.applicability || {};

            if (pickIndex >= 0) {
                return eligibleIds;
            }
        }
    }, [offer, pickIndex, dealType]);

    const displayProducts = useDealDisplayProductsByMenuIds(activeMenuIds, productsById, productDetailsPagePaths);
    const visibleDisplayProducts = displayProducts?.filter(
        (displayProduct) => displayProduct.displayProductDetails.isVisible
    );

    const putOffer = useCallback(
        (payload: DealPDPStore.PutOfferPayload) => {
            if (payload.offer) {
                const flatGetIds: IRewardMenuIdModel[] =
                    payload.offer?.applicability?.getIds?.reduce((acc, curr) => [...acc, ...curr.menuIds], []) || [];
                const { getCount } = payload.offer?.applicability || {};
                const payloadApplicability = {
                    ...payload.offer?.applicability,
                    getIds:
                        flatGetIds.length > 0
                            ? [
                                  {
                                      rule:
                                          getCount > 1
                                              ? IRewardMenuIdsRuleEnumModel.AllOf
                                              : IRewardMenuIdsRuleEnumModel.OneOf,
                                      menuIds: flatGetIds,
                                  },
                              ]
                            : [],
                };
                const offerPayload = {
                    ...payload,
                    offer: {
                        ...payload.offer,
                        applicability: payloadApplicability,
                    },
                };

                dispatch(DealPDPStore.actions.putOffer(offerPayload));
            }
        },
        [dispatch]
    );

    const putOfferSelectedIdsByPicks = useCallback(
        (payload: DealPDPStore.PutOfferSelectedIdsByPicks) => {
            dispatch(DealPDPStore.actions.putOfferSelectedIdsByPicks(payload));
        },
        [dispatch]
    );

    const resetDealPdpState = useCallback(() => {
        dispatch(DealPDPStore.actions.resetState());
    }, [dispatch]);

    const handleProductSelect = (id: string) => {
        const newSelectedItem = getDealTallyItemById(pickIndex, id);

        if (dealType?.mainType === MainDealsTypesByStructure.BuyX) {
            const shouldHideProductPrice = offer.applicability?.buyCount > 1;

            putOfferSelectedIdsByPicks({
                selectedItems: [newSelectedItem],
                shouldShowDiscountedPrice: true,
                shouldHideProductPrice,
                index: pickIndex,
                multipleSelection: false,
                maxQuantity: 1,
            });
        }

        if (dealType?.mainType === MainDealsTypesByStructure.BuyX_GetY) {
            const selectedItem = selectedIdsByPicks[pickIndex]?.selectedItems?.find((item) => item.productId === id);
            const multipleSelection =
                (isLastPick && offer?.applicability?.getCount > 1) ||
                (offer?.applicability?.buyCount > 1 &&
                    offer?.applicability?.buyCount > offer?.applicability?.buyIds?.length);

            const maxQuantity = isLastPick
                ? offer?.applicability?.getCount
                : offer?.applicability?.buyCount / offer?.applicability?.buyIds?.length;

            const newSelectedItems = selectedItem
                ? selectedIdsByPicks[pickIndex]?.selectedItems?.filter((item) => item.productId !== id) || []
                : [...(selectedIdsByPicks[pickIndex]?.selectedItems || []), newSelectedItem];
            const shouldHideProductPrice = offer.applicability?.getCount > 1 && isLastPick;

            putOfferSelectedIdsByPicks({
                selectedItems: multipleSelection ? newSelectedItems : [newSelectedItem],
                shouldShowDiscountedPrice: isLastPick, // GET PICK
                shouldHideProductPrice,
                index: pickIndex,
                multipleSelection,
                maxQuantity, // 1 for Buy or BuyCount if buyCount > buyIds.length and getCount for Get
            });
        }
    };

    const handleAddToBag = useCallback(() => {
        dealTallyItems.forEach((item) => {
            bag.actions.putToBag({
                pdpTallyItem: item,
            });
        });
        dispatch({ type: GTM_ADD_TO_BAG_DEAL, payload: { productNames: selectedProducts.join(', ') } });
    }, [bag, dealTallyItems, selectedProducts, dispatch]);

    useEffect(() => {
        if (dealId) {
            putOffer({ offer: offers.find((offer) => offer.userOfferId === dealId) });
        }

        return () => {
            !preventReset && resetDealPdpState();
        };
    }, [offers, dealId, preventReset, putOffer, resetDealPdpState]);

    /**
     * Method for editing offer product modifiers specified for SONIC brand
     * @method editSDIOfferProductModifiers
     * @added 1/31/2023
     * @author Pavel Shpakovich <pavel.shpakovich@inspirebrands.com>
     * @param {IEditSDIOfferProductModifiers} payload - The payload containing the data to edit the SDI offer product modifiers.
     * @return {void}
     */
    const editSDIOfferProductModifiers = useCallback(
        (payload: IEditSDIOfferProductModifiers) => {
            const tallyItem = selectDealTallyItemById(state, payload.sectionIndex, payload.productId);

            dispatch(
                DealPDPStore.actions.editOfferProductModifiers({
                    modifierGroups: selectSdiNewModifierGroups(
                        state,
                        payload.modifierGroupId,
                        payload.modifierId,
                        payload.quantity,
                        tallyItem,
                        payload.itemGroupId
                    ),
                    pickIndex: payload.sectionIndex,
                    productId: payload.productId,
                })
            );
        },
        [dispatch, state]
    );

    return {
        offer,
        selectedIdsByPicks,
        dealInfo,
        pickIndex,
        isLastPick,
        onlyOnePick: maxPickIndex === 0,
        displayProducts,
        visibleDisplayProducts,
        goNext,
        goBack,
        handleProductSelect,
        handleAddToBag,
        actions: {
            putOffer,
            resetDealPdpState,
            editSDIOfferProductModifiers,
        },
    };
}
