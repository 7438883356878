import { PaymentTypeModel } from '../../../@generated/webExpApi';

// Key-value parameters in Braze dashboard
export interface Extras {
    type?: 'primary' | 'secondary';
    badgeText?: string;
    badgeFontSize?: string;
    badgeFontColor?: string;
    badgeBackgroundColor?: string;
    buttonText?: string;
    buttonFontColor?: string;
    buttonFontSize?: string;
    buttonBackgroundColor?: string;
    titleFontSize?: string;
    titleFontColor?: string;
    descriptionFontSize?: string;
    descriptionFontColor?: string;
    linkFontSize?: string;
    linkFontColor?: string;
    isInFrontOfRewards?: string;
    fullScreenImage?: string;
}

export enum BrazeEventKey {
    APPLE_PAY_PURCHASE = 'apple_pay_purchase',
    GOOGLE_PAY_PURCHASE = 'google_pay_purchase',
    OA_CC_PURCHASE = 'oa_cc_purchase',
    OA_SVC_PURCHASE = 'oa_svc_purchase',
    ORDER_AHEAD_PURCHASE = 'order_ahead_purchase',
    TIP = 'tip',
    NEW_SVC_ADDED = 'new_svc_added',
    REORDER_ADD = 'reorder_add',
    CANCEL_ORDER = 'cancel_order',
    REWARD_REDEEMED = 'reward_redeemed',
    PROMO_CODE_REDEEMED = 'promo_code_redeemed',
    ORDER_AHEAD_PICKUP = 'order_ahead_pickup',
    SHOWS_UP_AT_WRONG_LOCATION = 'shows_up_at_wrong_location',
    USER_CREATE_ACCOUNT = 'user_create_account',
    USER_LOG_IN = 'user_log_in',
    USER_LOG_OUT = 'user_log_out',
}

export type BrazeEventProperties = {
    [index: string]: unknown;
};

export type CustomBrazeEvent = {
    key: BrazeEventKey | string;
    properties?: BrazeEventProperties;
};

export type BrazePurchaseEvent = {
    productId: string;
    price: number;
    currencyCode: string;
    quantity: number;
    properties?: BrazeEventProperties;
};

export const mapForBrazeEvent = {
    [PaymentTypeModel.Card]: BrazeEventKey.OA_CC_PURCHASE,
    [PaymentTypeModel.ApplePay]: BrazeEventKey.APPLE_PAY_PURCHASE,
    [PaymentTypeModel.GooglePay]: BrazeEventKey.GOOGLE_PAY_PURCHASE,
    [PaymentTypeModel.GiftCard]: BrazeEventKey.OA_SVC_PURCHASE,
};
