export const getPerimeterXScript = (key: string) =>
    key
        ? `(function(){
        var p = document.getElementsByTagName('script')[0],
        s = document.createElement('script');
        s.async = 1;
        s.src = '//client.px-cloud.net/${key}/main.min.js';
        p.parentNode.insertBefore(s,p);
        }());`
        : null;
