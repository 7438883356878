import {
    isBefore,
    addDays,
    parse,
    setDay,
    isEqual,
    isWithinInterval,
    addWeeks,
    areIntervalsOverlapping,
    closestTo,
    formatRelative,
    differenceInHours,
    differenceInMinutes,
    formatTZ,
    utcToZonedTime,
    isSameDay,
} from '../../common/helpers/dateTime';

import { PickupAddress } from '../../redux/orderLocation';
import { IHoursModel, TServiceTypeModel } from '../../@generated/webExpApi/models';

import getLocationService from './getLocationService';
import { weekDayIndexMap } from './constants';
import getBrandInfo from '../brandInfo';

export interface IResolveOpeningHoursResult {
    isOpen: boolean;
    isTwentyFourHourService: boolean;
    openTime: string | null;
    closeTime: string | null;
    isOpeningSoon: boolean;
    isClosingSoon: boolean;
    isClosingWithin90Minutes: boolean;
    openedTimeRanges: IDateRange[];
    storeTimezone: string;
    isOpeningToday: boolean;
    isOpeningTomorrow: boolean;
}

interface IOptions {
    relativeTimeFormatting: boolean;
    additionalServiceType?: TServiceTypeModel;
}

export interface IDateRange {
    start: Date;
    end: Date;
}

const isTwentyFourHoursService = (dateRange: IDateRange): boolean =>
    differenceInHours(dateRange.end, dateRange.start) === 24;

const preventOverlappingRanges = (sortedOpenedTimeRanges: IDateRange[]): IDateRange[] => {
    const firstTimeRange = sortedOpenedTimeRanges.slice(0, 1)[0];

    const combinedIntervals = sortedOpenedTimeRanges.slice(1).reduce<IDateRange[]>(
        (resultRanges, currentRange): IDateRange[] => {
            if (isTwentyFourHoursService(currentRange)) {
                return [...resultRanges, currentRange];
            }

            const lastRangeInResult = resultRanges.slice(-1)[0];

            const isOverlapping = areIntervalsOverlapping(lastRangeInResult, currentRange, { inclusive: true });

            if (isOverlapping) {
                return [
                    ...resultRanges.slice(0, -1),
                    {
                        start: lastRangeInResult.start,
                        end: currentRange.end,
                    },
                ];
            }

            return [...resultRanges, currentRange];
        },
        [firstTimeRange]
    );

    return combinedIntervals;
};

/*
 * week = -1 - previous week
 * week = 0 - current week
 * week = 1 - next week
 */
export const getWeekOpenedTimeRanges = (
    serviceHoursSorted: IHoursModel[],
    week: number,
    timezone: string
): IDateRange[] => {
    return serviceHoursSorted?.map((serviceHours) => {
        const localStoreDate = utcToZonedTime(Date.now(), timezone);
        const localStoreWeekDayDate = setDay(localStoreDate, weekDayIndexMap[serviceHours.dayOfWeek]);

        const timezoneOffset = formatTZ(new Date(Date.now()), 'XXX', { timeZone: timezone });

        if (serviceHours.isTwentyFourHourService && serviceHours.startTime === serviceHours.endTime) {
            const start = parse('00:00 ' + timezoneOffset, 'HH:mm XXX', localStoreWeekDayDate);
            const end = addDays(start, 1);

            return {
                start: addWeeks(start, week),
                end: addWeeks(end, week),
            };
        }

        const start = parse(serviceHours.startTime + ' ' + timezoneOffset, 'HH:mm XXX', localStoreWeekDayDate);
        let end = parse(serviceHours.endTime + ' ' + timezoneOffset, 'HH:mm XXX', localStoreWeekDayDate);

        // if close time is before open time. Example:
        // openTime: "10:00 AM"
        // closeTime: "01:00 AM"
        if (isBefore(end, start) || isEqual(end, start)) {
            end = addDays(end, 1);
        }

        return {
            start: addWeeks(start, week),
            end: addWeeks(end, week),
        };
    });
};

export const getOpenedTimeRanges = (serviceHours: IHoursModel[], timezone: string): IDateRange[] => {
    const currentWeekOpenedTimeRanges = getWeekOpenedTimeRanges(serviceHours, 0, timezone);

    const currentWeekLastOpenedTimeRange = currentWeekOpenedTimeRanges.slice(-1)[0];

    const previousWeekOpenedTimeRange = {
        start: addWeeks(currentWeekLastOpenedTimeRange.start, -1),
        end: addWeeks(currentWeekLastOpenedTimeRange.end, -1),
    };

    const nextWeekOpenedTimeRanges = getWeekOpenedTimeRanges(serviceHours, 1, timezone);

    const resultOpenedTimeRanges = [
        previousWeekOpenedTimeRange,
        ...currentWeekOpenedTimeRanges,
        ...nextWeekOpenedTimeRanges,
    ];

    return preventOverlappingRanges(resultOpenedTimeRanges);
};

export const getHoursWithTimezone = (
    hours: IHoursModel[],
    storeTimezone: string,
    options: IOptions = { relativeTimeFormatting: true }
): IResolveOpeningHoursResult => {
    const { relativeTimeFormatting } = options;

    const result = {
        isOpen: false,
        isTwentyFourHourService: false,
        openTime: null,
        closeTime: null,
        isOpeningSoon: false,
        isOpeningToday: false,
        isOpeningTomorrow: false,
        isClosingSoon: false,
        isClosingWithin90Minutes: false,
        closedTodayAndTomorrow: false,
    };
    const utcTimeNow = new Date(Date.now());
    const localStoreTimeWithTimezone = utcToZonedTime(utcTimeNow.toISOString(), storeTimezone);
    const openedTimeRanges = getOpenedTimeRanges(hours, storeTimezone);
    const currentOpenedTimeRange = openedTimeRanges.find((range) => isWithinInterval(utcTimeNow, range));

    // store closed
    if (!currentOpenedTimeRange) {
        const openedTimes = openedTimeRanges.map((range) => range.start).filter((date) => isBefore(utcTimeNow, date));

        const openTime = closestTo(utcTimeNow, openedTimes);
        const openTimeWithTimezone = utcToZonedTime(openTime, storeTimezone);

        return {
            ...result,
            openTime: relativeTimeFormatting
                ? formatRelative(openTimeWithTimezone, localStoreTimeWithTimezone)
                : formatTZ(openTimeWithTimezone, 'h:mm a'),
            isOpeningSoon: differenceInMinutes(openTime, utcTimeNow) <= 30, // 30 minutes
            storeTimezone,
            openedTimeRanges,
            isOpeningToday: isSameDay(localStoreTimeWithTimezone, openTimeWithTimezone),
            isOpeningTomorrow: isSameDay(addDays(localStoreTimeWithTimezone, 1), openTimeWithTimezone),
        };
    }

    const currentOpenedTimeRangeWithTimezone = {
        start: utcToZonedTime(currentOpenedTimeRange.start, storeTimezone),
        end: utcToZonedTime(currentOpenedTimeRange.end, storeTimezone),
    };

    const { brandId } = getBrandInfo();
    const cutOffMinutes = brandId === 'Arbys' ? 20 : 30;
    // store opened
    return {
        ...result,
        isOpen: !!currentOpenedTimeRange,
        openTime: relativeTimeFormatting
            ? formatRelative(currentOpenedTimeRangeWithTimezone.start, localStoreTimeWithTimezone)
            : formatTZ(currentOpenedTimeRangeWithTimezone.start, 'h:mm a'),
        closeTime: relativeTimeFormatting
            ? formatRelative(currentOpenedTimeRangeWithTimezone.end, localStoreTimeWithTimezone)
            : formatTZ(currentOpenedTimeRangeWithTimezone.end, 'h:mm a'),
        isClosingSoon: differenceInMinutes(currentOpenedTimeRange.end, utcTimeNow) <= cutOffMinutes, // 30 minutes
        isClosingWithin90Minutes: differenceInMinutes(currentOpenedTimeRange.end, utcTimeNow) <= 90, // 90 minutes
        isTwentyFourHourService: isTwentyFourHoursService(currentOpenedTimeRangeWithTimezone),
        storeTimezone,
        openedTimeRanges,
    };
};

const resolveOpeningHours = (
    location: PickupAddress,
    serviceType: TServiceTypeModel,
    options: IOptions = { relativeTimeFormatting: true }
): IResolveOpeningHoursResult | null => {
    if (!location) {
        return null;
    }

    const { timezone: storeTimezone } = location;

    if (!storeTimezone) {
        return null;
    }

    const locationService = getLocationService(location, serviceType);
    if (!locationService) {
        return null;
    }

    const { hours } = locationService;

    if (options.additionalServiceType) {
        const locationAdditionalService = getLocationService(location, options.additionalServiceType);

        if (!locationAdditionalService) {
            return null;
        }

        const { hours: additionalHours } = locationAdditionalService;
        const resultHours = hours
            .map((day) => {
                const altDay = additionalHours.find((altDay) => altDay.dayOfWeek === day.dayOfWeek);
                if (!altDay) return;

                const startTime = day.startTime > altDay.startTime ? day.startTime : altDay.startTime;

                let endTime;

                if (day.endTime === '00:00' || altDay.endTime === '00:00') {
                    endTime = day.endTime > altDay.endTime ? day.endTime : altDay.endTime;
                } else {
                    endTime = day.endTime < altDay.endTime ? day.endTime : altDay.endTime;
                }

                return {
                    dayOfWeek: day.dayOfWeek,
                    startTime,
                    endTime,
                    isTwentyFourHourService: day.isTwentyFourHourService && altDay.isTwentyFourHourService,
                };
            })
            .filter(Boolean);

        return getHoursWithTimezone(resultHours, storeTimezone, options);
    }

    return getHoursWithTimezone(hours, storeTimezone, options);
};

export default resolveOpeningHours;
