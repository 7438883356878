/**
 * Calculates distance between two geo points
 * @param {number} destLat
 * @param {number} destLon
 * @param {number} currentLat
 * @param {number} currentLon
 * @returns {number} distance in miles between two geo points
 * @example
 * // returns 472.24
 * getHaversineDistance(55.75, 37.6, 50.4, 30.5)
 */
const getHaversineDistance = (destLat: number, destLon: number, currentLat: number, currentLon: number): number => {
    if (
        !Number.isFinite(destLat) ||
        !Number.isFinite(destLon) ||
        !Number.isFinite(currentLat) ||
        !Number.isFinite(currentLon)
    ) {
        return undefined;
    }

    const eartRadius = 6371 / 1609e-3; //convert km -> mi (1.609)
    const radianStoreLat = (destLat * Math.PI) / 180;
    const radianCurrentLat = (currentLat * Math.PI) / 180;
    const deltaLat = ((currentLat - destLat) * Math.PI) / 180;
    const deltaLon = ((currentLon - destLon) * Math.PI) / 180;

    const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(radianStoreLat) * Math.cos(radianCurrentLat) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = eartRadius * c;

    return d;
};

export default getHaversineDistance;
