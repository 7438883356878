import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { LDPState } from '../ldp';
import { ILocationByStateOrProvinceDetailsModel } from '../../@generated/webExpApi';

const selectLdp = (state: RootState): LDPState => state.ldp;

export const selectLocationDetails = createSelector(
    selectLdp,
    (ldp: LDPState): ILocationByStateOrProvinceDetailsModel => ldp.locationDetails
);
