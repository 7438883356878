import { useDispatch, useSelector } from 'react-redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import * as MyTeamsStore from '../myTeams';
import { ISurveyModel } from '../../@generated/webExpApi';

interface IUseMyTeams {
    myTeams: MyTeamsStore.IMyTeamsState;
    actions: {
        setMyTeams: (payload: ISurveyModel) => PayloadAction<MyTeamsStore.IMyTeamsState>;
        setMyTeamsLoading: (payload: boolean) => void;
        setMyTeamsError: (payload: string) => void;
    };
}

export default function useMyTeams(): IUseMyTeams {
    const dispatch = useDispatch();

    const myTeams = useSelector<RootState, MyTeamsStore.IMyTeamsState>((state) => state.myTeams);

    const setMyTeams = (payload: MyTeamsStore.IMyTeamsState) => dispatch(MyTeamsStore.actions.setMyTeams(payload));

    const setMyTeamsLoading = (payload: boolean) => dispatch(MyTeamsStore.actions.setIsLoading(payload));

    const setMyTeamsError = (payload: string) => dispatch(MyTeamsStore.actions.setError(payload));

    return {
        myTeams,
        actions: {
            setMyTeams,
            setMyTeamsLoading,
            setMyTeamsError,
        },
    };
}
