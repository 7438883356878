import { TServiceTypeModel } from '../../@generated/webExpApi';
import { PickupAddress } from '../../redux/orderLocation';

import getLocationService from './getLocationService';

const isLocationDeliveryAvailable = (pickupAddress: PickupAddress, isOAEnabled: boolean): boolean => {
    return (
        isOAEnabled &&
        !!pickupAddress?.isDigitallyEnabled &&
        !!getLocationService(pickupAddress, TServiceTypeModel.Delivery)
    );
};

export default isLocationDeliveryAvailable;
