import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type justAddedToBagPayload = boolean;

export const initialState: justAddedToBagPayload = false;

const justAddedToBagSlice = createSlice({
    name: 'justAddedToBag',
    initialState,
    reducers: {
        setJustAddedToBag: (state, action: PayloadAction<justAddedToBagPayload>) => {
            return action.payload;
        },
    },
});

export const { actions } = justAddedToBagSlice;
export default justAddedToBagSlice.reducer;
