import { configureStore } from '@reduxjs/toolkit';
import { createSelectorHook, useDispatch } from 'react-redux';
import { combineReducers } from 'redux';

import bagReducer from './bag';
import pdpReducer from './pdp';
import dealPdpReducer from './dealPdp';
import ldpReducer from './ldp';
import justAddedToBagReducer from './justAddedToBag';
import dismissedAlertBannersReducer from './dismissedAlertBanners';
import orderLocationReducer from './orderLocation';
import submitOrderReducer from './submitOrder';
import tallyReducer from './tallyOrder';
import notificationsReducer from './notifications';
import navInterceptReducer from './navIntercept';
import domainMenuReducer from './domainMenu';
import pageLoaderReducer from './pageLoader';
import accountReducer from './account';
import auth0Reducer from './auth0';
import rewardsReducer from './rewards';
import selectedSellReducer from './selectedSell';
import loyaltyReducer from './loyalty';
import localTapListReducer from './localTapList';
import orderHistoryReducer from './orderHistory';
import personalizationReducer from './personalization';
import myTeamsReducer from './myTeams';
import configurationReducer from './configuration';
import globalPropsReducer from './globalProps';
import { loadState } from './localStorage';
import branchIOReducer from './branchIO';

import createPersistSubscription from './persist';

import gtmMiddleware from './middleware/gtmMiddleware';
import errorsMiddleware from './middleware/errorsMiddleware';

export const rootReducer = combineReducers({
    domainMenu: domainMenuReducer,
    bag: bagReducer,
    pdp: pdpReducer,
    dealPdp: dealPdpReducer,
    ldp: ldpReducer,
    justAddedToBag: justAddedToBagReducer,
    orderLocation: orderLocationReducer,
    dismissedAlertBanners: dismissedAlertBannersReducer,
    submitOrder: submitOrderReducer,
    tally: tallyReducer,
    notifications: notificationsReducer,
    navIntercept: navInterceptReducer,
    pageLoader: pageLoaderReducer,
    account: accountReducer,
    auth0: auth0Reducer,
    rewards: rewardsReducer,
    selectedSell: selectedSellReducer,
    loyalty: loyaltyReducer,
    localTapList: localTapListReducer,
    orderHistory: orderHistoryReducer,
    personalization: personalizationReducer,
    configuration: configurationReducer,
    myTeams: myTeamsReducer,
    globalProps: globalPropsReducer,
    branchIO: branchIOReducer,
});

export const initialState = rootReducer(undefined, { type: ' ' });
const localStorageState = loadState(initialState);

export const createStore = (_initialValue = {}) => {
    const store = configureStore({
        reducer: rootReducer,
        //@ts-ignore
        preloadedState: { ...localStorageState, ..._initialValue },
        //@ts-ignore
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: false,
                serializableCheck: false,
            }).concat([gtmMiddleware, errorsMiddleware]),
        devTools: true,
    });

    createPersistSubscription(store, ['domainMenu', 'bag', 'dismissedAlertBanners', 'orderLocation', 'localTapList']);

    return store;
};

export const createTestStore = (initialState = {}) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState: { ...initialState },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: true,
                serializableCheck: false,
                immutableCheck: false,
            }),
    });
};

export type AppStore = ReturnType<typeof createStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export const useAppSelector = createSelectorHook<RootState>();
