import { DiscountDetailsTypeModel } from '../../../@generated/webExpApi/models/DiscountDetailsTypeModel';
import { IBagLineItem } from '../../../redux/bag';

/**
 * @namespace calculateBagDiscount
 */

/**
 * Method to calculate whole bag discount
 * @method calculateBagDiscount
 * @memberof calculateBagDiscount
 * @param {IBagLineItem[]} lineItems line items from bag
 * @param {number} discountAmount bag discount Amount
 * @param {DiscountDetailsTypeModel} includeOnlyDiscountType discount type that should only includes in result
 * @returns {number} bag discount amount
 * @example
 * calculateBagDiscount(lineItems, 10)
 */
export const calculateBagDiscount = (
    lineItems: IBagLineItem[],
    discountAmount?: number,
    _?: DiscountDetailsTypeModel
): number => {
    return discountAmount || 0;
};
