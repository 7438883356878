import { createSelector } from 'reselect';
import { isLocationDeliveryAvailable, isLocationOrderAheadAvailable } from '../../lib/locations';
import { OrderLocationState, OrderLocationMethod } from '../orderLocation';
import { selectConfiguration } from './configuration';
import { RootState } from '../store';
const CONDIMENTS_LIMIT = 20;

const selectOrderLocation = (state: RootState): OrderLocationState => state.orderLocation;

export const selectOrderMethod = createSelector(selectOrderLocation, (orderLocation) => orderLocation.method);

export const selectCurrenOrderLocation = createSelector(selectOrderLocation, (orderLocation) => {
    switch (orderLocation?.method) {
        case OrderLocationMethod.PICKUP:
            return orderLocation.pickupAddress;
        case OrderLocationMethod.DELIVERY:
            return orderLocation.deliveryAddress.locationDetails;
        default:
            return null;
    }
});

export const selectIsCurrentLocationOAAvailable = createSelector(
    selectOrderMethod,
    selectCurrenOrderLocation,
    selectConfiguration,
    (orderMethod, currentLocation, configuration) => {
        const { isOAEnabled } = configuration;
        switch (orderMethod) {
            case OrderLocationMethod.PICKUP:
                return isLocationOrderAheadAvailable(currentLocation, isOAEnabled);
            case OrderLocationMethod.DELIVERY:
                return isLocationDeliveryAvailable(currentLocation, isOAEnabled);
            default:
                return false;
        }
    }
);

export const selectCondimentsLimit = createSelector(selectCurrenOrderLocation, (orderLocation) => {
    return (
        (orderLocation?.orderLimits?.maxAllowedItems?.itemType === 'CONDIMENT' &&
            orderLocation?.orderLimits?.maxAllowedItems?.maxAllowed) ||
        CONDIMENTS_LIMIT
    );
});
