export const MAX_PAYLOAD_SIZE = 8192;
export const GTM_MENU_IMPRESSION = 'GTM_MENU_IMPRESSION';
export const GTM_TRANSACTION_COMPLETE = 'GTM_TRANSACTION_COMPLETE';
export const GTM_TRANSACTION_INFO = 'GTM_TRANSACTION_INFO';
export const GTM_CHECKOUT_PAYMENT = 'GTM_CHECKOUT_PAYMENT';
export const GTM_CHECKOUT_VIEW = 'GTM_CHECKOUT_VIEW';
export const GTM_BEGIN_CHECKOUT_VIEW = 'GTM_BEGIN_CHECKOUT_VIEW';
export const GTM_CHECKOUT_CUSTOMER_INFO = 'GTM_CHECKOUT_CUSTOMER_INFO';
export const GTM_CHECKOUT_PAYMENT_INFO = 'GTM_CHECKOUT_PAYMENT_INFO';
export const GTM_CHECKOUT_PICK_UP_TIME_SELECTION = 'GTM_CHECKOUT_PICK_UP_TIME_SELECTION';
export const GTM_PRODUCT_VIEW = 'GTM_PRODUCT_VIEW';
export const GTM_REMOVE_FROM_CART = 'GTM_REMOVE_FROM_CART';
export const GTM_RESTORE_ITEM_TO_CART = 'GTM_RESTORE_ITEM_TO_CART';
export const GTM_RE_ADD_ITEM_TO_CART = 'GTM_RE_ADD_ITEM_TO_CART';
export const GTM_COMBO_SELECTION = 'GTM_COMBO_SELECTION';
export const GTM_COMBO_MODIFY = 'GTM_COMBO_MODIFY';
export const GTM_MODIFY_PRODUCT = 'GTM_MODIFY_PRODUCT';
export const GTM_MODIFY_IN_CART = 'GTM_MODIFY_IN_CART';
export const GTM_MODIFIER_CUSTOMIZATION = 'GTM_MODIFIER_CUSTOMIZATION';
export const GTM_SEARCH_NEW_LOCATION = 'GTM_SEARCH_NEW_LOCATION';
export const GTM_LOCATION_SHARE = 'GTM_LOCATION_SHARE';
export const GTM_START_PICKUP_ORDER = 'GTM_START_PICKUP_ORDER';
export const GTM_MAKE_MY_STORE = 'GTM_MAKE_MY_STORE';
export const GTM_ONLINE_ORDER_COMING_SOON = 'GTM_ONLINE_ORDER_COMING_SOON';
export const GTM_ADD_MORE_ITEMS = 'GTM_ADD_MORE_ITEMS';
export const GTM_EMAIL_SIGN_UP = 'GTM_EMAIL_SIGN_UP';
export const GTM_GIFT_CARD_BALANCE_FORM_SUBMIT_SUCCESS = 'GTM_GIFT_CARD_BALANCE_FORM_SUBMIT_SUCCESS';
export const GTM_MAP_CLICK = 'GTM_MAP_CLICK';
export const GTM_MAP_DOUBLE_CLICK = 'GTM_MAP_DOUBLE_CLICK';
export const GTM_MAP_DRAG_START = 'GTM_MAP_DRAG_START';
export const GTM_MAP_DRAG = 'GTM_MAP_DRAG';
export const GTM_MAP_DRAG_END = 'GTM_MAP_DRAG_END';
export const GTM_MAP_LOCATION_CLICK = 'GTM_MAP_LOCATION_CLICK';
export const GTM_MAP_LIST_LOCATION_CLICK = 'GTM_MAP_LIST_LOCATION_CLICK';
export const GTM_USER_ID = 'GTM_USER_ID';
export const GTM_MODIFIER_SECTION = 'GTM_MODIFIER_SECTION';
export const GTM_ERROR_EVENT = 'GTM_ERROR_EVENT';
export const GTM_CHECKOUT_TIP_SELECTION_EVENT = 'GTM_CHECKOUT_TIP_SELECTION_EVENT';
export const GTM_CHECKOUT_TIP_SELECTION_WITH_CHOICE_EVENT = 'GTM_CHECKOUT_TIP_SELECTION_WITH_CHOICE_EVENT';
export const GTM_LOCATION_ORDER = 'GTM_LOCATION_ORDER';
export const GTM_CHANGE_LOCATION = 'GTM_LOCATION_CHANGE';
export const GTM_CHECK_IN = 'GTM_CHECK_IN';
export const GTM_ACCOUNT_DELETED_FEEDBACK = 'GTM_ACCOUNT_DELETED_FEEDBACK';
export const GTM_ACCOUNT_DELETED_SUBMITTED = 'GTM_ACCOUNT_DELETED_SUBMITTED';
export const GTM_SEND_A_MESSAGE = 'GTM_SEND_A_MESSAGE';
export const GTM_WALLET_EVENT = 'GTM_WALLET_EVENT';
export const GTM_PICKUP_OR_LOCATION_ADDRESS_CHANGE = 'GTM_PICKUP_OR_LOCATION_ADDRESS_CHANGE';
export const GTM_SEARCH_NAVIGATION_LOCATION = 'GTM_SEARCH_NAVIGATION_LOCATION';
export const GTM_BANNER_SIGN_UP = 'GTM_BANNER_SIGN_UP';
export const GTM_INGREDIENT_SECTION = 'GTM_INGREDIENT_SECTION';
export const GTM_CLAIM_RECEIPT_REDEEM = 'GTM_CLAIM_RECEIPT_REDEEM';
export const GTM_SELECT_A_LOCATION = 'GTM_SELECT_A_LOCATION';
export const GTM_VIEW_DEALS = 'GTM_VIEW_DEALS';
export const GTM_VIEW_DEAL = 'GTM_VIEW_DEAL';
export const GTM_REDEEM_DEAL_ONLINE = 'GTM_REDEEM_DEAL_ONLINE';
export const GTM_REDEEM_DEAL_IN_STORE = 'GTM_REDEEM_DEAL_IN_STORE';
export const GTM_REDEEM_DEAL_FROM_BAG = 'GTM_REDEEM_DEAL_FROM_BAG';
export const GTM_SWAP_THIS_DEAL = 'GTM_SWAP_THIS_DEAL';
export const GTM_PRODUCT_VIEW_DEAL = 'GTM_PRODUCT_VIEW_DEAL';
export const GTM_ADD_TO_BAG_DEAL = 'GTM_ADD_TO_BAG_DEAL';
export const GTM_COMPLETE_DEAL_PURCHASE = 'GTM_COMPLETE_DEAL_PURCHASE';
export const GTM_RECENT_ORDERS_REORDER = 'GTM_RECENT_ORDERS_REORDER';
export const GTM_AUTO_SUGGEST_APPLY_DEAL = 'GTM_AUTO_SUGGEST_APPLY_DEAL';
export const GTM_RECENT_ORDERS_HAVE_QUESTION = 'GTM_RECENT_ORDERS_HAVE_QUESTION';
export const GTM_RECENT_ORDERS_CALL_STORE = 'GTM_RECENT_ORDERS_CALL_STORE';
export const GTM_ORDER_HISTORY_START_ORDER = 'GTM_ORDER_HISTORY_START_ORDER';
export const GTM_ORDER_HISTORY_VIEW_DETAILS = 'GTM_ORDER_HISTORY_VIEW_DETAILS';
export const GTM_ORDER_HISTORY_SHOW_MORE = 'GTM_ORDER_HISTORY_SHOW_MORE';
export const GTM_ORDER_HISTORY_SHOW_LESS = 'GTM_ORDER_HISTORY_SHOW_LESS';
export const GTM_ORDER_HISTORY_REORDER = 'GTM_ORDER_HISTORY_REORDER';
export const GTM_CROSS_SELL_EVENT = 'GTM_CROSS_SELL_EVENT';
export const GTM_AVATAR_IMAGE_UPLOAD = 'GTM_AVATAR_IMAGE_UPLOAD';
export const GTM_SIGNIN_CREATE_ACCOUNT = 'GTM_SIGNIN_CREATE_ACCOUNT';
export const GTM_CHANGE_LOCATION_CHECKOUT = 'GTM_CHANGE_LOCATION_CHECKOUT';
export const GTM_HAMBURGER_MENU_OPTION_SELECT = 'GTM_HAMBURGER_MENU_OPTION_SELECT';
export const GTM_CHECKOUT_PRODUCT_QUANTITY_CHANGE = 'GTM_CHECKOUT_PRODUCT_QUANTITY_CHANGE';
export const GTM_CONDIMENT_OPTION_SELECT = 'GTM_CONDIMENT_OPTION_SELECT';
export const GTM_BACK_TO_TOP = 'GTM_BACK_TO_TOP';
export const GTM_CHECKOUT_PAYMENT_METHOD_INFO = 'GTM_CHECKOUT_PAYMENT_METHOD_INFO';
export const GTM_COMBO_REPLACE = 'GTM_COMBO_REPLACE';
export const GTM_MAKE_IT_A_COMBO = 'GTM_MAKE_IT_A_COMBO';
export const GTM_CHECK_IN_CLICK = 'GTM_CHECK_IN_CLICK';
export const GTM_CHECK_IN_WRONG_LOCATION = 'GTM_CHECK_IN_WRONG_LOCATION';
export const GTM_JOIN_SONIC_REWARDS = 'GTM_JOIN_SONIC_REWARDS';
export const GTM_WALLET_CARD_EVENT = 'GTM_WALLET_CARD_EVENT';
export const GTM_EXPLORE_MORE_EVENT = 'GTM_EXPLORE_MORE_EVENT';
export const GTM_VIEW_ITEM_EVENT = 'GTM_VIEW_ITEM_EVENT';
export const GTM_ADD_TO_CART_EVENT = 'GTM_ADD_TO_CART_EVENT';
export const GTM_REMOVE_FROM_CART_EVENT = 'GTM_REMOVE_FROM_CART_EVENT';
export const GTM_PICKUP_TIME_AFTER_ORDER_SUBMIT = 'GTM_PICKUP_TIME_AFTER_ORDER_SUBMIT';
export const GTM_PROMOTIONAL_BANNER = 'GTM_PROMOTIONAL_BANNER';
export const GTM_PROMOTIONAL_IMPRESSION_BANNER = 'GTM_PROMOTIONAL_IMPRESSION_BANNER';
export const GTM_ORDER_FROM_ORDER_HISTORY = 'GTM_ORDER_FROM_ORDER_HISTORY';
