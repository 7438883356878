// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortObject = (object: { [key: string]: any }): { [key: string]: any } => {
    if (!object) {
        return object;
    }

    const isArray = object instanceof Array;
    let sortedObj = {};

    if (isArray) {
        sortedObj = object.map((item) => sortObject(item));
    } else {
        const keys = Object.keys(object);

        keys.sort(function (key1, key2) {
            if (key1.toLowerCase() < key2.toLowerCase()) return -1;
            if (key1.toLowerCase() > key2.toLowerCase()) return 1;
            return 0;
        });

        for (const index in keys) {
            const key = keys[index];
            if (typeof object[key] == 'object') {
                sortedObj[key] = sortObject(object[key]);
            } else {
                sortedObj[key] = object[key];
            }
        }
    }

    return sortedObj;
};
