import {
    DiscountDetailsTypeModel,
    IRewardApplicabilityModel,
    IRewardMenuIdModel,
    IRewardMenuIdsModel,
    TallyProductModel,
    TOffersUnionModel,
    TOfferTypeModel,
} from '../../@generated/webExpApi';
import { IBagRenderItem } from '../../components/organisms/bag/bagModel';
import { formatPrice } from '../../lib/domainProduct';
import { Offers } from '../../redux/rewards';
import { IProducts } from '../../redux/types';
import { isAfter } from './dateTime';
import { checkTimeRestrictions } from './checkTimeRestrictions';
import { checkProductIsAvailable } from './checkProductIsAvailable';

export enum MainDealsTypesByStructure {
    BuyX = 'BUY_X',
    BuyX_GetY = 'BUY_X_GET_Y',
}

export enum MainDealsTypesByDiscount {
    SetPrice = 'SET_PRICE',
    Percent = 'PERCENT',
    FixedAmount = 'FIXED_AMOUNT',
}

const CalculatePriceMap = {
    [MainDealsTypesByDiscount.SetPrice]: ({ price, buyCount }: TCalculatePriceArgument): number => {
        return price / buyCount;
    },
    [MainDealsTypesByDiscount.Percent]: ({ regularPrice, percent }: TCalculatePriceArgument): number => {
        const discount = Math.floor(regularPrice * (percent / 100) * 100) / 100;
        return regularPrice - discount;
    },
    [MainDealsTypesByDiscount.FixedAmount]: ({ regularPrice, price }: TCalculatePriceArgument): number => {
        return regularPrice - price;
    },
};

type TCalculatePriceArgument = { regularPrice: number; price?: number; percent?: number; buyCount?: number };

export type IDealsTypesMap = {
    [key in TOfferTypeModel]: {
        mainType: MainDealsTypesByStructure;
        calculatePrice: (prices: TCalculatePriceArgument) => number;
    };
};

export const DealsTypesMap: IDealsTypesMap = {
    [TOfferTypeModel.SetPrice]: {
        mainType: MainDealsTypesByStructure.BuyX,
        calculatePrice: CalculatePriceMap[MainDealsTypesByDiscount.SetPrice],
    },
    [TOfferTypeModel.Percent]: {
        mainType: MainDealsTypesByStructure.BuyX,
        calculatePrice: CalculatePriceMap[MainDealsTypesByDiscount.Percent],
    },
    [TOfferTypeModel.FixedAmount]: {
        mainType: MainDealsTypesByStructure.BuyX,
        calculatePrice: CalculatePriceMap[MainDealsTypesByDiscount.FixedAmount],
    },
    [TOfferTypeModel.BuyXGetYSetPrice]: {
        mainType: MainDealsTypesByStructure.BuyX_GetY,
        calculatePrice: CalculatePriceMap[MainDealsTypesByDiscount.SetPrice],
    },
    [TOfferTypeModel.BuyXGetYPercent]: {
        mainType: MainDealsTypesByStructure.BuyX_GetY,
        calculatePrice: CalculatePriceMap[MainDealsTypesByDiscount.Percent],
    },
    [TOfferTypeModel.BuyXGetYFixed]: {
        mainType: MainDealsTypesByStructure.BuyX_GetY,
        calculatePrice: CalculatePriceMap[MainDealsTypesByDiscount.FixedAmount],
    },
    UNKNOWN: {
        mainType: undefined,
        calculatePrice: undefined,
    },
};

export const getDealLabelText = (offer: { isRedeemableInStoreOnly: boolean; isRedeemableOnlineOnly: boolean }) => {
    if (offer?.isRedeemableOnlineOnly && !offer?.isRedeemableInStoreOnly) {
        return 'Online Only';
    }

    if (offer?.isRedeemableInStoreOnly && !offer?.isRedeemableOnlineOnly) {
        return 'In Store Only';
    }

    return null;
};

export const filterDealApplicability = (
    applicability: IRewardApplicabilityModel,
    products: IProducts
): IRewardApplicabilityModel => {
    const filterUnavailableProducts = (item: IRewardMenuIdModel) => checkProductIsAvailable(products?.[item.menuId]);
    const reduceUnavailableProducts = (acc: IRewardMenuIdsModel[], item: IRewardMenuIdsModel) => [
        ...acc,
        { ...item, menuIds: item.menuIds.filter(filterUnavailableProducts) },
    ];

    const buyIds = applicability.buyIds?.reduce(reduceUnavailableProducts, []);
    const getIds = applicability.getIds?.reduce(reduceUnavailableProducts, []);
    const eligibleIds = applicability.eligibleIds?.filter(filterUnavailableProducts);

    return {
        ...applicability,
        buyIds,
        getIds,
        eligibleIds,
    };
};

export type IRemainingMenuIds = {
    menuIds: IRewardMenuIdModel[];
    count: number;
};

export interface IDealWarning {
    partiallyMatch: boolean;
    fullMatch: boolean;
    remainingPrice: number;
    message?: string;
    isOfferApplicable: boolean;
    isOfferExpired?: boolean;
    remainingBuyIds?: IRemainingMenuIds;
    remainingGetIds?: IRemainingMenuIds;
    remainingEligibleIds?: IRemainingMenuIds;
    matchingItemWithLowestPrice?: TallyProductModel;
    checkIfOfferExpired?: () => boolean;
}

type IRemainingBagItem = {
    productId: string;
    quantity: number;
    price: number;
};

interface IGetDealWarning {
    offer: TOffersUnionModel;
    bagRenderItems: IBagRenderItem[];
    subTotalBeforeDiscounts: number;
    applicableOffers: Offers;
    isShowExpiredMessage?: boolean;
    locationTimezone?: string;
}

export const getDealWarning = ({
    offer,
    bagRenderItems,
    subTotalBeforeDiscounts,
    applicableOffers,
    isShowExpiredMessage,
    locationTimezone,
}: IGetDealWarning): IDealWarning => {
    if (!offer) {
        return {
            partiallyMatch: true,
            fullMatch: true,
            remainingPrice: 0,
            message: null,
            isOfferApplicable: true,
        };
    }

    const isOfferApplicable = applicableOffers.some((applicableOffer) => applicableOffer.id === offer?.id);

    if (offer && !isOfferApplicable) {
        return {
            isOfferApplicable,
            partiallyMatch: false,
            fullMatch: false,
            remainingPrice: 0,
            message: 'This deal is not available at this location. Select a new location to use this deal.',
        };
    }

    const minAmount = offer?.checkRestrictions?.minimumCheckAmount?.amount;
    let remainingPrice = 0;
    let message = null;

    if (subTotalBeforeDiscounts < minAmount) {
        remainingPrice = minAmount - subTotalBeforeDiscounts;
    }

    const dealType = DealsTypesMap[offer?.type];

    let partiallyMatch = false; // will be true when at least one item match
    let fullMatch = true; // will be false when at least one item don't match

    const { buyIds, getIds, eligibleIds, buyCount, getCount, isIncludesAll } = offer?.applicability || {};

    const accumulateBagEntries = (acc: IRemainingBagItem[], item: TallyProductModel): IRemainingBagItem[] => {
        const { productId, quantity, price, childItems } = item;

        const childsResult = (childItems || []).map((childItem) => ({
            productId: childItem.productId,
            quantity: childItem.quantity * quantity,
            price: childItem.price,
        }));

        return [...acc, { productId, quantity, price }, ...childsResult];
    };

    const availableBagEntries = bagRenderItems
        .filter((item) => !item.markedAsRemoved && item.isAvailable)
        .map((item) => item.entry)
        .sort((a, b) => a.price - b.price);

    const remainingBagItems: IRemainingBagItem[] = availableBagEntries.reduce(accumulateBagEntries, []);
    const matchingBagItems: string[] = [];

    const bagItemsTotalCount = availableBagEntries.reduce((acc, item) => acc + item.quantity, 0);

    if (remainingBagItems.length === 0) {
        return {
            isOfferApplicable,
            partiallyMatch: false,
            fullMatch: false,
            remainingPrice: 0,
            message: 'No qualifying items have been added to the bag',
        };
    }

    const checkMenuIds = (menuIds: IRewardMenuIdModel[], count: number): IRemainingMenuIds => {
        if (!menuIds) return;

        const bagItemIndex = remainingBagItems.findIndex((item) =>
            menuIds.some((menuId) => menuId.menuId === item.productId)
        );

        if (bagItemIndex > -1) {
            partiallyMatch = true;
            const bagItem = remainingBagItems[bagItemIndex];
            bagItem.quantity -= 1;

            const { productId } = bagItem;

            if (!matchingBagItems.includes(productId)) {
                matchingBagItems.push(productId);
            }

            if (bagItem.quantity === 0) {
                remainingBagItems.splice(bagItemIndex, 1);
            }
        } else {
            fullMatch = false;
            return { menuIds, count };
        }

        if (count > 1) {
            return checkMenuIds(menuIds, count - 1);
        }
    };

    let remainingBuyIds: IRemainingMenuIds, remainingGetIds: IRemainingMenuIds, remainingEligibleIds: IRemainingMenuIds;

    if (dealType?.mainType === MainDealsTypesByStructure.BuyX_GetY) {
        if (buyIds) {
            remainingBuyIds = checkMenuIds(buyIds[0].menuIds, buyCount);
        } else if (isIncludesAll) {
            const expectedCount = buyCount + getCount;

            if (bagItemsTotalCount < expectedCount) {
                fullMatch = false;
            }
        }

        if (getIds) {
            const flatGetIds: IRewardMenuIdModel[] = getIds.reduce((acc, curr) => [...acc, ...curr.menuIds], []) || [];

            remainingGetIds = checkMenuIds(flatGetIds, getCount);
        }
    }

    if (dealType?.mainType === MainDealsTypesByStructure.BuyX) {
        remainingEligibleIds = checkMenuIds(eligibleIds, buyCount);

        if (!eligibleIds && !isIncludesAll) {
            fullMatch = false;
        }
    }

    const matchingItemWithLowestPrice = availableBagEntries.find((item) => {
        return (
            matchingBagItems.includes(item.productId) ||
            item.childItems?.some((child) => matchingBagItems.includes(child.productId))
        );
    });

    if (remainingPrice) {
        fullMatch = false;
        message = `Only ${formatPrice(remainingPrice)} more needed to use this deal.`;
    } else if (!fullMatch && partiallyMatch) {
        message = 'Insufficient number of items added to the bag';
    } else if (!fullMatch && !partiallyMatch) {
        message = 'No qualifying items have been added to the bag';
    }

    const checkIfOfferExpired = () => {
        if (!isShowExpiredMessage) {
            return false;
        }
        const { isInTimeRange } = checkTimeRestrictions(offer.redemptionTimeRanges, locationTimezone);
        const isOfferExpired = isAfter(new Date(), new Date(offer.endDateTime));

        return !isInTimeRange || isOfferExpired;
    };

    const isOfferExpired = checkIfOfferExpired();

    if (isOfferExpired) {
        message = `This ${
            offer.discountDetailsType === DiscountDetailsTypeModel.PromoCode ? 'promo code' : 'reward'
        } is expired or no longer available.`;
    }

    const remainingFilteredGetIds = remainingGetIds && {
        count: remainingGetIds.count,
        menuIds: remainingGetIds.menuIds.filter(
            (menuIdObj) =>
                (!buyIds?.some((buyId) => buyId.menuIds.some(({ menuId }) => menuId === menuIdObj.menuId)) &&
                    !matchingBagItems.includes(menuIdObj.menuId)) ||
                (buyIds?.some((buyId) => buyId.menuIds.some(({ menuId }) => menuId === menuIdObj.menuId)) &&
                    matchingBagItems.includes(menuIdObj.menuId)) ||
                (buyIds?.some((buyId) => buyId.menuIds.some(({ menuId }) => menuId === menuIdObj.menuId)) &&
                    !matchingBagItems.includes(menuIdObj.menuId))
        ),
    };

    return {
        isOfferExpired,
        isOfferApplicable,
        partiallyMatch,
        fullMatch,
        remainingPrice,
        message,
        remainingBuyIds,
        remainingGetIds: remainingFilteredGetIds,
        remainingEligibleIds,
        matchingItemWithLowestPrice,
        checkIfOfferExpired,
    };
};
