import { TallyProductModel, TOffersUnionModel } from '../../@generated/webExpApi';
import { getDealWarning, IRemainingMenuIds } from '../../common/helpers/dealHelper';
import { useBagRenderItems } from '../../common/hooks/useBagRenderItems';
import { getCheckoutEntries } from '../../components/organisms/bag/bagModel';
import { useTallyItemsWithPricesAndCalories } from './domainMenu';
import useOrderLocation from './useOrderLocation';
import useRewards from './useRewards';

export interface ISuggestedDeal {
    fullMatchOffer?: TOffersUnionModel;
    partiallyMatchOffer?: TOffersUnionModel;
    remainingBuyIds?: IRemainingMenuIds;
    remainingGetIds?: IRemainingMenuIds;
    remainingEligibleIds?: IRemainingMenuIds;
    matchingItemWithLowestPrice?: TallyProductModel;
}

const useSuggestedDeal = (): ISuggestedDeal => {
    // eslint-disable-next-line testing-library/render-result-naming-convention
    const bagRenderItems = useBagRenderItems();
    const checkoutEntries = getCheckoutEntries(bagRenderItems);
    const bagItemPrices = useTallyItemsWithPricesAndCalories(checkoutEntries);

    const subTotal = bagItemPrices.reduce((acc, curr) => acc + curr.productData.totalPrice * curr.quantity, 0);
    const { currentLocation } = useOrderLocation();

    const { getApplicableOffers } = useRewards();
    const applicableOffers = getApplicableOffers(currentLocation?.id);

    return applicableOffers.reduce<ISuggestedDeal>((acc, offer) => {
        const {
            fullMatch,
            partiallyMatch,
            remainingBuyIds,
            remainingGetIds,
            remainingEligibleIds,
            matchingItemWithLowestPrice,
        } = getDealWarning({ offer, bagRenderItems, subTotalBeforeDiscounts: subTotal, applicableOffers });

        if (fullMatch && !acc.fullMatchOffer && !acc.partiallyMatchOffer) {
            return {
                fullMatchOffer: offer,
                matchingItemWithLowestPrice,
            };
        }

        if (
            partiallyMatch &&
            !acc.fullMatchOffer &&
            !acc.partiallyMatchOffer &&
            (remainingBuyIds?.count || remainingGetIds?.count || remainingEligibleIds?.count)
        ) {
            return {
                remainingBuyIds,
                remainingGetIds,
                remainingEligibleIds,
                matchingItemWithLowestPrice,
                partiallyMatchOffer: offer,
            };
        }

        return acc;
    }, {});
};

export default useSuggestedDeal;
