import { useSelector } from 'react-redux';
import { createNextState, PayloadAction } from '@reduxjs/toolkit';
import { RootState, useAppDispatch, useAppSelector } from '../store';
import * as BagStore from '../bag';
import * as PdpStore from '../pdp';
import * as JustAddedToBagStore from '../justAddedToBag';
import { isInRange } from '../../common/helpers/checkoutHelpers';
import { compareTallyItems, findItemIndexInBag } from '../../common/helpers/bagHelper';
import {
    selectLineItems,
    selectBagEntriesCount,
    selectBagEntryForPDPTally,
    selectDeal,
    generateNextLineItemId,
    selectOrderTimeAndType,
} from '../selectors/bag';
import {
    selectDefaultTallyItem,
    selectProductById,
    selectRootProductByItemId,
    selectTopCategory,
} from '../selectors/domainMenu';
import { selectTallyUpdateBagItems } from '../selectors/tally';
import { TallyProductModel } from '../../@generated/webExpApi';
import { getSelectedExtrasTallyModifiers } from '../../common/helpers/getSelectedExtrasTallyModifiers';
import { isExtrasModifierGroup } from '../../common/helpers/isExtrasModifierGroup';
import { PDPTallyItemModifier, PDPTallyItemModifierGroup } from '../../redux/types';

export interface UseBagHook {
    bagEntries: TallyProductModel[];
    dealId: string;
    isDiscountLoading: boolean;
    bagDiscount: number;
    bagPromocodeDiscount: number;
    entriesMarkedAsRemoved: number[];
    isOpen: boolean;
    tooltipOpen: boolean;
    entryCreated: boolean;
    entryUpdated: boolean;
    entryCreatedWithDeal: boolean;
    entryUpdatedWithDeal: boolean;
    bagEntriesCount: number;
    getProductQuantityById: (productId: string) => number;
    justAddedToBag: JustAddedToBagStore.justAddedToBagPayload;
    orderTime: string;
    orderTimeType: BagStore.OrderTimeType;
    pickupTime: string;
    pickupTimeType: BagStore.OrderTimeType;
    deliveryTime: string;
    deliveryTimeType: BagStore.OrderTimeType;
    pickupTimeValues: BagStore.WorkingHours;
    pickupTimeIsValid: boolean;
    lastRemovedLineItemId: number;
    lastEdit: number;
    updateItemId: string;
    isUpdateBagItem: boolean;
    actions: {
        toggleIsOpen: (payload: BagStore.ToggleIsOpenPayload) => PayloadAction<BagStore.ToggleIsOpenPayload>;
        updateTooltip: (payload: BagStore.UpdateTooltipPayload) => PayloadAction<BagStore.UpdateTooltipPayload>;
        setJustAddedToBag: (payload: JustAddedToBagStore.justAddedToBagPayload) => void;
        editBagLineItem: (payload: BagStore.EditBagLineItem) => PayloadAction<BagStore.EditBagLineItem>;
        addDefaultToBag: (
            payload: BagStore.AddToBagWithDefaultsPayload | BagStore.AddToBagWithDefaultsPayload[]
        ) => void;
        markAsRemoved: (payload: BagStore.MarkAsRemovedPayload) => PayloadAction<BagStore.MarkAsRemovedPayload>;
        removeFromBag: (payload: BagStore.RemoveFromBagPayload) => PayloadAction<BagStore.RemoveFromBagPayload>;
        removeAllFromBag: (
            payload: BagStore.RemoveAllFromBagPayload
        ) => PayloadAction<BagStore.RemoveAllFromBagPayload>;
        clearBag: () => PayloadAction<BagStore.ClearBagPayload>;
        updateBagItems: () => PayloadAction<BagStore.UpdateBagItemsPayload>;
        updateBagItemCount: (
            payload: BagStore.UpdateBagItemCountPayload
        ) => PayloadAction<BagStore.UpdateBagItemCountPayload>;
        putToBag: (payload: BagStore.PutToBagPayload) => void;
        addDealToBag: (payload: BagStore.AddDealToBagPayload) => PayloadAction<BagStore.AddDealToBagPayload>;
        removeDealFromBag: () => void;
        clearLastRemovedLineItemId: () => void;
        setPickupTime: (payload: BagStore.SetPickupTimePayload) => PayloadAction<BagStore.SetPickupTimePayload>;
        resetOrderTime: () => void;
        resetPickupTime: () => void;
        resetDeliveryTime: () => void;
        initPickupTimeValues: (
            payload: BagStore.InitPickupTimeValuesPayload
        ) => PayloadAction<BagStore.InitPickupTimeValuesPayload>;
        setPickupTimeValues: (
            payload: BagStore.SetPickupTimeValuesPayload
        ) => PayloadAction<BagStore.SetPickupTimeValuesPayload>;
        setLastEdit: (payload: number) => PayloadAction<number>;
        clearLastEdit: () => void;
        setDiscountIsLoading: (payload: BagStore.DiscountLoadingPayload) => void;
        setDiscountToBag: (payload: BagStore.ProductsDiscountsPayload) => void;
        setAutoDiscountToBag: (payload: BagStore.ProductsAutoDiscountsPayload) => void;
        resetDiscountsOnBag: () => void;
        putCondimentsToBag: (payload: { [key: string]: PDPTallyItemModifier }) => void;
        customizeBagItem: (payload: TallyProductModel) => void;
        setUpdateItemId: (payload: string) => void;
        setIsUpdateBagItem: (payload: boolean) => void;
    };
}

export default function useBag(): UseBagHook {
    const dispatch = useAppDispatch();

    const bagEntries = useAppSelector(selectLineItems);
    const entriesMarkedAsRemoved = useSelector<RootState, number[]>((state) => state?.bag?.markedAsRemoved);
    const rootState = useSelector<RootState, RootState>((state) => state);

    const isOpen = useSelector<RootState, boolean>((state) => state?.bag?.isOpen);
    const tooltipOpen = useSelector<RootState, boolean>((state) => state?.bag?.tooltipOpen);
    const entryCreated = useSelector<RootState, boolean>((state) => state?.bag?.entryCreated);
    const entryUpdated = useSelector<RootState, boolean>((state) => state?.bag?.entryUpdated);
    const entryCreatedWithDeal = useSelector<RootState, boolean>((state) => state?.bag?.entryCreatedWithDeal);
    const entryUpdatedWithDeal = useSelector<RootState, boolean>((state) => state?.bag?.entryUpdatedWithDeal);
    const lastRemovedLineItemId = useSelector<RootState, number>((state) => state?.bag?.lastRemovedLineItemId);
    const lastEdit = useSelector<RootState, number>((state) => state?.bag?.lastEdit);
    const dealId = useAppSelector(selectDeal);
    const isDiscountLoading = useSelector<RootState, boolean>((state) => state?.bag?.isDiscountLoading);
    const updateItemId = useSelector<RootState, string>((state) => state?.bag?.updateItemId);
    const isUpdateBagItem = useSelector<RootState, boolean>((state) => state?.bag?.isUpdateBagItem);

    const justAddedToBag = useSelector<RootState, JustAddedToBagStore.justAddedToBagPayload>(
        (state) => state.justAddedToBag
    );
    const bagEntriesCount = useAppSelector(selectBagEntriesCount);

    const getProductQuantityById = (itemId: string): number => {
        const rootProduct = selectRootProductByItemId(rootState, itemId);
        return bagEntries
            .filter((item) => Object.keys(rootProduct.items).includes(item.productId))
            .reduce((result, currentItem) => result + currentItem.quantity, 0);
    };

    const { orderTime, orderTimeType } = useAppSelector(selectOrderTimeAndType) || {};
    const pickupTimeValues = useSelector<RootState, BagStore.WorkingHours>((state) => state.bag.pickupTimeValues);
    const pickupTime = useSelector<RootState, string | null>((state) => state.bag.pickupTime);
    const pickupTimeType = useSelector<RootState, BagStore.OrderTimeType>((state) => state.bag.pickupTimeType);
    const deliveryTime = useSelector<RootState, string | null>((state) => state.bag.deliveryTime);
    const deliveryTimeType = useSelector<RootState, BagStore.OrderTimeType>((state) => state.bag.deliveryTimeType);
    const bagDiscount = useSelector<RootState, number>((state) => state.bag.bagDiscount);
    const bagPromocodeDiscount = useSelector<RootState, number>((state) => state.bag.bagPromocodeDiscount);

    const pickupTimeIsValid = pickupTimeValues && orderTime ? isInRange(orderTime, pickupTimeValues) : false;

    const addDealToBag = (payload: BagStore.AddDealToBagPayload) => {
        return dispatch(BagStore.actions.addDealToBag(payload));
    };

    const removeDealFromBag = () => {
        return dispatch(BagStore.actions.removeDealFromBag());
    };

    const clearLastRemovedLineItemId = () => {
        return dispatch(BagStore.actions.clearLastRemovedLineItemId());
    };

    const addDefaultToBag = (
        payloadArg: BagStore.AddToBagWithDefaultsPayload | BagStore.AddToBagWithDefaultsPayload[]
    ) => {
        dispatch(BagStore.actions.toggleIsOpen({ isOpen: true }));

        const payloadArgArray = !Array.isArray(payloadArg) ? [payloadArg] : payloadArg;

        payloadArgArray.forEach((payload) => {
            const bagEntry = selectDefaultTallyItem(rootState, payload.productId);
            const itemIndexInBag = findItemIndexInBag(bagEntries, bagEntry);

            if (itemIndexInBag > -1) {
                const targetItemInBag = bagEntries?.find((entry) => entry.productId === bagEntry.productId);
                if (isOpen && targetItemInBag && entriesMarkedAsRemoved.includes(targetItemInBag.lineItemId)) {
                    return dispatch(BagStore.actions.markAsRemoved({ lineItemId: targetItemInBag.lineItemId }));
                }
                return dispatch(
                    BagStore.actions.updateBagItemCount({
                        bagEntryIndex: itemIndexInBag,
                        value: bagEntry.quantity,
                    })
                );
            }

            return dispatch(
                BagStore.actions.addToBag({
                    name: payload.name,
                    category: payload.category,
                    udpRecommendationId: payload.recommendationId,
                    bagEntry: selectBagEntryForPDPTally(rootState, bagEntry),
                })
            );
        });
    };

    const editBagLineItem = (payload: BagStore.EditBagLineItem) => dispatch(BagStore.actions.editBagLineItem(payload));

    const setJustAddedToBag = (payload: JustAddedToBagStore.justAddedToBagPayload) =>
        dispatch(JustAddedToBagStore.actions.setJustAddedToBag(payload));

    const markAsRemoved = (payload: BagStore.MarkAsRemovedPayload) => dispatch(BagStore.actions.markAsRemoved(payload));
    const removeFromBag = (payload: BagStore.RemoveFromBagPayload) => dispatch(BagStore.actions.removeFromBag(payload));
    const removeAllFromBag = (payload: BagStore.RemoveAllFromBagPayload) =>
        dispatch(BagStore.actions.removeAllFromBag(payload));

    const clearBag = () => dispatch(BagStore.actions.clearBag());

    const updateBagItemCount = (payload: BagStore.UpdateBagItemCountPayload) =>
        dispatch(BagStore.actions.updateBagItemCount(payload));

    const toggleIsOpen = (payload: BagStore.ToggleIsOpenPayload) => {
        // when bag is closed remove items that are marked as removed and remove intermediate state for PDP pages
        if (!payload.isOpen && !!entriesMarkedAsRemoved?.length) {
            dispatch(BagStore.actions.removeAllMarkedAsRemovedFromBag());
            dispatch(PdpStore.actions.resetPdpState());
        }

        return dispatch(BagStore.actions.toggleIsOpen(payload));
    };

    const updateTooltip = (payload: BagStore.UpdateTooltipPayload) => dispatch(BagStore.actions.updateTooltip(payload));

    const setLastEdit = (payload: number) => dispatch(BagStore.actions.setLastEdit(payload));
    const clearLastEdit = () => dispatch(BagStore.actions.clearLastEdit());

    const putToBag = (payload: BagStore.PutToBagPayload) => {
        const extras = getSelectedExtrasTallyModifiers(payload.pdpTallyItem);

        // it removes extras modifiers from pdp tally item
        const pdpTallyItem = createNextState(payload.pdpTallyItem, (state) => {
            state.modifierGroups?.forEach((gr) => {
                if (isExtrasModifierGroup(gr)) {
                    // eslint-disable-next-line no-param-reassign
                    gr.modifiers = [];
                }
            });

            return state;
        });

        const targetItemInBag = bagEntries.find((entry) => entry.lineItemId === pdpTallyItem.lineItemId);
        const updateLineIndex = bagEntries.findIndex((entry) => entry.productId === updateItemId);

        const targetItemInBagIndex = targetItemInBag ? findItemIndexInBag(bagEntries, targetItemInBag) : -1;
        const sameItemInBagIndex = findItemIndexInBag(bagEntries, pdpTallyItem);

        const tallyItemWithoutExtrasAndRelatedItemGroups = {
            ...pdpTallyItem,
            relatedItemGroups: undefined,
        };

        dispatch(BagStore.actions.toggleIsOpen({ isOpen: true }));

        // Is the target item in the bag
        if (
            (sameItemInBagIndex > -1 && !entriesMarkedAsRemoved.includes(bagEntries[sameItemInBagIndex]?.lineItemId)) ||
            (targetItemInBagIndex > -1 &&
                !entriesMarkedAsRemoved.includes(bagEntries[targetItemInBagIndex]?.lineItemId))
        ) {
            if (sameItemInBagIndex === targetItemInBagIndex) {
                // Do nothing if no changes applied
            } else if (sameItemInBagIndex > -1 && targetItemInBagIndex > -1) {
                // Items consolidation
                dispatch(BagStore.actions.removeFromBag({ lineItemId: targetItemInBag.lineItemId }));

                dispatch(
                    BagStore.actions.updateBagItemCount({
                        bagEntryIndex:
                            sameItemInBagIndex > targetItemInBagIndex ? sameItemInBagIndex - 1 : sameItemInBagIndex,
                        value: pdpTallyItem.quantity,
                    })
                );
            } else if (sameItemInBagIndex > -1) {
                // Update quantity only
                dispatch(
                    BagStore.actions.updateBagItemCount({
                        bagEntryIndex: sameItemInBagIndex,
                        value: pdpTallyItem.quantity,
                    })
                );
            } else {
                dispatch(
                    BagStore.actions.editBagLineItem({
                        bagEntry: selectBagEntryForPDPTally(rootState, tallyItemWithoutExtrasAndRelatedItemGroups),
                    })
                );
            }

            const removeLineItem = bagEntries.find((entry) => entry.productId === updateItemId);
            if (removeLineItem) {
                dispatch(BagStore.actions.removeFromBag({ lineItemId: removeLineItem.lineItemId }));
            }

            putExtrasToBag(extras);
        } else if (updateLineIndex > -1) {
            dispatch(
                BagStore.actions.updateBagItem({
                    bagEntryIndex: updateLineIndex,
                    value: selectBagEntryForPDPTally(rootState, tallyItemWithoutExtrasAndRelatedItemGroups),
                })
            );

            putExtrasToBag(extras);
        } else {
            const nextLineItemId = generateNextLineItemId(bagEntries);

            dispatch(
                BagStore.actions.addToBag({
                    bagEntry: selectBagEntryForPDPTally(rootState, tallyItemWithoutExtrasAndRelatedItemGroups),
                    name: payload.name,
                    category: payload.category,
                    sauce: payload.sauce,
                })
            );

            putExtrasToBag(extras, nextLineItemId + 1);
        }

        dispatch(BagStore.actions.setIsUpdateBagItem(false));

        putRelatedItemGroupsToBag(pdpTallyItem.relatedItemGroups);
    };

    const putExtrasToBag = (extras: PDPTallyItemModifier[] = [], nextLineItemId?: number) => {
        let lineItemId = nextLineItemId ?? generateNextLineItemId(bagEntries);

        const toAddToBag: BagStore.AddToBagPayload[] = [];
        const toUpdateBagCount: BagStore.UpdateBagItemCountPayload[] = [];

        extras.forEach((item) => {
            if (item) {
                const sameItemInBagIndex = findItemIndexInBag(bagEntries, item);

                if (sameItemInBagIndex > -1) {
                    toUpdateBagCount.push({
                        bagEntryIndex: sameItemInBagIndex,
                        value: item.quantity,
                    });
                } else {
                    toAddToBag.push({
                        bagEntry: selectBagEntryForPDPTally(rootState, {
                            ...item,
                            lineItemId: lineItemId++,
                        }),
                        name: selectProductById(rootState, item.productId)?.name,
                        category: selectTopCategory(rootState, item.productId)?.name,
                    });
                }
            }
        });

        dispatch(BagStore.actions.addMultipleToBag(toAddToBag));
        dispatch(BagStore.actions.updateBagItemsCount(toUpdateBagCount));
    };

    const putCondimentsToBag = (condimentsById: { [id: string]: PDPTallyItemModifier }) => {
        Object.values(condimentsById).forEach((condiment) => {
            const { productId, quantity, price } = condiment;

            const sameItemInBagIndex = findItemIndexInBag(bagEntries, condiment);
            const currentBagEntry = bagEntries.find((entry) => compareTallyItems(entry, condiment));

            if (sameItemInBagIndex > -1) {
                if (condiment.quantity === 0 && currentBagEntry) {
                    //remove condiment from the shopping bag if quantity equals 0
                    dispatch(BagStore.actions.removeFromBag({ lineItemId: currentBagEntry.lineItemId }));
                } else {
                    // Update quantity only
                    if (condiment.quantity !== currentBagEntry?.quantity) {
                        dispatch(
                            BagStore.actions.updateBagCondimentCount({
                                bagEntryIndex: sameItemInBagIndex,
                                value: condiment.quantity,
                            })
                        );
                    }
                }
            } else {
                // Add items wit quantity !== 0 only
                if (condiment.quantity !== 0) {
                    dispatch(
                        BagStore.actions.addToBag({
                            bagEntry: selectBagEntryForPDPTally(rootState, {
                                productId,
                                price,
                                quantity,
                            }),
                            name: selectProductById(rootState, productId)?.name,
                        })
                    );
                }
            }
        });
    };

    const putRelatedItemGroupsToBag = (relatedItemGroups: PDPTallyItemModifierGroup[] = []) => {
        // handle condiments
        const condimentsGroup = relatedItemGroups[0];

        if (!condimentsGroup) return;

        const pdpCondiments = condimentsGroup.modifiers || [];
        const pdpCondimentsById = pdpCondiments.reduce((prev, el) => {
            return { ...prev, [el.productId]: el };
        }, {} as Record<string, PDPTallyItemModifier>);
        putCondimentsToBag(pdpCondimentsById);
    };

    const updateBagItems = () => {
        const payload: BagStore.UpdateBagItemsPayload = {
            value: selectTallyUpdateBagItems(rootState),
        };

        return dispatch(BagStore.actions.updateBagItems(payload));
    };

    const setPickupTime = (payload: BagStore.SetPickupTimePayload) => {
        return dispatch(BagStore.actions.setPickupTime(payload));
    };

    const resetOrderTime = () => {
        return dispatch(BagStore.actions.resetOrderTime());
    };

    const resetPickupTime = () => {
        return dispatch(BagStore.actions.resetPickupTime());
    };

    const resetDeliveryTime = () => {
        return dispatch(BagStore.actions.resetDeliveryTime());
    };

    const initPickupTimeValues = (payload: BagStore.InitPickupTimeValuesPayload) => {
        return dispatch(BagStore.actions.initPickupTimeValues(payload));
    };

    const setPickupTimeValues = (payload: BagStore.SetPickupTimeValuesPayload) => {
        return dispatch(BagStore.actions.setPickupTimeValues(payload));
    };

    const setDiscountIsLoading = (payload: boolean) => dispatch(BagStore.actions.setDiscountIsLoading(payload));

    const setDiscountToBag = (payload: BagStore.ProductsDiscountsPayload) =>
        dispatch(BagStore.actions.setDiscountToBag(payload));

    const setAutoDiscountToBag = (payload: BagStore.ProductsAutoDiscountsPayload) =>
        dispatch(BagStore.actions.setAutoDiscountToBag(payload));

    const resetDiscountsOnBag = () => dispatch(BagStore.actions.resetDiscountsOnBag());

    const customizeBagItem = (payload: TallyProductModel) => {
        dispatch(BagStore.actions.customizeBagItem(payload));
    };

    const setUpdateItemId = (payload: string) => {
        dispatch(BagStore.actions.setUpdateItemId(payload));
    };

    const setIsUpdateBagItem = (payload: boolean) => {
        dispatch(BagStore.actions.setIsUpdateBagItem(payload));
    };

    return {
        isOpen,
        tooltipOpen,
        entryCreated,
        entryUpdated,
        entryCreatedWithDeal,
        entryUpdatedWithDeal,
        bagEntries,
        dealId,
        isDiscountLoading,
        bagDiscount,
        bagPromocodeDiscount,
        entriesMarkedAsRemoved,
        bagEntriesCount,
        getProductQuantityById,
        justAddedToBag,
        orderTime,
        orderTimeType,
        pickupTime,
        pickupTimeType,
        deliveryTime,
        deliveryTimeType,
        pickupTimeValues,
        pickupTimeIsValid,
        lastRemovedLineItemId,
        lastEdit,
        updateItemId,
        isUpdateBagItem,
        actions: {
            toggleIsOpen,
            updateTooltip,
            addDealToBag,
            removeDealFromBag,
            addDefaultToBag,
            setJustAddedToBag,
            editBagLineItem,
            markAsRemoved,
            removeFromBag,
            removeAllFromBag,
            clearBag,
            updateBagItems,
            updateBagItemCount,
            putToBag,
            setPickupTime,
            resetOrderTime,
            resetPickupTime,
            resetDeliveryTime,
            initPickupTimeValues,
            setPickupTimeValues,
            clearLastRemovedLineItemId,
            setLastEdit,
            clearLastEdit,
            setDiscountIsLoading,
            setDiscountToBag,
            setAutoDiscountToBag,
            resetDiscountsOnBag,
            putCondimentsToBag,
            customizeBagItem,
            setUpdateItemId,
            setIsUpdateBagItem,
        },
    };
}
