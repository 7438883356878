import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetCustomerAccountLoyaltyPointsResponseModel } from '../@generated/webExpApi/models';

export type ILoyalty = IGetCustomerAccountLoyaltyPointsResponseModel;

export type ILoyaltyState = ILoyalty & { loading: boolean; error: Error | null };

export const initialState: ILoyaltyState = {
    loading: false,
    error: null,
    pointsBalance: 0,
    pointsExpiring: 0,
    pointsExpiringDate: null,
};

const loyaltySlice = createSlice({
    name: 'loyalty',
    initialState,
    reducers: {
        setLoyaltyPoints: (state, action: PayloadAction<ILoyaltyState>) => {
            state.pointsBalance = action.payload.pointsBalance;
            state.pointsExpiring = action.payload.pointsExpiring;
            state.pointsExpiringDate = action.payload.pointsExpiringDate;
            state.loading = false;
            state.error = null;
        },
        setLoyaltyPointsLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setLoyaltyError: (state, action: PayloadAction<Error>) => {
            state.error = action.payload;
        },
    },
});

export const { actions, name } = loyaltySlice;

export default loyaltySlice.reducer;
