import { createContext } from 'react';
import { noop } from '../helpers/noop';

interface IBottomCtaContext {
    bottomCtaId?: string;
    setBottomCtaId: (string) => void;
    dependency: string;
    setDependency: (string) => void;
}

const BottomCtaContext = createContext({
    bottomCtaId: '',
    setBottomCtaId: noop,
    dependency: '',
    setDependency: noop,
} as IBottomCtaContext);

export default BottomCtaContext;
