import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BannerEntry {
    bannerId: string;
}

type BannerState = BannerEntry[];

export const initialState: BannerState = [];

export type DismissBannerPayload = { bannerEntry: BannerEntry };

const dismissedAlertBannersSlice = createSlice({
    name: 'dismissedAlertBanners',
    initialState,
    reducers: {
        dismissBanner: (state, action: PayloadAction<DismissBannerPayload>) => {
            state.push(action.payload.bannerEntry);
            return state;
        },
    },
});

export const { actions } = dismissedAlertBannersSlice;
export default dismissedAlertBannersSlice.reducer;
