import React, { FC, useState } from 'react';
import bottomCtaContext from '../common/contexts/bottomCtaContext';

const { Provider } = bottomCtaContext;

const BottomCtaProvider: FC = ({ children }) => {
    const [bottomCtaId, setBottomCtaId] = useState();
    const [dependency, setDependency] = useState();

    return <Provider value={{ bottomCtaId, setBottomCtaId, dependency, setDependency }}>{children}</Provider>;
};

export default BottomCtaProvider;
