import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TOffersUnionModel } from '../@generated/webExpApi';
import { IDealTallyItem, PDPTallyItemModifierGroup } from './types';

export interface DealPDPState {
    offer?: TOffersUnionModel;
    selectionsByPicks: DealPDPSelectionByPicks[];
    modifyingFromBag?: boolean;
}

export interface DealPDPSelectionByPicks {
    selectedItems: IDealTallyItem[];
    shouldShowDiscountedPrice: boolean;
    shouldHideProductPrice: boolean;
    multipleSelection: boolean;
    maxQuantity: number;
}

export type PutOfferPayload = { offer: TOffersUnionModel };
export type PutOfferSelectedIdsByPicks = {
    selectedItems: IDealTallyItem[];
    shouldShowDiscountedPrice: boolean;
    shouldHideProductPrice: boolean;
    index: number;
    multipleSelection: boolean;
    maxQuantity: number;
};

export type EditOfferProductModifiers = {
    modifierGroups: PDPTallyItemModifierGroup[];
    productId: string;
    pickIndex: number;
};

export const initialState: DealPDPState = {
    offer: null,
    modifyingFromBag: false,
    selectionsByPicks: [],
};

const dealPdpSlice = createSlice({
    name: 'dealPDP',
    initialState,
    reducers: {
        putOffer: (state, action: PayloadAction<PutOfferPayload>) => {
            state.offer = action.payload.offer;
        },
        putOfferSelectedIdsByPicks: (state, action: PayloadAction<PutOfferSelectedIdsByPicks>) => {
            const {
                selectedItems,
                shouldShowDiscountedPrice,
                index,
                multipleSelection,
                maxQuantity,
                shouldHideProductPrice,
            } = action.payload;

            state.selectionsByPicks[index] = {
                selectedItems,
                shouldShowDiscountedPrice,
                shouldHideProductPrice,
                multipleSelection,
                maxQuantity,
            };
        },
        editOfferProductModifiers: (state, action: PayloadAction<EditOfferProductModifiers>) => {
            const { modifierGroups, productId, pickIndex } = action.payload;

            state.selectionsByPicks[pickIndex] = {
                ...state.selectionsByPicks[pickIndex],
                selectedItems: state.selectionsByPicks[pickIndex].selectedItems.map((item) => {
                    if (item.productId === productId) {
                        return { ...item, modifierGroups };
                    }

                    return item;
                }),
            };
        },
        resetState: (state) => {
            state.offer = null;
            state.selectionsByPicks = [];
            state.modifyingFromBag = false;
        },
    },
});

export const actions = {
    ...dealPdpSlice.actions,
};

export default dealPdpSlice.reducer;
