import { createSelector } from '@reduxjs/toolkit';
import { DealPDPSelectionByPicks, DealPDPState } from '../dealPdp';
import { RootState } from '../store';
import { selectOfferProductPrice } from './tally';
import {
    selectProducts,
    selectDefaultTallyModifierGroups,
    selectProductById,
    selectDefaultModifiers,
    selectProductGroupByProductId,
    selectProductSizes,
    selectSizeGroup,
    selectSelectedModifiers,
    selectProductSize,
} from './domainMenu';
import { IRewardMenuIdModel, ItemModel, OtherPriceTypeEnumModel, TOffersUnionModel } from '../../@generated/webExpApi';
import {
    IAboutSectionInfo,
    IDealDisplayProduct,
    IDealTallyItem,
    IDefaultModifier,
    INutritionInfoSizeSelection,
    ISelectedModifier,
    ISizeSelection,
} from '../types';
import { sortOrder } from '../../common/constants/aboutProduct';
import { IDealInfo } from '../hooks/useDealPdp';
import { getChangedModifiers } from '../../common/helpers/getChangedModifiers';
import { findItemIndexInBag, getBagItemSizeLabel } from '../../common/helpers/bagHelper';
import { IProductDetailsPagePath } from '../../lib/contentfulDelivery';
import { getProductPagePathByProductId } from '../../lib/domainProduct';
import { IProductItemById } from '../../common/services/globalContentfulProps';
import { DEAL_TITLE_IN_ABOUT_SECTION } from '../../common/constants/rewards';
import { checkProductIsAvailable } from '../../common/helpers/checkProductIsAvailable';

const selectDealPdp = (state: RootState): DealPDPState => state.dealPdp;

export const selectDealPDPOffer = createSelector(
    selectDealPdp,
    (dealPdp: DealPDPState): TOffersUnionModel => dealPdp.offer
);

export const selectDealPDPOfferPicks = createSelector(
    selectDealPdp,
    (dealPdp: DealPDPState): DealPDPSelectionByPicks[] => dealPdp.selectionsByPicks
);

export const selectDealInfoBySelectedIds = createSelector(
    (state: RootState, selectedIdsByPicks: DealPDPSelectionByPicks[]): IDealInfo => {
        const products = selectProducts(state);

        return selectedIdsByPicks.reduce(
            (acc, item) => {
                const curItemValues = item.selectedItems.reduce(
                    (prevValue, selectedItem) => {
                        const product = products[selectedItem.productId];
                        const offerPrice = selectOfferProductPrice(state, product?.price?.currentPrice);

                        const selectedModifiers = selectSelectedModifiers()(state, selectedItem);
                        const defaultModifiers = selectDefaultModifiers()(state, selectedItem.productId);

                        const { addedModifiers, removedDefaultModifiers } = getChangedModifiers(
                            selectedModifiers,
                            defaultModifiers
                        );

                        const priceAndCaloriesReducer = (
                            acc: { price: number; calories: number },
                            item: ISelectedModifier | IDefaultModifier
                        ) => ({
                            price: acc.price + (item.price || 0),
                            calories: acc.calories + (item.calories || 0),
                        });

                        const priceAndCaloriesFromAddedModifiers = addedModifiers.reduce(priceAndCaloriesReducer, {
                            price: 0,
                            calories: 0,
                        });
                        const priceAndCaloriesFromRemovedModifiers = removedDefaultModifiers.reduce(
                            priceAndCaloriesReducer,
                            { price: 0, calories: 0 }
                        );

                        const newCalories =
                            prevValue.calories +
                            (product?.nutrition?.totalCalories || 0) +
                            priceAndCaloriesFromAddedModifiers.calories -
                            priceAndCaloriesFromRemovedModifiers.calories;

                        const newPrice =
                            prevValue.price +
                            priceAndCaloriesFromAddedModifiers.price -
                            priceAndCaloriesFromRemovedModifiers.price +
                            (item.shouldShowDiscountedPrice ? offerPrice : product?.price?.currentPrice);

                        const productSize = selectProductSize(state, selectedItem.productId);
                        const sizeLabel = getBagItemSizeLabel(productSize);
                        const productName =
                            productSize && productSize.toLowerCase() !== 'none'
                                ? `${product?.name} (${sizeLabel})`
                                : product?.name;

                        return {
                            price: newPrice,
                            calories: newCalories,
                            selections: [...prevValue.selections, productName],
                            addedModifiers: [...prevValue.addedModifiers, ...addedModifiers],
                            removedDefaultModifiers: [...prevValue.removedDefaultModifiers, ...removedDefaultModifiers],
                        };
                    },
                    {
                        price: 0,
                        calories: 0,
                        selections: [],
                        addedModifiers: [],
                        removedDefaultModifiers: [],
                    }
                );

                return {
                    price: acc.price + curItemValues.price,
                    calories: acc.calories + curItemValues.calories,
                    selections: [...acc.selections, ...curItemValues.selections],
                    addedModifiers: [...acc.addedModifiers, ...curItemValues.addedModifiers],
                    removedDefaultModifiers: [...acc.removedDefaultModifiers, ...curItemValues.removedDefaultModifiers],
                };
            },
            {
                price: 0,
                calories: 0,
                selections: [],
                addedModifiers: [],
                removedDefaultModifiers: [],
            }
        );
    },
    (dealInfo) => dealInfo
);

export const selectDealDisplayProductsByMenuIds = createSelector(
    (
        state: RootState,
        menuIds: IRewardMenuIdModel[],
        productsById: IProductItemById,
        productDetailsPagePaths: IProductDetailsPagePath[]
    ): IDealDisplayProduct[] => {
        const products = selectProducts(state);

        return menuIds
            ?.map(({ menuId, quantity }) => {
                const product = products[menuId];

                const hasCategory = !!getProductPagePathByProductId(menuId, productDetailsPagePaths);
                const isVisible = productsById[menuId]?.fields?.isVisible;

                if (product && hasCategory) {
                    const currentPrice = product.hasChildItems
                        ? product.price?.otherPrices?.[OtherPriceTypeEnumModel.Meal]?.price
                        : product.price?.currentPrice;

                    const offerPrice = selectOfferProductPrice(state, currentPrice);
                    const productGroup = selectProductGroupByProductId(state, product.id);

                    const isAvailable =
                        !!product.isSaleable &&
                        checkProductIsAvailable(product) &&
                        (!!currentPrice || currentPrice === 0);

                    const allProductSizes = selectProductSizes(state, product.id);

                    return {
                        displayName: product.name,
                        displayProductDetails: {
                            quantity,
                            displayName: product.name,
                            productId: product.id,
                            calories: product?.nutrition?.totalCalories || 0,
                            price: currentPrice,
                            offerPrice,
                            mainProductSectionName: productGroup.name,
                            isAvailable,
                            isVisible,
                            selections: allProductSizes,
                            sizeGroupId: product.sizeGroupId,
                            isSaleable: !!product.isSaleable,
                        },
                    };
                }

                return null;
            })
            .filter(Boolean)
            .sort((a, b) => {
                if (a.displayProductDetails.isAvailable === false && b.displayProductDetails.isAvailable === true) {
                    return 1;
                }

                if (b.displayProductDetails.isAvailable === false && a.displayProductDetails.isAvailable === true) {
                    return -1;
                }

                return 0;
            });
    },
    (dealDisplayProducts) => dealDisplayProducts
);

export const selectDealTallyItems = createSelector(
    (state: RootState): IDealTallyItem[] => {
        const selectedIdsByPicks = selectDealPDPOfferPicks(state);

        const selectedItems = selectedIdsByPicks.reduce<IDealTallyItem[]>((acc, item) => {
            return [...acc, ...item.selectedItems];
        }, []);

        return selectedItems.reduce<IDealTallyItem[]>((acc, item) => {
            const sameItemInAccIndex = findItemIndexInBag(acc, item);

            if (sameItemInAccIndex > -1) {
                const newAcc = [...acc];
                newAcc[sameItemInAccIndex] = {
                    ...newAcc[sameItemInAccIndex],
                    quantity: newAcc[sameItemInAccIndex].quantity + 1,
                };
                return newAcc;
            }

            return [...acc, item];
        }, []);
    },
    (dealTallyItems) => dealTallyItems
);

export const selectDealTallyItemById = createSelector(
    (state: RootState, pickIndex: number, productId: string): IDealTallyItem => {
        const selectedIdsByPicks = selectDealPDPOfferPicks(state);
        const selectedItems = selectedIdsByPicks[pickIndex]?.selectedItems;
        const tallyItem = selectedItems?.find((item) => item?.productId === productId);

        return tallyItem;
    },
    (dealTallyItem) => dealTallyItem
);

export const selectDealDefaultTallyItemById = createSelector(
    (state: RootState, productId: string): IDealTallyItem => {
        const products = selectProducts(state);

        const product = products[productId];
        const curPrice = product?.price?.currentPrice;
        const modifierGroups = selectDefaultTallyModifierGroups(state, productId);

        return {
            productId,
            modifierGroups: modifierGroups,
            price: curPrice,
            quantity: 1,
        };
    },
    (dealTallyItem) => dealTallyItem
);

export const selectDealAboutSectionInfo = (state: RootState, pickIndex: number): IAboutSectionInfo | null => {
    const offer = selectDealPDPOffer(state);
    const selectedItems = selectDealPDPOfferPicks(state);

    const selectedItemsByStep = selectedItems
        .slice(0, pickIndex + 1)
        .map((pickItem) => pickItem.selectedItems)
        .flat()
        .map((item) => ({ menuId: item.productId }));

    const products = selectedItemsByStep?.map(({ menuId }) => selectProductById(state, menuId));
    const firstDealProduct = products[0];
    const firstSelectedItemId = firstDealProduct?.id;

    if (!firstSelectedItemId) {
        return null;
    }

    const buyCount = offer?.applicability?.buyCount || 0;
    const getCount = offer?.applicability?.getCount || 0;
    const isOnlyOneBuyItem = buyCount + getCount === 1;

    const firstSelectedProductDefaultModifiers = selectDefaultModifiers()(state, firstSelectedItemId);

    const firstSelectedProductGroup = selectProductGroupByProductId(state, firstSelectedItemId);
    if (!firstSelectedProductGroup?.name) {
        return null;
    }

    const uniqById = (item: ItemModel, index: number, arr: ItemModel[]) =>
        arr.findIndex((it) => it.id === item.id) === index;

    return {
        isOnlyOneBuyItem: isOnlyOneBuyItem,
        productId: firstSelectedItemId,
        productKind: isOnlyOneBuyItem ? firstSelectedProductGroup.name : DEAL_TITLE_IN_ABOUT_SECTION,
        description: isOnlyOneBuyItem ? firstDealProduct.description : null,
        whatsIncluded: isOnlyOneBuyItem ? firstSelectedProductDefaultModifiers.map((it) => it.name) : [],
        nutritionInfo: products?.filter(uniqById).map((product) => {
            const productSizes = selectProductSizes(state, product.id);
            const toProduct = (item: ISizeSelection) => item.product;

            const toNutritionInfoSizeSection = (product: ItemModel): INutritionInfoSizeSelection => {
                const { macroNutrients = {}, allergenInformation } = product.nutrition || {};
                const sortedFields = macroNutrients
                    ? Object.values(macroNutrients).sort((a, b) =>
                          sortOrder.indexOf(a.label) < sortOrder.indexOf(b.label) ? -1 : 1
                      )
                    : null;

                if (!sortedFields && !allergenInformation) {
                    return null;
                }

                const sizeGroup = selectSizeGroup(state, product.id);
                return {
                    name: sizeGroup?.name,
                    productId: product.id,
                    nutritionalFacts: sortedFields,
                    allergicInformation: allergenInformation,
                };
            };

            return {
                displayName: product.name,
                sizeSelections: productSizes
                    .map(toProduct)
                    .filter(Boolean)
                    .filter(uniqById)
                    .map(toNutritionInfoSizeSection)
                    .filter(Boolean),
            };
        }),
    };
};
