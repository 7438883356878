import useConfigurationState from '../../redux/hooks/useConfiguration';
import { ConfigurationValues } from '../../redux/configuration';
import { IFeatureFlags } from '../../lib/getFeatureFlags';

const createConfigurationMapper = (fileConfiguration: IFeatureFlags, cmsConfiguration: ConfigurationValues) => (
    fileConfigurationValue: string
) => {
    const fileToCMSMap = {
        isOAEnabled: 'disableOA',
        isDeliveryEnabled: 'disableDelivery',
        isApplePayEnabled: 'disableApplePay',
        isGooglePayEnabled: 'disableGooglePay',
        isTippingEnabled: 'disableTips',
        isPayAtStoredEnabled: 'disablePayAtStore',
        isBadgeEnabled: 'disableBadges',
    };

    const refreshKeys = ['configurationRefreshFrequency', 'personalizationRefreshFrequency'];

    if (refreshKeys.includes(fileConfigurationValue)) {
        return cmsConfiguration[fileConfigurationValue];
    }

    const fileFeatureIsManagedInCMS = fileConfigurationValue in fileToCMSMap;
    const fileFeatureIsEnabled = fileConfiguration[fileConfigurationValue];
    const cmsFeatureIsDisabled =
        fileToCMSMap[fileConfigurationValue] in cmsConfiguration &&
        cmsConfiguration[fileToCMSMap[fileConfigurationValue]];

    if (fileFeatureIsManagedInCMS && fileFeatureIsEnabled && cmsFeatureIsDisabled) {
        return false;
    }

    return fileConfiguration[fileConfigurationValue];
};

export const createConfiguration = (fileConfiguration: IFeatureFlags, cmsConfiguration: ConfigurationValues) => {
    const fileConfigurationKeys = Object.keys(fileConfiguration);
    const configurationMapper = createConfigurationMapper(fileConfiguration, cmsConfiguration);

    return fileConfigurationKeys.reduce((configuration, key) => {
        return { ...configuration, [key]: configurationMapper(key) };
    }, <ConfigurationValues>{});
};

export function useConfiguration(): {
    configuration: ConfigurationValues;
    actions: { setConfiguration: (payload: ConfigurationValues) => void };
} {
    const state = useConfigurationState();

    return { configuration: state.configuration, actions: state.actions };
}
