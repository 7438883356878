import React, { FC, ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';
import styles from './link.module.css';
import linkButtonStyles from './linkButton.module.css';

interface IInspireLinkButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    gtmId?: string;
    dataTestId?: string;
    linkType: 'primary' | 'secondary';
}

// use this component if you need a button with InspireLink styles
export const InspireLinkButton: FC<IInspireLinkButtonProps> = ({
    children,
    gtmId,
    dataTestId,
    linkType,
    className,
    type = 'button',
    ...rest
}) => {
    return (
        <button
            type={type}
            data-gtm-id={gtmId}
            data-testid={dataTestId}
            className={classnames(
                styles.link,
                linkButtonStyles.linkButton,
                {
                    [styles.linkPrimaryActive]: linkType === 'primary',
                    [styles.linkSecondaryActive]: linkType === 'secondary',
                },
                className
            )}
            {...rest}
        >
            {children}
        </button>
    );
};
