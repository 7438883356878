import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../store';

import navInterceptService from '../../common/services/navInterceptService';
import { track, untrack } from '../../common/services/navInterceptDecorator';
import { INavInterceptState } from '../navIntercept';

export interface INavInterceptHook {
    isPending: boolean;
    hasChanges: boolean;
    navigate: (result: boolean) => void;
}

const useNavIntercept = (): INavInterceptHook => {
    const dispatch = useAppDispatch();
    const { isPending, hasChanges } = useSelector<RootState, INavInterceptState>((state) => state.navIntercept);

    const navigate = useCallback((result: boolean) => isPending && navInterceptService.navigate(result), [isPending]);

    useEffect(() => {
        if (hasChanges) {
            track(dispatch);
        } else {
            untrack();
        }
    }, [hasChanges, dispatch]);

    return { isPending, hasChanges, navigate };
};

export default useNavIntercept;
