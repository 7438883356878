import { createSlice } from '@reduxjs/toolkit';
import { IFeatureFlags, getFeatureFlags } from '../lib/getFeatureFlags';
import { createConfiguration } from '../common/hooks/useConfiguration';
import contentfulConfiguration from '../contentfulConfiguration.json';

export interface ConfigurationValues extends IFeatureFlags {
    configurationRefreshFrequency: number;
    personalizationRefreshFrequency: number;
}

export interface ConfigurationState {
    lastSyncedAt: null | Date;
    configuration: null | ConfigurationValues;
}

const fallbackConfiguration = {
    ...getFeatureFlags(),
    configurationRefreshFrequency: 30,
    personalizationRefreshFrequency: 30,
};

export const initialState: ConfigurationState = {
    lastSyncedAt: new Date(0),
    configuration: createConfiguration(
        fallbackConfiguration,
        (contentfulConfiguration as unknown) as ConfigurationValues
    ),
};

const configuration = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        setConfiguration: (state, action) => ({
            configuration: createConfiguration(state.configuration, action.payload),
            lastSyncedAt: new Date(),
        }),
    },
});

export const { actions } = configuration;
export default configuration.reducer;
