import { ILocationsPageModel, SearchLocationRequest, WebExperienceApi } from '../../../@generated/webExpApi';
import { getLocale } from '../../helpers/locale';
import createErrorWrapper from '../createErrorWrapper';
import { Coordinates, ILocationSearchResult } from './types';

const locationSearch = async (coordinates: Coordinates, page = 0): Promise<ILocationSearchResult> => {
    const DEFAULT_RADIUS = 50;

    const expApi = new WebExperienceApi();

    const searchLocationByCoordinatesApi = createErrorWrapper<ILocationsPageModel, SearchLocationRequest>(
        'searchLocationByCoordinates',
        expApi.searchLocation.bind(expApi)
    );

    return searchLocationByCoordinatesApi({
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        radius: DEFAULT_RADIUS, // miles
        limit: 10,
        page,
        locale: getLocale(),
    });
};

export default locationSearch;
