import {
    CalorieRangeModel,
    ItemModel,
    MacroNutrientModel,
    SizeGroupModel,
    TallyModifierGroupModel,
    TallyModifierModel,
} from '../@generated/webExpApi';
import { IGlobalContentfulProps } from '../common/services/globalContentfulProps';

/***** IDisplayFullProduct ****/

export enum ProductTypesEnum {
    Single = 'SINGLE',
    Meal = 'MEAL',
    Promo = 'PROMO',
}
export interface IDisplayFullProduct {
    productType: ProductTypesEnum;
    displayName: string; // For simplicity.  e.g. Roast Beef Combo
    productId: string;
    mainProductId: string;
    mainProductSectionName: string;
    productSections: IDisplayProductSection[]; // ORDER MATTERS.  (e.g. Drink above Side)  This would contain all "Main" options, "Side" options, or "Drink" options
    upgradeProductId?: string;
    price: number;
    calories: number;
    calorieRange?: CalorieRangeModel;
    comboSize?: string;
    comboSizeName?: string;
    totalPrice?: number;
    offerPrice?: number;
}

export interface IDisplayProductSection {
    productSectionType?:
        | 'main'
        | 'side'
        | 'drink'
        | 'promo'
        | 'sauce'
        | ModifierGroupType.WINGTYPE
        | ModifierGroupType.CONDIMENTS
        | 'complimentary'
        | 'item';
    productSectionDisplayName?: string; // e.g. Side or Drink or Kid's Side
    productGroupId?: string;
}

export interface IDisplayProduct {
    tags?: { [key: string]: string };
    displayName: string; // e.g. Roast Beef Sandwich, Curly Fry, Coca-Cola
    displayProductDetails: IDisplayProductDetails; // AKA portion size
}
export interface IDealDisplayProduct extends Omit<IDisplayProduct, 'displayProductDetails'> {
    displayProductDetails: IDisplayDealProductDetails;
}

export interface IDisplayProductDetails {
    // Ideally we want to sort these by multiple attributes on same PDP.
    // e.g. SM, MD, LG for Roast Beef Classic,  Beef N Cheddar
    // e.g. Regular, Giant for Spicy East Coast Italian or Italian Night Club
    productId: string;
    // (not currently supported but future case)  For example, PDP could support 3 sizes * Classic, Double, Half Pound for a total of 9 products
    calories?: number;
    price?: number;
    offerPrice?: number;
    displayName?: string;
    defaultQuantity?: number;
    maxQuantity?: number;
    minQuantity?: number;
    quantity?: number;
    selections?: ISizeSelection[];
    // Intensity of sauces, TODO create a separate type for BWW
    intensity?: ProductIntensityEnum;
    isRecommended?: boolean;
    actionCodes?: string[];
    productGroupId?: string;
    sequence?: number;
    modifiers?: IDisplayProductDetails[];
}

export interface IDisplayDealProductDetails extends IDisplayProductDetails {
    isAvailable: boolean;
    isVisible: boolean;
    sizeGroupId: string;
    isSaleable: boolean;
}

export interface IDisplayModifierGroup {
    displayName: string; // e.g. Meat, Cheese, Veggies
    modifiers: IDisplayProduct[]; // ORDER MATTERS
    modifierGroupId: string;
    sequence: number;
    minQuantity: number;
    maxQuantity: number;
    metadata?: { [key: string]: string };
}

export interface ISaucesDisplayModifierGroup extends IDisplayModifierGroup {
    hasOnSideOption: boolean;
    isOnSideChecked?: boolean;
    isOnSideOptionDisabled?: boolean;
}
/* ********** */

export interface ISelectedModifier {
    name: string;
    quantity: number;
    isWhatsOnItModifierGroup?: boolean;
    productId: string;
    price: number;
    calories?: number;
    selection?: string;
    relatedSelections?: string[];
    metadata?: { [key: string]: string };
    modifiers?: ISelectedSubModifier[];
    removedModifiers?: IDefaultModifier[];
    actionCodes?: string[];
    itemGroupId?: string;
}

export type ISelectedSubModifier = {
    name: string;
    calories?: number;
    modifiers?: TallyModifierModel[];
} & TallyModifierModel;

export interface IDefaultModifier {
    name: string;
    defaultQuantity: number;
    productId: string;
    minQuantity: number;
    maxQuantity: number;
    price?: number;
    calories?: number;
    selection?: string;
    relatedSelections?: string[];
    metadata?: { [key: string]: string };
    modifiers?: TallyModifierModel[];
    itemGroupId?: string;
}

export interface ISelectedExtraItem {
    name: string;
    price: number;
    calories?: number;
}

/* ********** */
export interface ISizeSelection {
    sizeGroup: SizeGroupModel;
    disabled: boolean;
    product: ItemModel;
    isGroupedBySize: boolean;
}

/* ********* */
export interface INutritionInfoSizeSelection {
    name: string;
    nutritionalFacts?: MacroNutrientModel[];
    allergicInformation?: string;
    productId?: string;
}
export interface INutritionInfo {
    displayName: string;
    sizeSelections: INutritionInfoSizeSelection[];
}

export interface IAboutSectionInfo {
    productId: string;
    productKind: string;
    description: string;
    whatsIncluded: string[];
    nutritionInfo?: INutritionInfo[];
    isOnlyOneBuyItem?: boolean;
}

export interface IExtraItemModel extends ItemModel {
    resultPrice: number;
    resultCalories: number;
    quantity: number;
    sizeSelections?: ISizeSelection[];
}

export interface IExtraProductGroup {
    displayName: string;
    products: IExtraItemModel[];
    sequence: number;
    productGroupId: string;
    minQuantity: number;
    maxQuantity: number;
    isMaxAmountReached: boolean;
}

export enum ProductIntensityEnum {
    MILD = 'Mild',
    MEDIUM = 'Medium',
    HOT = 'Hot',
    WILD = 'Wild',
}

export interface IDomainProductItem extends ItemModel {
    isSaleable: boolean;
}

export type ModifierCardSelectorType = 'default' | 'quantity' | 'size';

export enum ItemGroupEnum {
    BOGO = 'BOGO',
    BOG6 = 'BOG6',
    BOGO_50_OFF = 'BOGO 50%',
    PROTEIN = 'Protein',
    FLAVOR = 'Flavor',
    SIZE = 'Size',
}

export enum ModifierGroupType {
    MODIFICATIONS = 'Modifications',
    SELECTIONS = 'Selections',
    SAUCES = 'Sauces',
    EXTRAS = 'Extras',
    WINGTYPE = 'WingType',
    CONDIMENTS = 'Condiments',
    WHATS_ON_IT = 'WhatsOnIt',
}

export enum ActionCodesEnum {
    NO = 'NO',
    EXTRA = 'EXTRA',
    REGULAR = 'REGULAR',
    ADD = 'ADD',
    EASY = 'EASY',
}

export interface PDPTallyItemModifier extends Omit<TallyModifierModel, 'priceType' | 'actionCode'> {
    modifierGroups?: PDPTallyItemModifierGroup[];
    modifiers?: PDPTallyItemModifier[];
}

export interface PDPTallyItemModifierGroup extends TallyModifierGroupModel {
    isOnSideChecked?: boolean;
    modifiers?: PDPTallyItemModifier[];
    metadata?: { [key: string]: string };
    isOnSideOptionDisabled?: boolean;
}

export interface IDealTallyItem {
    price: number;
    productId: string;
    quantity: number;
    modifierGroups?: PDPTallyItemModifierGroup[];
}

export enum OrderHistoryPickupTimeType {
    ASAP = 'ASAP',
    FUTURE = 'FUTURE',
}

export enum CardInWalletInfo {
    SAVED = 'SAVED',
    FAILED = 'FAILED',
    NOT_SELECTED = 'NOT_SELECTED',
}

export interface IUseSdiDisplayModifierGroup {
    stage?: number;
    productId?: string;
    /**
     * @param childIndex pass this to select displayModifierGroup for combo item (tallyItem.childItems[childIndex])
     */
    childIndex?: number;
    /**
     * @param sectionIndex pass this to select the correct item from the selectionsByPicks array in the dealPdp state (selectedIdsByPicks[pickIndex]?.selectedItems)
     */
    sectionIndex?: number;
    productGroupId: string;
    productsById: IGlobalContentfulProps['productsById'];
}

export interface IUseSdiDisplayModifierGroups extends Omit<IUseSdiDisplayModifierGroup, 'productGroupId'> {
    productGroupIds: string[];
}

export interface IEditSDIOfferProductModifiers {
    modifierGroupId: string;
    modifierId: string;
    itemGroupId?: string;
    quantity: number;
    /**
     * @param sectionIndex pass this to select the correct item from the selectionsByPicks array in the dealPdp state (selectedIdsByPicks[pickIndex]?.selectedItems)
     */
    sectionIndex: number;
    productId: string;
}

export type IProducts = { [key: string]: IDomainProductItem };
