export const sortOrder = [
    'Serving Weight (g)',
    'Calories',
    'Calories from Fat',
    'Fat - Total (g)',
    'Saturated Fat (g)',
    'Trans Fat (g)',
    'Cholesterol (mg)',
    'Sodium (mg)',
    'Total Carbohydrates (g)',
    'Dietary Fiber (g)',
    'Sugars (g)',
    'Proteins (g)',
    'Allergens',
];
