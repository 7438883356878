import React, { FC, useEffect, useState, useMemo } from 'react';

import {
    useAccount,
    useDomainMenu,
    useNotifications,
    useRewards,
    useSubmitOrder,
    useSelectedSell,
    useLoyalty,
    useOrderLocation,
    useOrderHistory,
    usePersonalization,
    useTallyOrder,
    useBag,
} from '../redux/hooks';
import { getLocationById } from '../common/services/locationService';
import { useAuth0 } from '@auth0/auth0-react';
import { initAccountService } from '../common/services/customerService/account';
import DealsService from '../common/services/customerService/deals';
import LoyaltyService from '../common/services/customerService/loyalty';
import OrderHistoryService from '../common/services/customerService/orders';
import { useFeatureFlags } from '../redux/hooks/useFeatureFlags';
import { authorizationHeaderBuilder } from '../common/helpers/accountHelper';
import {
    ILocationWithDetailsModel,
    SellingChannelNamesModel,
    TOffersByStatusModel,
    TOfferStatusModel,
    TOffersUnionModel,
} from '../@generated/webExpApi/models';
import CustomerRewardsService from '../common/services/customerService/rewards';
import { getDefaultDate } from '../common/helpers/getDefaultActivityHistoryDate';
import { OrderLocationMethod } from '../redux/orderLocation';
import { FACTS_DEPENDENCIES } from '../common/constants/personalization';
import { useLocationUnavailableError } from '../common/hooks/useLocationUnavailableError';
import { useConfiguration } from '../common/hooks/useConfiguration';
import { ORDER_AHEAD_NOT_AVAIABLE_MESSAGE } from '../common/constants/orderAhead';
import { useGtmErrorEvent } from '../common/hooks/useGtmErrorEvent';
import { GtmErrorCategory } from '../common/services/gtmService/types';
import { getContentfulConfigurationExpApi } from '../common/services/contentfulConfiguration/contentfulConfiguration';
import useLocationAvailableTimes from '../common/hooks/useLocationAvailableTimes';
import { MINUTES_IN_SECONDS } from '../common/constants/menuRefresh';
import { initialState } from '../redux/rewards';
import { useLogout } from '../common/hooks/useLogout';

const DataProvider: FC = ({ children }) => {
    const {
        configuration,
        actions: { setConfiguration },
    } = useConfiguration();
    const { isOAEnabled, isDeliveryEnabled, configurationRefreshFrequency } = configuration;

    const {
        currentLocation,
        pickupAddress,
        deliveryAddress,
        method,
        actions: {
            updateDeliveryLocation,
            updatePickupLocation,
            flushDeliveryLocation,
            setDeliveryLocationAvailableTimeSlots,
            setPickupLocationAvailableTimeSlots,
        },
    } = useOrderLocation();

    const {
        products,
        actions: { getDomainMenu },
    } = useDomainMenu();

    const {
        actions: { enqueueError },
    } = useNotifications();

    const {
        actions: { initializePersonalizationDependency },
    } = usePersonalization();

    const { lastOrder } = useSubmitOrder();
    const {
        actions: { setCorrelationId },
    } = useSelectedSell();
    const { setUnavailableTallyItems } = useTallyOrder();

    const { pushLocationUnavailableError } = useLocationUnavailableError();

    const { fetchAndSetLocationTimeSlots } = useLocationAvailableTimes();

    const isProductsLoaded = !!products?.length;

    const initLocationUpdate = async ({ locationId, updateLocation, setAvailableTimeSlots }) => {
        const location = await getLocationById({
            locationId,
        });

        updateLocation(location);

        if (!location.isDigitallyEnabled) {
            pushLocationUnavailableError(location);
        }

        await fetchAndSetLocationTimeSlots({
            locationId,
            setAvailableTimeSlots,
        });
    };

    const initOrderLocation = async () => {
        await Promise.all([
            deliveryAddress
                ? initLocationUpdate({
                      locationId: String(deliveryAddress.pickUpLocation.id),
                      updateLocation: (location: ILocationWithDetailsModel) =>
                          updateDeliveryLocation({ ...deliveryAddress, locationDetails: location }),
                      setAvailableTimeSlots: setDeliveryLocationAvailableTimeSlots,
                  })
                : Promise.resolve(),
            pickupAddress
                ? initLocationUpdate({
                      locationId: pickupAddress.id,
                      updateLocation: updatePickupLocation,
                      setAvailableTimeSlots: setPickupLocationAvailableTimeSlots,
                  })
                : Promise.resolve(),
        ]);

        setUnavailableTallyItems([]);
    };

    useEffect(() => {
        initOrderLocation().then(() => {
            if (!isDeliveryEnabled) {
                flushDeliveryLocation();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeliveryEnabled]);

    const initDomainMenu = () => {
        //  we refresh menu cache at the beginning of each session to keep prices updated
        const isMenuCacheValid = !!sessionStorage.getItem('menuCacheValid');

        if (!isMenuCacheValid) {
            sessionStorage.setItem('menuCacheValid', 'true');
        }
        if (!(isMenuCacheValid && isProductsLoaded)) {
            updateDomainMenu();
        }
    };

    const updateDomainMenu = () => {
        switch (method) {
            case OrderLocationMethod.DELIVERY: {
                const location = deliveryAddress.locationDetails;
                getDomainMenu(location, OrderLocationMethod.DELIVERY);
                break;
            }
            case OrderLocationMethod.PICKUP: {
                getDomainMenu(pickupAddress, OrderLocationMethod.PICKUP);
                break;
            }
            default:
                getDomainMenu(); // Request national menu
        }
    };

    useEffect(() => {
        initDomainMenu();

        // set Correlation ID for Suggested Sell Recommended Items
        setCorrelationId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { isAuthenticated, user, getIdTokenClaims, isLoading: isAuthLoading } = useAuth0();
    const { logoutAndClearCookies } = useLogout();

    const { actions } = useAccount();
    const {
        actions: {
            setRewards,
            setAutoDiscounts,
            setRewardsLoading,
            setRewardsCatalogLoading,
            setRewardsCatalog,
            setRewardsRecommendations,
            setRewardsActivityHistoryLoading,
            setRewardsActivityHistory,
        },
    } = useRewards();
    const {
        actions: { setLoyaltyPointsLoading, setLoyaltyPoints, setLoyaltyError },
        loyalty: { pointsBalance },
    } = useLoyalty();

    const {
        dealId,
        actions: { removeDealFromBag },
    } = useBag();

    const {
        actions: { setOrderHistory, setOrderHistoryLoading },
    } = useOrderHistory();

    const { featureFlags } = useFeatureFlags();

    const [idToken, setIdToken] = useState<string>('');
    const token = authorizationHeaderBuilder(idToken);

    const { getAccount } = useMemo(() => initAccountService(token), [token]);

    const { pushGtmErrorEvent } = useGtmErrorEvent();

    // TODO: waiting answer from auth0 to fix DBBP-36107, now reverting DBBP-35877
    /*  useEffect(() => {
        if (error) {
            enqueueError({
                message: experiencingTechnicalDifficultiesError,
                title: 'sign in',
            });
        }
    }, [error]); */

    useEffect(() => {
        if (featureFlags.account && !isAuthLoading) {
            getIdTokenClaims().then((res) => {
                setIdToken(res?.__raw);

                if (!res?.__raw) {
                    initializePersonalizationDependency(FACTS_DEPENDENCIES.ACCOUNT);
                    initializePersonalizationDependency(FACTS_DEPENDENCIES.REWARDS);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, user, isAuthLoading]);

    const isUserAuthenticated = featureFlags.account && isAuthenticated && user && idToken;
    const metadata = isUserAuthenticated && Object.values(user).find((item) => item.idpCustomerId);
    const hasValidIDPId = isUserAuthenticated && metadata?.idpCustomerId;

    const handleRemoveDealFromBag = (dealId: string, offers: TOffersByStatusModel[] | TOffersUnionModel[]): void => {
        if (dealId && !offers.some((offer) => offer.userOfferId === dealId)) {
            removeDealFromBag();
        }
    };

    useEffect(() => {
        if (isUserAuthenticated) {
            // handle cases when IDP account is not created
            if (!hasValidIDPId) {
                enqueueError({
                    message: 'Please try to sign in again',
                    title: 'Something went wrong',
                });
                logoutAndClearCookies(process.env.NEXT_PUBLIC_APP_URL);
            }

            if (hasValidIDPId) {
                getAccount({
                    sellingChannel: SellingChannelNamesModel.Weboa,
                })
                    .then((res) => {
                        actions.setAccount(res);
                    })
                    .catch(({ status, message }) => {
                        if (status === 404) {
                            logoutAndClearCookies(process.env.NEXT_PUBLIC_APP_URL);
                        }

                        enqueueError({ message });
                    })
                    .finally(() => {
                        initializePersonalizationDependency(FACTS_DEPENDENCIES.ACCOUNT);
                    });

                if (featureFlags.isEpsilonRewardsOn) {
                    const AccountLoyaltyService = new LoyaltyService(authorizationHeaderBuilder(idToken));
                    const RewardsService = new CustomerRewardsService(authorizationHeaderBuilder(idToken));
                    setLoyaltyPointsLoading(true);
                    AccountLoyaltyService.getAccountLoyaltyPoints({
                        sellingChannel: SellingChannelNamesModel.Weboa,
                    })
                        .then((res) => {
                            setLoyaltyPoints(res);
                        })
                        .catch(() => {
                            enqueueError({
                                message: "We couldn't display your points balance. Please try to update the page.",
                            });
                            setLoyaltyPointsLoading(false);
                            setLoyaltyError(
                                new Error("We couldn't display your points balance. Please try to update the page.")
                            );
                        })
                        .finally(() => {
                            initializePersonalizationDependency(FACTS_DEPENDENCIES.REWARDS);
                        });

                    setRewardsActivityHistoryLoading(true);
                    RewardsService.getRewardsActivityHistory(getDefaultDate())
                        .then((res) => {
                            setRewardsActivityHistory(res);
                        })
                        .catch(() => setRewardsActivityHistory([]))
                        .finally(() => setRewardsActivityHistoryLoading(false));
                }

                if (featureFlags.isSessionMRewardsOn || featureFlags.isEpsilonRewardsOn) {
                    // TODO need to refactor after implement common service to add JWT to secure routes
                    const AccountDealsService = new DealsService(authorizationHeaderBuilder(idToken));

                    setRewardsLoading(true);

                    AccountDealsService.getAccountDeals({
                        sellingChannel: SellingChannelNamesModel.Weboa,
                    })
                        .then((res) => {
                            setRewards(res);
                            handleRemoveDealFromBag(dealId, res.offers);
                        })
                        .catch(({ status, message }) => {
                            // TODO: refactor handling error status 404
                            if (status !== 404) {
                                const errMsg = featureFlags.isEpsilonRewardsOn
                                    ? "We couldn't display your rewards. Please try again later."
                                    : message;
                                enqueueError({ message: errMsg });
                                pushGtmErrorEvent({
                                    ErrorCategory: GtmErrorCategory.REWARDS,
                                    ErrorDescription: errMsg,
                                });
                            }
                            setRewards(initialState);
                            removeDealFromBag();
                        });
                }

                if (featureFlags.orderHistory) {
                    const AccountOrderHistoryService = new OrderHistoryService(authorizationHeaderBuilder(idToken));

                    setOrderHistoryLoading(true);

                    AccountOrderHistoryService.getOrderHistory()
                        .then((res) => {
                            setOrderHistory(res);
                        })
                        .catch(({ status, message }) => {
                            // TODO: refactor handling error status 404
                            if (status !== 404) {
                                const errMsg = featureFlags.isEpsilonRewardsOn
                                    ? "We couldn't display your orders. Please try again later."
                                    : message;
                                enqueueError({ message: errMsg });
                            }
                            setOrderHistoryLoading(false);
                        });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, user, idToken, lastOrder?.orderId]);

    useEffect(() => {
        if (featureFlags.isSonicOMSRewardsOn && currentLocation?.id) {
            const AccountDealsService = new DealsService();
            const idpCustomerId = metadata?.idpCustomerId || '';

            setRewardsLoading(true);

            AccountDealsService.getCustomerRewardsV2({
                status: TOfferStatusModel.Open,
                idpCustomerId,
                location: currentLocation.id,
            })
                .then((res) => {
                    if (idpCustomerId) {
                        handleRemoveDealFromBag(dealId, res.offers);
                        setRewards(res);
                    } else {
                        setAutoDiscounts(res.autodiscounts);
                    }
                })
                .catch(({ status, message }) => {
                    if (status !== 404) {
                        enqueueError({ message });
                        pushGtmErrorEvent({
                            ErrorCategory: GtmErrorCategory.REWARDS,
                            ErrorDescription: message,
                        });
                    }
                    setRewards(initialState);
                    removeDealFromBag();
                })
                .finally(() => setRewardsLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation?.id, metadata?.idpCustomerId, lastOrder?.orderId]);

    useEffect(() => {
        if (isUserAuthenticated && hasValidIDPId && featureFlags.isEpsilonRewardsOn) {
            const AccountDealsService = new DealsService(authorizationHeaderBuilder(idToken));

            setRewardsCatalogLoading(true);
            AccountDealsService.getCustomerAccountRewards({
                sellingChannel: SellingChannelNamesModel.Weboa,
                pointsBalance,
            })
                .then((res) => {
                    setRewardsCatalog(res.certificatesByCategory);
                    setRewardsRecommendations(res.recommendations);
                })
                .catch(() => {
                    const msg = "We couldn't display reward certificates. Please try again later.";
                    enqueueError({ message: msg });
                    pushGtmErrorEvent({
                        ErrorCategory: GtmErrorCategory.REWARDS,
                        ErrorDescription: msg,
                    });
                })
                .finally(() => {
                    setRewardsCatalogLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsBalance]);

    useEffect(() => {
        if (!isOAEnabled) {
            enqueueError({ message: ORDER_AHEAD_NOT_AVAIABLE_MESSAGE });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOAEnabled]);

    // contentful configuration ExpApi polling
    useEffect(() => {
        let timeoutId;

        const pollingWorker = async () => {
            try {
                const contentfulConfiguration = await getContentfulConfigurationExpApi();
                setConfiguration(contentfulConfiguration);
            } catch (e) {
                console.error(e);
            } finally {
                const timeout = configurationRefreshFrequency * 1000 * 60;
                timeoutId = setTimeout(pollingWorker, timeout);
            }
        };

        pollingWorker();

        return () => {
            clearTimeout(timeoutId);
        };
    }, [configurationRefreshFrequency, setConfiguration]);

    useEffect(() => {
        const interval = setInterval(updateDomainMenu, MINUTES_IN_SECONDS);
        return () => clearInterval(interval);
    }, []);

    return <>{children}</>;
};

export default DataProvider;
