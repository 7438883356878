import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MENU_VERSION } from '../common/constants/menuVersion';
import { isInRange, prepareWorkingHours } from '../common/helpers/checkoutHelpers';
import { IDateRange } from '../lib/locations';
import * as PdpStore from './pdp';
import {
    compareTallyItems,
    updateStateBagItemCount,
    findDuplicatedItemAndCollapse,
    findItemIndexInBag,
} from '../common/helpers/bagHelper';
import {
    AutoDiscountOfferModel,
    DiscountDetailsTypeModel,
    TallyDiscountModel,
    TallyProductModel,
} from '../@generated/webExpApi';
import { addLineItemId } from '../common/helpers/tallyHelper';
import { OrderLocationMethod } from './orderLocation';
import { generateNextLineItemId } from './selectors/bag';
import { AppDispatch, RootState } from './store';
import { WritableDraft } from 'immer/dist/internal';
import { selectDefaultTallyRelatedItemGroups } from './selectors/domainMenu';
import { getTimezone } from '../common/helpers/getTimezone';
import { calculateBagDiscount } from '../common/helpers/bagHelper/calculateBagDiscount';

/**
 * Modifier item with possible nested modifier groups
 */
interface ModifierItem {
    modifierItemId: string;
    quantity: number;
    modifierGroups?: ModifierGroup;
}

/**
 * Modifier group (ex. Bread, Cheese) with included modifier items
 */
export interface ModifierGroup {
    modifierGroupId: string;
    modifierItems: ModifierItem[];
}

/**
 * High level entry in bag
 */

export type WorkingHours = {
    day: string;
    timeRange: string[];
}[];

export type OrderTimeType = 'asap' | 'future';

export interface IDiscountAppliedItems {
    lineItemId: number;
    menuItemId?: string;
    quantity?: number;
}

export interface IBagLineItemDiscount extends TallyDiscountModel {
    type?: DiscountDetailsTypeModel;
    name?: string;
    applied?: boolean;
    code?: string; // discount Id
    externalProductId?: string; // external discountId
}

export interface IBagLineItem extends TallyProductModel {
    discounts?: IBagLineItemDiscount[];
}

export interface BagState {
    LineItems: IBagLineItem[];
    markedAsRemoved: number[];
    isOpen: boolean;
    tooltipOpen: boolean;
    entryCreated: boolean;
    entryUpdated: boolean;
    entryCreatedWithDeal: boolean;
    entryUpdatedWithDeal: boolean;
    pickupTime: string;
    pickupTimeType: OrderTimeType;
    deliveryTime: string;
    deliveryTimeType: OrderTimeType;
    pickupTimeValues: WorkingHours;
    version: number;
    dealId?: string;
    lastRemovedLineItemId: number;
    lastEdit: number;
    isDiscountLoading?: boolean;
    bagDiscount?: number;
    bagPromocodeDiscount?: number;
    updateItemId?: string;
    isUpdateBagItem?: boolean;
}

export type AddToBagPayload = {
    name?: string;
    category?: string;
    udpRecommendationId?: string;
    bagEntry: Omit<TallyProductModel, 'lineItemId'>;
    sauce?: { [key: string]: string };
};

export type AddDealToBagPayload = {
    id: string;
};

export type DiscountLoadingPayload = boolean;

export type AddToBagWithDefaultsPayload = {
    name?: string;
    category?: string;
    recommendationId?: string;
    productId: string;
};
export type PutToBagPayload = {
    name?: string;
    category?: string;
    pdpTallyItem: PdpStore.PDPTallyItem;
    sauce?: { [key: string]: string };
};
export type EditBagLineItem = { bagEntry: TallyProductModel };
export type MarkAsRemovedPayload = { lineItemId: number };
export type RemoveFromBagPayload = { lineItemId?: number };
export type RemoveAllFromBagPayload = { lineItemIdList: number[]; shouldNotRemoveDeal?: boolean };
export type ToggleIsOpenPayload = { isOpen: boolean };
export type UpdateTooltipPayload = {
    tooltipIsOpen: boolean;
    updated?: boolean;
    created?: boolean;
    createdWithDeal?: boolean;
    updatedWithDeal?: boolean;
};
export type ClearBagPayload = undefined;
export type UpdateBagItemPayload = { bagEntryIndex: number; value: TallyProductModel };
export type UpdateBagItemsPayload = { value: TallyProductModel[] };
export type UpdateBagItemCountPayload = { bagEntryIndex: number; value: number };
export type SetPickupTimePayload = { time?: string; asap?: boolean; method: OrderLocationMethod };
export type InitPickupTimeValuesPayload = { openedTimeRanges: IDateRange[]; timezone: string; prepTime?: number };
export type SetPickupTimeValuesPayload = WorkingHours;
export type ValidateItemsPayload = {
    tallyBagEntries: TallyProductModel[];
    time?: Date;
};

export type ProductsDiscountsItemPayload = {
    discountableQuantity: number;
    lineItemId: number;
    productId: string;
    quantity: number;
    childItems?: {
        discountableQuantity: number;
        lineItemId: number;
        productId: string;
        quantity: number;
    }[];
};

export type ProductsDiscountsPayload = {
    discountName?: string;
    discountId?: string;
    externalPOSDiscountId?: string;
    items: ProductsDiscountsItemPayload[];
    subTotal: number;
    discountAmount: number;
    discountType?: DiscountDetailsTypeModel;
};

export type ProductsAutoDiscountsPayload = {
    discountedProducts: {
        productId: string;
        discount?: AutoDiscountOfferModel;
        discountedPrice?: number;
        discountAmount?: number;
    }[];
};

export const initialState: BagState = {
    LineItems: [],
    markedAsRemoved: [],
    isOpen: false,
    tooltipOpen: false,
    entryCreated: false,
    entryUpdated: false,
    entryCreatedWithDeal: false,
    entryUpdatedWithDeal: false,
    pickupTime: null,
    pickupTimeType: 'asap',
    deliveryTime: null,
    deliveryTimeType: 'asap',
    pickupTimeValues: null,
    dealId: null,
    version: MENU_VERSION,
    lastRemovedLineItemId: null,
    lastEdit: null,
    isDiscountLoading: false,
    bagDiscount: 0,
    updateItemId: null,
    isUpdateBagItem: false,
};

export const reassignLineItemsIds = (state: WritableDraft<BagState>) => {
    const oldNewLineItemsIdsMap: Map<number, number> = new Map();

    state.LineItems = state.LineItems.map((el, i) => {
        const newLineItem = addLineItemId(el, i);

        oldNewLineItemsIdsMap.set(el.lineItemId, newLineItem.lineItemId);

        return newLineItem;
    });

    state.markedAsRemoved = state.markedAsRemoved.map((el) => {
        return oldNewLineItemsIdsMap.get(el);
    });
};

const bagSlice = createSlice({
    name: 'bag',
    initialState,
    reducers: {
        addToBag: (state, action: PayloadAction<AddToBagPayload>): BagState => {
            const { bagEntry, ...rest } = action.payload;

            const nextLineItemId = generateNextLineItemId(state.LineItems);

            const product = addLineItemId(bagEntry, nextLineItemId - 1);

            state.LineItems.push({
                ...product,
                ...rest,
            });

            return state;
        },
        addMultipleToBag: (state, action: PayloadAction<AddToBagPayload[]>): BagState => {
            state.LineItems.push(
                ...action.payload.map(({ bagEntry, ...rest }) => ({
                    ...bagEntry,
                    ...rest,
                }))
            );

            return state;
        },
        addDealToBag: (state, action: PayloadAction<AddDealToBagPayload>): BagState => {
            const { id } = action.payload;

            state.dealId = id;

            return state;
        },
        removeDealFromBag: (state): BagState => {
            state.dealId = null;

            return state;
        },
        editBagLineItem: (state, action: PayloadAction<EditBagLineItem>): BagState => {
            const updatedLineItem = action.payload.bagEntry;

            state.LineItems = state.LineItems.map((lineItem) => {
                if (
                    !lineItem?.childItems &&
                    updatedLineItem?.childItems &&
                    lineItem.lineItemId === updatedLineItem.lineItemId
                ) {
                    const product = addLineItemId(updatedLineItem, updatedLineItem.lineItemId - 1);

                    return { ...product };
                }
                if (lineItem.lineItemId === updatedLineItem.lineItemId) {
                    return updatedLineItem;
                }
                return lineItem;
            });

            return state;
        },
        markAsRemoved: (state, action: PayloadAction<MarkAsRemovedPayload>): BagState => {
            const { lineItemId } = action.payload;
            const index = state.markedAsRemoved.indexOf(lineItemId);
            const alreadyMarkedAsRemoved = index !== -1;

            if (!alreadyMarkedAsRemoved) {
                state.markedAsRemoved.push(lineItemId);
            } else {
                state.markedAsRemoved.splice(index, 1);
            }

            return state;
        },
        clearLastRemovedLineItemId: (state): BagState => {
            state.lastRemovedLineItemId = null;

            return state;
        },
        removeFromBag: (state, action: PayloadAction<RemoveFromBagPayload>): BagState => {
            const { lineItemId } = action.payload;

            const lineItemIndex = state.LineItems.findIndex((lineItem) => lineItem.lineItemId == lineItemId);

            state.LineItems.splice(lineItemIndex, 1);

            state.lastRemovedLineItemId = lineItemId;

            reassignLineItemsIds(state);

            return state;
        },
        removeAllMarkedAsRemovedFromBag: (state): BagState => {
            state.LineItems = state.LineItems.filter(
                (lineItem) => !state.markedAsRemoved.includes(lineItem.lineItemId)
            ).map((el, i) => addLineItemId(el, i));

            state.markedAsRemoved = [];

            return state;
        },
        removeAllFromBag: (state, action: PayloadAction<RemoveAllFromBagPayload>): BagState => {
            const { lineItemIdList, shouldNotRemoveDeal } = action.payload;

            state.LineItems = state.LineItems.filter(
                (lineItem) => lineItemIdList.findIndex((lineItemId) => lineItem.lineItemId === lineItemId) < 0
            );

            reassignLineItemsIds(state);

            if (!shouldNotRemoveDeal) {
                state.dealId = null;
            }

            return state;
        },
        clearBag: (): BagState => {
            return initialState;
        },
        updateBagItem: (state, action: PayloadAction<UpdateBagItemPayload>): BagState => {
            const { bagEntryIndex, value } = action.payload;

            if (bagEntryIndex < 0) return state;

            state.LineItems[bagEntryIndex] = value;

            return state;
        },
        updateBagItems: (state: BagState, action: PayloadAction<UpdateBagItemsPayload>): BagState => {
            const newLineItems = action.payload.value;
            const LineItems: TallyProductModel[] = state.LineItems.map(
                (LineItem): TallyProductModel => {
                    const newLineItem = newLineItems.find(
                        (item) => item.lineItemId === LineItem.lineItemId && item.productId === LineItem.productId
                    );

                    return newLineItem || LineItem;
                }
            );

            return {
                ...state,
                LineItems,
            };
        },
        updateBagCondimentCount: (state, action: PayloadAction<UpdateBagItemCountPayload>): BagState => {
            const { bagEntryIndex, value } = action.payload;
            state.LineItems[bagEntryIndex].quantity = value;
            return state;
        },
        updateBagItemCount: (state, action: PayloadAction<UpdateBagItemCountPayload>): BagState => {
            const { bagEntryIndex, value } = action.payload;
            updateStateBagItemCount(state, bagEntryIndex, value);
            return state;
        },
        updateBagItemsCount: (state, action: PayloadAction<UpdateBagItemCountPayload[]>): BagState => {
            action.payload.forEach(({ bagEntryIndex, value }) => {
                updateStateBagItemCount(state, bagEntryIndex, value);
            });

            return state;
        },
        toggleIsOpen: (state, action: PayloadAction<ToggleIsOpenPayload>): BagState => {
            state.isOpen = action.payload.isOpen;
            return state;
        },
        updateTooltip: (state, action: PayloadAction<UpdateTooltipPayload>): BagState => {
            state.tooltipOpen = action.payload.tooltipIsOpen;
            state.entryUpdated = action.payload.updated || false;
            state.entryCreated = action.payload.created || false;
            state.entryCreatedWithDeal = action.payload.createdWithDeal || false;
            state.entryUpdatedWithDeal = action.payload.updatedWithDeal || false;
            return state;
        },
        setPickupTime: (state, action: PayloadAction<SetPickupTimePayload>): BagState => {
            const { time, asap, method } = action.payload;

            if (method === OrderLocationMethod.PICKUP) {
                state.pickupTime = time !== undefined ? time : state.pickupTime;
                state.pickupTimeType = asap ? 'asap' : 'future';
            }

            if (method === OrderLocationMethod.DELIVERY) {
                state.deliveryTime = time !== undefined ? time : state.pickupTime;
                state.deliveryTimeType = asap ? 'asap' : 'future';
            }

            return state;
        },
        resetOrderTime: (state): BagState => {
            state.pickupTime = initialState.pickupTime;
            state.pickupTimeType = initialState.pickupTimeType;
            state.deliveryTime = initialState.deliveryTime;
            state.deliveryTimeType = initialState.deliveryTimeType;
            state.pickupTimeValues = initialState.pickupTimeValues;

            return state;
        },
        resetPickupTime: (state): BagState => {
            state.pickupTime = initialState.pickupTime;
            state.pickupTimeType = initialState.pickupTimeType;
            state.pickupTimeValues = initialState.pickupTimeValues;

            return state;
        },
        resetDeliveryTime: (state): BagState => {
            state.deliveryTime = initialState.deliveryTime;
            state.deliveryTimeType = initialState.deliveryTimeType;

            return state;
        },
        //TODO: remove after Arby's migrate to use fulfillment timeslots
        initPickupTimeValues: (state, action: PayloadAction<InitPickupTimeValuesPayload>): BagState => {
            const workingHours = prepareWorkingHours(
                action.payload.openedTimeRanges,
                getTimezone(action.payload?.timezone, 'initPickupTimeValues'),
                action.payload.prepTime
            );
            state.pickupTimeValues = workingHours;

            if (!state.pickupTime || !isInRange(state.pickupTime, workingHours)) {
                state.pickupTime = null;
                state.pickupTimeType = 'asap';
            } else {
                state.pickupTimeType = 'future';
            }

            return state;
        },
        setPickupTimeValues: (state, action: PayloadAction<SetPickupTimeValuesPayload>): BagState => {
            state.pickupTimeValues = action.payload;

            return state;
        },
        setLastEdit: (state, action: PayloadAction<number>): BagState => {
            state.lastEdit = action.payload;

            return state;
        },
        clearLastEdit: (state): BagState => {
            state.lastEdit = null;

            return state;
        },
        setDiscountIsLoading: (state, action: PayloadAction<boolean>): BagState => {
            state.isDiscountLoading = action.payload;

            return state;
        },
        setUpdateItemId: (state, action: PayloadAction<string>): BagState => {
            state.updateItemId = action.payload;

            return state;
        },
        setIsUpdateBagItem: (state, action: PayloadAction<boolean>): BagState => {
            state.isUpdateBagItem = action.payload;
            state.updateItemId = null;

            return state;
        },
        setDiscountToBag: (state, action: PayloadAction<ProductsDiscountsPayload>): BagState => {
            const sumDiscountQuantity = action.payload.items.reduce((sum, current) => {
                return (
                    sum +
                    current.discountableQuantity +
                    current?.childItems.reduce((childSum, childCurrent) => {
                        return childSum + childCurrent.discountableQuantity;
                    }, 0)
                );
            }, 0);

            const nextLineItemId = generateNextLineItemId(state.LineItems);

            const LineItems: IBagLineItem[] = state.LineItems.reduce(
                (resultLineItems: IBagLineItem[], currentLineItem: IBagLineItem): IBagLineItem[] => {
                    if (state.markedAsRemoved.includes(currentLineItem.lineItemId)) {
                        return [...resultLineItems, currentLineItem];
                    }

                    const duplicatedItemIndex = resultLineItems.findIndex((it) => {
                        return (
                            compareTallyItems(it, currentLineItem) &&
                            !it?.discounts?.filter(
                                (discount) =>
                                    discount.type === DiscountDetailsTypeModel.OfferReward ||
                                    discount.type === DiscountDetailsTypeModel.PromoCode ||
                                    !discount.type
                            ).length &&
                            !state.markedAsRemoved.includes(it.lineItemId)
                        );
                    });

                    const discountedItem = action.payload.items.find(
                        (item) => item.discountableQuantity > 0 && item.lineItemId === currentLineItem.lineItemId
                    );

                    const currentAutoDiscounts =
                        currentLineItem.discounts?.filter(
                            (discount) => discount.type === DiscountDetailsTypeModel.AutoDiscount
                        ) || [];

                    if (discountedItem?.discountableQuantity === 1 && sumDiscountQuantity === 1) {
                        const discountedLineItem = {
                            ...currentLineItem,
                            discounts: [
                                ...currentAutoDiscounts.map((discount) => ({
                                    ...discount,
                                    quantity: 1,
                                    applied: false,
                                })),
                                {
                                    name: action.payload.discountName,
                                    type: action.payload.discountType,
                                    amount: parseFloat(action.payload.discountAmount.toFixed(2)),
                                    quantity: 1,
                                    applied: true,
                                    code: action.payload.discountId,
                                    externalProductId: action.payload.externalPOSDiscountId,
                                },
                            ],
                            quantity: 1,
                        };

                        if (currentLineItem.quantity > 1) {
                            const newLineItem = addLineItemId(
                                {
                                    ...currentLineItem,
                                    quantity: currentLineItem.quantity - 1,
                                    discounts: currentAutoDiscounts.map((discount) => ({
                                        ...discount,
                                        quantity: currentLineItem.quantity - 1,
                                        applied: true,
                                    })),
                                },
                                nextLineItemId
                            );

                            if (duplicatedItemIndex > -1) {
                                return findDuplicatedItemAndCollapse(
                                    resultLineItems,
                                    newLineItem,
                                    duplicatedItemIndex,
                                    discountedLineItem
                                );
                            }

                            return [discountedLineItem, newLineItem, ...resultLineItems];
                        }

                        return [discountedLineItem, ...resultLineItems];
                    }

                    if (duplicatedItemIndex > -1) {
                        return findDuplicatedItemAndCollapse(resultLineItems, currentLineItem, duplicatedItemIndex);
                    }

                    return [
                        ...resultLineItems,
                        {
                            ...currentLineItem,
                            discounts: currentAutoDiscounts,
                        },
                    ];
                },
                []
            );

            const bagDiscount = calculateBagDiscount(LineItems, action.payload.discountAmount);
            const bagPromocodeDiscount = calculateBagDiscount(LineItems, 0, DiscountDetailsTypeModel.PromoCode);

            state.LineItems = LineItems;
            state.bagDiscount = bagDiscount;
            state.bagPromocodeDiscount = bagPromocodeDiscount;

            return state;
        },
        resetDiscountsOnBag: (state): BagState => {
            const LineItems = state.LineItems.reduce((resultLineItems, currentLineItem) => {
                if (state.markedAsRemoved.includes(currentLineItem.lineItemId)) {
                    return [
                        ...resultLineItems,
                        {
                            ...currentLineItem,
                            discounts: [],
                        },
                    ];
                }

                const duplicatedItemIndex = resultLineItems.findIndex((it) => {
                    return (
                        compareTallyItems(it, currentLineItem) &&
                        !it?.discounts?.filter(
                            (discount) =>
                                discount.type === DiscountDetailsTypeModel.OfferReward ||
                                discount.type === DiscountDetailsTypeModel.PromoCode ||
                                !discount.type
                        ).length &&
                        !state.markedAsRemoved.includes(it.lineItemId)
                    );
                });

                if (duplicatedItemIndex > -1) {
                    return findDuplicatedItemAndCollapse(resultLineItems, currentLineItem, duplicatedItemIndex);
                }

                return [
                    ...resultLineItems,
                    {
                        ...currentLineItem,
                        discounts: currentLineItem.discounts
                            ?.filter((discount) => discount.type === DiscountDetailsTypeModel.AutoDiscount)
                            ?.map((discount) => ({ ...discount, quantity: currentLineItem.quantity })),
                    },
                ];
            }, []);

            const bagDiscount = calculateBagDiscount(LineItems);
            const bagPromocodeDiscount = calculateBagDiscount(LineItems, 0, DiscountDetailsTypeModel.PromoCode);

            state.LineItems = LineItems;
            state.bagDiscount = bagDiscount;
            state.bagPromocodeDiscount = bagPromocodeDiscount;

            return state;
        },
        setAutoDiscountToBag: (state, action: PayloadAction<ProductsAutoDiscountsPayload>): BagState => {
            const LineItems: IBagLineItem[] = state.LineItems.reduce(
                (resultLineItems: IBagLineItem[], currentLineItem: IBagLineItem): TallyProductModel[] => {
                    if (state.markedAsRemoved.includes(currentLineItem.lineItemId)) {
                        return [...resultLineItems, { ...currentLineItem, discounts: [] }];
                    }

                    const autoDiscountForCurrentItem = action.payload.discountedProducts.find((discountedProduct) => {
                        return discountedProduct.productId === currentLineItem.productId;
                    });

                    const currentDiscounts =
                        currentLineItem.discounts?.filter(
                            (discount) =>
                                discount.type === DiscountDetailsTypeModel.OfferReward ||
                                discount.type === DiscountDetailsTypeModel.PromoCode ||
                                !discount.type
                        ) || [];

                    if (autoDiscountForCurrentItem) {
                        return [
                            ...resultLineItems,
                            {
                                ...currentLineItem,
                                discounts: [
                                    ...currentDiscounts,
                                    {
                                        name: autoDiscountForCurrentItem?.discount?.name || '',
                                        type: DiscountDetailsTypeModel.AutoDiscount,
                                        amount: autoDiscountForCurrentItem.discountAmount * currentLineItem.quantity,
                                        quantity: currentLineItem.quantity,
                                        applied: currentDiscounts?.length === 0,
                                        code: autoDiscountForCurrentItem.discount.userOfferId,
                                        externalProductId: autoDiscountForCurrentItem.discount.posDiscountId,
                                    },
                                ],
                            },
                        ];
                    }

                    return [...resultLineItems, currentLineItem];
                },
                []
            );

            const bagDiscount = calculateBagDiscount(LineItems);
            const bagPromocodeDiscount = calculateBagDiscount(LineItems, 0, DiscountDetailsTypeModel.PromoCode);

            state.LineItems = LineItems;
            state.bagDiscount = bagDiscount;
            state.bagPromocodeDiscount = bagPromocodeDiscount;

            return state;
        },
    },
});

export const customizeBagItem = (entry: TallyProductModel) => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const bagEntries = state.bag.LineItems;
    // for case when lineItemId on entry is changed between functions calls
    // we need to ensure that correct lineItemId will be set to tallyItem
    const lineItemId = bagEntries[findItemIndexInBag(bagEntries, entry)]?.lineItemId || entry.lineItemId;
    dispatch(
        PdpStore.actions.putTallyItem({
            lineItemId,
            pdpTallyItem: {
                ...entry,
                lineItemId,
                relatedItemGroups: selectDefaultTallyRelatedItemGroups(state, entry.productId),
            },
        })
    );
};

export const actions = { ...bagSlice.actions, customizeBagItem };

export default bagSlice.reducer;
