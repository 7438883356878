/**
 * @namespace capitalize
 */

/**
 * @method capitalize
 * @memberOf capitalize
 * @description Converts `string` to `capitalize`.
 * @param {string} str The string to convert.
 * @returns {string} Returns the capitalized string.
 * @example
 *
 * capitalize('example')
 * // => 'Example'
 *
 * capitalize('eXample')
 * // => 'Example'
 *
 */

export const capitalize = (str: string) => (str.length ? str[0].toUpperCase() + str.slice(1).toLowerCase() : str);
