import { useMemo } from 'react';
import { useOrderLocation } from '../../redux/hooks';

export const useLocationTimeZone = (): string => {
    const { isPickUp, pickupAddress, deliveryAddress } = useOrderLocation();
    const pickUpLocation = deliveryAddress?.pickUpLocation;

    const locationTimeZone = useMemo(
        () => (isPickUp ? pickupAddress?.timezone : pickUpLocation?.utcOffset?.replace('UTC', '')),
        [pickUpLocation, pickupAddress, isPickUp]
    );

    return locationTimeZone;
};
