import { useEffect } from 'react';
import { AppStore } from '../../redux/store';
import { GTM_MAKE_MY_STORE } from '../services/gtmService/constants';
import { OrderLocationMethod } from '../../redux/orderLocation';

export function useInitApp(store: AppStore): void {
    useEffect(() => {
        const state = store.getState();

        // TODO update GA the delivery location should be pushed GA as well update structure @ga-31325
        if (!state.orderLocation || state.orderLocation.method !== OrderLocationMethod.PICKUP) return;

        const selectedLocation = state.orderLocation.pickupAddress;
        const isLocationDataSentToGA = !!sessionStorage.getItem('isLocationDataSentToGA');
        if (selectedLocation && !isLocationDataSentToGA) {
            sessionStorage.setItem('isLocationDataSentToGA', 'true');
            store.dispatch({
                type: GTM_MAKE_MY_STORE,
                payload: {
                    id: selectedLocation.id,
                    name: selectedLocation.displayName,
                },
            });
        }
    }, [store]);
}
