import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { OrderRequest, OrderResponseModel } from '../@generated/webExpApi';
import { CardInWalletInfo } from './types';

const SUBMIT_ORDER = 'order/submit';
export interface ISubmitOrderState {
    lastOrder: OrderResponseModel;
    lastRequest: OrderRequest;
    isLoading: boolean;
    error: Error;
    unavailableItems: string[];
    isShowAlertModal: boolean;
    cardInWalletInfo: CardInWalletInfo;
}

export interface IFullfilledOrderPayload {
    response: OrderResponseModel;
    request: OrderRequest;
    code?: string;
}

export const initialState = {
    lastOrder: null,
    lastRequest: null,
    isLoading: false,
    error: null,
    unavailableItems: null,
    isShowAlertModal: false,
    cardInWalletInfo: CardInWalletInfo.NOT_SELECTED,
};

const submitOrderSlice = createSlice<ISubmitOrderState, SliceCaseReducers<ISubmitOrderState>>({
    name: SUBMIT_ORDER,
    initialState,
    reducers: {
        pending: (state: ISubmitOrderState): ISubmitOrderState => ({
            ...state,
            isLoading: true,
        }),
        hideAlertModal: (state: ISubmitOrderState): ISubmitOrderState => ({
            ...state,
            isShowAlertModal: false,
        }),
        rejected: (_: ISubmitOrderState, action: PayloadAction<Error>): ISubmitOrderState => ({
            error: action.payload,
            isLoading: false,
            lastOrder: null,
            lastRequest: null,
            unavailableItems: null,
            isShowAlertModal: false,
            cardInWalletInfo: CardInWalletInfo.NOT_SELECTED,
        }),
        fulfilled: (state: ISubmitOrderState, action: PayloadAction<IFullfilledOrderPayload>): ISubmitOrderState => ({
            ...state,
            error: null,
            isLoading: false,
            lastOrder: action.payload.response,
            lastRequest: action.payload.request,
            unavailableItems: null,
            isShowAlertModal: false,
        }),
        setUnavailableItems: (state: ISubmitOrderState, action: PayloadAction<string[]>): ISubmitOrderState => ({
            ...state,
            error: null,
            isLoading: false,
            lastOrder: null,
            lastRequest: null,
            unavailableItems: action.payload,
            isShowAlertModal: true,
        }),
        reset: (state: ISubmitOrderState): ISubmitOrderState => ({
            ...initialState,
            isShowAlertModal: state.isShowAlertModal,
            unavailableItems: state.unavailableItems,
        }),
        setCardInWalletInfo: (state: ISubmitOrderState, action: PayloadAction<CardInWalletInfo>): void => {
            state.cardInWalletInfo = action.payload;
        },
    },
});

export const { actions } = submitOrderSlice;

export default submitOrderSlice.reducer;
