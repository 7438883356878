/** @namespace checkTimeRestrictions*/
import { IHoursModel } from '../../@generated/webExpApi/models/IHoursModel';
import { getOpenedTimeRanges, IDateRange } from '../../lib/locations/resolveOpeningHours';
import { isWithinInterval } from './dateTime';

interface ICheckTimeRestrictions {
    isInTimeRange: boolean;
    currentOpenedTimeRange?: IDateRange;
}

/**
 * @description Method to check time restrictions
 * @method checkTimeRestrictions
 * @param {Array<IHoursModel>|undefined} hours - time ranges
 * @param {string|undefined} storeTimezone - store timezone
 * @author Pvel Shpakovich <pavel.shpakovich@inspirebrands.com>
 * @added 2023-04-11
 * @version 1.0.0
 * @memberOf checkTimeRestrictions
 * @returns {ICheckTimeRestrictions} - current opened time range and boolean flag to know if current time in this time range
 */
export const checkTimeRestrictions = (
    hours: IHoursModel[] | undefined,
    storeTimezone: string | undefined
): ICheckTimeRestrictions => {
    if (!(hours && storeTimezone)) {
        return { isInTimeRange: true };
    }
    const timeNow = new Date(Date.now());
    const openedTimeRanges = getOpenedTimeRanges(hours, storeTimezone);
    const currentOpenedTimeRange = openedTimeRanges.find((range) => isWithinInterval(timeNow, range));

    const isInTimeRange = !!currentOpenedTimeRange;

    return { isInTimeRange, currentOpenedTimeRange };
};
