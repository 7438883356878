import { useMemo } from 'react';
import { authorizationHeaderBuilder } from '../helpers/accountHelper';
import OrderHistoryService from '../services/customerService/orders';
import { useIDToken } from './useIDToken';

export const useOrderHistoryService = (): OrderHistoryService => {
    const { idToken } = useIDToken();
    const orderHistoryService = useMemo(() => {
        return new OrderHistoryService(authorizationHeaderBuilder(idToken));
    }, [idToken]);

    return orderHistoryService;
};
