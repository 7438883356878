import { IProductFields } from '../../@generated/@types/contentful';
import {
    ItemModel,
    ItemModifierModel,
    IMenuModel,
    OtherPriceTypeEnumModel,
    TallyModifierModel,
} from '../../@generated/webExpApi';

import { getDiscountAvailability } from '../../common/helpers/discountHelper';
import { IGlobalContentfulProps, IProductItemById } from '../../common/services/globalContentfulProps';
import { LocationWithDetailsModel } from '../../common/services/locationService/types';
import { PDPTallyItem } from '../../redux/pdp';
import { IProductsWithRootProductId } from '../../redux/selectors/domainMenu';
import {
    ActionCodesEnum,
    IDefaultModifier,
    IDisplayModifierGroup,
    IDisplayProductDetails,
    IDomainProductItem,
    ISelectedExtraItem,
    ISelectedModifier,
    ModifierCardSelectorType,
    PDPTallyItemModifierGroup,
    IProducts,
} from '../../redux/types';
import { IProductDetailsPagePath } from '../contentfulDelivery';
import { NO_SAUCE_CATEGORY_ID } from '../../common/constants/product';
import { InspireCmsEntry } from '../../common/types';
import { getModifications } from '../../common/helpers/getModifications';
import { SHOULD_USE_CURRENT_PRICE_FOR_MEAL } from './constants';

export const formatPrice = (price: number): string =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(price);

export const formatOfferPrice = (price: number): string => (price === 0 ? 'Free' : formatPrice(price));

export const formatNumber = (amount: number): string => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export function getProductItemsFromMenu(menu: IMenuModel): IProducts {
    const { products } = menu;

    return (
        products &&
        Object.keys(products).reduce((prev, current) => {
            const acc = prev;

            Object.keys(products[current].items || {}).map((item) => {
                acc[products[current].items[item].id] = {
                    ...products[current].items[item],
                    isSaleable: products[current].isSaleable,
                };
            });
            return acc;
        }, {})
    );
}

export function getProductItemsWithRootIdFromMenu(menu: IMenuModel): IProductsWithRootProductId {
    const { products } = menu;

    return (
        products &&
        Object.keys(products).reduce<IProductsWithRootProductId>((prev, current) => {
            const acc = prev;

            Object.keys(products[current].items || {}).map((item) => {
                acc[products[current].items[item].id] = {
                    ...products[current].items[item],
                    rootProductId: current,
                    menuItemName: products[current].name,
                    isSaleable: products[current].isSaleable,
                };
            });
            return acc;
        }, {})
    );
}

export function getCheapestPriceFromProduct(product: ItemModel, location: LocationWithDetailsModel): number | null {
    const productPrice = product?.price;

    if (!productPrice || !location) {
        return null;
    }
    const isMeal = product?.hasChildItems;

    const currentPrice = productPrice?.currentPrice;
    let resultPrice = null;
    if (isMeal) {
        const mealPrice = productPrice?.otherPrices?.[OtherPriceTypeEnumModel.Meal]?.price;
        resultPrice = SHOULD_USE_CURRENT_PRICE_FOR_MEAL || mealPrice === 0 ? currentPrice : mealPrice;
    } else {
        const otherPrices = productPrice.otherPrices;
        const discountPrices: number[] = [];
        if (otherPrices) {
            const discountAvailability = getDiscountAvailability(location);
            Object.keys(otherPrices).map((key) => {
                Number.isFinite(otherPrices[key]?.price) &&
                    otherPrices[key]?.price >= 0 &&
                    discountAvailability[key] &&
                    discountPrices.push(otherPrices[key]?.price);
            });
        }

        resultPrice = Math.min(currentPrice, ...discountPrices);
    }
    return Number.isFinite(resultPrice) ? resultPrice : null;
}

export function getFormattedPriceFromProduct(product: ItemModel, location: LocationWithDetailsModel): string {
    if (!product) return null;
    const price = getCheapestPriceFromProduct(product, location);
    if (price === 0) return null;
    return formatPrice(price);
}

export function getModifierPrice(modifier: ItemModifierModel, product: ItemModel): number {
    const override = modifier?.overridePrice?.currentPrice;
    const price = product?.price?.currentPrice;
    // TODO handle otherPrice
    return [override, price].find((it) => !isNaN(it)) || 0; // BE reccomendation: if the price is not set, assume that it is 0
}

export function getModifierSectionType(modifier: ItemModifierModel): string {
    return modifier?.tags?.COMBO_SECTION;
}
export function getCaloriesFromProduct(product: ItemModel): number | null {
    const calories = product?.nutrition?.totalCalories;

    return Number.isFinite(calories) ? calories : null;
}

export function getCalorieRangeFromProduct(product: ItemModel): { min: string; max: string } | null {
    const calorieRange = product?.nutrition?.calorieRange;

    return calorieRange?.min && calorieRange?.max ? calorieRange : null;
}

type ModifiersById = { [key: string]: TallyModifierModel };

const getModifiersByIdFromModifierGroups = (modifierGroups: Array<PDPTallyItemModifierGroup>): ModifiersById => {
    return (
        modifierGroups &&
        modifierGroups.reduce<ModifiersById>((acc, mg) => {
            const prev = acc;

            mg.modifiers?.forEach((modifier) => {
                prev[modifier.productId] = modifier;
            });
            return prev;
        }, {})
    );
};

export const getModifierQuantityForSingleProduct = (
    modifierId: string,
    tallyItem: PDPTallyItem,
    parentModifierGroupId?: string,
    parentModifierId?: string
): number => {
    let groups: PDPTallyItemModifierGroup[];
    const isNestedModifier = parentModifierGroupId && parentModifierId;

    if (isNestedModifier) {
        const parentGroup = tallyItem.modifierGroups?.find((it) => it.productId === parentModifierGroupId);
        const parentModifier = parentGroup?.modifiers?.find((it) => it.productId === parentModifierId);
        groups = parentModifier?.modifierGroups;
    } else {
        groups = tallyItem?.modifierGroups;
    }
    const modifiersById = getModifiersByIdFromModifierGroups(groups);

    return modifiersById?.[modifierId]?.quantity || 0;
};

export const getRelatedModifierQuantityForSingleProduct = (
    modifierId: string,
    { relatedItemGroups }: PDPTallyItem
): number => {
    const modifiersById = getModifiersByIdFromModifierGroups(relatedItemGroups);

    return modifiersById?.[modifierId]?.quantity || 0;
};

export const getQuantityByProductIdAndTallyItem = (
    productId: string,
    tallyItem: PDPTallyItem,
    childIndex: number
): number => {
    const childTallyItem = tallyItem?.childItems?.find((i, index) => index === childIndex && i.productId === productId);

    // combo
    if (childTallyItem) {
        const quantity = childTallyItem ? childTallyItem.quantity : 0;
        return quantity;
    }

    //promo
    if (tallyItem.childItems) {
        const childPromoSandwichModifiers = getModifiersByIdFromModifierGroups(
            tallyItem.childItems?.[childIndex]?.modifierGroups
        );

        if (childPromoSandwichModifiers) {
            return childPromoSandwichModifiers?.[productId]?.quantity || 0;
        }
    }

    // single
    return getModifierQuantityForSingleProduct(productId, tallyItem);
};

export function getProductPagePathByProductId(
    productId: string,
    productDetailsPagePaths: IProductDetailsPagePath[]
): IProductDetailsPagePath | undefined {
    if (!productId) return;

    return productDetailsPagePaths.find((p) => p.productIds.includes(productId));
}

export function getProductPath(
    product: ItemModel | null,
    productDetailsPagePaths: IProductDetailsPagePath[]
): { href: string; as: string } | undefined {
    if (!product) return;

    let pdp: IProductDetailsPagePath = productDetailsPagePaths.find(
        (p) =>
            p.productIds.includes(product.id) && product?.categoryIds?.some((item) => p.menuCategoryIds.includes(item))
    );

    if (!pdp) {
        product.upsellRelationships?.SIZE?.forEach((id) => {
            pdp = pdp || productDetailsPagePaths.find((p) => p.productIds.includes(id));
        });
    }

    if (!pdp) return;

    return {
        href: '/menu/[menuCategoryUrl]/[...nestedCategoryOrPDPPageUrl]',
        as: pdp.productPath,
    };
}

// TODO: probably not the best place to put this function
// move to contentfulProduct lib?
/**
 * Retrieves related product from Contentful products
 */
export function getRelatedProduct(
    product: ItemModel,
    productsById: IProductItemById
): InspireCmsEntry<IProductFields> | undefined {
    let relatedProduct: InspireCmsEntry<IProductFields> | undefined;
    product?.upsellRelationships?.SIZE?.forEach((id) => {
        relatedProduct = relatedProduct || productsById[id];
    });
    return relatedProduct;
}

/**
 * @method getRelatedDomainProduct
 * @param {IDomainProductItem} domainProduct
 * @param {IProducts} domainProducts
 * @author Andrei Shubich <ashubich@inspirebrands.com>
 * @added 2023-04-04
 * @version 1.0
 * @returns {IDomainProductItem | undefined}
 */
export const getRelatedDomainProduct = (
    domainProduct: IDomainProductItem,
    domainProducts: IProducts
): IDomainProductItem | undefined =>
    domainProduct?.upsellRelationships?.SIZE?.reduce<IDomainProductItem>((relatedProduct, id) => {
        return relatedProduct || domainProducts[id];
    }, null);

export function getUpgradeMealPath(
    domainProduct: ItemModel,
    productDetailsPagePaths: IProductDetailsPagePath[]
): {
    path: string;
    mealId: string;
} {
    const upgradeToMealProductIds = domainProduct?.upsellRelationships?.COMBO;
    const makeItAMealProductDetailsPage =
        upgradeToMealProductIds &&
        productDetailsPagePaths.find((pdpPath) => pdpPath.productIds.includes(upgradeToMealProductIds[0]));

    return {
        path:
            makeItAMealProductDetailsPage &&
            `/menu/${makeItAMealProductDetailsPage.menuCategoryUrl}/${makeItAMealProductDetailsPage.nestedCategoryOrPDPPageUrl}`,
        mealId: upgradeToMealProductIds && upgradeToMealProductIds[0],
    };
}

export const isModifierVisible = (modifier: ItemModifierModel): boolean => {
    const { defaultQuantity, min, max } = modifier;
    // Do not display if modifier cannot be changed
    return !(defaultQuantity === min && defaultQuantity === max);
};

export const getFormattedModifications = (
    addedModifiers: ISelectedModifier[],
    removedDefaultModifiers: IDefaultModifier[],
    includeQuantity?: boolean,
    noItemProductIds: string[] = []
): string => getModifications({ addedModifiers, removedDefaultModifiers, includeQuantity, noItemProductIds });

export const getFormattedExtrasModifications = (selectedExtras: ISelectedExtraItem[]): string => {
    return selectedExtras.map((item) => `${item.name} (+${formatPrice(item.price)})`).join(', ');
};

export const getPriceAndCaloriesWithAddedRemovedModifiers = (
    addedModifiers: ISelectedModifier[],
    removedDefaultModifiers: IDefaultModifier[],
    defaultPrice: number,
    defaultCalories: number,
    quantity: number
): {
    resultPrice: number;
    resultCalories: number;
    modifiersPrice: number;
} => {
    let modifiersPrice = 0;

    if (!quantity) {
        return {
            resultPrice: defaultPrice,
            resultCalories: defaultCalories,
            modifiersPrice,
        };
    }

    modifiersPrice = (addedModifiers || []).reduce(
        (result, current) => (!isNaN(current.price) ? result + current.price : result),
        0
    );

    const resultPrice = defaultPrice + modifiersPrice;

    const addedCalories =
        addedModifiers?.reduce(
            (result, current) => (!isNaN(current.calories) ? result + current.calories : result),
            0
        ) || 0;

    const removedCalories =
        removedDefaultModifiers?.reduce(
            (result, current) => (!isNaN(current.calories) ? result + current.calories : result),
            0
        ) || 0;

    const resultCalories = defaultCalories + addedCalories - removedCalories;

    return {
        resultPrice,
        resultCalories,
        modifiersPrice,
    };
};

export const getModifierCardType = (
    domainProduct: IDomainProductItem,
    selectorType: ModifierCardSelectorType
): ModifierCardSelectorType => {
    let result = selectorType;
    //Adjust card type if it is "No sauce" category
    if (domainProduct?.categoryIds?.some((item) => item === NO_SAUCE_CATEGORY_ID)) {
        result = 'default';
    }
    return result;
};

export const getDisplayModifierGroupDefaultModifiersCount = (modifierGroup: IDisplayModifierGroup) => {
    return (
        modifierGroup?.modifiers?.reduce((res, modifier) => {
            return res + (modifier.displayProductDetails.defaultQuantity || 0);
        }, 0) || 0
    );
};

export const getTallyModifierGroupModifiersCount = (modifierGroup: PDPTallyItemModifierGroup) => {
    return (
        modifierGroup?.modifiers?.reduce((res, modifier) => {
            return res + (modifier.quantity || 0);
        }, 0) || 0
    );
};

export const isModifierItemGroup = (modifier: ItemModifierModel) => {
    return !!modifier?.itemModifiers;
};

export const isModifierItemGroupFromDisplayProductDetails = (modifier: IDisplayProductDetails) => {
    return !!modifier?.modifiers;
};

export const isNoneActionCode = (actionCode: string) => {
    return actionCode === ActionCodesEnum.NO;
};

export const getActionCodeName = (actionCode: string) => {
    const actionCodeToName = {
        [ActionCodesEnum.EASY]: 'Easy',
        [ActionCodesEnum.EXTRA]: 'Extra',
        [ActionCodesEnum.REGULAR]: 'Regular',
        [ActionCodesEnum.ADD]: 'Regular',
        [ActionCodesEnum.NO]: 'None',
    };

    return actionCodeToName[actionCode] || actionCode;
};

export const getDefaultNestedModifier = <T extends { defaultQuantity?: number; actionCodes?: string[] }>(
    modifiers: T[] = []
): T => {
    return (
        modifiers.find((m) => m.defaultQuantity > 0) ||
        modifiers.find((m) => m.actionCodes?.[0] === ActionCodesEnum.ADD) ||
        modifiers.find((m) => !isNoneActionCode(m.actionCodes?.[0]))
    );
};

export const getDefaultSizeModifier = (
    modifiers: ItemModifierModel[],
    productsById: IGlobalContentfulProps['productsById']
) => {
    return (
        modifiers.find(({ defaultQuantity }) => defaultQuantity === 1) ||
        modifiers.find(({ itemId }) => productsById[itemId]?.fields?.isVisible)
    );
};

export const INTERNAL_REGULAR_MODIFIER_ID = 'REGULAR';
