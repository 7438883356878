import { useRouter } from 'next/router';
import { IBreadcrumbsPath } from '../../components/atoms/Breadcrumbs';

export const useBreadcrumbs = (): Array<IBreadcrumbsPath> => {
    const { asPath, pathname } = useRouter();

    const separator = '/';
    const splitter = (path: string) => path.split(separator).filter(Boolean);
    const concatenator = (arr: string[], i: number) => `${separator}${arr.slice(0, i + 1).join(separator)}`;
    const asSteps = splitter(asPath);
    const hrefSteps = splitter(pathname);

    return asSteps.map((asStep, i) => ({
        as: concatenator(asSteps, i),
        href: concatenator(hrefSteps, i),
        title: asStep.split('-').join(' '),
    }));
};
